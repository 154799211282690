import React from "react";

const DashboardIllustration = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" width="1029.56255" height="548.69495" viewBox="0 0 1029.56255 548.69495">
        <path
            d="M133.37467,722.76736l.21351-1.12612c.04736-.24754,4.90753-24.90966,23.12635-39.22042,18.21964-14.3114,43.33085-13.19135,43.58186-13.17828l1.145.05909-.21358,1.12564c-.04737.24754-4.90763,24.90918-23.12636,39.22042-18.21964,14.3114-43.33084,13.19135-43.58186,13.17828ZM157.95,683.994c-15.40414,12.09937-20.95072,31.88327-22.13763,36.85812,5.11571.02181,25.6644-.68921,41.0536-12.77757,15.38743-12.08706,20.94624-31.88051,22.13762-36.85812C193.88493,671.19461,173.33832,671.90627,157.95,683.994Z"
            transform="translate(-85.21873 -175.65252)"
            fill="#f1f1f1"
        />
        <path d="M165.19537,689.48163c-8.34609,21.49794-30.31721,33.0224-30.31721,33.0224s-8.43942-23.33063-.09333-44.82857S165.102,644.65305,165.102,644.65305,173.54146,667.98369,165.19537,689.48163Z" transform="translate(-85.21873 -175.65252)" fill="#f1f1f1" />
        <path
            d="M1084.55991,722.826c-.251.01307-25.36223,1.13312-43.58186-13.17828-18.21874-14.31124-23.079-38.97288-23.12636-39.22042l-.21359-1.12564,1.145-.05909c.251-.01307,25.36223-1.13312,43.58186,13.17828,18.21882,14.31076,23.079,38.97288,23.12636,39.22042l.2135,1.12612Zm-64.484-51.60955c1.19139,4.97761,6.75019,24.77106,22.13763,36.85812,15.38919,12.08836,35.93789,12.79938,41.05359,12.77757-1.1869-4.97485-6.73348-24.75875-22.13762-36.85812C1045.74117,671.90627,1025.19457,671.19461,1020.076,671.21642Z"
            transform="translate(-85.21873 -175.65252)"
            fill="#f1f1f1"
        />
        <path d="M1053.88412,689.48163c8.34609,21.49794,30.31722,33.0224,30.31722,33.0224s8.43942-23.33063.09333-44.82857-30.31722-33.02241-30.31722-33.02241S1045.538,667.98369,1053.88412,689.48163Z" transform="translate(-85.21873 -175.65252)" fill="#f1f1f1" />
        <path d="M675.31479,717.62706l-14.5923-6.1443-10.01026-73.15138H517.40762L506.55725,711.1839l-13.05512,6.52746a3.10016,3.10016,0,0,0,1.38657,5.873H674.11213A3.1,3.1,0,0,0,675.31479,717.62706Z" transform="translate(-85.21873 -175.65252)" fill="#e6e6e6" />
        <path d="M912.20621,648.06385H257.377a12.97344,12.97344,0,0,1-12.9443-12.97332V542.751h680.7178v92.33952A12.97356,12.97356,0,0,1,912.20621,648.06385Z" transform="translate(-85.21873 -175.65252)" fill="#ccc" />
        <path d="M925.835,586.39288h-682V191.29161a15.6572,15.6572,0,0,1,15.63964-15.63909H910.1952A15.65735,15.65735,0,0,1,925.835,191.29161Z" transform="translate(-85.21873 -175.65252)" fill="#3f3d56" />
        <path d="M885.10186,557.71639H284.56818a12.07023,12.07023,0,0,1-12.057-12.05667v-329.274a12.07087,12.07087,0,0,1,12.057-12.05741H885.10186a12.07088,12.07088,0,0,1,12.057,12.05741v329.274A12.07024,12.07024,0,0,1,885.10186,557.71639Z" transform="translate(-85.21873 -175.65252)" fill="#fff" />
        <path d="M1113.255,724.34746l-1026.44821,0a1.56682,1.56682,0,0,1-1.53909-1.13363,1.52912,1.52912,0,0,1,1.47725-1.91893l1026.385,0a1.61535,1.61535,0,0,1,1.61617,1.19368A1.52819,1.52819,0,0,1,1113.255,724.34746Z" transform="translate(-85.21873 -175.65252)" fill="#ccc" />
        <rect x="219.58182" y="153.2027" width="98.80647" height="9.27916" fill="#e5e5e5" />
        <rect x="219.58182" y="175.70578" width="98.80647" height="9.27916" fill="#e5e5e5" />
        <rect x="219.58182" y="198.70578" width="98.80647" height="9.27916" fill="#e5e5e5" />
        <rect x="219.58182" y="221.70578" width="98.80647" height="9.27916" fill="#e5e5e5" />
        <rect x="242.98506" y="250.93227" width="52" height="8.05267" fill="#6c63ff" />
        <rect x="471.19198" y="210.75367" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <path d="M557.35549,449.63117h-.94478V443.592h.94478Zm0-12.07807h-.94478v-6.03916h.94478Zm0-12.07831h-.94478v-6.03893h.94478Zm0-12.07808h-.94478v-6.03916h.94478Zm0-12.07808h-.94478v-6.03916h.94478Z" transform="translate(-85.21873 -175.65252)" fill="#e5e5e5" />
        <rect x="471.19198" y="280.01781" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <rect x="574.64601" y="210.75367" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <path d="M660.80952,449.63117h-.94479V443.592h.94479Zm0-12.07807h-.94479v-6.03916h.94479Zm0-12.07831h-.94479v-6.03893h.94479Zm0-12.07808h-.94479v-6.03916h.94479Zm0-12.07808h-.94479v-6.03916h.94479Z" transform="translate(-85.21873 -175.65252)" fill="#e5e5e5" />
        <rect x="574.64601" y="280.01781" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <rect x="626.83111" y="155.3061" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <path
            d="M712.99462,449.86668h-.94478V444.063h.94478Zm0-11.6073h-.94478V432.4555h.94478Zm0-11.60753h-.94478V420.8482h.94478Zm0-11.6073h-.94478V409.2409h.94478Zm0-11.6073h-.94478v-5.80388h.94478Zm0-11.60753h-.94478v-5.80365h.94478Zm0-11.60731h-.94478v-5.80365h.94478Zm0-11.6073h-.94478v-5.80365h.94478Zm0-11.60753h-.94478v-5.80365h.94478Zm0-11.6073h-.94478v-5.80365h.94478Z"
            transform="translate(-85.21873 -175.65252)"
            fill="#e5e5e5"
        />
        <rect x="626.83111" y="280.01781" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <rect x="678.49585" y="132.63125" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <path
            d="M764.65936,449.88836h-.94479v-5.7822h.94479Zm0-11.56417h-.94479V432.542h.94479Zm0-11.56416h-.94479v-5.7822h.94479Zm0-11.56417h-.94479v-5.7822h.94479Zm0-11.56417h-.94479v-5.7822h.94479Zm0-11.56416h-.94479v-5.7822h.94479Zm0-11.56417h-.94479v-5.7822h.94479Zm0-11.56417h-.94479V363.157h.94479Zm0-11.56416h-.94479v-5.7822h.94479Zm0-11.56417h-.94479v-5.7822h.94479Zm0-11.56417h-.94479v-5.7822h.94479Zm0-11.56417h-.94479v-5.78219h.94479Z"
            transform="translate(-85.21873 -175.65252)"
            fill="#e5e5e5"
        />
        <rect x="678.49585" y="280.01781" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <rect x="523.15519" y="172.78464" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <path
            d="M609.3187,450.17576h-.94478V444.6812h.94478Zm0-10.98936h-.94478v-5.49456h.94478Zm0-10.98936h-.94478v-5.49456h.94478Zm0-10.98936h-.94478v-5.49456h.94478Zm0-10.98936h-.94478v-5.49456h.94478Zm0-10.98936h-.94478v-5.49457h.94478Zm0-10.98936h-.94478V378.745h.94478Zm0-10.98936h-.94478v-5.49457h.94478Zm0-10.98936h-.94478v-5.49457h.94478Z"
            transform="translate(-85.21873 -175.65252)"
            fill="#e5e5e5"
        />
        <rect x="523.15519" y="280.01781" width="0.94479" height="2.83436" fill="#e5e5e5" />
        <path d="M817.06089,459.82073H504.27977a.97891.97891,0,0,1-.97886-.97886v-149.916a.97886.97886,0,0,1,1.95772,0V457.863H817.06089a.97886.97886,0,1,1,0,1.95772Z" transform="translate(-85.21873 -175.65252)" fill="#3f3d56" />
        <polygon points="575.548 212.069 523.544 173.118 472.251 211.537 471.077 209.97 523.544 170.672 575.3 209.438 626.731 153.155 626.938 153.071 678.817 132.168 679.549 133.985 627.876 154.804 575.548 212.069" fill="#3f3d56" />
        <circle cx="471.66442" cy="210.75355" r="5.87317" fill="#6c63ff" />
        <circle cx="523.54411" cy="172.57792" r="5.87317" fill="#6c63ff" />
        <circle cx="575.42381" cy="210.75355" r="5.87317" fill="#6c63ff" />
        <circle cx="627.3035" cy="153.97954" r="5.87317" fill="#6c63ff" />
        <circle cx="679.1832" cy="132.44458" r="5.87317" fill="#6c63ff" />
        <rect x="347.26975" y="28.67555" width="2" height="353.38818" fill="#e5e5e5" />
        <path d="M905.98772,554.09977a7.34552,7.34552,0,0,0-1.961-11.09143l4.882-25.64286-12.52095,5.218L893.6713,546.1344a7.38532,7.38532,0,0,0,12.31642,7.96537Z" transform="translate(-85.21873 -175.65252)" fill="#ffb7b7" />
        <polygon points="883.2 535.797 872.598 535.797 867.554 494.903 883.202 494.904 883.2 535.797" fill="#ffb7b7" />
        <path d="M971.1222,721.727l-34.18529-.00126v-.43239a13.30659,13.30659,0,0,1,13.30587-13.30566h.00084l20.87921.00085Z" transform="translate(-85.21873 -175.65252)" fill="#2f2e41" />
        <polygon points="825.26 535.797 814.658 535.797 809.614 494.903 825.262 494.904 825.26 535.797" fill="#ffb7b7" />
        <path d="M910.58783,721.727l-34.18529-.00126v-.43239a13.30659,13.30659,0,0,1,13.30587-13.30566h.00084l20.87921.00085Z" transform="translate(-85.21873 -175.65252)" fill="#2f2e41" />
        <polygon points="824.391 363.083 814.014 386.99 805.453 524.403 827.073 523.538 832.978 458.033 851.192 399.689 861.664 464.733 865.988 521.808 883.877 522.583 891.005 363.083 824.391 363.083" fill="#2f2e41" />
        <path d="M961.48266,420.76584l-30.07691.75236-17.65821,9.065-.96906,66.87968-11.0131,46.17488s68.811,4.73581,78.32355-2.1824L968.84683,488.704,983.925,431.69483Z" transform="translate(-85.21873 -175.65252)" fill="#6c63ff" />
        <path d="M916.96023,431.62873l-3.21269-1.04552s-10.19135,6.54059-9.22166,22.35367c0,0-.382,8.1827-.01724,13.62852.41834,6.24634-12.10688,68.31736-12.10688,68.31736h18.16032l7.783-56.21048Z" transform="translate(-85.21873 -175.65252)" fill="#6c63ff" />
        <path d="M983.45379,554.96455a7.34552,7.34552,0,0,1,1.961-11.09143l-4.882-25.64286,12.52095,5.218,2.71649,23.55092a7.38532,7.38532,0,0,1-12.31642,7.96537Z" transform="translate(-85.21873 -175.65252)" fill="#ffb7b7" />
        <path d="M979.39949,432.4935l3.2127-1.04551s10.19134,6.54059,9.22166,22.35367c0,0,.382,8.1827.01724,13.62852-.41834,6.24634,5.18866,68.31736,5.18866,68.31736H978.87944l-7.783-56.21049Z" transform="translate(-85.21873 -175.65252)" fill="#6c63ff" />
        <path d="M968.86587,392.707a20.84977,20.84977,0,1,1-20.84978-20.84975h0a20.79787,20.79787,0,0,1,20.84975,20.74588Q968.866,392.655,968.86587,392.707Z" transform="translate(-85.21873 -175.65252)" fill="#ffb7b7" />
        <path
            d="M927.23562,364.702c2.44244-5.25207,7.169-4.21369,11.30278-2.43339,5.23475-1.16114,10.21334-4.63745,15.85348-2.58158,5.55578,8.08056,24.209,5.70314,20.24058,18.85835-.00519,3.15227,5.93159,1.31744,4.89514,6.48014,3.14494,9.93555-11.35179,28.752-19.674,24.78956,2.05822-3.77215,6.76-12.34-.37128-13.18978-15.34037,14.27367-1.58274-27.18028-20.85755-15.1248C932.24272,387.341,923.50556,370.544,927.23562,364.702Z"
            transform="translate(-85.21873 -175.65252)"
            fill="#2f2e41"
        />
    </svg>
);

export { DashboardIllustration };
