import { gql } from "@apollo/client";

const INSERT_CAR_SELECTOR_CONFIG = gql`
    mutation re_rentaleye_car_selector_config_insert_input($config: re_rentaleye_car_selector_config_insert_input!) {
        insert_re_rentaleye_car_selector_config_one(object: $config, on_conflict: { constraint: car_selector_config_pkey, update_columns: [players] }) {
            id
        }
    }
`;

const GET_CAR_SELECTOR_CUSTOM_FIELDS = gql`
    query getCustomFields {
        re_rentaleye_car_selector_config {
            id
            players
            field_name
            operation
            filters
        }
    }
`;

const DELETE_CUSTOM_FIELD = gql`
    mutation deleteField($id: uuid!) {
        delete_re_rentaleye_car_selector_config_by_pk(id: $id) {
            id
        }
    }
`;

export { INSERT_CAR_SELECTOR_CONFIG, GET_CAR_SELECTOR_CUSTOM_FIELDS, DELETE_CUSTOM_FIELD };
