function UserParams({
    key,
    login,
    ctryid,
    channel1,
    mth_code,
    duration,
    fl_default,
    fl_demo,
    fl_rw,
    flag_ser,
    flag_tyr,
    flag_bre,
    flag_rep,
    flag_ins,
    flag_fin,
    flag_win,
    flag_co2,
    flag_evb,
    customer_slot_1,
    customer_slot_2,
    customer_slot_3,
    customer_slot_4,
    customer_slot_5,
    customer_slot_6,
    customer_slot_7,
    customer_slot_8,
}) {
    this.key = key;
    this.login = login;
    this.ctryid = ctryid;
    this.channel1 = channel1;
    this.mth_code = mth_code;
    this.duration = duration;
    this.fl_default = fl_default;
    this.fl_demo = fl_demo;
    this.fl_rw = fl_rw;
    this.customer_slot_1 = customer_slot_1;
    this.customer_slot_2 = customer_slot_2;
    this.customer_slot_3 = customer_slot_3;
    this.customer_slot_4 = customer_slot_4;
    this.customer_slot_5 = customer_slot_5;
    this.customer_slot_6 = customer_slot_6;
    this.customer_slot_7 = customer_slot_7;
    this.customer_slot_8 = customer_slot_8;
    this.flag_ser = flag_ser;
    this.flag_tyr = flag_tyr;
    this.flag_bre = flag_bre;
    this.flag_rep = flag_rep;
    this.flag_ins = flag_ins;
    this.flag_fin = flag_fin;
    this.flag_win = flag_win;
    this.flag_co2 = flag_co2;
    this.flag_evb = flag_evb;
    return this;
}

export { UserParams };
