import React, { useContext, useMemo } from "react";
import { ArrowDownIcon } from "../Icons/Arrows";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import * as Md from "../../md/full";
import { useAppContext } from "../../contexts/AppContext";
import FiltersContext from "../../contexts/Filters";
import { Popover } from "antd";

const ButtonFilter = ({ onClick = () => {}, isActive = false, title }) => {
    const { currentFilter } = useContext(FiltersContext);
    const { result } = useExecutionDataView(
        {
            execution: { seriesBy: [Md.ListPrice.Avg, Md.Ps.Avg, Md.Co2malus.Avg, Md.Kw.Avg], slicesBy: [Md.Custmixindex], filters: currentFilter },
        },
        [currentFilter],
    );
    const { getCustlabelByCustId } = useAppContext();

    const staticElements = useMemo(
        () =>
            result
                ?.meta()
                .allHeaders()[0][0]
                // @ts-ignore
                .map((m) => ({ name: m?.attributeHeaderItem?.name, title: m?.attributeHeaderItem?.name, uri: m?.attributeHeaderItem?.uri })) ?? [],

        [result],
    )?.map((e) => {
        return e.name.split(" ");
    });
    const Customercodes = [].concat(...staticElements).filter((value, index, array) => {
        return array.indexOf(value) === index;
    });

    const PopoverContent = (
        <div>
            <div style={{ padding: 10 }}>
                <ul>
                    {Customercodes?.map((e) => (
                        <li key={e}>
                            {e} : {getCustlabelByCustId(e)}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
    return (
        <Popover content={PopoverContent} title="Player's Code" placement="leftTop" trigger="click">
            <button
                onClick={onClick}
                className={(isActive ? "text-white bg-indigo-500" : "text-indigo-900 hover:bg-indigo-100") + " flex justify-between items-center w-[125px] max-[1400px]:w-[90px] max-[1200px]:w-[70px] max-[1000px]:w-[60px] px-2.5 py-0.5 border border-indigo-500 rounded font-semibold shadow-button"}
            >
                <span>{title}</span>
                <ArrowDownIcon width={20} height={15} fill={isActive ? "white" : "#0a0d79"} />
            </button>
        </Popover>
    );
};

export default ButtonFilter;
