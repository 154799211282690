import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { Typography } from "antd";
import React from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import FiltersContext from "../../contexts/Filters";
import MeasuresContext from "../../contexts/Measures";
import * as Md from "../../md/full";
import jsonPivot from "../../utils/jsonPivot";
import TableComponent from "../TableComponent";
const DataByMake = ({ ...props }) => {
    const [Maketable, setMaketable] = useState([]);
    const [columns, setColumns] = useState([]);
    const {
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterCustmix,
        filterVersionStd,
        filterExtrapType,
        filterRolledForward,
        filterChannel3,
        filterCo2,
        filterListPrice,
        filterPs,
        filterKw,
    } = useContext(FiltersContext);
    const filters = [
        filterCountryId,
        filterMthCode,
        filterChannel1,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterCustmix,
        filterVersionStd,
        filterExtrapType,
        filterRolledForward,
        filterChannel3,
        filterCo2,
        filterListPrice,
        filterPs,
        filterKw,
    ];
    const { seriesBy } = useContext(MeasuresContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId, Md.CorrMake],
            filters: filters,
        },
    });

    useEffect(() => {
        const slices = result?.data()?.slices().toArray();
        const newdata = [];
        const data = slices?.map((slice) => {
            return {
                CustId: slice.sliceTitles()[0],
                Make: slice.sliceTitles()[1],
                count: Number(slice.dataPoints()[28].rawValue),
            };
        });
        data?.forEach((item) => {
            const newitem = {
                CustId: item.CustId,
                Make: item.Make,
                count: item.count,
            };
            if (!newdata.some((e) => e.CustId === newitem.CustId && e.Make === newitem.Make)) {
                newdata.push(newitem);
            }
        });
        if (newdata.length > 0) {
            const dataSource = jsonPivot(newdata, { row: "Make", column: "CustId", value: "count" });
            const newdataSource = dataSource.map((item) => {
                const total = Object.values(item)
                    .filter((m) => typeof m === "number")
                    .reduce((a, b) => a + b, 0);
                const newitem = {
                    ...item,
                    Total: total,
                };
                return newitem;
            });
            setMaketable(newdataSource);

            setColumns(
                Object.keys(newdataSource[0]).map((m) => {
                    return {
                        title: () => <center>{["Make", "Total"].includes(m) ? m : <img src={`/images/logos/Logo_${m}.png`} width={64} height={64} alt={m} />}</center>,
                        dataIndex: m,
                        key: m,
                        width: m === "Make" ? 200 : 100,
                        fixed: ["Make"].includes(m) ? "left" : null,
                        align: "center",
                        render: (cell) => {
                            return (
                                <Typography.Text style={{ fontSize: 12 }} strong>
                                    {cell}
                                </Typography.Text>
                            );
                        },
                        filters: ["Make"].includes(m)
                            ? null
                            : [
                                  {
                                      text: "Show values only",
                                      value: null,
                                  },
                              ],

                        onFilter: (value, record) => (["Make"].includes(m) ? value : record[m] !== value),
                    };
                }),
            );
        }
    }, [result]);
    return status === "loading" ? (
        <LoadingComponent />
    ) : (
        <div>
            <TableComponent
                columns={columns}
                bordered
                className="border border-gray-200"
                dataSource={Maketable}
                rowKey="Make"
                size="small"
                pagination={false}
                scroll={{ x: columns.reduce((a, b) => a + b.width, 0), y: 400 }}
                widgetProps={{
                    id: "vehicle-available-make",
                    section: "datacoverageadjusted",
                    filters,
                    metadata: {
                        Databy: "Make",
                    },
                }}
                {...props}
            />
        </div>
    );
};

export default DataByMake;
