const mode = (arr) => {
    const store = {};
    arr.forEach((num) => (store[num] ? (store[num] += 1) : (store[num] = 1)));
    return Object.keys(store).sort((a, b) => (store[b] > store[a] ? 1 : -1))[0];
};

function toPivot({ dataTable = [], categoryLabel = undefined, comparatorLabel = undefined, valueLabel = undefined, additionalColumns = [], listPriceLabel = "ListPrice" }) {
    let columns = [categoryLabel, ...additionalColumns, listPriceLabel];
    let distinctVehicles = [];
    let pivotData = [];
    // Remove unecessary columns
    let data = dataTable.map((item) => {
        return Object.entries(item)
            .filter(([key, value]) => [...additionalColumns, comparatorLabel, categoryLabel, valueLabel, listPriceLabel].includes(key))
            .reduce((acc, nextValue) => {
                acc = {
                    ...acc,
                    [nextValue[0]]: nextValue[1],
                };
                return acc;
            }, {});
    });
    data.forEach((dataElem) => {
        if (!distinctVehicles.find((elem) => elem[categoryLabel] === dataElem[categoryLabel])) {
            distinctVehicles.push(dataElem);
        }
    });
    for (let vehicleObj of distinctVehicles) {
        let dataVehicles = data.filter((elem) => elem[categoryLabel] === vehicleObj[categoryLabel]);
        let transposedData = dataVehicles.reduce((acc, nextVal) => {
            !columns.includes(nextVal[comparatorLabel]) && columns.push(nextVal[comparatorLabel]);
            return Object.assign({
                ...acc,
                [listPriceLabel]: listPriceLabel in acc && acc[listPriceLabel]?.length > 0 ? [...acc[listPriceLabel], nextVal[listPriceLabel]] : [nextVal[listPriceLabel]],
                [nextVal[comparatorLabel]]: Number(nextVal[valueLabel]) > 0 ? Number(nextVal[valueLabel]) : null,
            });
        }, vehicleObj);
        delete transposedData[comparatorLabel];
        delete transposedData[valueLabel];
        const modeListPrice = mode(transposedData[listPriceLabel]);
        transposedData[listPriceLabel] = Number(modeListPrice) > 0 ? Number(modeListPrice) : null;
        pivotData.push(transposedData);
    }
    return {
        columns: columns,
        data: pivotData,
    };
}

function calculateMedian(arrList) {
    arrList.sort((a, b) => a - b);
    const setLength = arrList.length;
    const middlePosition = setLength / 2;
    if (setLength % 2 !== 0) {
        return arrList[Math.floor(middlePosition)];
    }
    return (arrList[middlePosition] + arrList[middlePosition - 1]) / 2;
}

function calculateAvg(arrList) {
    return Number(arrList.reduce((acc, item) => acc + item, 0) / arrList.length);
}

function weigthedAverage(arrValues, coefs) {
    const var1 = arrValues.reduce((acc, nextItem, index) => {
        return acc + nextItem * coefs[index];
    }, 0);
    const coefSum = coefs.reduce((acc, nextCoef) => acc + nextCoef, 0);
    return var1 / coefSum;
}

function weightedAverageByMake(models, kpi, custIds = ["OEM"]) {
    // Get the minimum value for each offer
    const modelsObj = Object.entries(models).reduce((accModel, [model, versions]) => {
        accModel[model] = versions.map((version) => {
            const obj = Object.assign({
                label: version.label,
            });
            const custId = Object.keys(version)
                .filter((key) => key !== "label" && custIds.includes(key))
                .sort((key1, key2) => version[key1][kpi.index] - version[key2][kpi.index])[0];
            if (custId) obj.custId = version[custId][kpi.index];
            return obj;
        });
        return accModel;
    }, {});
    const [sumValues, modelsCount] = Object.values(modelsObj).reduce(
        (acc, versions) => {
            if (versions.length === 0 || versions.filter((obj) => obj !== null && "custId" in obj && obj["custId"] > 0).length === 0) return acc;
            else {
                const coeff = versions.filter((obj) => obj !== null && "custId" in obj && obj.custId !== null).length;
                const sum = versions.filter((obj) => obj !== null && "custId" in obj && obj.custId !== null).reduce((sum, obj) => sum + obj["custId"], 0) / coeff;
                return [acc[0] + sum, acc[1] + 1];
            }
        },
        [0, 0],
    );
    return kpi.id === 4 ? Number(((sumValues / modelsCount) * 100).toFixed(2)) : kpi.roundValue(sumValues / modelsCount) || null;
}

function calculateModelAvg(versionObjArr = [], custIds = []) {
    /**
     * Method to calculate the avg of a specific model,
     * custIds: list of custIds used to get the minimum offer for each version of the model
     */
    const groupByVersion = versionObjArr.reduce((acc, obj) => {
        // get the minimum for each offer
        if (obj.versionStd in acc && custIds.includes(obj.custId) && obj.value !== null && acc[obj.versionStd] > obj.value) {
            acc[obj.versionStd] = obj.value;
        } else {
            acc[obj.versionStd] = obj.value;
        }
        return acc;
    }, {});
    const [sumTotal, coeffSum] = Object.entries(groupByVersion).reduce(
        (acc, [version, value]) => {
            if (value) {
                acc[0] += value;
                acc[1] += 1;
            }
            return acc;
        },
        [0, 0],
    );
    return coeffSum !== 0 ? sumTotal / coeffSum : null;
}

export { mode, toPivot, calculateMedian, calculateAvg, weigthedAverage, weightedAverageByMake, calculateModelAvg };
