import { Dropdown } from "antd";
import React from "react";

const DropdownButton = ({ options, defaultOption = {}, objIndex, onSelect = (index, newRole) => {}, ...props }) => {
    return (
        <Dropdown
            menu={{
                items: options,
                selectable: true,
                defaultSelectedKeys: [defaultOption?.key],
                onClick: ({ key }) => {
                    if (key !== defaultOption?.key) onSelect(objIndex, key);
                },
            }}
            trigger={["click"]}
        >
            <span>{defaultOption.label}</span>
        </Dropdown>
    );
};

export default DropdownButton;
