import React, { useContext, useCallback, useEffect, useState, useMemo } from "react";
import { Dropdown, Table, Tooltip, Typography } from "antd";
import { ErrorComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import FiltersContext from "../../contexts/Filters";
import { useAppContext } from "../../contexts/AppContext";
import Servicesbanner from "../Layout/Servicesbanner";
import Exportbtn from "../Export/Exportbtn";
import { tableLegendItems } from "../../routes/Vehicles";
import { useCarSelector } from "../../contexts/CarSelectorProvider";
import LoadingSpinner from "../LoadingSpinner";
import { weigthedAverage } from "../../utils/calculationUtils";
import { useUserContext } from "../../contexts/User";
import { CarSelectorConfigModal, DropCustomField, operations } from "./CarSelectorConfigModal";
import { FiMoreVertical, FiSettings } from "react-icons/fi";
import MeasuresContext from "../../contexts/Measures";
import VehicleExport from "./VehiclesExport";
import VersionMatch from "./VersionMatch";
import MagicWand from "../Icons/MagicWand";

const mode = (arr) => {
    const store = {};
    arr.forEach((num) => (store[num] ? (store[num] += 1) : (store[num] = 1)));
    return Object.keys(store)
        .filter((val) => val !== "null")
        .sort((a, b) => (store[b] > store[a] ? 1 : -1))[0];
};

function TableVehicles({
    seriesBy,
    slicesBy,
    filters,
    kpi,
    modelItem = undefined,
    defaultElements = undefined,
    footnote,
    isPaginated = false,
    showLegends = false,
    enableMultiSelection = false,
    displayCustomFields = true,
    loadingComponent = <LoadingSpinner />,
    showFootnote = true,
    onSelectVehicle = (model, item) => {},
    onLoadData = (data) => {},
}) {
    const { selectedVehicles, selectedModels, customFields, insertVehicle, removeVehicle, setSelectedVehiclesByModel, selectModelObject, setSelectedVehicles, editCarSelectorConfig, dropField } = useCarSelector();
    const { kpiSettings } = useUserContext();
    const { getCustlabelByCustId } = useAppContext();
    const [selectedPage, setSelectedPage] = useState(1);
    const { currentFilter, filterCountryId, filterDuration, filterMthCode, filterChannel1 } = useContext(FiltersContext);
    const { selectedKpi, selectedAdjDeposit } = useContext(MeasuresContext);
    const [isExport, setExportStatus] = useState(false);
    const [offset, setOffset] = useState(0);
    const [flat, setFlat] = useState([]);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [configAction, setConfigAction] = useState("editCustomField");
    const [activePlayers, setActivePlayers] = useState([]);
    const [custIDs, setCustIDs] = useState([]);
    const [showVersionMatch, setShowVersionMatch] = useState(false);
    const { result, status, error } = useExecutionDataView(
        {
            execution: { seriesBy, slicesBy, filters },
            window: { offset: [offset], size: [500, 500] },
        },
        [offset],
    );

    const rowSelection = {
        onSelect: (record, selected, selectedRows, nativeEvent) => {
            if (enableMultiSelection) {
                if (modelItem) {
                    onSelectVehicle(modelItem, selectedRows);
                } else {
                    const modelObj = selectedModels.find((modelObj) => modelObj.vehicles.map((vehicle) => vehicle?.Version).includes(record.Version));
                    if (modelObj && !selected) {
                        modelObj.vehicles.splice(
                            modelObj.vehicles.findIndex((item) => item?.Version === record.Version),
                            1,
                        );
                        modelObj.selectionStatus = "partial";
                        selectModelObject(modelObj);
                    } else if (record.Version) {
                        if (selected) {
                            insertVehicle(record);
                        } else {
                            removeVehicle(record);
                        }
                    }
                }
            } else {
                setSelectedVehicles([...selectedRows.filter((row) => row?.Version)]);
            }
        },
        selectedRowKeys: selectedVehicles.length > 0 && selectedVehicles.map((row) => row?.Version),
    };

    const moreMenuItems = [
        {
            key: 1,
            icon: <FiSettings width={20} className="text-indigo-700" />,
            label: "Customize new field",
            clickHandler: () => {
                setShowModal(!showModal);
                setConfigAction("editCustomField");
            },
            isEnabled: true,
        },
        // {
        //     key: 2,
        //     icon: <FaTable width={20} className="text-indigo-700" />,
        //     label: "Show/hide players",
        //     clickHandler: () => {
        //         setShowModal(!showModal);
        //         setConfigAction("editPlayersVisibility");
        //     },
        //     isEnabled: true,
        // },
    ];

    useEffect(() => {
        setOffset(0);
        setFlat([]);
        setSelectedPage(1);
    }, [currentFilter, kpi, selectedAdjDeposit]);

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices) {
            const newFlat = slices?.map((slice) => {
                const dataPoints = slice.dataPoints();
                return {
                    Version: slice.sliceTitles()[0],
                    CustId: slice.sliceTitles()[1],
                    "List Price": Math.round(Number(slice.dataPoints()[1].rawValue)) > 0 ? Math.round(Number(slice.dataPoints()[1].rawValue)) : null,
                    CellColor: slice.sliceTitles()[2],
                    model: slice.sliceTitles()[3],
                    Value: dataPoints[kpi?.index]?.rawValue !== null ? (kpi?.index === 24 ? Math.round(10000 * Number(dataPoints[kpi?.index]?.rawValue)) / 100 : Math.round(Number(dataPoints[kpi?.index]?.rawValue))) : null,
                    versionCount: Number(dataPoints[30].rawValue),
                    Segment: slice.sliceTitles()[4],
                    Style: slice.sliceTitles()[5],
                    Gear: slice.sliceTitles()[6],
                    Energy: slice.sliceTitles()[7],
                    Doors: slice.dataPoints()[32].rawValue,
                    Kw: slice.dataPoints()[29].rawValue,
                    Power: slice.dataPoints()[22].rawValue,
                    CO2: slice.dataPoints()[31].rawValue,
                    "Adj. Deposit": slice.dataPoints()[2].rawValue,
                };
            });
            setFlat((prevFlat) => [...prevFlat, ...newFlat]);
        }
    }, [result, kpi]);

    const filteredFlat = useMemo(() => {
        if (selectedAdjDeposit !== "Total Payable by month") {
            return flat.filter((item) => item["Adj. Deposit"] !== null);
        }
        return flat;
    }, [selectedAdjDeposit, flat]);

    useEffect(() => {
        if (data?.length > 0) {
            let tableRows = data;
            if (modelItem) {
                if (modelItem?.selectionStatus === "all") {
                    setSelectedVehiclesByModel(modelItem, [...tableRows]);
                } else if (modelItem?.selectionStatus === "partial") {
                    tableRows = data.filter((item) => selectedVehicles.find((obj) => obj.Version === item.Version) !== undefined);
                    setSelectedVehiclesByModel(modelItem, [...tableRows]);
                } else {
                    setSelectedVehiclesByModel(modelItem, []);
                }
            }
        }
    }, [data, modelItem]);

    useEffect(() => {
        if (filteredFlat.length > 0) {
            // @ts-ignore
            const custIdKeys = [...new Set(filteredFlat.map(({ CustId }) => CustId))].filter((custId) => isNaN(custId) && custId !== null);
            if (custIdKeys) setCustIDs([...custIdKeys]);
            let vehicleOffersObj = filteredFlat
                // .filter((item) => (activePlayers.length > 0 ? activePlayers.includes(item.CustId) : true))
                .reduce((acc, nextItem) => {
                    const vehicleInfo = Object.entries(nextItem)
                        .filter(([key, val]) => !["CustId", "Value", "List Price"].includes(key))
                        .reduce((acc, [key, value]) => Object.assign({ [key]: value, ...acc }), {});
                    if (nextItem?.Version in acc && nextItem.CustId in acc[nextItem?.Version]) {
                        acc[nextItem?.Version][nextItem.CustId].values.push(nextItem.Value);
                        acc[nextItem?.Version][nextItem.CustId].versionCount.push(nextItem.versionCount);
                    } else {
                        acc[nextItem.Version] = {
                            [nextItem.CustId]: {
                                values: [nextItem.Value],
                                versionCount: [nextItem.versionCount],
                            },
                            ...vehicleInfo,
                            ...acc[nextItem?.Version],
                        };
                    }
                    acc[nextItem?.Version] = {
                        ...acc[nextItem?.Version],
                        listPrices: "listPrices" in acc[nextItem?.Version] ? [...acc[nextItem?.Version]["listPrices"], nextItem["List Price"]] : [nextItem["List Price"]],
                    };
                    return acc;
                }, {});
            // Calculate the weighted average
            Object.keys(vehicleOffersObj).forEach((key) => {
                custIdKeys.forEach((custId) => {
                    vehicleOffersObj[key][custId] = custId in vehicleOffersObj[key] ? kpi?.roundValue(weigthedAverage(vehicleOffersObj[key][custId]?.values, vehicleOffersObj[key][custId]?.versionCount)) : null;
                });
            });
            const dataSource = Object.values(vehicleOffersObj).map((item) => {
                const custValues = Object.keys(item)
                    .filter((key) => custIdKeys.includes(key) && item[key] > 0)
                    .map((key) => item[key]);
                let rentalSum = custValues.reduce((acc, currentVal) => acc + currentVal, 0);
                let Average = rentalSum > 0 ? kpi?.roundValue(rentalSum / custValues.length) : null;
                let ModListPrice = item.listPrices?.length > 0 ? Math.round(Number(mode(item.listPrices))) || null : null;
                const customFieldsObj = customFields.reduce((acc, field) => {
                    const fieldValue = operations.calculate(
                        field.players.map((player) => item[player] || null),
                        field.operation,
                        selectedKpi.roundValue,
                    );
                    return {
                        ...acc,
                        [field.fieldName]: fieldValue,
                    };
                }, {});
                return { Version: item?.Version, "List Price": ModListPrice, Average, ...customFieldsObj, ...item };
            });
            setData(dataSource);
        }
    }, [filteredFlat, kpi, customFields, activePlayers]);

    useEffect(() => {
        status === "success" && data?.length > 0 && onLoadData();
        const dataTable = data || defaultElements;
        if (dataTable?.length > 0) {
            const firstHeaderColumns = ["Version", "List Price", "Average"];
            setColumns(
                Object.keys(dataTable[0])
                    .filter((key) => firstHeaderColumns.includes(key) || (activePlayers.length > 0 && activePlayers.find((player) => custIDs.includes(player)) ? activePlayers.includes(key) : custIDs.includes(key)) || customFields.find((field) => field.field_name === key) !== undefined)
                    .sort((a, b) => {
                        const aIndex = firstHeaderColumns.findIndex((item) => item === a),
                            bIndex = firstHeaderColumns.findIndex((item) => item === b);
                        if (firstHeaderColumns.includes(a) || firstHeaderColumns.includes(b)) {
                            return aIndex !== -1 ? aIndex - bIndex : bIndex - aIndex;
                        }

                        if (custIDs.includes(a) && custIDs.includes(b)) {
                            return getCustlabelByCustId(a).localeCompare(getCustlabelByCustId(b));
                        }

                        return 1;
                    })
                    .map((m) => {
                        return {
                            title: () => (
                                <div>
                                    {custIDs.includes(m) ? (
                                        <center>
                                            <img src={`/images/logos/Logo_${m}.png`} width={70} height={70} alt={m} />
                                        </center>
                                    ) : ["Version", "List Price", "Average"].includes(m) ? (
                                        <span>{m}</span>
                                    ) : (
                                        <Tooltip title={`${Object.keys(operations).find((key) => operations[key] === customFields.find((item) => item.fieldName === m)?.operation)}(${customFields.find((item) => item.fieldName === m)?.players.join(",")})`} className="flex items-center gap-2">
                                            <span className="flex-1 self-center">{m}</span>
                                            <DropCustomField field={customFields.find((item) => item.fieldName === m)} onDelete={dropField} />
                                        </Tooltip>
                                    )}
                                </div>
                            ),
                            fixed: ["Version"].includes(m) ? "left" : null,
                            align: m !== "Version" && "center",
                            dataIndex: m,
                            key: m,
                            width: m === "Version" ? 350 : !custIDs.includes(m) ? (m.length > 8 ? m.length * 12 : 100) : isPaginated || Object.keys(dataTable[0]).length <= 9 ? 90 : 75,
                            render: (cell, record) => {
                                let filteredVehicle = filteredFlat.find((f) => f.Version === record.Version && f.CustId === m);
                                return ["Version"].includes(m) ? (
                                    <div className="flex items-center justify-between">
                                        <Tooltip title={`Segment ${record.Segment} , Style ${record.Style} , Power ${record.Power} , KW ${record.Kw}` + ` ${record.CO2 ? `, CO2 ${record.CO2}` : ""}`} placement="right" overlayStyle={{ fontSize: 11, minWidth: "max-content" }}>
                                            <Typography.Text style={{ fontSize: 11 }}>{cell}</Typography.Text>
                                        </Tooltip>
                                        {/* <button
                                            onClick={() => {
                                                setShowVersionMatch(true);
                                                setSelectedVehicles([record]);
                                            }}
                                            className="hover:opacity-50"
                                        >
                                            <MagicWand className="w-4 h-4 text-indigo-900" />
                                        </button> */}
                                    </div>
                                ) : (
                                    <Typography.Text
                                        style={
                                            filteredVehicle?.Value > 0
                                                ? {
                                                      fontSize: 11,
                                                      padding: filteredVehicle?.CellColor && "2px",
                                                      borderRadius: "2px",
                                                      color: filteredVehicle?.CellColor.split(",")[0],
                                                      background: filteredVehicle?.CellColor.split(",")[1],
                                                  }
                                                : {
                                                      fontSize: 11,
                                                  }
                                        }
                                    >
                                        {cell?.toLocaleString("en-US", { useGrouping: false })}
                                    </Typography.Text>
                                );
                            },
                            filters: !custIDs.includes(m)
                                ? null
                                : [
                                      {
                                          text: "Show values only",
                                          value: null,
                                      },
                                  ],

                            onFilter: (value, record) => (["Version", "List Price", "Average"].includes(m) ? value : record[m] !== value),
                        };
                    }),
            );
        }
    }, [status, data, defaultElements, activePlayers, custIDs, customFields]);

    // useEffect(() => {
    //     hiddenPlayersField?.players ? setActivePlayers([...hiddenPlayersField.players]) : setActivePlayers([]);
    // }, [hiddenPlayersField]);

    const handleLoadMore = () => {
        setOffset(offset + 500);
        // onSelectVehicle(undefined);
    };

    const exportFile = useCallback(() => {
        setExportStatus(true);
        // setData([]);
    }, []);

    function insertNewField({ id, players, fieldName = "EE_field", operation = operations.avg }) {
        const res = data.map((item) => {
            const fieldValue = operations.calculate(
                players.map((player) => item[player]),
                operation,
            );
            return {
                ...item,
                [fieldName]: fieldValue,
            };
        });
        setData([...res]);
        return editCarSelectorConfig({ fieldName, players, operation });
    }
    function maskPlayers(obj) {
        return editCarSelectorConfig({ ...obj }).then((res) => setActivePlayers([...obj.players]));
    }

    return (
        <>
            {status === "loading" ? (
                <div className="my-12">{loadingComponent}</div>
            ) : status === "error" ? (
                <ErrorComponent
                    message={error?.getMessage() === "NO_DATA" ? "This data is not available with your access" : "The data is too large to compute"}
                    description={error?.getMessage() === "NO_DATA" ? "Please feel free to contact us if you need any information" : "Please filter down or change to the model/energy view via settings."}
                />
            ) : status === "success" ? (
                data.length > 0 &&
                columns.length > 0 && (
                    <>
                        {displayCustomFields && (
                            <div className="relative">
                                <Dropdown
                                    menu={{
                                        items: moreMenuItems,
                                        onClick: ({ key }) => {
                                            moreMenuItems.find((obj) => obj.key === Number(key))?.clickHandler();
                                        },
                                    }}
                                    arrow={{ pointAtCenter: true }}
                                    trigger={["click"]}
                                >
                                    <FiMoreVertical className={`absolute -right-2 -top-10 z-40 rounded w-8 h-8 px-1.5 text-gray-500 hover:bg-backgroundColor hover:text-indigo-700`} />
                                </Dropdown>
                            </div>
                        )}
                        {
                            <div className="flex flex-col gap-2 items-stretch max-w-fit">
                                <div className="flex items-start">
                                    <Table
                                        rowSelection={{
                                            type: enableMultiSelection ? "checkbox" : "radio",
                                            hideSelectAll: true,
                                            ...rowSelection,
                                        }}
                                        columns={columns}
                                        dataSource={defaultElements?.length > 0 ? defaultElements : data}
                                        rowKey={(record) => record.Version}
                                        size="small"
                                        pagination={{
                                            defaultPageSize: 100,
                                            hideOnSinglePage: true,
                                            defaultCurrent: selectedPage,
                                            showSizeChanger: false,
                                            position: ["bottomLeft"],
                                            onChange: (page) => {
                                                setSelectedPage(page);
                                            },
                                        }}
                                        scroll={{ x: columns.reduce((a, b) => a + b.width, 0), y: isPaginated ? 400 : null }}
                                    />
                                    {/* {displayCustomFields && (
                            <span
                                className="bg-gray-100 shadow rounded-sm cursor-pointer"
                                style={{
                                    padding: "7px",
                                }}
                            >
                                <IoAddCircleOutline className="w-6 h-6 text-steel" onClick={() => setShowModal(!showModal)} />
                            </span>
                        )} */}
                                </div>

                                {showLegends && (
                                    <div className="self-start my-4 flex gap-4 rounded-sm">
                                        <label className="flex items-center font-semibold text-steel gap-2">Legend :</label>
                                        {tableLegendItems.map((legendItem) => (
                                            <div className="flex items-center gap-2">
                                                <div
                                                    className="py-1 px-2 border border-gray-500 text-xs font-semibold"
                                                    style={{
                                                        backgroundColor: legendItem.isbackground && legendItem.color,
                                                        color: !legendItem.isbackground && legendItem.color,
                                                    }}
                                                >
                                                    123
                                                </div>
                                                <span className="text-xs font-medium">{legendItem.label}</span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                                {showFootnote && <Servicesbanner kpiSettings={kpiSettings} />}
                                {showFootnote ? (
                                    <div className="self-stretch flex justify-start gap-4">
                                        <Exportbtn onclick={() => exportFile()} />
                                        {isPaginated && (
                                            <button className="flex items-center rounded border bg-indigo-700 border-indigo-700 px-8 py-0.5 text-white font-medium shadow-button hover:bg-indigo-500" onClick={handleLoadMore}>
                                                Load more
                                            </button>
                                        )}
                                    </div>
                                ) : (
                                    <div className="h-2"></div>
                                )}
                                {showFootnote && <p className="text-left text-xs font-medium my-4">{footnote}</p>}
                            </div>
                        }
                    </>
                )
            ) : (
                <div></div>
            )}
            {showModal && (
                <CarSelectorConfigModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    onSubmit={configAction === "editCustomField" ? insertNewField : maskPlayers}
                    action={configAction}
                    title={configAction === "editCustomField" ? "Customize new field" : "Hide/Show data points of the players"}
                    players={custIDs}
                    configField={{}}
                />
            )}
            {isExport && (
                <VehicleExport
                    seriesBy={seriesBy}
                    slicesBy={slicesBy}
                    filters={filters}
                    kpi={kpi}
                    onDownloadCallback={() => {
                        setExportStatus(false);
                    }}
                />
            )}
            {showVersionMatch && selectedVehicles.length === 1 && (
                <VersionMatch selectedVehicle={selectedVehicles[0]} onCancel={() => setShowVersionMatch(false)} seriesBy={[...seriesBy]} slicesBy={[...slicesBy]} filters={[filterCountryId, filterDuration, filterMthCode, filterChannel1]} action={setSelectedVehicles} kpi={kpi} />
            )}
        </>
    );
}

export { TableVehicles };
