import React, { useState, Fragment, useContext, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";

import UserContext from "../contexts/User";
import FiltersContext from "../contexts/Filters";
import { ArrowDownIcon } from "./Icons/Arrows";

const countryLabels = {
    GB: "UK (PCP)",
};

export default function ComboBoxScopeCountryFilter({ value, mdAttribute, title, filterID }) {
    const { updateFilter } = useContext(FiltersContext);
    const { getCountries } = useContext(UserContext);

    const [query, setQuery] = useState("");
    const [selectedItem, setSelectedItem] = useState(value);

    const availableCountries = getCountries();

    const staticElements =
        query === ""
            ? availableCountries
            : availableCountries.filter((f) => {
                  return f.toLowerCase().includes(query.toLowerCase());
              });

    useEffect(() => {
        if (value) setSelectedItem(value);
    }, [value]);

    return (
        <Combobox
            value={selectedItem}
            onChange={(obj) => {
                setSelectedItem(obj);
                updateFilter(filterID, newPositiveAttributeFilter(mdAttribute, [obj]));
            }}
        >
            <div className="relative mt-1">
                <Combobox.Input
                    onChange={(event) => {
                        setQuery(event.target.value);
                    }}
                    displayValue={(elem) => countryLabels[elem] || elem}
                    className="w-[125px] px-2.5 py-1 bg-steel border border-indigo-500 text-white rounded font-normal shadow-button"
                />
                <Combobox.Button className="absolute inset-y-0 right-0  flex items-center rounded-r-md px-2 focus:outline-none">
                    <ArrowDownIcon fill={"#3a0ca3"} width={20} height={20} />
                </Combobox.Button>
                {staticElements.length > 0 && (
                    <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Combobox.Option disabled className="px-2 font-semibold">
                            {title}
                        </Combobox.Option>
                        {staticElements.map((elem, index) => (
                            <Combobox.Option key={index} value={elem} as={Fragment} className={({ active }) => (active ? "bg-backgroundColor" : "") + " cursor-default py-2 pl-3 pr-1"}>
                                {({ active, selected }) => {
                                    return (
                                        <div className="flex justify-between px-2">
                                            <span className="whitespace-nowrap">{countryLabels[elem] || elem}</span>
                                            {selected && (
                                                <div>
                                                    <CheckIcon className="h-5 w-5" color="#490fcc" />
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}
