import React, { useState, useContext, useEffect } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newAttributeSort, newMeasureSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import moment from "moment";
import { LiaCarSideSolid } from "react-icons/lia";
import "./print.css";
import * as Md from "../../../md/full";
import { useBasketContext } from "../../../contexts/BasketProvider";
import UserContext from "../../../contexts/User";
import FiltersContext from "../../../contexts/Filters";
import MeasuresContext from "../../../contexts/Measures";
import { BasketBarChart } from "../BasketBarChart";
import { BasketLineChart } from "../BasketLineChart";
import { useAppContext } from "../../../contexts/AppContext";
import { BasketReference } from "../BasketReference";
import UpDownButton from "../../Buttons/UpDownButton";
import { newJsonPivot } from "../../../utils/jsonPivot";
import { colors } from "../../../constants";
import Selectedcust from "../../Layout/Selectedcust";
import Servicesbanner from "../../Layout/Servicesbanner";
import BasketSynthesisChart from "./BasketSynthesisChart";
import { Button, message, Modal, Tooltip } from "antd";
import BasketVersionMatch from "./BasketVersionMatch";
import { PiMagicWandFill } from "react-icons/pi";

const PdfHeader = ({ basketName, country, date, channel1, duration, kpi, adjdeposititle }) => (
    <div className="flex justify-between items-end pb-2 border-b border-indigo-700">
        <div className="flex-1 flex flex-col w-full items-stretch">
            <span className="leading-5 text-xl font-bold text-indigo-700">
                {country} - Basket {basketName} - {date}
            </span>
            <span className="font-semibold">
                Monthly {kpi} in {country !== "United Kingdom" ? "€" : "£"} {adjdeposititle ? "| " + adjdeposititle : ""} | {channel1} Channel | {duration} (Months/{country === "United Kingdom" ? "Miles" : "KM"})
            </span>
        </div>
        <img className="relative float-right z-10" alt="Experteye_Logo" src="images/experteye-logo.png" height={12.0} />
    </div>
);

const BasketSummary = ({ userPref = {}, kpi = {}, ...props }) => {
    const summaryLabels = {
        Maintenance: userPref?.flag_ser,
        Breakdown: userPref?.flag_bre,
        Insurance: userPref?.flag_ins,
        Tyres: userPref?.flag_tyr,
        "Financial loss": userPref?.flag_fin,
    };
    let summaryLabelsIterator = Object.entries(summaryLabels);
    return (
        <span className="italic text-gray-500 text-xs font-light">
            {`Monthly Rate Incl. VAT, LCV Rental Excl. VAT,  ${kpi?.name}, `}
            {/* kpi is Adj.Rental or lease factor */}
            {[1, 4].includes(kpi?.id) && summaryLabelsIterator.map(([key, value], index) => (value ? "Incl." : "Excl.") + " " + key + (index > summaryLabelsIterator?.length - 1 ? ", " : " "))}
        </span>
    );
};

function BasketViz({ basket, printMode, custIds = [], selectedKpi, filterCountryId, filterMthCode, filterChannel1, filterDuration, printStatus, setIsLoading, adjdeposititle }) {
    const { displayMode, currentFilter } = useContext(FiltersContext);
    const userPreferenceContext = useContext(UserContext);
    const { seriesBy } = useContext(MeasuresContext);
    const [versionStdFilter, setVersionStdFilter] = useState(newPositiveAttributeFilter(Md.VersionStd, [basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value)]));
    const [colorSeries, setColorSeries] = useState({});
    const [colorVSeries, setColorVSeries] = useState({});
    const { getCountryByCode, getColorByCustId, getCustlabelByCustId } = useAppContext();
    const { reference, updateBasketPrintStatus, addVehicle, editBasket, removeVehicle, loadBasket } = useBasketContext();
    // Data used to render barcharts
    const [monthData, setMthData] = useState([]);
    const [monthVData, setMthVData] = useState([]);
    const [showVersionMatch, setShowVersionMatch] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState();
    const overview = React.createRef();
    const { user } = useContext(UserContext);
    const [showGraph, setShowGraph] = useState(user?.basket_sections?.Basket_Overview);
    const [showModal, setShowModal] = useState(false);
    const [scale, setScale] = useState({
        maxValue: undefined,
    });

    // Data used to render trend charts
    const [trendData, setTrendData] = useState({});
    const [trendDataV, setTrendDataV] = useState({});
    const [trendCategoryTitles, setTrendCategoryTitles] = useState([]);
    const [basketReference, setBasketReference] = useState({
        customer: undefined,
        versionStd: undefined,
        value: undefined,
    });

    const [readyVizCounter, setReadyVizCounter] = useState(0);
    const [showBarFigures, setShowBarFigures] = useState(userPreferenceContext?.user?.basket_sections?.Basket_BarChart);
    const [showTrendCharts, setShowTrendCharts] = useState(userPreferenceContext?.user?.basket_sections?.Basket_Trends);
    // const incrementReadyVizCounter = () => {
    //     setReadyVizCounter(readyVizCounter + 1);
    // };
    const versionSlots = basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value);
    const custSlots = Object.entries(userPreferenceContext.defaultUserPref)
        .filter(([key, val]) => key.includes("customer_slot") && val !== null)
        .map(([key, val]) => val);
    const custIdFilters = newPositiveAttributeFilter(Md.CustId, custSlots);

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId, Md.VersionStd],
            filters: [versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode],
            sortBy: [newMeasureSort(seriesBy[selectedKpi.index]), newAttributeSort(Md.CustId)],
        },
    });

    // Reference line
    const { result: referenceResult, status: referenceStatus } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.VersionStd, Md.CustId],
            filters: [newPositiveAttributeFilter(Md.CustId, [basketReference?.customer]), newPositiveAttributeFilter(Md.VersionStd, [basketReference?.versionStd]), versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode],
        },
    });

    const { result: trendResult, status: trendStatus } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId, Md.MthCode, Md.VersionStd],
            filters: [versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters],
        },
    });

    // const measures = [24, 23].includes(selectedKpi.index) ? seriesBy : [seriesBy[selectedKpi.index]];
    var monthDate = moment(filterMthCode.positiveAttributeFilter.in?.values[0], "YYYY MM").format("MMMM YY");
    const country = filterCountryId.positiveAttributeFilter.in?.values[0];
    const countryLabel = getCountryByCode(country);

    useEffect(() => {
        setReadyVizCounter(0);
    }, []);

    useEffect(() => {
        if (readyVizCounter === custIds?.length * 2) {
            updateBasketPrintStatus(basket);
        }
    }, [readyVizCounter]);

    useEffect(() => {
        if (status !== "loading" && referenceStatus !== "loading" && trendStatus !== "loading") {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [status, referenceStatus, trendStatus, setIsLoading]);

    useEffect(() => {
        if (basket !== undefined) {
            let versionStdFilters = basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value);
            // versionStdFilters.forEach((elem, index) => {
            //     vehiclesColors[elem] = colors[index];
            // });
            let defaultReference = basket.basketFilters.find((elem) => elem.reference === 1);
            setBasketReference({
                ...reference,
                versionStd: defaultReference?.value,
            });
            // setVehiclesColors(vehiclesColors);
            let vehicleStdAttributeFilter = newPositiveAttributeFilter(Md.VersionStd, versionStdFilters);
            setVersionStdFilter(vehicleStdAttributeFilter);
        }
    }, [basket, reference]);

    useEffect(() => {
        if (result) {
            const data = result.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            const parsedData = newJsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
            const prepasedData = newJsonPivot(dataPoints, { row: "custId", column: "versionStd", value: "value" });
            const maxValue = parsedData.reduce((acc, nextElem) => {
                const elemMaxValue = Object.entries(nextElem)
                    .filter(([key, val]) => custSlots.includes(key))
                    .reduce((maxValue, [key, nextValue]) => {
                        return maxValue > nextValue ? maxValue : nextValue;
                    }, 0);
                return acc > elemMaxValue ? acc : elemMaxValue;
            }, 0);
            setScale({
                maxValue,
            });
            setMthData(prepasedData);
            setMthVData(parsedData);
        }
    }, [result, selectedKpi]);
    useEffect(() => {
        const dataSerie = trendResult?.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
        const slices = trendResult?.data().slices().toArray();
        if (dataSerie && slices) {
            const mthCodes = slices.reduce((acc, slice) => {
                let mthCode = slice.sliceTitles()[1];
                !acc.includes(mthCode) && acc.push(mthCode);
                return acc;
            }, []);
            mthCodes.sort();
            let trendColorCopy = colors.slice();
            const serieColors = slices.reduce((acc, slice, index) => {
                let attrValue = displayMode.attributes.reduce((acc, attr, index) => acc + " " + slice.sliceTitles()[index + 2], "");
                if (!(attrValue in acc)) {
                    acc[attrValue] = trendColorCopy.shift();
                }
                // !acc.includes(attrValue) && acc.push(Object.assign());
                return acc;
            }, {});
            const serieVColors = slices.reduce((acc, slice, index) => {
                let attrValue = displayMode.attributes.reduce((acc, attr, index) => acc + "" + slice.sliceTitles()[index], "");
                if (!(attrValue in acc)) {
                    acc[attrValue] = trendColorCopy.shift();
                }
                // !acc.includes(attrValue) && acc.push(Object.assign());
                return acc;
            }, {});

            const trendData = dataSerie.dataPoints().reduce((acc, dp) => {
                const sliceTitles = dp.sliceDesc.sliceTitles();
                const custValue = sliceTitles[0];
                const serieTitle = displayMode.attributes.reduce((acc, attr, index) => acc + " " + sliceTitles[index + 2], "");
                const mthCode = sliceTitles[1];
                const dataPoint = Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue));
                if (custValue in acc) {
                    let serieElem = acc[custValue].find((item) => item.name === serieTitle);
                    if (serieElem) {
                        const mthIndex = mthCodes.findIndex((item) => item === mthCode);
                        serieElem.data[mthIndex] = dataPoint;
                    } else {
                        let dataValues = Array(mthCodes?.length).fill(null);
                        const mthIndex = mthCodes.findIndex((item) => item === mthCode);
                        dataValues[mthIndex] = dataPoint;
                        acc[custValue].push({
                            name: serieTitle,
                            data: dataValues,
                            color: serieColors[serieTitle],
                        });
                    }
                } else {
                    let dataValues = Array(mthCodes?.length).fill(null);
                    const mthIndex = mthCodes.findIndex((item) => item === mthCode);
                    dataValues[mthIndex] = dataPoint;
                    acc[custValue] = [
                        {
                            name: serieTitle,
                            data: dataValues,
                            color: serieColors[serieTitle],
                        },
                    ];
                }
                return acc;
            }, {});

            const TrendDataV = dataSerie.dataPoints().reduce((acc, dp) => {
                const sliceTitles = dp.sliceDesc.sliceTitles();
                const versionStd = sliceTitles[2];
                const custSerieTitle = displayMode.attributes.reduce((acc, attr, index) => acc + "" + sliceTitles[index], "");
                const mthCode = sliceTitles[1];
                const dataPoint = Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue));
                if (versionStd in acc) {
                    let serieElem = acc[versionStd].find((item) => item.name === custSerieTitle);
                    if (serieElem) {
                        const mthIndex = mthCodes.findIndex((item) => item === mthCode);
                        serieElem.data[mthIndex] = dataPoint;
                    } else {
                        let dataValues = Array(mthCodes?.length).fill(null);
                        const mthIndex = mthCodes.findIndex((item) => item === mthCode);
                        dataValues[mthIndex] = dataPoint;
                        acc[versionStd].push({
                            name: custSerieTitle,
                            data: dataValues,
                            color: getColorByCustId(custSerieTitle),
                        });
                    }
                } else {
                    let dataValues = Array(mthCodes?.length).fill(null);
                    const mthIndex = mthCodes.findIndex((item) => item === mthCode);
                    dataValues[mthIndex] = dataPoint;
                    acc[versionStd] = [
                        {
                            name: custSerieTitle,
                            data: dataValues,
                            color: getColorByCustId(custSerieTitle),
                        },
                    ];
                }
                return acc;
            }, {});
            setTrendCategoryTitles(mthCodes);
            setTrendData(trendData);
            setTrendDataV(TrendDataV);
            setColorSeries(serieColors);
            setColorVSeries(serieVColors);
        }
    }, [trendResult]);

    const minMaxValues = Object.values(trendData).reduce(
        (acc, nextElem) => {
            const elemMaxValue = nextElem.reduce((acc, nextElem) => {
                const serieMaxValue = nextElem.data.reduce((acc, nextElem) => {
                    return acc > nextElem ? acc : nextElem;
                }, 0);
                return acc > serieMaxValue ? acc : serieMaxValue;
            }, 0);
            const elemMinValue = nextElem.reduce((acc, nextElem) => {
                const serieMinValue = nextElem.data
                    .filter((item) => item !== null)
                    .reduce((acc, nextElem) => {
                        return acc < nextElem ? acc : nextElem;
                    }, Infinity);
                return acc < serieMinValue ? acc : serieMinValue;
            }, Infinity);
            return {
                maxValue: acc.maxValue > elemMaxValue ? acc.maxValue : elemMaxValue,
                minValue: acc.minValue < elemMinValue ? acc.minValue : elemMinValue,
            };
        },
        { maxValue: 0, minValue: Infinity },
    );
    useEffect(() => {
        const dataSerie = referenceResult?.data().series().firstForMeasure(seriesBy[selectedKpi.index]);
        if (dataSerie) {
            const referenceValue = dataSerie.dataPoints().map((dp) => (selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue))))[0];
            setBasketReference({
                ...basketReference,
                value: referenceValue,
            });
        }
    }, [referenceResult]);

    useEffect(() => {
        // if print mode, open figures
        if (printStatus) {
            !showBarFigures && setShowBarFigures(!showBarFigures);
            !showTrendCharts && setShowTrendCharts(!showTrendCharts);
            !showGraph && setShowGraph(!showGraph);
        }
        // if not print mode, set figures to user preference
        if (!printStatus) {
            showBarFigures !== userPreferenceContext?.user?.basket_sections?.Basket_BarChart && setShowBarFigures(userPreferenceContext?.user?.basket_sections?.Basket_BarChart);
            showTrendCharts !== userPreferenceContext?.user?.basket_sections?.Basket_Trends && setShowTrendCharts(userPreferenceContext?.user?.basket_sections?.Basket_Trends);
            showGraph !== userPreferenceContext?.user?.basket_sections?.Basket_Overview && setShowGraph(userPreferenceContext?.user?.basket_sections?.Basket_Overview);
        }
    }, [printStatus]);

    let valableSlots = custSlots.filter((elem) => monthData.find((item) => item.custId === elem));
    let basketSlotsPages = [];
    let basketTSlotsPages = [];
    for (let i = 0; i < valableSlots?.length; i += 4) {
        let newArr = valableSlots.slice(i, i + 4);
        basketSlotsPages.push(newArr);
    }
    for (let i = 0; i < valableSlots?.length; i += 2) {
        let newArr = valableSlots.slice(i, i + 2);
        basketTSlotsPages.push(newArr);
    }
    let valableVSlots = versionSlots.filter((elem) => monthVData.find((item) => item.versionStd === elem));
    let basketVSlotsPages = [];
    let basketTVSlotsPages = [];
    for (let i = 0; i < valableVSlots?.length; i += 4) {
        let newArr = valableVSlots.slice(i, i + 4);
        basketVSlotsPages.push(newArr);
    }
    for (let i = 0; i < valableVSlots?.length; i += 2) {
        let newArr = valableVSlots.slice(i, i + 2);
        basketTVSlotsPages.push(newArr);
    }

    function onAddToBasket(Version) {
        let vehicles = basket.basketFilters.filter(({ type, value }) => value && type === "version_std").map(({ type, value }) => Object.assign({ Vehicle: value }));
        if (vehicles.length < 12) {
            vehicles.push({ Vehicle: Version });
            loadBasket(basket, vehicles);
            message.success({
                content: "Vehicle added to the basket",
                style: {
                    top: "80px",
                    right: "20px",
                    position: "absolute",
                },
                duration: 2,
            });
        } else {
            message.error({
                content: "You can only add up to 12 vehicles to the basket",
                style: {
                    top: "80px",
                    right: "20px",
                    position: "absolute",
                },
                duration: 2,
            });
        }
    }

    function onReplaceVehicle(Version) {
        let vehicles = basket.basketFilters
            .filter(({ type, value }) => value && type === "version_std")
            .filter((elem) => elem.value !== selectedVehicle)
            .map(({ type, value }) => Object.assign({ Vehicle: value }))
            .concat({ Vehicle: Version });
        loadBasket(basket, vehicles);
        message.success({
            content: "Vehicle replaced in the basket",
            style: {
                top: "80px",
                right: "20px",
                position: "absolute",
            },
            duration: 2,
        });
    }

    const missingVehicleSlots = versionSlots.filter((elem) => !valableVSlots.includes(elem));
    return (
        <div className={!printStatus && "w-full flex flex-col items-stretch gap-4"}>
            {printMode !== "trends" && (
                <div className="break-after-page">
                    {!printStatus && (
                        <>
                            <div className="pt-2">
                                <span className="grow leading-5 text-xl font-bold text-indigo-700">Basket {basket.name}</span>
                            </div>
                            <div>
                                <div className="flex items-center gap-2 justify-between">
                                    <Selectedcust />
                                    {
                                        //@ts-ignore
                                        <BasketReference custIds={custIds} vehiclesStd={versionStdFilter.positiveAttributeFilter.in?.values} onSelect={setBasketReference} />
                                    }
                                </div>
                                <Servicesbanner kpiSettings={userPreferenceContext.kpiSettings} />
                                {/* {!printStatus && missingVehicleSlots.length > 0 && (
                                    <div className="flex flex-col text-xs">
                                        <span className="font-medium mb-2">Missing vehicles:</span>
                                        <div className="flex items-center gap-2 overflow-x-auto pb-2">
                                            {missingVehicleSlots.map((item, index) => (
                                                <div
                                                    key={index}
                                                    className="flex items-center border border-gray-300 rounded-full px-2 py-1 whitespace-nowrap cursor-pointer transition duration-300 ease-in-out hover:bg-indigo-700 hover:border-indigo-500 hover:text-white"
                                                    onClick={() => {
                                                        setSelectedVehicle(item);
                                                        setShowVersionMatch(true);
                                                    }}
                                                >
                                                    <LiaCarSideSolid className="w-4 h-4 text-secondary-300 mr-1" />
                                                    <span className="font-semibold text-xs">{item}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )} */}
                            </div>
                        </>
                    )}
                    <div className="pt-4">
                        {!printStatus && (
                            <div className="flex justify-between items-center py-4">
                                <span className="font-semibold text-lg text-steel">Overview</span>
                                <UpDownButton visibilityState={showGraph} onClickHandler={() => setShowGraph(!showGraph)} />
                            </div>
                        )}
                        {showGraph && printMode !== "bars" && (
                            <div className={printStatus && "break-after-page"}>
                                {printStatus && (
                                    <PdfHeader adjdeposititle={adjdeposititle} country={countryLabel} channel1={filterChannel1.positiveAttributeFilter.in?.values[0]} duration={filterDuration.positiveAttributeFilter.in?.values[0]} kpi={selectedKpi.name} basketName={basket.name} date={monthDate} />
                                )}
                                <div
                                    style={{
                                        display: printStatus ? "flex" : "block",
                                        justifyContent: printStatus ? "center" : "initial",
                                        alignItems: printStatus ? "center" : "initial",
                                        height: printStatus ? (printMode === "Overview" ? "600px" : "800px") : "auto",
                                    }}
                                >
                                    <div className={`${printStatus ? "mt-4 border-2 border-gray-300 rounded" : ""} min-w-full`} ref={overview}>
                                        <BasketSynthesisChart
                                            printStatus={printStatus}
                                            data={monthVData}
                                            filters={[versionStdFilter, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode]}
                                            basketReference={basketReference}
                                            isLoading={status === "loading"}
                                            title={
                                                printStatus
                                                    ? "Overview"
                                                    : `${basket.name} Monthly rate by player | ${countryLabel} | ${filterChannel1.positiveAttributeFilter.in?.values[0]} | ${filterDuration.positiveAttributeFilter.in?.values[0]} ${adjdeposititle ? " | " + adjdeposititle : ""} | ${selectedKpi.name}`
                                            }
                                            kpiSettings={userPreferenceContext.kpiSettings}
                                            menuButtonPosition="inside"
                                            width={printStatus ? (printMode === "Overview" ? 1024 : 1200) : overview?.current?.offsetWidth}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {!printStatus && (
                        <div className="pt-4">
                            <div className="flex justify-between items-center">
                                <span className="font-semibold text-lg text-steel">Monthly rate by {displayMode.title}</span>
                                <UpDownButton visibilityState={showBarFigures} onClickHandler={() => setShowBarFigures(!showBarFigures)} />
                            </div>
                        </div>
                    )}
                    {showBarFigures &&
                        printMode !== "Overview" &&
                        (status === "loading" ? (
                            <LoadingComponent />
                        ) : displayMode.key === "player" ? (
                            basketSlotsPages.map((custSlots, index) => (
                                <div className={"break-after-page " + (basketSlotsPages?.length !== 1 ? "mt-1" : "")}>
                                    {printStatus && (
                                        <PdfHeader
                                            adjdeposititle={adjdeposititle}
                                            country={countryLabel}
                                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                                            kpi={selectedKpi.name}
                                            basketName={basket.name}
                                            date={monthDate}
                                        />
                                    )}
                                    {
                                        <div className={printStatus ? "grid py-4 gap-4 grid-cols-basketPdfGrid" : "grid grid-cols-2 py-4 gap-4"}>
                                            {custSlots.map((custIndex, index) => {
                                                let player = monthData?.find((elem) => elem.custId === custIndex);
                                                let playerkeys = player && Object.keys(player).filter((key) => key !== "custId");
                                                let barChartData = playerkeys
                                                    ?.map((key) => {
                                                        return {
                                                            title: key,
                                                            dataPoint: player[key],
                                                        };
                                                    })
                                                    .filter((elem) => elem.dataPoint !== null);
                                                barChartData?.sort((a, b) => b.dataPoint - a.dataPoint);
                                                let filterCustId = newPositiveAttributeFilter(Md.CustId, [custIndex]);
                                                return (
                                                    <BasketBarChart
                                                        kpiSettings={userPreferenceContext.kpiSettings}
                                                        displayMode={displayMode}
                                                        filters={[versionStdFilter, filterChannel1, filterCountryId, filterDuration, filterCustId, filterMthCode]}
                                                        key={`${custIndex}-bar`}
                                                        custIndex={custIndex}
                                                        title={
                                                            printStatus
                                                                ? getCustlabelByCustId(custIndex)
                                                                : country === "GB"
                                                                ? `${getCustlabelByCustId(custIndex)} - ${countryLabel} - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration?.positiveAttributeFilter?.in?.values[0]}
                                                                ${adjdeposititle ? adjdeposititle + "-" : ""} ${selectedKpi.name}`
                                                                : `${getCustlabelByCustId(custIndex)} - ${countryLabel} - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration?.positiveAttributeFilter?.in?.values[0]} - ${selectedKpi.name}`
                                                        }
                                                        subtitle={!printStatus ? basket.name : null}
                                                        // chartData={props.basket.loadVizData("bar", custIndex)}
                                                        // onComplete={(data) => {
                                                        //     props.basket.storeVizData("bar", custIndex, data);
                                                        // }}
                                                        reference={basketReference}
                                                        data={barChartData}
                                                        chartProps={{
                                                            barWidth: printStatus ? 20 : 25,
                                                            width: printStatus ? 580 : null,
                                                            height: printStatus ? 350 : 450,
                                                            pdfMode: printStatus,
                                                            maxValue: scale.maxValue,
                                                            marginLeft: barChartData?.length > 0 ? 230 : null,
                                                            screenshotName: `basket-${basket.name}-spot-figure-${custIndex}`,
                                                            menuButtonPosition: "inside",
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    }
                                    {printStatus && basketReference?.customer && basketReference?.versionStd && (
                                        <div className="mt-2">
                                            <span className="font-semibold text-sm text-steel">
                                                Reference: {getCustlabelByCustId(basketReference.customer)} - {basketReference.versionStd}
                                            </span>
                                        </div>
                                    )}
                                    {printStatus && (
                                        <div className="fixed bottom-0">
                                            <BasketSummary userPref={userPreferenceContext.kpiSettings} kpi={selectedKpi} />
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            basketVSlotsPages.map((versionSlots, index) => (
                                <div className={"break-after-page " + (basketSlotsPages?.length !== 1 ? "mt-1" : "")}>
                                    {printStatus && (
                                        <PdfHeader
                                            adjdeposititle={adjdeposititle}
                                            country={countryLabel}
                                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                                            kpi={selectedKpi.name}
                                            basketName={basket.name}
                                            date={monthDate}
                                        />
                                    )}
                                    {
                                        <div className={printStatus ? "grid py-4 gap-4 grid-cols-basketPdfGrid" : "grid grid-cols-2 py-4 gap-4"}>
                                            {versionSlots.map((version_std, index) => {
                                                let version = monthVData?.find((elem) => elem.versionStd === version_std);
                                                let versionkeys = version && Object.keys(version).filter((key) => key !== "versionStd");
                                                let barChartData = versionkeys
                                                    ?.map((key) => {
                                                        return {
                                                            title: key,
                                                            dataPoint: version[key],
                                                        };
                                                    })
                                                    .filter((elem) => elem.dataPoint !== null);
                                                barChartData?.sort((a, b) => b.dataPoint - a.dataPoint);
                                                let filterVersion = newPositiveAttributeFilter(Md.VersionStd, [version_std]);
                                                return (
                                                    <BasketBarChart
                                                        kpiSettings={userPreferenceContext.kpiSettings}
                                                        filters={[filterVersion, filterChannel1, filterCountryId, filterDuration, custIdFilters, filterMthCode]}
                                                        displayMode={displayMode}
                                                        key={`${version_std}-bar`}
                                                        custIndex={version_std}
                                                        title={
                                                            printStatus
                                                                ? version_std
                                                                : `${version_std}
                                            - ${countryLabel}
                                            - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration?.positiveAttributeFilter?.in?.values[0]} - ${adjdeposititle ? adjdeposititle + "-" : ""} ${selectedKpi.name}`
                                                        }
                                                        subtitle={!printStatus ? basket.name : null}
                                                        // chartData={props.basket.loadVizData("bar", custIndex)}
                                                        // onComplete={(data) => {
                                                        //     props.basket.storeVizData("bar", custIndex, data);
                                                        // }}
                                                        reference={basketReference}
                                                        data={barChartData}
                                                        chartProps={{
                                                            barWidth: printStatus ? 20 : 25,
                                                            width: printStatus ? 580 : null,
                                                            height: printStatus ? 350 : 450,
                                                            pdfMode: printStatus,
                                                            maxValue: scale.maxValue,
                                                            marginLeft: barChartData?.length > 0 ? 150 : null,
                                                            screenshotName: `basket-${basket.name}-spot-figure-${version_std}`,
                                                            menuButtonPosition: "inside",
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    }
                                    {printStatus && basketReference?.customer && basketReference?.versionStd && (
                                        <div className="mt-2">
                                            <span className="font-semibold text-sm text-steel">
                                                Reference: {getCustlabelByCustId(basketReference.customer)} - {basketReference.versionStd}
                                            </span>
                                        </div>
                                    )}
                                    {printStatus && (
                                        <div className="fixed bottom-0">
                                            <BasketSummary userPref={userPreferenceContext.kpiSettings} kpi={selectedKpi} />
                                        </div>
                                    )}
                                </div>
                            ))
                        ))}
                </div>
            )}
            {printMode !== "bars" && printMode !== "Overview" && (
                <div className="break-after-page">
                    {!printStatus && (
                        <div className="mt-2 flex justify-between items-stretch">
                            <span className="font-semibold text-lg text-steel">Monthly rate trends by {displayMode.title}</span>
                            <UpDownButton visibilityState={showTrendCharts} onClickHandler={() => setShowTrendCharts(!showTrendCharts)} />
                        </div>
                    )}
                    {showTrendCharts &&
                        (referenceStatus === "loading" ? (
                            <div className="my-2">
                                <LoadingComponent />
                            </div>
                        ) : displayMode.key === "player" ? (
                            basketTSlotsPages.map((custSlots, index) => (
                                <div className={"break-after-page " + (basketTSlotsPages?.length !== 1 ? "mt-1" : "")}>
                                    {printStatus && (
                                        <PdfHeader
                                            adjdeposititle={adjdeposititle}
                                            country={countryLabel}
                                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                                            kpi={selectedKpi.name}
                                            basketName={basket.name}
                                            date={monthDate}
                                        />
                                    )}
                                    {
                                        <div className={printStatus ? "grid py-4 gap-4" : "flex flex-col gap-4 items-stretch"}>
                                            {custSlots.map((custIndex, index) => {
                                                let filterCustId = newPositiveAttributeFilter(Md.CustId, [custIndex]);
                                                return (
                                                    <BasketLineChart
                                                        kpiSettings={userPreferenceContext.kpiSettings}
                                                        filters={[versionStdFilter, filterChannel1, filterCountryId, filterDuration, filterCustId]}
                                                        displayMode={displayMode}
                                                        custIndex={custIndex}
                                                        minValue={minMaxValues?.minValue}
                                                        maxValue={minMaxValues?.maxValue}
                                                        key={`${custIndex}-line`}
                                                        data={trendData[custIndex]}
                                                        categories={trendCategoryTitles}
                                                        title={
                                                            printStatus
                                                                ? getCustlabelByCustId(custIndex)
                                                                : `${getCustlabelByCustId(custIndex)}
                                                                - ${countryLabel}
                                                                - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration.positiveAttributeFilter.in?.values[0]} - ${adjdeposititle ? adjdeposititle + "-" : ""} ${selectedKpi.name}`
                                                        }
                                                        subtitle={!printStatus ? basket.name : null}
                                                        height={printStatus ? 300 : 500}
                                                        width={printStatus ? 1200 : null}
                                                        chartProps={{
                                                            pdfMode: printStatus,
                                                            screenshotName: `basket-${basket.name}-trends-${custIndex}`,
                                                            enableLegend: !printStatus,
                                                            marginBottom: printStatus ? 70 : trendData[custIndex]?.length > 6 ? 180 : 120,
                                                            menuButtonPosition: "inside",
                                                            // maxValue: maxTrendsValue * 1.1,
                                                            // minValue: minTrendsValue * 0.8,
                                                        }}
                                                    />
                                                );
                                            })}
                                        </div>
                                    }
                                    {printStatus && (
                                        <div className="legend h-min w-full grid grid-cols-2 gap-1">
                                            {Object.entries(colorSeries).map(([name, color]) => (
                                                <div className="flex gap-2 items-center">
                                                    <div
                                                        style={{
                                                            width: "15px",
                                                            // height: "10px",
                                                            borderBottom: `solid ${color}`,
                                                        }}
                                                    ></div>
                                                    <span className="text-xs font-normal">{name}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {printStatus && (
                                        <div className="fixed bottom-0">
                                            <BasketSummary userPref={userPreferenceContext.kpiSettings} kpi={selectedKpi} />
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            basketTVSlotsPages.map((versionSlots, index) => (
                                <div className={"break-after-page " + (basketTVSlotsPages?.length !== 1 ? "mt-1" : "")}>
                                    {printStatus && (
                                        <PdfHeader
                                            adjdeposititle={adjdeposititle}
                                            country={countryLabel}
                                            channel1={filterChannel1.positiveAttributeFilter.in?.values[0]}
                                            duration={filterDuration.positiveAttributeFilter.in?.values[0]}
                                            kpi={selectedKpi.name}
                                            basketName={basket.name}
                                            date={monthDate}
                                        />
                                    )}
                                    <div className={printStatus ? "grid py-4 gap-4" : "flex flex-col gap-4 items-stretch"}>
                                        {versionSlots.map((version, index) => {
                                            let filterVersion = newPositiveAttributeFilter(Md.VersionStd, [version]);
                                            return (
                                                <BasketLineChart
                                                    kpiSettings={userPreferenceContext.kpiSettings}
                                                    minValue={minMaxValues?.minValue}
                                                    maxValue={minMaxValues?.maxValue}
                                                    filters={[filterVersion, filterChannel1, filterCountryId, filterDuration, custIdFilters]}
                                                    displayMode={displayMode}
                                                    custIndex={version}
                                                    key={`${version}-line`}
                                                    data={trendDataV[version]}
                                                    categories={trendCategoryTitles}
                                                    title={
                                                        printStatus
                                                            ? version
                                                            : `${version}
                                                                - ${countryLabel}
                                                                - ${filterChannel1.positiveAttributeFilter.in?.values[0]} - ${filterDuration.positiveAttributeFilter.in?.values[0]} - ${adjdeposititle ? adjdeposititle + "-" : ""} ${selectedKpi.name}`
                                                    }
                                                    subtitle={!printStatus ? basket.name : null}
                                                    height={printStatus ? 300 : 500}
                                                    width={printStatus ? 1200 : null}
                                                    chartProps={{
                                                        pdfMode: printStatus,
                                                        screenshotName: `basket-${basket.name}-trends-${version}`,
                                                        enableLegend: !printStatus,
                                                        marginBottom: printStatus ? 70 : 120,
                                                        menuButtonPosition: "inside",
                                                        // maxValue: maxTrendsValue * 1.1,
                                                        // minValue: minTrendsValue * 0.8,
                                                    }}
                                                />
                                            );
                                        })}
                                    </div>
                                    {printStatus && (
                                        <div className="legend h-min w-full grid grid-cols-2 gap-1">
                                            {Object.entries(colorVSeries).map(([name, color]) => (
                                                <div className="flex gap-2 items-center">
                                                    <div
                                                        style={{
                                                            width: "15px",
                                                            // height: "10px",
                                                            borderBottom: `solid ${getColorByCustId(name)}`,
                                                        }}
                                                    ></div>
                                                    <span className="text-xs font-normal">{getCustlabelByCustId(name)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    {printStatus && (
                                        <div className="fixed bottom-0">
                                            <BasketSummary userPref={userPreferenceContext.kpiSettings} kpi={selectedKpi} />
                                        </div>
                                    )}
                                </div>
                            ))
                        ))}
                </div>
            )}
            {/* <Modal
                title="Missing vehicles"
                open={showModal}
                onCancel={() => setShowModal(false)}
                className="w-96"
                footer={
                    <div className="flex justify-end">
                        <Button onClick={() => setShowModal(false)}>Close</Button>
                    </div>
                }
            >
                <ul className="list-disc pl-4">
                    {missingVehicleSlots.map((item, index) => (
                        <li key={index} className="text-indigo-700 py-2 text-xs font-medium flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <LiaCarSideSolid className="w-5 h-5 text-red" />
                                {item}
                            </div>
                            <PiMagicWandFill
                                className="w-4 h-4 text-indigo-700 hover:text-indigo-700/50 cursor-pointer"
                                onClick={() => {
                                    setSelectedVehicle(item);
                                    setShowVersionMatch(true);
                                    setShowModal(false);
                                }}
                            />
                        </li>
                    ))}
                </ul>
            </Modal> */}
            {showVersionMatch && selectedVehicle && (
                <BasketVersionMatch
                    Vehicle={selectedVehicle}
                    onCancel={() => setShowVersionMatch(false)}
                    seriesBy={[...seriesBy, Md.Emissions.Avg, Md.Doors.Avg]}
                    slicesBy={[Md.VersionStd, Md.CustId, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.GearsPlus, Md.Fuel, Md.CorrMake]}
                    currentFilter={currentFilter}
                    filters={[filterCountryId, filterChannel1, filterDuration]}
                    onAdd={onAddToBasket}
                    onReplace={onReplaceVehicle}
                    kpi={selectedKpi}
                />
            )}
        </div>
    );
}
export { BasketViz, PdfHeader };
