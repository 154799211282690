import React from "react";
import { BackendProvider } from "@gooddata/sdk-ui";
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import AppRouter from "./routes/AppRouter";
import { useAuth } from "./contexts/Auth";
import { WorkspaceListProvider } from "./contexts/WorkspaceList";

import { FiltersProvider } from "./contexts/Filters";
import { MeasuresProvider } from "./contexts/Measures";
import { AppProvider } from "./contexts/AppContext";
import { apolloLink } from "./config/apolloConfig";
import { BasketProvider } from "./contexts/BasketProvider";
import { UserProvider } from "./contexts/User";
import { NotificationProvider } from "./contexts/NotificationProvider";
import "./styles/global.css";
import { DashboardProvider } from "./contexts/DashboardProvider";
import { BrandConfiguratorProvider } from "./contexts/BrandConfiguratorProvider";

function App() {
    const { backend } = useAuth();

    const client = new ApolloClient({
        cache: new InMemoryCache(),
        link: apolloLink,
    });

    return (
        // @ts-ignore
        <BackendProvider backend={backend}>
            <WorkspaceListProvider>
                <ApolloProvider client={client}>
                    <UserProvider>
                        <FiltersProvider>
                            <MeasuresProvider>
                                <DashboardProvider>
                                    <AppProvider>
                                        <BasketProvider>
                                            <NotificationProvider>
                                                <BrandConfiguratorProvider>
                                                    <AppRouter />
                                                </BrandConfiguratorProvider>
                                            </NotificationProvider>
                                        </BasketProvider>
                                    </AppProvider>
                                </DashboardProvider>
                            </MeasuresProvider>
                        </FiltersProvider>
                    </UserProvider>
                </ApolloProvider>
            </WorkspaceListProvider>
        </BackendProvider>
    );
}

export default App;
