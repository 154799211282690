import React, { useContext } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { useAppContext } from "../../contexts/AppContext";

import HighchartsComponent from "../HighchartsComponent";
import { newMeasureSort } from "@gooddata/sdk-model";
import Servicesbanner from "../Layout/Servicesbanner";
import MeasuresContext from "../../contexts/Measures";

export default function BarVehicles({ seriesBy, slicesBy, filters, ...props }) {
    const id = "vehicle-bar-chart";
    const { selectedKpi, selectedAdjDeposit } = useContext(MeasuresContext);
    const { getColorByCustId, getCustlabelByCustId } = useAppContext();
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy,
            filters,
            sortBy: [newMeasureSort(seriesBy[selectedKpi.index])],
        },
    });

    const slices = result?.data().slices().toArray();

    const data =
        selectedAdjDeposit !== "Total Payable by month"
            ? slices
                  ?.map((slice) => ({
                      label: slice.sliceTitles()[0],
                      Channel3: slice.sliceTitles()[1],
                      color: getColorByCustId(slice.sliceTitles()[0]),
                      value: Number(slice.dataPoints()[selectedKpi?.index].rawValue) > 1 ? Math.round(Number(slice.dataPoints()[selectedKpi?.index].rawValue)) : Math.round(10000 * Number(slice.dataPoints()[selectedKpi?.index].rawValue)) / 100,
                      adj: Number(slice.dataPoints()[2].rawValue) > 0 ? Number(Number(slice.dataPoints()[2].rawValue).toFixed(0)) : null,
                  }))
                  .filter((elem) => elem["adj"] !== null)
            : slices?.map((slice) => ({
                  label: slice.sliceTitles()[0],
                  Channel3: slice.sliceTitles()[1],
                  color: getColorByCustId(slice.sliceTitles()[0]),
                  value: Number(slice.dataPoints()[selectedKpi?.index].rawValue) > 1 ? Math.round(Number(slice.dataPoints()[selectedKpi?.index].rawValue)) : Math.round(10000 * Number(slice.dataPoints()[selectedKpi?.index].rawValue)) / 100,
              }));

    // Calculate average
    const dataValues = data?.map((dp) => dp.value);
    const average = selectedKpi?.roundValue(dataValues?.reduce((acc, nextValue) => acc + nextValue, 0) / dataValues?.length);

    return (
        <>
            {status === "loading" && (
                <div className="my-2">
                    <LoadingComponent />
                </div>
            )}

            {status === "error" && <ErrorComponent message="Unable to calculate your query" description={"Please verify if the selected vehicles are compatible with the active filters"} />}

            {status === "success" && (
                <>
                    <HighchartsComponent
                        widgetProps={{
                            id,
                            filters,
                            section: "car-selector",
                            flag_services: props?.kpiSettings,
                        }}
                        options={{
                            chart: {
                                type: "column",
                                height: props.height || 400,
                            },
                            title: {
                                text: "",
                                style: {
                                    display: "none",
                                },
                            },
                            tooltip: {
                                formatter: function () {
                                    return getCustlabelByCustId(this.x.label);
                                },
                            },
                            subtitle: {
                                text: "",
                                style: {
                                    display: "none",
                                },
                            },
                            xAxis: {
                                categories: data.map((m) => ({ label: m["label"], channel3: m["Channel3"] })),
                                title: {
                                    text: "",
                                },
                                labels: {
                                    useHTML: true,
                                    formatter: ({ value }) => {
                                        return `<div style="width:70px; height:70px" ><p style="text-align: center" >${value.channel3}</p><img src="/images/logos/Logo_${value.label}.png" width="70" height="70" /></div>`;
                                    },
                                },
                            },
                            yAxis: {
                                min: 0,
                                title: {
                                    text: "",
                                },
                                plotLines: [
                                    {
                                        value: average,
                                        width: 2,
                                        zIndex: 10,
                                        dashStyle: "shortdash",
                                        color: "#506e96",
                                        label: {
                                            text: `Average = ${average}`,
                                            style: {
                                                color: "#506e96",
                                            },
                                        },
                                    },
                                ],
                            },
                            plotOptions: {
                                column: {
                                    borderWidth: 0,
                                    pointPadding: 0.1,
                                    groupPadding: 0,
                                    pointWidth: 55,
                                },
                                series: {
                                    dataLabels: {
                                        enabled: true,
                                        // formatter: function () {
                                        //     return Highcharts.numberFormat(this.y, 0);
                                        // },
                                    },
                                },
                            },
                            series: [
                                {
                                    name: "Adj. Rental",
                                    data: data.map((m) => ({ y: m["value"], color: m["color"] })),
                                    showInLegend: false,
                                },
                            ],
                        }}
                        imgName="car-selector-bar-graph"
                        {...props}
                    />
                    {selectedKpi?.id === 1 && <Servicesbanner kpiSettings={props?.kpiSettings} />}
                </>
            )}
        </>
    );
}
