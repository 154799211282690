import React, { useEffect, useState, useCallback, useContext } from "react";
import { Table, Typography } from "antd";
import { utils, writeFileXLSX } from "xlsx";
import MeasuresContext from "../../contexts/Measures";
import FiltersContext from "../../contexts/Filters";
import Exportbtn from "../Export/Exportbtn";
import { calculateAvg } from "../../utils/calculationUtils";

export default function TableIndices({ data, calculation, startMonth, name, displayBy, dpTitleHandler }) {
    const [columns, setColumns] = useState([]);
    const [isExport, setisExported] = useState(false);
    const { getScopeForTitleHistory } = useContext(MeasuresContext);
    const { getFilterValue } = useContext(FiltersContext);
    const ctryId = getFilterValue("filterCountryId")[0];

    const exportTable = useCallback(() => {
        setisExported(true);
    }, []);

    const [dataSource, setDataSource] = useState(undefined);

    useEffect(() => {
        if (isExport) {
            let formattedData = [];
            for (let item in dataSource) {
                let obj = {};
                for (let key in dataSource[item]) {
                    if (key !== displayBy) {
                        obj[key] = dataSource[item][key] !== null ? Math.round(10 * dataSource[item][key]) / 10 : null;
                    } else {
                        obj[key] = dataSource[item][key] !== "Average" ? dpTitleHandler(dataSource[item][key], ctryId) : "Average";
                    }
                }
                formattedData.push(obj);
            }
            const ws = utils.json_to_sheet(formattedData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, `${name} Table for ${getScopeForTitleHistory("Web Price").replaceAll(" | ", "_")}.xlsx`);
            setisExported(false);
        }
    }, [isExport]);

    useEffect(() => {
        if (dataSource?.length > 0) {
            setColumns(
                Object.keys(dataSource[0]).map((m) => ({
                    title: <Typography.Text style={{ fontSize: 11 }}>{m}</Typography.Text>,
                    fixed: [displayBy].includes(m) ? "left" : null,
                    dataIndex: m,
                    key: m,
                    align: "center",
                    width: 100,
                    render: (cell) => (
                        <div>
                            {m !== displayBy ? (
                                <Typography.Text style={{ fontSize: 11 }}>{cell !== null ? (name === "Monthly % Change" ? Math.round(10 * cell) / 10 + " %" : Math.round(10 * cell) / 10) : null}</Typography.Text>
                            ) : m === "Player" ? (
                                cell !== "Average" ? (
                                    <center>
                                        <img src={`/images/logos/Logo_${cell}.png`} width={60} height={60} alt={m} />
                                    </center>
                                ) : (
                                    <Typography.Text style={{ fontSize: 12, fontWeight: 600, color: "#506e96" }}>{cell}</Typography.Text>
                                )
                            ) : (
                                <Typography.Text style={{ fontSize: 11 }}>{cell}</Typography.Text>
                            )}
                        </div>
                    ),
                })),
            );
        }
    }, [dataSource]);

    useEffect(() => {
        const monthList = data
            .map((m) => {
                const obj = [];
                Object.keys(m).forEach((key) => {
                    if (key !== displayBy && !obj.includes(key)) {
                        obj.push(key);
                    }
                });
                return obj;
            })[0]
            .sort((e1, e2) => e1.localeCompare(e2));

        const calculatedData =
            name === "Index"
                ? data.map((m) => {
                      const dataArr = calculation(m, startMonth);
                      const mthValueObj = monthList.reduce((acc, mth, index) => {
                          acc[mth] = dataArr[index];
                          return acc;
                      }, {});
                      return Object.assign({
                          Player: m[displayBy],
                          ...mthValueObj,
                      });
                  })
                : calculation(data, displayBy, startMonth);
        const avgArr = monthList.reduce((acc, mth, index) => {
            const mthValues = data.reduce((acc, elem) => {
                if (mth in elem && elem[mth] !== null && !isNaN(elem[mth])) acc.push(Number(elem[mth]));
                return acc;
            }, []);
            const avgValue = index !== 0 ? acc[index - 1] * (1 + (calculateAvg(mthValues) / 100 || 0)) : 100;
            acc.push(Number(avgValue));
            return acc;
        }, []);
        const avgChange = avgArr.map((item, index) => {
            if (index !== 0) {
                const prevValue = avgArr
                    .slice(0, index)
                    .reverse()
                    .find((e, i) => e !== null);
                if (prevValue !== undefined) {
                    return item - prevValue;
                }
            } else {
                return 0;
            }
            return item;
        });
        const averageObj = {
            Player: "Average",
            ...monthList.reduce((acc, month, index) => {
                acc[month] = name === "Index" ? avgArr[index] : avgChange[index];
                return acc;
            }, {}),
        };
        setDataSource([averageObj, ...calculatedData]);
    }, [name, calculation, data, startMonth]);

    return (
        <>
            <div>
                <Table className="mb-4" dataSource={dataSource} columns={columns} rowKey={displayBy} size="small" pagination={false} scroll={{ x: columns.reduce((a, b) => a + b.width, 0), y: 400 }} />
                <Exportbtn onclick={exportTable} />
            </div>
        </>
    );
}
