import React, { useContext, useEffect, useState } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { useAppContext } from "../../contexts/AppContext";
import FiltersContext from "../../contexts/Filters";
import HighchartsComponent from "../HighchartsComponent";
import * as Md from "../../md/full";
import { newJsonPivot } from "../../utils/jsonPivot";
import MeasuresContext from "../../contexts/Measures";
import Servicesbanner from "../Layout/Servicesbanner";

/**
 *
 * @param {*} data: List of cust kpi values for each vehicle
 * @returns
 */
const MakeModelOverview = ({ seriesBy, filters, footnote, title, kpiSettings, ...props }) => {
    const id = "synthesis-graph";
    const { getParamsByCustID } = useAppContext();
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { selectedKpi } = useContext(MeasuresContext);
    const [data, setData] = useState([]);

    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId, Md.VersionStd],
            filters: filters,
        },
    });

    useEffect(() => {
        if (result) {
            const data = result
                ?.data()
                .series()
                .toArray()
                .find((serie) => serie.measureTitle() === seriesBy[selectedKpi.index].measure.localIdentifier);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            if (dataPoints) {
                const parsedData = newJsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
                setData(parsedData);
            }
        }
    }, [result, selectedKpi]);

    const chartData = data.map((elem, index) => {
        const dataValues = Object.entries(elem)
            .filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null)
            .map(([key, value]) => value)
            .sort((a, b) => a - b);

        return Object.assign({
            name: elem.versionStd,
            y: dataValues[0],
            color: "#506e96",
        });
    });
    chartData.sort((a, b) => b.y - a.y);

    const categories = chartData.map((item) => item.name);
    const custOfferSeries = data.reduce((acc, elem) => {
        const custOffersMatrix = Object.entries(elem).filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null);
        // find position of the versionStd in the data used in bar chart
        const xIndex = categories.findIndex((category) => category === elem?.versionStd);
        custOffersMatrix.forEach((custOffer) => {
            const custObj = getParamsByCustID(custOffer[0], getFirstFilterValue("filterCountryId"));
            const custSerie = acc.find((item) => item.id === custOffer[0]);
            if (custSerie !== undefined) {
                custSerie.data.push({
                    x: xIndex,
                    y: custOffer[1],
                });
            } else {
                acc.push(
                    Object.assign({
                        type: "scatter",
                        id: custOffer[0],
                        name: custObj?.custLabel,
                        color: custObj?.hexColor,
                        data: [
                            {
                                x: xIndex,
                                y: custOffer[1],
                            },
                        ],
                    }),
                );
            }
        });
        return acc;
    }, []);

    return (
        <div>
            {status === "loading" ? (
                <LoadingComponent />
            ) : (
                <div className="border border-gray-200 shadow rounded mt-1">
                    <HighchartsComponent
                        widgetProps={{
                            id,
                            filters,
                            section: "car-selector",
                            flag_services: kpiSettings,
                        }}
                        options={{
                            chart: {
                                height: props.height || (categories.length < 6 ? 450 : 600),
                            },
                            plotOptions: {
                                // series: {
                                // },
                                bar: {
                                    pointWidth: 30.0,
                                    borderRadius: 2.0,
                                    color: "#506e96",
                                    enableMouseTracking: false,
                                    dataLabels: {
                                        enabled: true,
                                        align: "right",
                                        x: -15,
                                        color: "#FFFFFF",
                                    },
                                },
                                scatter: {
                                    stickyTracking: false,
                                    // findNearestPointBy: 'y',
                                    marker: {
                                        radius: 6,
                                    },
                                },
                            },
                            title: {
                                text: title,
                                align: "center",
                                style: {
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                    color: "#595959",
                                },
                            },
                            tooltip: {
                                snap: 0,
                                formatter: function () {
                                    if (this.series.name !== "Cheapest") {
                                        return `<b>${this.series.name}</b><br/>${this.y}`;
                                    } else return `<span>${this.x}</span><br/><em>Adj.rental</em> ${this.y}`;
                                },
                            },
                            series: [
                                {
                                    name: "Cheapest",
                                    type: "bar",
                                    data: chartData,
                                },
                                ...custOfferSeries,
                            ],
                            xAxis: {
                                categories: categories,
                            },
                            yAxis: {
                                title: {
                                    text: null,
                                },
                            },
                            legend: {
                                enabled: true,
                                symbolRadius: 0,
                                symbolHeight: 16,
                                labelFormatter: function () {
                                    if (this.name === "Cheapest") return `<span style="width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;color:#506e96">${this.name}</span>`;
                                    else return `<div style="width: 200px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">${this.name}</div>`;
                                },
                            },
                        }}
                        {...props}
                    />
                </div>
            )}
            {selectedKpi?.id === 1 && <Servicesbanner kpiSettings={kpiSettings} />}
            {footnote && <span className="text-xs text-gray-500">{footnote}</span>}
        </div>
    );
};

export default MakeModelOverview;
