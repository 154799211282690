import React, { useContext, useEffect, useState } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { ErrorComponent } from "@gooddata/sdk-ui";
import * as Md from "../../md/full";
import FiltersContext from "../../contexts/Filters";
import MeasuresContext from "../../contexts/Measures";
import { tableLegendItems } from "../../routes/Vehicles";
import { CaretDownFill, CaretUpFill } from "../Icons/CaretIcon";
import { StableInflationIcon } from "../Icons/InflationIcon";
import { TableVehicles } from "./TableVehicles";
import { ModelObject, useCarSelector } from "../../contexts/CarSelectorProvider";
import LoadingSpinner from "../LoadingSpinner";
import Servicesbanner from "../Layout/Servicesbanner";

const MakeModelTable = ({ onSelectMultipleRows, footnote, onLoadData = () => {}, enableMultiselection = false }) => {
    const { seriesBy, selectedKpi } = useContext(MeasuresContext);
    const { currentFilter, getFilterValue } = useContext(FiltersContext);
    const { hiddenPlayersField } = useCarSelector();
    const [filterCustID, setFilterCustID] = useState(newPositiveAttributeFilter(Md.CustId, hiddenPlayersField?.players ? [...hiddenPlayersField.players] : []));
    const filterCustIDValues = getFilterValue("filterCustLabel");
    // Getting vehicles by make/model/fuel
    const { result, status, error } = useExecutionDataView({
        execution: {
            seriesBy: [Md.VersionStdCount, Md.ListPrice.Min, Md.ListPrice.Max, Md.Evolpct.Avg, Md.PlayerCount, Md.ListPrice.Avg, Md.Ps.Avg, Md.Co2malus.Avg, Md.Kw.Avg],
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.Fuel, Md.VersionStd],
            filters: [...currentFilter, filterCustIDValues.length <= 0 ? filterCustID : null],
        },
    });
    const { result: players, status: playerstatus } = useExecutionDataView({
        execution: {
            seriesBy: [Md.VersionStdCount, Md.ListPrice.Min, Md.ListPrice.Max, Md.Evolpct.Avg, Md.PlayerCount, Md.ListPrice.Avg, Md.Ps.Avg, Md.Co2malus.Avg, Md.Kw.Avg],
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.Fuel, Md.CustId],
            filters: [...currentFilter, filterCustIDValues.length <= 0 ? filterCustID : null],
        },
    });
    const { selectModelObject, selectedModels, showViz } = useCarSelector();
    const [dataTable, setDataTable] = useState([]);
    const [tableVehicleFilter, setTableVehicleFilter] = useState([]);
    const columns = [
        {
            title: "Make",
            key: "make",
            type: "String",
        },
        {
            title: "Model",
            key: "model",
            type: "String",
        },
        {
            title: "Energy",
            key: "fuel",
            type: "String",
        },
        {
            title: "Versions",
            key: "Versions",
            type: "Number",
        },
        {
            title: "Players",
            key: "players",
            type: "Number",
        },
        {
            title: "min List Price",
            key: "minListPrice",
            type: "Number",
        },
        {
            title: "max List Price",
            key: "maxListPrice",
            type: "Number",
        },
        {
            title: "Rental Evolution",
            key: "evolution",
            type: "Number",
        },
    ];

    function onClickTableRow(dataElement, index) {
        dataElement.openVehicleTable = !dataElement.openVehicleTable;
        dataTable.splice(index, 1, dataElement);
        setDataTable([...dataTable]);
    }

    function onSelectNestedRow(modelObj, vehicles) {
        modelObj.vehicles = vehicles;
        modelObj.selectionStatus = "partial";
        selectModelObject(modelObj);

        // const elemIndex = dataTable.findIndex((elem) => JSON.stringify(elem) === JSON.stringify(modelRow));
        // if (elemIndex !== -1) {
        //     dataTable[elemIndex].selectionStatus = "partial";
        //     setDataTable([...dataTable]);
        // }
    }
    function onSelectAll(dataElement, event) {
        event.stopPropagation();
        // Handler fired when clicking on the radio btn in the table
        let elemIndex = dataTable.findIndex((elem) => JSON.stringify(elem) === JSON.stringify(dataElement));
        if (elemIndex !== -1) {
            dataTable[elemIndex] = {
                ...dataTable[elemIndex],
                // selectAll: !dataElement?.selectAll || false,
                selectionStatus: !(dataElement?.selectionStatus === "all") ? "all" : "none",
                // row must be opened
                openVehicleTable: true,
            };
            selectModelObject(dataTable[elemIndex]);
            setDataTable([...dataTable]);
        }
    }

    function onClickViz(item) {
        return showViz(item);
    }

    function sortColumn(column, sortOrder) {
        let comparator;
        if (column.type === "String") {
            comparator = sortOrder === "asc" ? (a, b) => a[column.key].localeCompare(b[column.key]) : (a, b) => b[column.key].localeCompare(a[column.key]);
        } else {
            comparator = sortOrder === "asc" ? (a, b) => a[column.key] - b[column.key] : (a, b) => b[column.key] - a[column.key];
        }
        const sortedData = dataTable.sort(comparator);
        setDataTable([...sortedData]);
    }

    useEffect(() => {
        // Parse the data
        const slices = result?.data().slices().toArray();
        // group the data by make/model/fuel
        const groupedData =
            slices?.length > 0 &&
            slices?.reduce((acc, slice) => {
                const sliceTitles = slice.sliceTitles();
                const make = sliceTitles[0];
                const model = sliceTitles[1];
                const fuel = sliceTitles[2];
                if (!acc[make]) {
                    acc[make] = {};
                }
                if (!acc[make][model]) {
                    acc[make][model] = {};
                }
                if (!acc[make][model][fuel]) {
                    acc[make][model][fuel] = [];
                }
                acc[make][model][fuel].push(slice);
                return acc;
            }, {});

        // count the number of custId for each make/model/fuel
        const playersData = players?.data().slices().toArray();
        const playersGroupedData =
            playersData?.length > 0 &&
            playersData?.reduce((acc, slice) => {
                const sliceTitles = slice.sliceTitles();
                const make = sliceTitles[0];
                const model = sliceTitles[1];
                const fuel = sliceTitles[2];
                if (!acc[make]) {
                    acc[make] = {};
                }
                if (!acc[make][model]) {
                    acc[make][model] = {};
                }
                if (!acc[make][model][fuel]) {
                    acc[make][model][fuel] = [];
                }
                acc[make][model][fuel].push(slice);
                return acc;
            }, {});
        const parsedData =
            (groupedData &&
                Object.keys(groupedData)
                    .map((make) => {
                        return Object.keys(groupedData[make]).map((model) => {
                            return Object.keys(groupedData[make][model]).map((fuel) => {
                                const slice = groupedData[make][model][fuel];
                                const mesuresData = slice.map((item) => item.rawData());
                                return new ModelObject({
                                    make: make,
                                    model: model,
                                    fuel: fuel,
                                    Versions: mesuresData?.length,
                                    minListPrice: mesuresData?.length > 0 ? (isFinite(Math.min(...mesuresData.filter((item) => item[1] !== null).map((item) => item[1]))) ? Math.min(...mesuresData.filter((item) => item[1] !== null).map((item) => item[1])).toFixed(0) : null) : null,
                                    maxListPrice: mesuresData?.length > 0 ? (isFinite(Math.max(...mesuresData.filter((item) => item[2] !== null).map((item) => item[2]))) ? Math.max(...mesuresData.filter((item) => item[2] !== null).map((item) => item[2])).toFixed(0) : null) : null,
                                    evolution:
                                        mesuresData?.filter((item) => item[3] !== null).length > 0
                                            ? (
                                                  (Number(
                                                      mesuresData.reduce((acc, item) => {
                                                          if (item[3] !== null) {
                                                              return Number(acc) + Number(item[3]);
                                                          }
                                                          return acc;
                                                      }, 0),
                                                  ) /
                                                      mesuresData.filter((item) => item[3] !== null).length) *
                                                  100
                                              ).toFixed(1)
                                            : null,
                                    players: playersGroupedData && playersGroupedData[make] && playersGroupedData[make][model] && playersGroupedData[make][model][fuel] ? playersGroupedData[make][model][fuel].length : null,
                                    filters: [newPositiveAttributeFilter(Md.CorrMake, [make]), newPositiveAttributeFilter(Md.CorrModel, [model]), newPositiveAttributeFilter(Md.Fuel, [fuel])],
                                    selectionStatus: selectedModels.find((item) => item.make === make && item.model === model && item.fuel === fuel)?.selectionStatus || "none",
                                });
                            });
                        });
                    })
                    ?.flat(2)) ||
            [];
        if (parsedData) {
            setDataTable(parsedData);
            // setColumns(
            //     Object.keys(parsedData[0]).map((column) =>
            //         Object.assign({
            //             title: column,
            //             key: column,
            //         }),
            //     ),
            // );
        }
    }, [result, players]);

    useEffect(() => {
        // Initialize nested vehicle table filter with all filters except make, model and fuel filters
        const vehicleFilters = currentFilter.filter((itemFilter) => ![Md.CorrMake.attribute?.displayForm?.identifier, Md.CorrModel.attribute?.displayForm?.identifier, Md.Fuel.attribute?.displayForm?.identifier].includes(itemFilter?.positiveAttributeFilter?.displayForm?.identifier));
        setTableVehicleFilter([...vehicleFilters]);
    }, [currentFilter]);

    useEffect(() => {
        setFilterCustID(newPositiveAttributeFilter(Md.CustId, hiddenPlayersField?.players ? [...hiddenPlayersField.players] : []));
    }, [hiddenPlayersField]);

    useEffect(() => {
        status === "success" && dataTable?.length > 0 && onLoadData();
    }, [dataTable, status]);

    return status === "loading" || playerstatus === "loading" ? (
        <LoadingSpinner />
    ) : status === "error" ? (
        <ErrorComponent
            className="h-96 w-full flex flex-col items-center justify-center my-8"
            message={error?.getMessage() === "NO_DATA" ? "This data is not available with your access" : "The data is too large to compute"}
            description={error?.getMessage() === "NO_DATA" ? "Please feel free to contact us if you need any information" : "Please filter down or change to the model/energy view via settings."}
            // ? "We're experiencing data size limit issue. Please filter down your selection to display data in Car Selector until the issue is resolved." :
            // "No data available for your selection."
        />
    ) : (
        <>
            {dataTable?.length > 0 && (
                <div className="flex flex-col items-stretch gap-2">
                    <div
                        className="overflow-y-auto overflow-x-hidden shadow-inner"
                        style={{
                            maxHeight: "550px",
                        }}
                    >
                        <table className="w-full text-left">
                            <thead className="bg-gray-50 sticky top-0 z-10">
                                <tr>
                                    {enableMultiselection && <th className="max-w-8 bg-gray-100" />}
                                    {columns.map((column) => (
                                        <th className="py-2 px-4 font-normal text-base max-w-12 bg-gray-100" align={["make", "model", "fuel"].includes(column.key) ? "left" : "center"}>
                                            <span className="flex items-center justify-around">
                                                <div className="flex-1">{column.title}</div>
                                                <div className="flex flex-col">
                                                    <span>
                                                        <CaretUpFill width={12} onClick={() => sortColumn(column, "desc")} className="fill-current cursor-pointer text-gray-300 hover:text-indigo-500" />
                                                    </span>
                                                    <span>
                                                        <CaretDownFill width={12} className="fill-current text-gray-300 cursor-pointer hover:text-indigo-500" onClick={() => sortColumn(column, "asc")} />
                                                    </span>
                                                </div>
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {dataTable.map((item, index) => (
                                    <>
                                        <tr
                                            onClick={() => {
                                                onClickTableRow(item, index);
                                            }}
                                            className={(item.openVehicleTable && "bg-indigo-100") + " cursor-copy border-b border-gray-200 hover:bg-gray-100"}
                                        >
                                            {enableMultiselection && (
                                                <td key={index} className="flex gap-2 items-center justify-center p-2">
                                                    <input
                                                        type="checkbox"
                                                        style={{
                                                            accentColor: "#6B50B6",
                                                            cursor: "pointer",
                                                        }}
                                                        ref={(input) => {
                                                            if (input) input.indeterminate = item.selectionStatus === "partial";
                                                        }}
                                                        onClick={(event) => onSelectAll(item, event)}
                                                        // indeterminate={item.selectionStatus === "partial"}
                                                        checked={item.selectionStatus === "all"}
                                                        onChange={() => {}}
                                                    />
                                                </td>
                                            )}
                                            {columns.map((column) => {
                                                let dt = item[column.key];
                                                if (column.key === "evolution") {
                                                    // Include inflation arrows

                                                    let inflationStatus = dt && <StableInflationIcon width={20} height={20} color={"#f0ad4e"} />;
                                                    if (Number(dt) > 0.1) {
                                                        inflationStatus = <StableInflationIcon width={20} height={20} className="transform -rotate-45" color={"#bb2124"} />;
                                                    } else if (Number(dt) < -0.1) {
                                                        inflationStatus = <StableInflationIcon width={20} height={20} className="transform rotate-45" color={"#22bb33"} />;
                                                    }
                                                    const cellContent = dt && dt + "%";

                                                    return (
                                                        <td className="py-2 px-4 flex justify-center items-center gap-1">
                                                            {cellContent} {inflationStatus}
                                                        </td>
                                                    );
                                                }
                                                if (["make", "model", "fuel"].includes(column.key)) {
                                                    return (
                                                        <td align="left" className="py-2 px-4 border border-gray-100">
                                                            {dt}
                                                        </td>
                                                    );
                                                }
                                                return (
                                                    <td align="center" className="py-2 px-4 border border-gray-100">
                                                        {dt}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                        {item.openVehicleTable && (
                                            <tr className="h-32">
                                                <td colSpan={columns.length + 1} className="py-2 px-4">
                                                    <div
                                                        style={{
                                                            width: "1100px",
                                                        }}
                                                    >
                                                        <TableVehicles
                                                            seriesBy={[...seriesBy, Md.Emissions.Avg, Md.Doors.Avg]}
                                                            slicesBy={[Md.VersionStd, Md.CustId, Md.FontBackColor, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.GearsPlus, Md.Fuel]}
                                                            filters={tableVehicleFilter.concat(item.filters)}
                                                            kpi={selectedKpi}
                                                            footnote={footnote}
                                                            modelItem={item}
                                                            onSelectVehicle={onSelectNestedRow}
                                                            onLoadData={onSelectMultipleRows}
                                                            showFootnote={false}
                                                            displayCustomFields={false}
                                                            loadingComponent={<LoadingComponent />}
                                                            enableMultiSelection={enableMultiselection}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="self-start mt-6 flex gap-4 rounded-sm">
                        <label className="flex items-center font-semibold text-steel gap-2">Legend :</label>
                        {tableLegendItems.map((legendItem) => (
                            <div className="flex items-center gap-2">
                                <div
                                    className="py-1 px-2 border border-gray-500 text-xs font-semibold"
                                    style={{
                                        backgroundColor: legendItem.isbackground && legendItem.color,
                                        color: !legendItem.isbackground && legendItem.color,
                                    }}
                                >
                                    123
                                </div>
                                <span className="text-xs font-medium">{legendItem.label}</span>
                            </div>
                        ))}
                    </div>
                    <Servicesbanner />
                    <p className="text-left text-xs font-medium">{footnote}</p>
                </div>
            )}
        </>
    );
};

export { MakeModelTable };
