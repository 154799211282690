import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Basket } from "../types/Basket";
import FiltersContext from "./Filters";
import { GET_BASKETS, GET_SYSTEM_BASKETS, UPDATE_BASKET_MUTATION, SHARE_BASKET_MUTATION, DROP_USER_BASKET_MUTATION, GET_ASSIGNED_USERS_TO_BASKET, REMOVE_USER_ASSIGNMENT_TO_BASKET, UPDATE_BASKET_ASSIGNMENT } from "../queries/baskets";

let initialValues = {
    vehicles: [],
    basket: undefined,
    basketCollaborators: [],
    // Selected basket filter value
    selectedBasketFilters: undefined,
    basketViz: undefined,
    isBasketModalOpened: false,
    //Basket reference
    reference: {
        customer: undefined,
        versionStd: undefined,
        value: undefined,
    },
    // List of user baskets
    userBaskets: [],
    // The list of baskets shared by other users
    assignedBaskets: [],
    // List of system baskets
    systemBaskets: [],
    refetchUserBaskets: ({ country }) => {},
    refetchSystemBaskets: ({ country }) => {},
    // This state's used for PDF generation
    selectedBaskets: [],
    selectedBasketGroup: undefined,
    // This state's used for scope option
    scope: "Selected duration",
    updateState: (state) => {},
    addVehicle: (vehicle) => false,
    addVehicles: (vehicles) => false,
    editBasket: (basketId, payload) => {},
    getBasketType: (user) => undefined,
    removeVehicle: (vehicle) => {},
    emptyBasket: () => {},
    loadBasket: (basket, vehicles) => {},
    shareBasket: ({ basket, sharedBy, collaborators, role = "reader" }) => Promise.resolve(undefined),
    visualizeBasket: (basket) => {},
    unSelectBasketViz: () => {},
    selectBasketFilters: (baskets) => {},
    setSelectedBaskets: (baskets) => {},
    updateBasketPrintStatus: (basket) => {},
    clearSelectedBaskets: () => {},
    selectBasketGroup: (basketGroup) => {},
    setBasketModal: (modalStatus) => {},
    setScope: (scope) => {},
    setReference: (reference) => {},
    updateBasketAssignmentInfo: (objects) => Promise.resolve(undefined),
    dropBasketUserPermission: ({ basket }) => Promise.resolve(undefined),
    removeAssignedUsersFromBasket: ({ ids }) => Promise.resolve(undefined),
};

const basketTypes = {
    owner: "Personal",
    reader: "Shared",
    manager: "Collaborative",
};

let BasketContext = React.createContext({
    ...initialValues,
});

function BasketProvider({ children }) {
    const { getFirstFilterValue } = useContext(FiltersContext);
    const [basketState, setBasketState] = useState({ ...initialValues });
    const [basketCollaborators, setBasketCollaborators] = useState([]);
    const countryValue = getFirstFilterValue("filterCountryId");
    const { data: userBasketResult, refetch: refetchUserBaskets } = useQuery(GET_BASKETS, {
        variables: {
            country: countryValue,
        },
    });

    const { data: systemBasketResults, refetch: refetchSystemBaskets } = useQuery(GET_SYSTEM_BASKETS, {
        variables: {
            country: countryValue,
        },
    });
    const { data: basketCollaboratorsDataQuery } = useQuery(GET_ASSIGNED_USERS_TO_BASKET, {
        variables: {
            basketId: basketState.basket?.id,
        },
    });

    const [editBasketMutation] = useMutation(UPDATE_BASKET_MUTATION);
    const [assignBasketToUsers] = useMutation(SHARE_BASKET_MUTATION);
    const [dropAuthUserFromBasket] = useMutation(DROP_USER_BASKET_MUTATION);
    const [removeAssignedUsersFromBasketMutation] = useMutation(REMOVE_USER_ASSIGNMENT_TO_BASKET);
    const [updateBasketAssignment] = useMutation(UPDATE_BASKET_ASSIGNMENT, {
        refetchQueries: [
            {
                query: GET_ASSIGNED_USERS_TO_BASKET,
            },
        ],
    });

    function shareBasket({ basket, sharedBy, collaborators }) {
        if (collaborators?.length === 0) throw new Error("Unable to find a connected user with those emails");
        const data = collaborators.map((item) =>
            Object.assign({
                assigned_by: sharedBy.id,
                assigned_to: item.id,
                basket: basket.id,
                access_role: item.access_role,
            }),
        );
        return assignBasketToUsers({
            variables: {
                data,
            },
        });
    }

    function dropBasketUserPermission({ basket }) {
        return dropAuthUserFromBasket({
            variables: {
                id: basket.id,
            },
        });
    }

    function removeAssignedUsersFromBasket({ ids }) {
        return removeAssignedUsersFromBasketMutation({
            variables: {
                ids,
            },
        });
    }

    function updateBasketAssignmentInfo(updatedObjects) {
        const payload = updatedObjects.map((obj) =>
            Object.assign({
                where: {
                    id: {
                        _eq: obj.id,
                    },
                },
                _set: {
                    ...Object.entries(obj).reduce((acc, [key, val]) => {
                        if (key !== "id") {
                            acc[key] = val;
                        }
                        return acc;
                    }, {}),
                },
            }),
        );
        return updateBasketAssignment({
            variables: {
                payload,
            },
        });
    }

    function getBasketType(user) {
        return basketTypes[basketCollaborators.find((item) => item.email === user.username)?.role];
    }

    useEffect(() => {
        let userBasketsTmp = userBasketResult?.re_rentaleye_baskets?.map((basket) => {
            return new Basket({
                id: basket.id,
                name: basket.name,
                type: "user",
                description: basket.description,
                group: basket.basketgroup,
                createdBy: basket.created_by,
                createdAt: basket.created_at,
                updatedAt: basket.updated_at,
                basketFilters: basket.basket_filters,
            });
        });
        let systemBasketsTmp = systemBasketResults?.re_rentaleye_system_baskets?.map((basket) => {
            return new Basket({
                id: basket.id,
                name: basket.name,
                type: "system",
                description: basket.description,
                group: basket.basketgroup,
                createdAt: basket.created_at,
                updatedAt: basket.updated_at,
                basketFilters: basket.basket_filters,
            });
        });
        if (userBasketsTmp && systemBasketsTmp)
            setBasketState({
                ...basketState,
                userBaskets: userBasketsTmp,
                systemBaskets: systemBasketsTmp,
            });
    }, [userBasketResult, systemBasketResults]);

    useEffect(() => {
        let basketUsers = [];
        if (basketCollaboratorsDataQuery?.re_rentaleye_basket_permissions?.length > 0 && JSON.stringify(basketCollaboratorsDataQuery?.re_rentaleye_basket_permissions) !== JSON.stringify(basketCollaborators)) {
            basketUsers = basketCollaboratorsDataQuery?.re_rentaleye_basket_permissions?.map((item) =>
                Object.assign({
                    role: item.access_role,
                    email: item.basket_permissions_user.username,
                }),
            );
            const basketOwner = {
                role: "owner",
                email: basketCollaboratorsDataQuery.re_rentaleye_basket_permissions[0].basket_permissions_assigned_by.username,
            };
            basketUsers.push(basketOwner);
        }
        setBasketCollaborators([...basketUsers]);
    }, [basketCollaboratorsDataQuery]);

    function updateState(newState) {
        return setBasketState({
            ...basketState,
            ...newState,
        });
    }

    function addVehicle(vehicle) {
        let vehicles = basketState.vehicles;
        if (vehicles.length >= 12) {
            throw Error(`Basket can't surpass 12 vehicles`);
        }
        let vehicleOcc = vehicles.find((elem) => elem.Vehicle === vehicle.Vehicle);
        if (!vehicleOcc) {
            basketState.vehicles.push(vehicle);
            setBasketState({
                vehicles: basketState.vehicles,
                ...basketState,
            });
        }
        return !vehicleOcc ? true : false;
    }

    function addVehicles(vehicles) {
        // Adding multiple vehicles
        let newVehicles = vehicles.filter((elem) => !basketState.vehicles.find((item) => elem.Vehicle === item.Vehicle));
        if (newVehicles.length + basketState.vehicles.length > 12) {
            throw Error(`Basket can't surpass 12 vehicles`);
        }
        if (newVehicles.length > 0) {
            basketState.vehicles.push(...newVehicles);
            setBasketState({
                vehicles: basketState.vehicles,
                ...basketState,
            });
        }
        return newVehicles.length > 0 ? true : false;
    }

    function emptyBasket() {
        basketState.vehicles.length = 0;
        basketState.basket = undefined;
        setBasketState({
            ...basketState,
        });
    }

    function editBasket(basketId, payload) {
        const setInput = {
            ...payload,
            updated_at: new Date().toISOString(),
        };
        return editBasketMutation({
            variables: {
                setInput,
                id: basketId,
            },
        }).then((res) => {
            refetchUserBaskets({
                country: countryValue,
            });
        });
    }

    function loadBasket(basket, vehicles) {
        setBasketState({
            ...basketState,
            vehicles: vehicles,
            basket: basket,
        });
    }

    function removeVehicle(vehicle) {
        let vehicleIndex = basketState.vehicles.findIndex((item) => item.Vehicle === vehicle.Vehicle);
        if (vehicleIndex > -1) {
            basketState.vehicles.splice(vehicleIndex, 1);
            setBasketState({
                ...basketState,
            });
        }
    }

    function visualizeBasket(basket) {
        setBasketState({
            ...basketState,
            basketViz: basket,
        });
    }

    function selectBasketFilters(baskets) {
        setBasketState({
            ...basketState,
            selectedBasketFilters: baskets,
        });
    }

    function unSelectBasketViz() {
        basketState.basketViz = undefined;
        setBasketState({
            ...basketState,
        });
    }

    function setSelectedBaskets(baskets) {
        setBasketState({
            ...basketState,
            selectedBaskets: baskets,
        });
    }

    function updateBasketPrintStatus(basket) {
        let selectedBaskets = basketState.selectedBaskets.map((elem) => {
            if (elem.name === basket.name) {
                elem.printStatus = true;
            }
            return elem;
        });
        setBasketState({
            ...basketState,
            selectedBaskets: selectedBaskets,
        });
    }
    function clearSelectedBaskets() {
        basketState.selectedBaskets = [];
        setBasketState({
            ...basketState,
        });
    }

    function setBasketModal(modalStatus) {
        setBasketState({
            ...basketState,
            isBasketModalOpened: modalStatus,
        });
    }

    function selectBasketGroup(elem) {
        setBasketState({
            ...basketState,
            selectedBasketGroup: elem,
        });
    }

    function setReference(reference) {
        setBasketState({
            ...basketState,
            reference: reference,
        });
    }
    function setScope(scope) {
        setBasketState({
            ...basketState,
            scope: scope,
        });
    }

    basketState.addVehicle = addVehicle;
    basketState.addVehicles = addVehicles;
    basketState.emptyBasket = emptyBasket;
    basketState.loadBasket = loadBasket;
    basketState.visualizeBasket = visualizeBasket;
    basketState.selectBasketFilters = selectBasketFilters;
    basketState.removeVehicle = removeVehicle;
    basketState.setSelectedBaskets = setSelectedBaskets;

    return (
        <BasketContext.Provider
            value={{
                ...basketState,
                basketCollaborators,
                editBasket,
                updateState,
                updateBasketPrintStatus,
                setScope,
                selectBasketGroup,
                setReference,
                setBasketModal,
                clearSelectedBaskets,
                unSelectBasketViz,
                shareBasket,
                refetchUserBaskets,
                refetchSystemBaskets,
                dropBasketUserPermission,
                updateBasketAssignmentInfo,
                removeAssignedUsersFromBasket,
                getBasketType,
            }}
        >
            {children}
        </BasketContext.Provider>
    );
}

const useBasketContext = () => useContext(BasketContext);

export { BasketProvider, useBasketContext, GET_BASKETS };
