import React, { useContext, useEffect, useState } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { CustIDByScope, useBrandConfigurator } from "../../contexts/BrandConfiguratorProvider";
import MeasuresContext from "../../contexts/Measures";
import HighchartsComponent from "../HighchartsComponent";
import * as Md from "../../md/full";
import FiltersContext from "../../contexts/Filters";

const widgetId = "brand-model-column-chart";

const ColumnModelAnalysis = ({ filters, ...props }) => {
    const { filterSelectors } = useBrandConfigurator();
    const { selectedKpi } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    // Group by make
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");
    const oemPromoCustIDs = CustIDByScope[`${countryId}-${channel1}`]?.promo;
    const oemConfigCustIDs = CustIDByScope[`${countryId}-${channel1}`]?.config;

    useEffect(() => {
        const serieModelsByMake = Object.entries(filterSelectors).map(([make, modelsObject], index) => {
            const modelsAvg = Object.entries(modelsObject).map(([model, versionStdArr]) => {
                const configValues = versionStdArr.reduce((accConfig, version) => {
                    // Getting the min config
                    const custId = Object.keys(version)
                        .filter((key) => key !== "label" && oemConfigCustIDs.includes(key) && version[key][selectedKpi.index] !== null)
                        .sort((key1, key2) => version[key1][selectedKpi.index] - version[key2][selectedKpi.index])[0];
                    if (custId) accConfig.push(version[custId][selectedKpi.index]);
                    return accConfig;
                }, []);

                const promoValues = versionStdArr.reduce((accPromo, version) => {
                    // Getting the min promo
                    const custId = Object.keys(version)
                        .filter((key) => key !== "label" && oemPromoCustIDs.includes(key) && version[key][selectedKpi.index] !== null)
                        .sort((key1, key2) => version[key1][selectedKpi.index] - version[key2][selectedKpi.index])[0];
                    if (custId) accPromo.push(version[custId][selectedKpi.index]);
                    return accPromo;
                }, []);
                const configAvg =
                    configValues.length > 0
                        ? selectedKpi.id === 4
                            ? Number(((configValues.reduce((sumAcc, nextValue) => sumAcc + nextValue, 0) / configValues.length) * 100).toFixed(2))
                            : Math.round(configValues.reduce((sumAcc, nextValue) => sumAcc + nextValue, 0) / configValues.length) || null
                        : null;
                const promoAvg =
                    promoValues.length > 0 ? (selectedKpi.id === 4 ? Number(((promoValues.reduce((sumAcc, nextValue) => sumAcc + nextValue, 0) / promoValues.length) * 100).toFixed(2)) : Math.round(promoValues.reduce((sumAcc, nextValue) => sumAcc + nextValue, 0) / promoValues.length) || null) : null;
                const barValue = configAvg < promoAvg ? configAvg || promoAvg : promoAvg || configAvg;
                // const barColor = configValues.length > 0 ? BrandColors[make]: "white";
                return Object.assign({
                    make: make,
                    model: model,
                    y: barValue,
                    promo: promoAvg,
                    config: configAvg,
                    color: "white",
                    borderColor: "#3a0ca3",
                    borderWidth: 2,
                    // enableTooltip: configValues.length > 0
                });
            });
            modelsAvg.sort((a, b) => a.y - b.y);
            return modelsAvg;
        }, []);
        // Sort at the make level
        serieModelsByMake.sort((e1, e2) => e2.map((obj) => obj.y).reduce((acc, val) => acc + val, 0) / e2.length - e1.map((obj) => obj.y).reduce((acc, val) => acc + val, 0) / e1.length);
        const categoriesArr = serieModelsByMake
            .filter((serie) => serie?.length > 0)
            .reduce((acc, serieModels) => {
                const makeCategory = Object.assign({
                    name: serieModels[0].make,
                    categories: serieModels.map((serie) => serie.model),
                });
                return [...acc, makeCategory];
            }, []);

        setSeries([
            {
                name: "Cheapest",
                // colors: colorsGrouping,
                colorByPoint: true,
                data: serieModelsByMake.flat(),
                promos: serieModelsByMake.flat().map((serie) => serie.promo),
                configs: serieModelsByMake.flat().map((serie) => serie.config),
                enableMouseTracking: false,
                // showInLegend: false,
            },
        ]);
        setCategories([...categoriesArr]);
    }, [filterSelectors, selectedKpi]);

    return series?.length > 0 && categories.length > 0 ? (
        <>
            <ColumnChartWithNestedLabels
                categories={categories}
                series={series}
                filters={filters}
                brandConfigurator={filterSelectors}
                custIdLabels={{
                    "OEM Promo": oemPromoCustIDs,
                    "OEM Config": oemConfigCustIDs,
                }}
                {...props}
            />
            {props.footnote && <span className="pl-3 pb-2 text-left text-xs font-medium">{props.footnote}</span>}
        </>
    ) : (
        <LoadingComponent />
    );
};

const ColumnModelAnalysisWidget = ({ filters, ...props }) => {
    const { selectedKpi, seriesBy } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.CorrMake, Md.CorrModel, Md.CustId, Md.VersionStd],
            filters,
        },
    });
    // Group by make
    const [series, setSeries] = useState([]);
    const [categories, setCategories] = useState([]);

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");
    const oemPromoCustIDs = CustIDByScope[`${countryId}-${channel1}`]?.promo;
    const oemConfigCustIDs = CustIDByScope[`${countryId}-${channel1}`]?.config;

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        const dataByBrand = slices?.reduce((acc, slice) => {
            const [make, model, custId, versionStd] = slice.sliceTitles();
            const versionObject = {
                label: versionStd,
                [custId]: slice.dataPoints().map((dp) => (dp.rawValue !== null ? Number(dp.rawValue) : null)),
            };
            if (!(make in acc)) {
                acc[make] = {
                    [model]: [versionObject],
                };
            } else {
                if (model in acc[make]) {
                    const index = acc[make][model].findIndex((obj) => obj.label === versionStd);
                    if (index !== -1) {
                        acc[make][model][index] = {
                            ...acc[make][model][index],
                            ...versionObject,
                        };
                    } else {
                        acc[make][model] = [...acc[make][model], versionObject];
                    }
                } else {
                    acc[make][model] = [versionObject];
                }
            }
            return acc;
        }, {});

        if (dataByBrand) {
            const serieModelsByMake = Object.entries(dataByBrand).map(([make, modelsObject], index) => {
                const modelsAvg = Object.entries(modelsObject).map(([model, versionStdArr]) => {
                    const configValues = versionStdArr
                        .filter((obj) => oemConfigCustIDs in obj)
                        .reduce((accConfig, version) => {
                            // Getting the min config
                            const custId = Object.keys(version)
                                .filter((key) => key !== "label" && oemConfigCustIDs.includes(key) && version[key][selectedKpi.index] !== null)
                                .sort((key1, key2) => version[key1][selectedKpi.index] - version[key2][selectedKpi.index])[0];
                            if (custId) accConfig.push(version[custId][selectedKpi.index]);
                            return accConfig;
                        }, []);

                    const promoValues = versionStdArr
                        .filter((obj) => oemPromoCustIDs in obj)
                        .reduce((accPromo, version) => {
                            // Getting the min promo
                            const custId = Object.keys(version)
                                .filter((key) => key !== "label" && oemPromoCustIDs.includes(key) && version[key][selectedKpi.index] !== null)
                                .sort((key1, key2) => version[key1][selectedKpi.index] - version[key2][selectedKpi.index])[0];
                            if (custId) accPromo.push(version[custId][selectedKpi.index]);
                            return accPromo;
                        }, []);

                    const configAvg = selectedKpi.id === 4 ? Number(((configValues.reduce((sumAcc, nextValue) => sumAcc + nextValue, 0) / configValues.length) * 100).toFixed(2)) : Math.round(configValues.reduce((sumAcc, nextValue) => sumAcc + nextValue, 0) / configValues.length) || null;
                    const promoAvg = selectedKpi.id === 4 ? Number(((promoValues.reduce((sumAcc, nextValue) => sumAcc + nextValue, 0) / promoValues.length) * 100).toFixed(2)) : Math.round(promoValues.reduce((sumAcc, nextValue) => sumAcc + nextValue, 0) / promoValues.length) || null;
                    const barValue = configAvg < promoAvg ? configAvg || promoAvg : promoAvg || configAvg;
                    // const barColor = configValues.length > 0 ? BrandColors[make]: "white";

                    return Object.assign({
                        make: make,
                        model: model,
                        y: barValue,
                        promo: promoAvg,
                        config: configAvg,
                        color: "white",
                        borderColor: "#3a0ca3",
                        borderWidth: 2,
                        // enableTooltip: configValues.length > 0
                    });
                });
                modelsAvg.sort((a, b) => a.y - b.y);
                return modelsAvg;
            }, []);
            // Sort at the make level
            serieModelsByMake.sort((e1, e2) => e2.map((obj) => obj.y).reduce((acc, val) => acc + val, 0) / e2.length - e1.map((obj) => obj.y).reduce((acc, val) => acc + val, 0) / e1.length);
            const categoriesArr = serieModelsByMake
                .filter((serie) => serie.length > 0)
                .reduce((acc, serieModels) => {
                    const makeCategory = Object.assign({
                        name: serieModels[0].make,
                        categories: serieModels.map((serie) => serie.model),
                    });
                    return [...acc, makeCategory];
                }, []);

            setSeries([
                {
                    name: "Cheapest",
                    // colors: colorsGrouping,
                    colorByPoint: true,
                    data: serieModelsByMake.flat(),
                    promos: serieModelsByMake.flat().map((serie) => serie.promo),
                    configs: serieModelsByMake.flat().map((serie) => serie.config),
                    enableMouseTracking: false,
                    // showInLegend: false,
                },
            ]);
            setCategories([...categoriesArr]);
        }
    }, [result]);

    return status !== "loading" && series?.length > 0 && categories.length > 0 ? (
        <ColumnChartWithNestedLabels
            categories={categories}
            series={series}
            custIdLabels={{
                promos: oemPromoCustIDs,
                configs: oemConfigCustIDs,
            }}
            {...props}
        />
    ) : (
        <div className="flex flex-col items-center justify-center gap-2 min-h-[400px]">
            <div>
                <LoadingComponent />
            </div>
            <span>Loading...</span>
        </div>
    );
};

const ColumnChartWithNestedLabels = ({ categories, series, ...props }) => (
    <HighchartsComponent
        widgetProps={{
            id: widgetId,
            filters: props.filters,
            section: "oem-views",
        }}
        options={{
            chart: {
                type: "column",
                // width: width,
                height: props.height || 500,
                events: {
                    click: function () {
                        // deactivate tooltip
                        this.series.forEach((serie) => {
                            Object.keys(serie.chart)
                                .filter((key) => key.includes("customTooltip"))
                                .forEach((key) => {
                                    serie.chart[key]?.destroy();
                                    serie.chart[key] = undefined;
                                });
                        });
                    },
                },
            },
            plotOptions: {
                column: {
                    maxPointWidth: 45,
                    // color: "#3a0ca3",
                    colorByPoint: true,
                    borderWidth: 0,
                    groupPadding: 0,
                    pointPadding: 0.1,
                    dataLabels: {
                        enabled: true,
                    },
                },
                spline: {
                    lineWidth: 0,
                    states: {
                        hover: {
                            enabled: false,
                        },
                    },
                    marker: {
                        radius: 7,
                        fillColor: "#ff7d93",
                    },

                    cursor: "pointer",
                    point: {
                        events: {
                            click: function () {
                                const chart = this.series.chart;
                                const point = this;
                                // const tooltipName = `tooltip-${point.x}`;
                                const tooltipName = `customTooltip`;
                                if (chart[tooltipName]) {
                                    // destroy the old one when rendering new
                                    chart[tooltipName].destroy();
                                    chart[tooltipName] = undefined;
                                }
                                // else {
                                // @ts-ignore
                                const tooltipContent = props.brandConfigurator[point.category.parent.name][point.category.name]
                                    .filter((item) => Object.keys(item).includes(...props.custIdLabels[point.series.name]))
                                    .map((item) => item.label)
                                    .sort((a, b) => b.length - a.length);
                                chart[tooltipName] = chart.renderer
                                    .label(tooltipContent.join("<br />"), point.plotX < chart.plotWidth * 0.7 ? chart.plotLeft + point.plotX : point.plotX - 5 * tooltipContent[0].length, point.plotY < chart.plotHeight * 0.5 ? point.plotY : point.plotY - 5 * tooltipContent.length)
                                    .attr({
                                        "stroke-width": 1,
                                        zIndex: 30,
                                        stroke: point.series.color,
                                        padding: 8,
                                        r: 3,
                                        fill: "rgb(247, 247, 247)",
                                    })
                                    .css({
                                        "font-size": "10px",
                                    })
                                    .add(chart.rGroup);
                                // }
                            },
                        },
                    },
                },
            },
            title: {
                text: props.title,
                align: "center",
                style: {
                    fontSize: "16px",
                    fontWeight: 600,
                    color: "#3a0ca3",
                },
            },
            subtitle: {
                text: props.subtitle,
                align: "center",
                style: {
                    fontSize: "12px",
                    fontWeight: 600,
                    color: "#506e96",
                },
            },
            legend: {
                padding: 10,
                margin: 20,
                symbolRadius: 0,
                symbolWidth: 0,
                squareSymbol: false,
                useHTML: true,
                labelFormatter: function () {
                    return this.name === "Cheapest"
                        ? `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:12px; height:12px; border: 2px solid #3a0ca3;"></div> <span>${this.name}</span></div>`
                        : `<div style="display:flex; gap:3px; align-items: center; margin: 4px 2px;"><div style="width:11px; height:11px; border-radius: 20px; background-color: ${this.color};"></div> <span>${this.name}</span></div>`;
                },
            },
            tooltip: {
                formatter: function () {
                    return `${this.x.parent.name}, ${this.x.name}<br/>${this.series.name} <b>${this.y}</b>`;
                },
            },
            xAxis: {
                categories: categories,
                labels: {
                    // align: 'center',
                    groupedOptions: [
                        {
                            rotation: 0,
                            style: {
                                paddingTop: "10px",
                                margin: "100px",
                            },
                        },
                        {
                            rotation: 0,
                            style: {
                                paddingTop: "10px",
                                fontSize: "4px",
                            },
                        },
                    ],
                    rotation: 0,
                    style: {
                        paddingTop: "10px",
                        paddingBottom: "0px",
                        fontFamily: "Verdana, sans-serif",
                    },
                },
                title: {
                    text: null,
                },
                // min: -0.75,
                // max: Object.keys(filterSelectors).length-1 + 0.75
            },
            yAxis: {
                // labels: {
                //     enabled: false,
                // },
                title: {
                    text: null,
                },
                min: 0,
                // max: series[0].y * 1.1,
                // tickAmount: null,
            },
            series: [
                ...series,
                {
                    name: "OEM Promo",
                    type: "spline",
                    color: "#ff7d93",
                    data: series[0].promos,
                    showInLegend: series[0].promos.filter((val) => val !== null).length > 0,
                },
                {
                    name: "OEM Config",
                    type: "spline",
                    color: "#3a0ca3",
                    data: series[0].configs,
                    showInLegend: series[0].configs.filter((val) => val !== null).length > 0,
                    marker: {
                        symbol: "circle",
                        fillColor: "#3a0ca3",
                    },
                },
            ],
            lang: {
                noData: "No data available",
            },
            noData: {
                style: {
                    fontWeight: "bold",
                    fontSize: "15px",
                    color: "#595959",
                },
            },
        }}
        {...props}
        menuButtonPosition={"inside"}
    />
);

export { ColumnModelAnalysis, ColumnModelAnalysisWidget };
