import React, { useContext, useState, useEffect } from "react";
import { Switch } from "antd";
import UserContext from "../../contexts/User";

const settingsAliases = {
    Bakset_LFMatrix: "Lease Factor Matrix",
    Basket_BarChart: "Bar Charts",
    Basket_Details: "Detailed View",
    Basket_Overview: "Overview",
    Basket_Summary: "Summary Table",
    Basket_Trends: "Trends",
};

/**
 * BasketSettings component displays the Basket Sections Configuration settings.
 * @param {Object} props - Component props.
 * @param {Object} props.initialValues - Initial values for the settings.
 * @param {Function} props.onChange - Function to call when the settings change.
 * @returns {JSX.Element} - Rendered component.
 */
const BasketSettings = ({ initialValues, onChange = (key, val) => {} }) => {
    const { user } = useContext(UserContext);
    const [basket_sections, setBasket_sections] = useState(user?.basket_sections);

    /**
     * Reset the basket_sections state if it contains invalid values.
     */
    useEffect(() => {
        if (basket_sections !== undefined && Object.values(basket_sections).filter((v) => isNaN(v)).length !== 0 && user?.basket_sections !== basket_sections) setBasket_sections(user?.basket_sections);
    }, [user, basket_sections]);

    return (
        initialValues.basket_sections !== undefined && (
            <section className="space-y-4">
                <div>
                    <h3 className="text-xl font-semibold text-indigo-700 my-0">Basket Sections Configuration</h3>
                    <span className="text-sm text-gray-500 opacity-80 font-medium">Select sections to expand in basket view</span>
                </div>
                <div className="w-2/3 grid grid-cols-3 gap-2">
                    {Object.entries(initialValues?.basket_sections)?.map(([key]) => {
                        return (
                            key in settingsAliases && (
                                // Enabled &&
                                // Enabled[key] === 1 &&
                                <div key={key} className="w-max flex gap-2 items-center">
                                    <Switch
                                        size="small"
                                        style={{
                                            backgroundColor: initialValues.basket_sections[key] ? "#3a0ca3" : "#EEF1FF",
                                        }}
                                        checked={initialValues.basket_sections[key]}
                                        onChange={(checked) => {
                                            onChange("basket_sections", { ...initialValues.basket_sections, [key]: checked });
                                        }}
                                    />
                                    <label className="text-base" htmlFor={key}>
                                        {settingsAliases[key]}
                                    </label>
                                </div>
                            )
                        );
                    })}
                </div>
            </section>
        )
    );
};

export default BasketSettings;
