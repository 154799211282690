import React, { Fragment, useContext } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";

import MeasuresContext from "../../contexts/Measures";
import { ArrowDownIcon } from "../Icons/Arrows";

export default function SelectKpi({ title, onSelect = (item) => {}, selectedKpi }) {
    const { availableKpi } = useContext(MeasuresContext);

    return (
        <Combobox
            value={selectedKpi}
            onChange={(obj) => {
                onSelect(obj);
            }}
        >
            <div className="relative mt-1">
                <Combobox.Input
                    onChange={(event) => {
                        console.log(event);
                    }}
                    displayValue={(item) => item?.name}
                    className="w-[125px] px-2.5 py-1 bg-steel border border-indigo-500 text-white rounded font-normal shadow-button"
                />
                <Combobox.Button className="absolute inset-y-0 right-0  flex items-center rounded-r-md px-2 focus:outline-none">
                    <ArrowDownIcon fill={"#3a0ca3"} width={20} height={20} />
                </Combobox.Button>
                {availableKpi.length > 0 && (
                    <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Combobox.Option disabled className="px-2 font-semibold">
                            {title}
                        </Combobox.Option>
                        {availableKpi.map((m) => (
                            <Combobox.Option key={m.id} value={m} as={Fragment} className={({ active }) => (active ? "bg-backgroundColor" : "") + " cursor-default py-2 px-3"}>
                                {({ active, selected }) => {
                                    return (
                                        <div className="flex justify-between">
                                            <span className="whitespace-nowrap">{m.name}</span>
                                            {selected && (
                                                <div>
                                                    <CheckIcon className="h-5 w-5" color="#490fcc" />
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}
