import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import FiltersContext from "../../contexts/Filters";
import { ENABLED_SETTINGS } from "../../queries/Users";
import MeasuresContext from "../../contexts/Measures";

const Servicesbanner = ({ kpiSettings }) => {
    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };
    const { filterCountryId, filterChannel1 } = useContext(FiltersContext);
    const { selectedKpi } = useContext(MeasuresContext);
    // const { kpiSettings } = useUserContext();
    const Enabled = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid: filterCountryId.positiveAttributeFilter.in["values"][0], channel1: filterChannel1.positiveAttributeFilter.in["values"][0] },
    }).data?.re_rentaleye_re_services_settings[0];
    const enabledKpiSettings =
        kpiSettings && Enabled
            ? Object.keys(settingsAliases).reduce((acc, kpiKey) => {
                  acc = {
                      ...acc,
                      [kpiKey]: kpiKey in Enabled && Enabled[kpiKey] ? (kpiKey in kpiSettings && kpiSettings[kpiKey] ? "Y" : "N") : "",
                  };
                  return acc;
              }, {})
            : null;

    const services =
        enabledKpiSettings &&
        Object.keys(enabledKpiSettings)
            ?.filter((key) => enabledKpiSettings[key] === "Y")
            .map((key) => settingsAliases[key]);
    const message = services?.length > 0 ? `${services.join(", ")}` : "No Services";

    return selectedKpi?.index === 23 ? (
        <div className="mb-4 mt-2">
            <div className=" flex text-start text-xs font-semibold">Adjusted Rental Includes : {message}</div>
        </div>
    ) : (
        <div></div>
    );
};

export default Servicesbanner;
