import crossfilter from "crossfilter";

export default function jsonPivot(data, options) {
    var ndx = crossfilter(data);

    var pivotCol = options.column;
    var pivotVal = options.value;
    var pivotRow = options.row;

    var out = [];

    var pivotRowDimension = ndx.dimension(function (d) {
        return d[pivotRow];
    });

    var pivotColDimension = ndx.dimension(function (d) {
        return d[pivotCol];
    });

    var totalByPivotRow = pivotRowDimension.group().reduceSum(function (d) {
        return d[pivotVal];
    });

    var allRecs = totalByPivotRow.all();

    allRecs.forEach(function (rec) {
        pivotRowDimension.filter();
        pivotRowDimension.filter(rec.key);

        var totalByPivotCol = pivotColDimension.group().reduceSum(function (d) {
            return d[pivotVal];
        });

        var data = totalByPivotCol.all();

        var doc = {};

        doc[pivotRow] = rec.key;

        data.forEach(function (d) {
            doc[d.key] = d.value === 0 ? null : d.value;
            // doc[d.key] =
            //     d.value === 0
            //         ? null
            //         : Math.round(d.value)
            //               .toString()
            //               .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        });

        out.push(doc);
    });

    return out;
}
function newJsonPivot(data, options) {
    var flat = data;
    var pivotCol = options.column;
    var pivotVal = options.value;
    var pivotRow = options.row;
    var attributes = options.attributes;

    const pivots = [...new Set(flat.map((item) => item[pivotCol]))].reduce((a, b) => ({ ...a, [b]: null }), {});
    const Pivotedata = flat.reduce((a, b) => {
        a[b[pivotRow]] = {
            [pivotRow]: b[pivotRow],
            ...pivots,
            ...a[b[pivotRow]],
            [b[pivotCol]]: b[pivotVal],
            ...attributes?.reduce((acc, attr) => {
                acc[attr] = b[attr];
                return acc;
            }, {}),
        };
        return a;
    }, {});
    return Object.values(Pivotedata);
}
export { newJsonPivot };
