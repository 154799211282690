import React, { useContext, useEffect, useState } from "react";

import FiltersContext from "../contexts/Filters";
import MeasuresContext from "../contexts/Measures";
import BarCompetitiveness from "../components/Competitiveness/BarCompetitiveness";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import benchmarkComp from "../utils/benchmarkComp";
import VolumeCompetitiveness from "../components/Competitiveness/VolumeCompetitiveness";
import { useAppContext } from "../contexts/AppContext";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { useUserContext } from "../contexts/User";
import * as Md from "../md/full";
import Widget from "../components/Widget";
// import LineCompetitiveness from "../components/Competitiveness/LineCompetitiveness";

export default function Competitiveness() {
    const { currentFilter, getFiltersForFootnote, getFirstFilterValue, filterCountryId, filterDuration, filterChannel1 } = useContext(FiltersContext);
    const { seriesBy, getScopeForTitle, selectedAdjDeposit } = useContext(MeasuresContext);
    const { getCountryByCode, benchmarkReference, setBenchmarkReference } = useAppContext();
    const { defaultUserPref, kpiSettings } = useUserContext();
    const competitivenessChartTitle = getScopeForTitle("Adj. Rental");
    const compVolumesChartTitle = `${getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])} | ${filterDuration.positiveAttributeFilter.in["values"][0]} | ${filterChannel1.positiveAttributeFilter.in["values"][0]}`;
    const footNote = getFiltersForFootnote();
    const country = getFirstFilterValue("filterCountryId");

    // Remove the channel3 && product from the filters and make sure that roll forward filter always enabled
    let newFilters = [];
    currentFilter.forEach((item) => {
        if (![Md.Channel3, Md.Extraptype].map((item) => item?.attribute?.displayForm?.identifier).includes(item?.positiveAttributeFilter?.displayForm?.identifier)) {
            // Make sure the filter of Rw doesn't contain any value
            if (Md.Rolledforward.attribute?.displayForm?.identifier === item?.positiveAttributeFilter?.displayForm?.identifier && item?.positiveAttributeFilter?.in?.values?.length > 0) {
                item = newPositiveAttributeFilter(Md.Rolledforward, []);
            }
            newFilters.push(item);
        }
    });

    const { result, error, status } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy: [Md.Custmixindex, Md.CustId, Md.CustLabel],
            filters: newFilters,
        },
    });

    // const trendExecutionFilters = currentFilter.filter((elem) => elem !== null && ![Md.MthCode.attribute.displayForm.identifier, Md.CustId.attribute.displayForm.identifier].includes(elem.positiveAttributeFilter.displayForm.identifier));
    const [benchmark, setBenchmark] = useState(benchmarkReference);
    const [data, setData] = useState([]);
    const [flat, setFlat] = useState([]);

    function onBenchmarkUpdate(event) {
        setBenchmark(event.target.value);
    }

    useEffect(() => {
        setBenchmark("");
        setData([]);
    }, [currentFilter]);

    useEffect(() => {
        const slices = result?.data()?.slices()?.toArray();
        if (slices) {
            let flat = slices?.map((slice) => {
                return {
                    Custmixindex: slice.sliceTitles()[0],
                    custId: slice.sliceTitles()[1],
                    custLabel: slice.sliceTitles()[2],
                    adjRental: Number(slice.dataPoints()[23].rawValue),
                    adjDeposit: slice.dataPoints()[2].rawValue !== null ? Number(slice.dataPoints()[2].rawValue) : null,
                    count: Number(slice.dataPoints()[28].rawValue),
                };
            });
            if (selectedAdjDeposit !== "Total Payable by month" && country === "GB") {
                flat = flat.filter((elem) => elem.adjDeposit !== null);
            }
            setFlat(flat);
        }
    }, [result, country]);

    useEffect(() => {
        if (flat.length > 0) {
            if (benchmark !== "") {
                const pivots = [...new Set(flat.map(({ custLabel }) => custLabel))].reduce((a, b) => ({ ...a, [b]: null }), {});

                const o1 = flat.reduce((a, b) => {
                    a[b.Custmixindex] = a[b.Custmixindex] || null;
                    a[b.Custmixindex] = a[b.Custmixindex] ? { Custmixindex: b.Custmixindex, ...pivots, ...a[b.Custmixindex], [b.custLabel]: b.adjRental } : { ...pivots, [b.custLabel]: b.adjRental };
                    return a;
                }, {});

                const o2 = flat.reduce((a, b) => {
                    a[b.Custmixindex] = a[b.Custmixindex] || null;
                    a[b.Custmixindex] = a[b.Custmixindex] ? { Custmixindex: b.Custmixindex, ...pivots, ...a[b.Custmixindex], [b.custLabel]: b.count } : { ...pivots, [b.custLabel]: b.count };
                    return a;
                }, {});

                const values = benchmarkComp(Object.values(o1), Object.values(o2), "Custmixindex", benchmark, "adjRental");
                // Sort datafrom negative to positive
                const benchmarkData = values.filter((f) => f.count > 0).sort((a, b) => a.adjRental - b.adjRental);
                setData(benchmarkData);
                if (benchmark !== benchmarkReference) {
                    setBenchmarkReference(benchmark);
                }
            } else if (flat.find((item) => item.custLabel === benchmarkReference)) {
                setBenchmark(benchmarkReference);
            }
        }
    }, [flat, benchmark]);

    useEffect(() => {
        if (benchmark !== benchmarkReference) {
            setBenchmark(benchmarkReference);
        }
    }, [benchmarkReference]);

    return status === "loading" ? (
        <div className="flex justify-center items-center">
            <div aria-label="Loading..." role="status">
                <svg className="h-12 w-12 animate-spin" viewBox="3 3 18 18">
                    <path className="fill-gray-200" d="M12 5C8.13401 5 5 8.13401 5 12c0 3.866 3.13401 7 7 7 3.866.0 7-3.134 7-7 0-3.86599-3.134-7-7-7zM3 12c0-4.97056 4.02944-9 9-9 4.9706.0 9 4.02944 9 9 0 4.9706-4.0294 9-9 9-4.97056.0-9-4.0294-9-9z"></path>
                    <path
                        className="fill-indigo-700"
                        d="M16.9497 7.05015c-2.7336-2.73367-7.16578-2.73367-9.89945.0-.39052.39052-1.02369.39052-1.41421.0-.39053-.39053-.39053-1.02369.0-1.41422 3.51472-3.51472 9.21316-3.51472 12.72796.0C18.7545 6.02646 18.7545 6.65962 18.364 7.05015c-.390599999999999.39052-1.0237.39052-1.4143.0z"
                    ></path>
                </svg>
            </div>
        </div>
    ) : (
        <div className="flex flex-col items-stretch gap-8">
            {flat.length > 0 && (
                <div>
                    <select name="benchmark" onChange={onBenchmarkUpdate} value={benchmark} className="w-[192px] py-0.5 pl-2 border text-indigo-700 font-semibold border-indigo-700 rounded outline-indigo-700">
                        <option disabled value={""}>
                            Choose Benchmark
                        </option>
                        {[...new Set(flat.map(({ custLabel }) => custLabel))].map((m) => (
                            <option key={m} value={m} className="text-indigo-700 font-semibold font-sans">
                                {m}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {data.length > 0 && defaultUserPref?.fl_rw === 0 && (
                <div className="flex gap-2 items-center">
                    <InformationCircleIcon width={20} color="#3a0ca3" />
                    <span className="text-md text-indigo-500 opacity-80 font-medium">Competitiveness analysis includes rolled forward vehicles from last month, when missing on spot month (even when deactivated in settings)</span>
                </div>
            )}

            {data.length > 0 && benchmark !== "" && data.length > 0 && (
                <Widget
                    title={`Rental Gap Vs Benchmark - ${competitivenessChartTitle}`}
                    description={`Difference in ${["UK", "GB"].includes(filterCountryId.positiveAttributeFilter.in?.values[0]) ? "£" : "€"} per month vs the selected benchmark. Calculation made only for the common vehicles between selected benchmark and other players`}
                    footnote={footNote}
                >
                    <BarCompetitiveness data={data} error={error} status={status} benchmark={benchmark} filters={newFilters} kpiSettings={kpiSettings} />
                </Widget>
            )}

            {data.length > 0 && benchmark !== "" && data.length > 0 && (
                <Widget title={`Rental Gap Vehicles Volumes - ${compVolumesChartTitle}`} description={"Number of versions utilised in the gap calculation"} footnote={footNote}>
                    <VolumeCompetitiveness filters={newFilters} benchmark={benchmark} data={data} error={error} status={status} />
                </Widget>
            )}

            {/* {benchmark !== "" && <LineCompetitiveness seriesBy={seriesBy} measureIdentifier={seriesBy[selectedKpi.index]} slicesBy={[Md.MthCode]} benchmarkBy={benchmark} filters={[...trendExecutionFilters]} />} */}
        </div>
    );
}
