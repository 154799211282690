import React from "react";
import { Dropdown } from "antd";

const MenuButton = ({ items }) => {
    return (
        <Dropdown menu={{ items }} trigger={["click"]} arrow>
            <button className="bg-indigo-700 hover:bg-indigo-500 text-white font-semibold shadow-button py-1 rounded-md w-44">Export</button>
        </Dropdown>
    );
};

export default MenuButton;
