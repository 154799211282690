import React from "react";

const ActionButton = ({ title, icon = null, suffixIcon = null, ...props }) => {
    return (
        <button className="flex justify-between items-center gap-2 py-1 px-3 text-steel text-sm font-semibold hover:bg-backgroundColor rounded border-gray-200 border-2 hover:border-indigo-700 disabled:border-gray-300" {...props}>
            {icon} {title} {suffixIcon}
        </button>
    );
};

export { ActionButton };
