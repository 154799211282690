import React, { useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import * as Md from "../../md/full";
import { countriesMap } from "../../contexts/Measures";
import { AiOutlineSync } from "react-icons/ai";
import { Tooltip } from "antd";
import { useAppContext } from "../../contexts/AppContext";
import { BiCollapseHorizontal, BiCollapseVertical, BiExpandHorizontal, BiExpandVertical } from "react-icons/bi";

const primaryFilterAttributes = [Md.Ctryid, Md.Channel1, Md.Duration, Md.MthCode, Md.CustId, Md.VersionStd];

const carAttributes = {
    [Md.CorrMake.attribute.displayForm.identifier]: "Make",
    [Md.CorrModel.attribute.displayForm.identifier]: "Model",
    [Md.Fuel.attribute.displayForm.identifier]: "Fuel",
    [Md.TrimName.attribute.displayForm.identifier]: "Trim",
    [Md.VehSegment.attribute.displayForm.identifier]: "Segment",
    [Md.BodyStyle.attribute.displayForm.identifier]: "Body",
    [Md.VehType.attribute.displayForm.identifier]: "Type",
    [Md.Size.attribute.displayForm.identifier]: "Size",
    [Md.GearsPlus.attribute.displayForm.identifier]: "Gears",
    [Md.Drive.attribute.displayForm.identifier]: "Drive",
    [Md.Channel3.attribute.displayForm.identifier]: "Channel",
    [Md.CustLabel.attribute.displayForm.identifier]: "Player",
    [Md.Extraptype.attribute.displayForm.identifier]: "Quote",
    // mix : ("filterCustmix"),
    [Md.ListPrice.Avg.measure.localIdentifier]: "List Price",
    [Md.Ps.Avg.measure.localIdentifier]: "PS",
    [Md.Kw.Avg.measure.localIdentifier]: "KW",
    [Md.Co2malus.Avg.measure.localIdentifier]: "CO2",
};
const measureOperation = {
    GREATER_THAN: ">",
    GREATER_THAN_OR_EQUAL_TO: ">=",
    LESS_THAN: "<",
    LESS_THAN_OR_EQUAL_TO: "<=",
    EQUAL_TO: "=",
    NOT_EQUAL_TO: "!=",
    BETWEEN: "BETWEEN",
    NOT_BETWEEN: "NOT_BETWEEN",
};

const WidgetWrapper = ({ widget, widgetIndex, enableDnd = false, onUpdateWidgetDimention = (widgetIndex, widgetProps) => {}, onUpdateWidgetPosition = (sourceIndex, targetIndex) => {}, onChangeTitle = (widgetIndex, widgetProps) => {}, ...props }) => {
    const [widgetTitle, setWidgetTitle] = useState(widget.title);
    const [height, setHeight] = useState(widget.height);
    const [width, setWidth] = useState(widget.width);
    const ref = useRef();
    const { getCustlabelByCustId } = useAppContext();
    const [{ isDragging }, drag] = useDrag(
        () => ({
            type: "Widget",
            item: { index: widgetIndex },
            options: {
                dropEffect: "move",
            },
            canDrag: (monitor) => enableDnd,
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
            }),
        }),
        [enableDnd],
    );

    const [{ canDrop, highlighted }, drop] = useDrop(() => ({
        accept: "Widget",
        drop: (targetItem, monitor) => {
            if (targetItem.index !== widgetIndex) {
                onUpdateWidgetPosition(widgetIndex, targetItem.index);
            }
            return undefined;
        },
        collect: (monitor) => ({
            canDrop: monitor.getItem()?.index !== widgetIndex && monitor.canDrop(),
            highlighted: monitor.isOver(),
        }),
    }));

    drop(drag(ref));

    useEffect(() => {
        if (widget.title !== widgetTitle) setWidgetTitle(widget.title);
        if (widget.height !== height) setHeight(widget.height);
        if (widget.width !== width) setWidth(widget.width);
    }, [widget]);

    useEffect(() => {
        if (widget.height !== height || widget.width !== width) onUpdateWidgetDimention(widgetIndex, { height, width });
    }, [height, width]);

    const scopeSubTitle = primaryFilterAttributes
        .filter((filterAttr) => widget.filters.find((filterItem) => filterItem.positiveAttributeFilter.displayForm.identifier === filterAttr.attribute.displayForm.identifier)?.positiveAttributeFilter.in.values.length === 1)
        // Object.keys(widget.filters).includes(filterAttr.attribute.displayForm.identifier) && widget.filters[filterAttr.attribute.displayForm.identifier].length === 1)
        .map((filterAttr) => {
            const scopeFilterValueList = widget.filters.find((filterItem) => filterItem.positiveAttributeFilter.displayForm.identifier === filterAttr.attribute.displayForm.identifier).positiveAttributeFilter.in.values;
            if (filterAttr === Md.Ctryid) {
                return countriesMap(scopeFilterValueList[0]);
            }
            if (filterAttr === Md.CustId) {
                return getCustlabelByCustId(scopeFilterValueList[0]);
            }
            return scopeFilterValueList;
        })
        .join(" | ");

    const filtersSubTitle = Object.keys(carAttributes)
        .filter((filterAttrKey) => Object.keys(widget.filters).includes(filterAttrKey))
        .map((filterAttrKey) => {
            let scopeFilterValue = widget.filters[filterAttrKey];
            if ("comparison" in scopeFilterValue) {
                scopeFilterValue = `${measureOperation[scopeFilterValue.comparison.operator]} ${scopeFilterValue.comparison.value}`;
            }
            return [carAttributes[filterAttrKey], scopeFilterValue].join(" : ");
        })
        .join(" | ");

    const positionMoreButtonTuning = 6 * (Math.round(scopeSubTitle.length / 50) || 1) + 6 * (Math.round(filtersSubTitle.length / 50) || 1) + 50;
    const widgetContent = React.cloneElement(props.children, {
        menuLocation: { top: `-${positionMoreButtonTuning}px` },
    });
    return (
        <div
            ref={ref}
            className={`${enableDnd ? "cursor-grab " : ""} ${height === 2 ? "row-span-2" : ""} ${height === 2 ? "widget-max-height" : "widget-min-height"} ${width === 2 ? "col-span-2" : ""} overflow-auto w-full h-fit min-h-full z-10 border border-gray-200 rounded-md shadow-md p-1 ${
                isDragging ? "shadow-steel shadow-xl" : canDrop && highlighted ? "shadow-indigo-700 shadow-xl" : canDrop ? "shadow-xl" : ""
            }`}
        >
            {/* {props.children} */}
            <div className="flex flex-col items-center  gap-1 pt-2 mb-2">
                {enableDnd && (
                    <div className="flex justify-end gap-2 items-center w-full pt-2 pb-1 px-6">
                        <Tooltip title="Resize widget height" color="#6B50B6">
                            {height === 1 ? (
                                <BiExpandVertical className="w-8 h-8 px-1.5 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" onClick={() => setHeight(2)} />
                            ) : (
                                <BiCollapseVertical className="w-8 h-8 px-1.5 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" onClick={() => setHeight(1)} />
                            )}
                        </Tooltip>
                        <Tooltip title="Resize widget width" color="#6B50B6">
                            {width === 1 ? (
                                <BiExpandHorizontal className="w-8 h-8 px-1.5 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" onClick={() => setWidth(2)} />
                            ) : (
                                <BiCollapseHorizontal className="w-8 h-8 px-1.5 text-gray-500 cursor-pointer hover:bg-backgroundColor hover:text-indigo-700" onClick={() => setWidth(1)} />
                            )}
                        </Tooltip>
                    </div>
                )}
                <div className="inline-flex self-stretch gap-1 px-8 items-center">
                    <input
                        className="text-indigo-700 text-lg font-bold w-full text-center focus:outline-none bg-white"
                        maxLength={52}
                        type={"text"}
                        value={widgetTitle}
                        onChange={(event) => {
                            setWidgetTitle(event.target.value);
                            // onChangeTitle(widgetIndex, {title: event.target.value});
                        }}
                        disabled={!enableDnd}
                    />
                    {widget.title !== widgetTitle && (
                        <Tooltip title="Sync widget name" color="#6B50B6">
                            <AiOutlineSync className="text-indigo-500 cursor-pointer w-5 h-5 mr-4" onClick={() => onChangeTitle(widgetIndex, { title: widgetTitle })} />
                        </Tooltip>
                    )}
                    {/* {(widget.width !== width || widget.height !== height) && (
                        <Tooltip title="Sync widget size" color="#6B50B6">
                            <AiOutlineSync className="text-indigo-500 cursor-pointer w-5 h-5 mr-4" onClick={() => onChangeTitle(widgetIndex, { width, height })} />
                        </Tooltip>
                    )} */}
                </div>
                {widget.description && <span className="font-medium">{widget.description}</span>}
                <span className="px-8 text-sm font-semibold text-steel text-center">{scopeSubTitle}</span>
                {filtersSubTitle && <span className="px-8 text-xs font-semibold text-gray-500 text-center">{filtersSubTitle}</span>}
            </div>
            <div
                className="px-2"
                style={{
                    pointerEvents: enableDnd ? "none" : "auto",
                    opacity: enableDnd ? 0.5 : 1,
                }}
            >
                {widgetContent}
            </div>
        </div>
    );
};

export { WidgetWrapper };
