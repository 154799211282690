import React, { useContext, useState } from "react";
import { Table } from "antd";
import FiltersContext from "../../contexts/Filters";

function ExportTable({ data, columns, type, ...props }) {
    const { getFiltersForFootnote } = useContext(FiltersContext);
    const [selectedPage, setSelectedPage] = useState(1);

    return (
        <div className="flex justify-center items-stretch min-h-full">
            {data?.length > 0 && (
                <div className="bg-white rounded-md overflow-hidden">
                    <Table
                        columns={columns}
                        dataSource={data}
                        rowKey="Id"
                        size="small"
                        pagination={{
                            defaultCurrent: selectedPage,
                            onChange: (page) => {
                                setSelectedPage(page);
                            },
                            pageSize: 250,
                        }}
                        scroll={{ x: columns.reduce((a, b) => a + b.width, 0), y: 400 }}
                    />
                    <p className="mt-5 self-start font-medium text-xs">{getFiltersForFootnote()}</p>
                </div>
            )}
        </div>
    );
}

export default ExportTable;
