import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "antd";
import { useQuery } from "@apollo/client";
import { ENABLED_SETTINGS, GET_KPI_ADJ_SETTINGS } from "../../queries/Users";
import FiltersContext from "../../contexts/Filters";

const DataCoverageWidget = ({ id, execDefinition, componentChild, ...props }) => {
    const { filterChannel1, filterCountryId } = useContext(FiltersContext);
    const [data, setData] = useState([]);
    const { result, status, error } = useExecutionDataView({
        execution: execDefinition,
    });

    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };
    const { data: kpiSettingsResults } = useQuery(GET_KPI_ADJ_SETTINGS, {
        variables: {
            ctryid: filterCountryId.positiveAttributeFilter.in["values"][0],
            channel1: filterChannel1.positiveAttributeFilter.in["values"][0],
        },
    });
    const Enabled = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid: filterCountryId.positiveAttributeFilter.in["values"][0], channel1: filterChannel1.positiveAttributeFilter.in["values"][0] },
    }).data?.re_rentaleye_re_services_settings[0];
    useEffect(() => {
        const slices = result?.data()?.slices().toArray();
        const data = slices?.map((slice) => {
            return {
                CustId: slice.sliceTitles()[0],
                [settingsAliases.flag_bre]: +slice.dataPoints()[0].rawValue === 1 ? "Y" : +slice.dataPoints()[0].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[0].rawValue * 100) + "%",
                [settingsAliases.flag_co2]: +slice.dataPoints()[1].rawValue === 1 ? "Y" : +slice.dataPoints()[1].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[1].rawValue * 100) + "%",
                [settingsAliases.flag_fin]: +slice.dataPoints()[2].rawValue === 1 ? "Y" : +slice.dataPoints()[2].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[2].rawValue * 100) + "%",
                [settingsAliases.flag_ins]: +slice.dataPoints()[3].rawValue === 1 ? "Y" : +slice.dataPoints()[3].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[3].rawValue * 100) + "%",
                [settingsAliases.flag_rep]: +slice.dataPoints()[4].rawValue === 1 ? "Y" : +slice.dataPoints()[4].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[4].rawValue * 100) + "%",
                [settingsAliases.flag_tyr]: +slice.dataPoints()[5].rawValue === 1 ? "Y" : +slice.dataPoints()[5].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[5].rawValue * 100) + "%",
                [settingsAliases.flag_win]: +slice.dataPoints()[6].rawValue === 1 ? "Y" : +slice.dataPoints()[6].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[6].rawValue * 100) + "%",
                [settingsAliases.flag_evb]: +slice.dataPoints()[7].rawValue === 1 ? "Y" : +slice.dataPoints()[7].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[7].rawValue * 100) + "%",
                [settingsAliases.flag_ser]: +slice.dataPoints()[8].rawValue === 1 ? "Y" : +slice.dataPoints()[8].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[8].rawValue * 100) + "%",
            };
        });
        const kpiSettings = kpiSettingsResults?.re_rentaleye_re_params_user_prefs.map((kpi) => {
            return {
                [settingsAliases.flag_ser]: Enabled?.flag_ser ? (kpi.flag_ser ? "Y" : "N") : "",
                [settingsAliases.flag_bre]: Enabled?.flag_bre ? (kpi.flag_bre ? "Y" : "N") : "",
                [settingsAliases.flag_ins]: Enabled?.flag_ins ? (kpi.flag_ins ? "Y" : "N") : "",
                [settingsAliases.flag_rep]: Enabled?.flag_rep ? (kpi.flag_rep ? "Y" : "N") : "",
                [settingsAliases.flag_fin]: Enabled?.flag_fin ? (kpi.flag_fin ? "Y" : "N") : "",
                [settingsAliases.flag_co2]: Enabled?.flag_co2 ? (kpi.flag_co2 ? "Y" : "N") : "",
                [settingsAliases.flag_tyr]: Enabled?.flag_tyr ? (kpi.flag_tyr ? "Y" : "N") : "",
                [settingsAliases.flag_win]: Enabled?.flag_win ? (kpi.flag_win ? "Y" : "N") : "",
                [settingsAliases.flag_evb]: Enabled?.flag_evb ? (kpi.flag_evb ? "Y" : "N") : "",
            };
        });
        if (data?.length > 0) {
            setData(data);
        } else {
            setData(kpiSettings);
        }
    }, [result]);
    const Customer = {
        title: "",
        dataIndex: "CustId",
        key: "CustId",
        render: (cell) => (
            <center>
                <img src={`/images/logos/Logo_${cell}.png`} width={64} height={64} alt={cell} />
            </center>
        ),
        align: "center",
        width: 100,
    };
    let columns =
        Enabled &&
        Object.keys(Enabled)
            .filter((key) => Enabled[key] === 1)
            .map((key) => {
                return {
                    title: settingsAliases[key],
                    dataIndex: settingsAliases[key],
                    key: settingsAliases[key],
                    render: (text) => (
                        <Typography.Text style={{ fontSize: 12 }} strong>
                            {text}
                        </Typography.Text>
                    ),
                    align: "center",
                    width: 100,
                };
            });

    columns?.length > 0 && execDefinition.slicesBy !== undefined && columns.unshift(Customer);

    return status === "success" && data?.length > 0 ? React.createElement(componentChild, { data, columns, ...props }) : status === "loading" ? <LoadingComponent /> : <ErrorComponent message={error?.message} />;
};

export { DataCoverageWidget };
