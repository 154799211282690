import React, { useContext } from "react";
import { ErrorComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { useEffect } from "react";
import { useState } from "react";
import { newAttributeSort, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../../md/full";
import { useAppContext } from "../../../contexts/AppContext";
import UpDownButton from "../../Buttons/UpDownButton";
import UserContext from "../../../contexts/User";
import MeasuresContext from "../../../contexts/Measures";
import { ScatterChart } from "../../Visualization/ScatterChart";
import jStat from "jstat";

function regression(arrWeight, arrHeight) {
    let r, sy, sx, b, a, meanX, meanY;
    r = jStat.corrcoeff(arrWeight, arrHeight);
    sy = jStat.stdev(arrWeight);
    sx = jStat.stdev(arrHeight);
    meanY = jStat(arrWeight).mean();
    meanX = jStat(arrHeight).mean();
    b = r * (sy / sx);
    a = meanY - meanX * b;
    //Set up a line
    let y1, y2, x1, x2;
    x1 = jStat.min(arrHeight);
    x2 = jStat.max(arrHeight);
    y1 = a + b * x1;
    y2 = a + b * x2;
    return {
        line: [
            [x1, y1],
            [x2, y2],
        ],
        r,
    };
}

const LFGraph = ({ filters, kpiSettings, Basket, country, title, setIsLoading, ...props }) => {
    const userPreferenceContext = useContext(UserContext);
    const { seriesBy, selectedAdjDeposit } = useContext(MeasuresContext);
    const [data, setData] = useState([]);
    const [flat, setFlat] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const [corrline, setCorrline] = useState({});
    const [showSummary, setShowSummary] = useState(userPreferenceContext?.user?.basket_sections?.Bakset_LFMatrix);
    const { getColorByCustId } = useAppContext();
    const [versionStdFilter, setVersionStdFilter] = useState(newPositiveAttributeFilter(Md.VersionStd, [Basket.basketFilters.filter((elem) => elem.type === "version_std").map((elem) => elem.value)]));
    const custSlots = Object.entries(userPreferenceContext.defaultUserPref)
        .filter(([key, val]) => key.includes("customer_slot") && val !== null)
        .map(([key, val]) => val);
    const custIdFilters = newPositiveAttributeFilter(Md.CustId, custSlots);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [...seriesBy, Md.Deposit.Avg, Md.Emissions.Avg],
            slicesBy: [Md.Channel1, Md.Ctryid, Md.Duration, Md.Channel3, Md.TrimName, Md.Fuel, Md.GearsPlus, Md.Drive, Md.VehSegment, Md.CustId, Md.CorrModel],
            filters: [...filters, versionStdFilter, custIdFilters],
            sortBy: [newAttributeSort(Md.CorrModel)],
        },
    });
    useEffect(() => {
        if (Basket !== undefined) {
            let versionStds = [];
            Basket.basketFilters
                .filter((basketFilter) => basketFilter.type === "version_std")
                .forEach((basketFilter) => {
                    versionStds.push(basketFilter.value);
                });
            setVersionStdFilter(newPositiveAttributeFilter(Md.VersionStd, versionStds));
        }
    }, [Basket]);
    useEffect(() => {
        if (status !== "loading") {
            setIsLoading(false);
        } else {
            setIsLoading(true);
        }
    }, [status, setIsLoading]);

    useEffect(() => {
        if (result && status === "success") {
            const slices = result?.data()?.slices().toArray();
            if (slices?.length > 0) {
                const flat = slices?.map((slice) => {
                    return {
                        Model: slice.sliceTitles()[10],
                        Trim: slice.sliceTitles()[4],
                        Player: slice.sliceTitles()[9],
                        "List Price": Number(slice.dataPoints()[1].rawValue) > 0 ? Number(Number(slice.dataPoints()[1].rawValue).toFixed(0)) : null,
                        "Adj. Rental": Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null,
                        "Adj. Deposit": Number(slice.dataPoints()[2].rawValue) > 0 ? Number(Number(slice.dataPoints()[2].rawValue).toFixed(0)) : null,
                        "Leasing Factor": 100 * Number(slice.dataPoints()[24].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null,
                    };
                });
                if (selectedAdjDeposit !== "Total Payable by month") {
                    setFlat(flat.filter((elem) => elem["Adj. Deposit"] !== null));
                } else {
                    setFlat(flat);
                }
                const series = flat
                    .map((elem) => {
                        return {
                            name: elem.Player,
                            color: getColorByCustId(elem.Player),
                            data: flat
                                .filter((elem2) => elem2.Player === elem.Player)
                                .map((elem2) => {
                                    return {
                                        x: elem2["List Price"],
                                        y: elem2["Adj. Rental"],
                                        z: elem2["Leasing Factor"],
                                        t: elem2.Trim,
                                        m: elem2.Model,
                                    };
                                }),
                        };
                    })
                    .filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i);
                setSelectedCustomer(series.map((elem) => elem.name));
                setData(series);
            }
        }
    }, [result]);

    useEffect(() => {
        const filtered = flat?.filter((elem) => selectedCustomer.includes(elem.Player));
        const arrLP = filtered.filter((elem) => elem["Leasing Factor"] !== null).map((elem) => elem["List Price"]);
        const arrAR = filtered.filter((elem) => elem["Leasing Factor"] !== null).map((elem) => elem["Adj. Rental"]);
        const { line, r } = regression(arrAR, arrLP);
        setCorrline({ line, r });
    }, [selectedCustomer, flat]);

    return (
        <>
            {status === "error" && (
                <div>
                    <ErrorComponent />
                </div>
            )}
            {status === "success" && (
                <div className="my-8">
                    <div className="mt-2 flex justify-between items-stretch">
                        <span className="font-semibold text-lg text-steel">Leasing factor matrix</span>
                        <UpDownButton visibilityState={showSummary} onClickHandler={() => setShowSummary(!showSummary)} />
                    </div>
                    {showSummary && (
                        <>
                            <ScatterChart
                                series={data}
                                country={country}
                                title={title}
                                corrline={corrline}
                                selectedCustomer={selectedCustomer}
                                setSelectedCustomer={setSelectedCustomer}
                                filters={[...filters, versionStdFilter, custIdFilters]}
                                id={"basket-LFMatrix-chart"}
                                section={"basketLFM"}
                                kpiSettings={kpiSettings}
                                {...props}
                            />
                        </>
                    )}
                </div>
            )}
        </>
    );
};

export default LFGraph;
