import React, { useRef, useState } from "react";
import { MeasureValueFilterDropdown } from "@gooddata/sdk-ui-filters";
import ButtonFilter from "./Filters/ButtonFilter";

const DropdownButton = ({ isActive, measureTitle, onClick, filterapplied }) => {
    return <ButtonFilter onClick={onClick} isActive={filterapplied !== null} title={measureTitle} />;
};

export const MesureFilterCustomizations = ({ measureIdentifier, title, filter, onApply }) => {
    const [displayDropdown, setDisplayDropdown] = useState(false);

    const ref = useRef();

    const onCancel = () => {
        setDisplayDropdown(false);
    };

    const toggleDropdown = () => {
        setDisplayDropdown((current) => !current);
    };
    return (
        <div>
            <div ref={ref}>
                <DropdownButton onClick={toggleDropdown} isActive={displayDropdown} filterapplied={filter} measureTitle={title} />
            </div>
            {displayDropdown ? <MeasureValueFilterDropdown onApply={onApply} onCancel={onCancel} filter={filter} anchorEl={ref.current} measureIdentifier={measureIdentifier} /> : null}
        </div>
    );
};

export default MesureFilterCustomizations;
