import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

import CustomLoading from "../CustomLoading";

import { auth0Domain, auth0ClientID, auth0LogoutUrl } from "../../constants";

const LogoutForm = ({ logout }) => {
    useEffect(
        () => {
            // logout().then(() => history.push("/login"));
            logout().then(() => window.location.assign(`https://${auth0Domain}/v2/logout?client_id=${auth0ClientID}&returnTo=${encodeURIComponent(auth0LogoutUrl)}`));
        },
        // only call the logout on initial mount -> the empty array is correct here
        [], // eslint-disable-line react-hooks/exhaustive-deps
    );

    return <CustomLoading label="Logging you out..." />;
};

export default withRouter(LogoutForm);
