import React, { useContext, useState } from "react";
import UpDownButton from "../../Buttons/UpDownButton";
import UserContext from "../../../contexts/User";
import TableComponent from "../../TableComponent";

const Summary = ({ columns, filters, dataTable, selectedBaskets, ...props }) => {
    const { user } = useContext(UserContext);
    const [showSynthesis, setShowSynthesis] = useState(user?.basket_sections?.Basket_Summary);
    return (
        <div className="my-8">
            <div className="my-4 flex justify-between items-stretch">
                <span className="font-semibold text-lg text-steel">Summary Table</span>
                <UpDownButton visibilityState={showSynthesis} onClickHandler={() => setShowSynthesis(!showSynthesis)} />
            </div>
            {showSynthesis && (
                <>
                    <TableComponent
                        columns={columns}
                        dataSource={dataTable}
                        size={"small"}
                        pagination={false}
                        scroll={{
                            y: 400,
                        }}
                        bordered
                        className="border border-steel-300 mt-16"
                        widgetProps={{
                            id: "summary-table",
                            filters: filters,
                            section: "basketsummary",
                            metadata: { selectedBaskets },
                        }}
                        {...props}
                    />
                </>
            )}
        </div>
    );
};

export default Summary;
