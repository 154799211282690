import { useExecutionDataView } from "@gooddata/sdk-ui";
import { Modal, Select } from "antd";
import React from "react";
import { utils, writeFile } from "xlsx";
import * as Md from "../../md/full";
import { useEffect } from "react";
import { useState } from "react";

const exportFile = (data) => {
    const ws = utils.json_to_sheet(data);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Sheet1");
    writeFile(wb, "oem_offers.xlsx");
};

const Export = ({ modalVisible, setModalVisible, seriesBy, data, filters }) => {
    const [durations, setDurations] = useState([]);
    const [selectedDurations, setSelectedDurations] = useState([]);
    const [exportData, setExportData] = useState([]);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [...seriesBy, Md.Doors.Avg, Md.Apr.Avg],
            slicesBy: [Md.VersionStd, Md.CorrMake, Md.Fuel, Md.Channel3, Md.Duration, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.TrimName, Md.Version, Md.GearsPlus],
            filters: filters,
        },
    });

    useEffect(() => {
        const slices = result?.data()?.slices().toArray();
        if (slices?.length > 0) {
            const flat = slices?.map((slice) => {
                return {
                    Duration: slice.sliceTitles()[4],
                };
            });
            setDurations(flat?.map((d) => d.Duration)?.filter((v, i, a) => a.indexOf(v) === i));
        }
    }, [result]);

    useEffect(() => {
        const slices = result?.data()?.slices().toArray();
        if (slices?.length > 0) {
            const raw = slices?.map((slice) => {
                return {
                    Vehicle: slice.sliceTitles()[0],
                    Duration: slice.sliceTitles()[4],
                    "Adj. Rental": Number(slice.dataPoints()[23].rawValue).toFixed(0),
                    "Leasing Factor": (100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2),
                };
            });
            const filtred = selectedDurations?.map((d) => {
                return raw?.filter((r) => r.Duration === d);
            });

            const adjusted = filtred?.map((e) => {
                return e?.map((ee) => {
                    return {
                        Vehicle: ee.Vehicle,
                        ["Adj. Rental ".concat(`(${ee.Duration})`)]: Number(ee["Adj. Rental"]),
                        ["Leasing Factor ".concat(`(${ee.Duration})`)]: Number(ee["Leasing Factor"]),
                    };
                });
            });
            // const tobeExported = data?.map((e) => {
            //     e = { ...e, ...e["Adj. Rental"], ...e["Leasing Factor"] };
            //     adjusted.forEach((a) => {
            //         a.forEach((aa) => {
            //             if (e.Vehicle === aa.Vehicle) {
            //                 e = { ...e, ...aa };
            //             }
            //         });
            //     });
            //     return e;
            // });

            const part1 = data?.map((d) => {
                return {
                    Vehicle: d.Vehicle,
                    "List Price": Number(d["List Price"]) > 0 ? Number(d["List Price"]) : null,
                    Channel: d.Channel,
                    "Finance Product": d["Finance Product"],
                    Month: Number(d.Month),
                    Mileage: Number(d.Mileage),
                    "Web Rental": Number(d["Web Rental"]) > 0 ? Number(d["Web Rental"]) : null,
                    Deposit: Number(d.Deposit) > 0 ? Number(d.Deposit) : null,
                    RV: Number(d.RV) > 0 ? Number(d.RV) : null,
                    "Web Rental Includes": d["Web Rental Includes"],
                };
            });
            const part2 = data?.map((d) => {
                return {
                    Vehicle: d.Vehicle,
                    "Adj. Rental Includes": d["Adj. Rental Includes"],
                    Make: d.Make,
                    Model: d.Model,
                    Energy: d.Energy,
                    Trim: d.Trim,
                    Segment: d.Segment,
                    Body: d.Body,
                    GearBox: d.GearBox,
                    Doors: Number(d.Doors) > 0 ? Number(d.Doors) : null,
                    Power: Number(d.Power) > 0 ? Number(d.Power) : null,
                    Transmission: d.Transmission,
                    Version: d.Version,
                    Comment: d.Comment,
                    Url: d.Url,
                };
            });
            const toExport = part1?.map((p1, i) => {
                adjusted.forEach((a) => {
                    a.forEach((aa) => {
                        if (p1.Vehicle === aa.Vehicle) {
                            p1 = { ...p1, ...aa };
                        }
                    });
                });
                return p1;
            });
            const header = toExport
                ?.map((e) => {
                    return Object.keys(e);
                })
                .flat();
            const uniqueHeader = header?.filter((v, i, a) => a.indexOf(v) === i);
            const tobeExported = toExport?.map((e, i) => {
                const obj = {};
                uniqueHeader?.forEach((h) => {
                    obj[h] = e[h];
                });
                return { ...obj, ...part2[i] };
            });
            if (selectedDurations?.length > 0) {
                setExportData(tobeExported);
            } else {
                let Exported = data?.map((d) => {
                    return {
                        Vehicle: d.Vehicle,
                        "List Price": Number(d["List Price"]) > 0 ? Number(d["List Price"]) : null,
                        Channel: d.Channel,
                        "Finance Product": d["Finance Product"],
                        Month: Number(d.Month),
                        Mileage: Number(d.Mileage),
                        "Web Rental": Number(d["Web Rental"]) > 0 ? Number(d["Web Rental"]) : null,
                        Deposit: Number(d.Deposit) > 0 ? Number(d.Deposit) : null,
                        RV: Number(d.RV) > 0 ? Number(d.RV) : null,
                        "Web Rental Includes": d["Web Rental Includes"],
                        "Adj. Rental": Number(d["Adj. Rental"]) > 0 ? Number(d["Adj. Rental"]) : null,
                        "Leasing Factor": Number(d["Leasing Factor"]) > 0 ? Number(d["Leasing Factor"]) : null,
                        "Adj. Rental Includes": d["Adj. Rental Includes"],
                        Make: d.Make,
                        Model: d.Model,
                        Energy: d.Energy,
                        Trim: d.Trim,
                        Segment: d.Segment,
                        Body: d.Body,
                        GearBox: d.GearBox,
                        Doors: Number(d.Doors) > 0 ? Number(d.Doors) : null,
                        Power: Number(d.Power) > 0 ? Number(d.Power) : null,
                        Transmission: d.Transmission,
                        Version: d.Version,
                        Comment: d.Comment,
                        Url: d.Url,
                    };
                });
                setExportData(Exported);
            }
        }
    }, [result, selectedDurations, data]);

    return (
        <Modal
            open={modalVisible}
            title={"Export"}
            onOk={() => {
                exportData && exportFile(exportData);
                setModalVisible(false);
            }}
            onCancel={() => setModalVisible(false)}
        >
            {status === "success" && (
                <>
                    <p>Select Extrapolation Durations :</p>
                    <Select
                        mode={"multiple"}
                        style={{ width: 400 }}
                        placeholder="Select Your Durations"
                        onChange={(value) => {
                            setSelectedDurations(value);
                        }}
                    >
                        {durations?.map((d) => {
                            return <Select.Option value={d}>{d}</Select.Option>;
                        })}
                    </Select>
                </>
            )}
        </Modal>
    );
};

export default Export;
