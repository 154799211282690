import React from "react";

import LoginForm from "../components/Auth/LoginForm";
import { useAuth } from "../contexts/Auth";

const Login = () => {
    // @ts-ignore
    const { login, authError } = useAuth();

    return <LoginForm login={login} loginError={authError} email="" password="" />;
};

export default Login;
