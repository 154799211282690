function intersectAvg(arr, wei, key, benchmark) {
    return arr.reduce((a, b, i) => a + b[key] * wei[i][key], 0) / wei.reduce((a, b) => a + b[key], 0) - arr.reduce((a, b, i) => a + b[benchmark] * wei[i][benchmark], 0) / wei.reduce((a, b) => a + b[benchmark], 0);
}

function getSeries(data, row, benchmark) {
    return data.filter((f) => f[benchmark] && f[row] !== null).map((m) => ({ [row]: m[row], [benchmark]: m[benchmark] }));
}

export default function benchmarkComp(data, weights, row, benchmark, value) {
    const columns = Object.keys(data[0])
        .filter((f) => f !== row)
        .map((m) => m);
    return columns.map((d, i) => {
        const currentValues = getSeries(data, d, benchmark);
        const currentWeights = getSeries(weights, d, benchmark);

        const calculated = intersectAvg(currentValues, currentWeights, columns[i], benchmark);

        return {
            custLabel: columns[i],
            [value]: Math.round(calculated),
            count: currentWeights.reduce((a, b) => a + b[columns[i]], 0),
        };
    });
}
