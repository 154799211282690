import { useMutation, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { DELETE_WIDGET, GET_WIDGETS, INSERT_WIDGET, UPDATE_WIDGETS } from "../queries/dashboard";
import _ from "lodash";

class Widget {
    constructor({ id = undefined, label, title, description = undefined, section, filters = {}, width = 550, height = 550, position = undefined, metadata = {} }) {
        this.id = id;
        this.label = label;
        this.title = title;
        this.description = description;
        this.section = section;
        this.filters = filters;
        this.width = width;
        this.height = height;
        this.position = position;
        this.metadata = metadata;
    }
}

const dashboardState = {
    widgets: [],
    setWidgets: (widgets) => {},
    loadingWidgets: false,
    updateWidgetLoadingStatus: false,
    deleteLoadingStatus: false,
    pinWidget: (widget) => Promise.resolve(undefined),
    unPinwWidget: (uid) => {},
    updateWidgets: (widgets) => Promise.resolve(undefined),
};

const DashboardContext = React.createContext({ ...dashboardState });

const DashboardProvider = ({ children }) => {
    const [widgets, setWidgets] = useState(dashboardState.widgets);
    const { data, loading: loadingWidgets } = useQuery(GET_WIDGETS);
    const [insertWidget, { loading }] = useMutation(INSERT_WIDGET, {
        refetchQueries: [GET_WIDGETS],
    });
    const [deleteWidget, { loading: deleteLoadingStatus }] = useMutation(DELETE_WIDGET, {
        refetchQueries: [GET_WIDGETS],
    });

    const [updateWidgetsAPI, { loading: updateWidgetLoadingStatus }] = useMutation(UPDATE_WIDGETS, {
        refetchQueries: [GET_WIDGETS],
    });

    useEffect(() => {
        if (data?.re_rentaleye_dashboard_widgets) {
            const newWidgets = data.re_rentaleye_dashboard_widgets.map((item, index) => new Widget({ ...item, position: index + 1 }));
            setWidgets(newWidgets);
        }
    }, [data]);

    function pinWidget({ label, title, description = undefined, section, filters, metadata = {}, width = 1, height = 2, position = undefined }) {
        // Verify if a widget with the same label and filters does not exist
        if (widgets.find((item) => item.label === label && _.isEqual(item.filters, filters) && ("flag_services" in metadata ? _.isEqual(item.metadata.flag_services, metadata.flag_services) : true))) {
            return Promise.reject("This widget already pinned to dashboard with the same filters");
        }
        if (section === "basketsummary") {
            width = 2;
            height = 2;
            const widget = new Widget({ label, title, description, section, filters, width, height, position, metadata });
            return insertWidget({
                variables: {
                    widget: {
                        ...Object.entries(widget)
                            .filter(([key, val]) => key !== "id")
                            .reduce((acc, [key, val]) => {
                                return { ...acc, [key]: val };
                            }, {}),
                    },
                },
            }).then((res) => {
                widgets.push(widget);
                setWidgets([...widgets]);
            });
        }
        const widget = new Widget({ label, title, description, section, filters, width, height, position, metadata });
        return insertWidget({
            variables: {
                widget: {
                    ...Object.entries(widget)
                        .filter(([key, val]) => key !== "id")
                        .reduce((acc, [key, val]) => {
                            return { ...acc, [key]: val };
                        }, {}),
                },
            },
        }).then((res) => {
            widgets.push(widget);
            setWidgets([...widgets]);
        });
    }

    function unPinwWidget(uid) {
        // const removedWidgets = widgets.splice(index, 1);
        // // setWidgets([...widgets]);
        return deleteWidget({
            variables: {
                id: uid,
            },
        });
    }

    function updateWidgets(payload) {
        return updateWidgetsAPI({
            variables: {
                payload: payload,
            },
        });
    }

    return (
        <DashboardContext.Provider
            value={{
                widgets,
                loadingWidgets,
                updateWidgetLoadingStatus,
                deleteLoadingStatus,
                pinWidget,
                unPinwWidget,
                setWidgets,
                updateWidgets,
            }}
        >
            {children}
        </DashboardContext.Provider>
    );
};

const useDashboard = () => React.useContext(DashboardContext);

export { DashboardProvider, useDashboard };
