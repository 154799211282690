import React from "react";
import { LoadingIcon } from "../Icons/LoadingIcon";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";

const PDFPrintButton = ({ items, submissionStatus, selectedBaskets, isLoading }) => {
    return (
        <div>
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="w-44 bg-indigo-700 hover:bg-indigo-500 text-white font-semibold shadow-button py-1 rounded-md disabled:bg-gray-500" disabled={["disabled", "submitted"].includes(submissionStatus) ? !isLoading : isLoading}>
                        {submissionStatus !== "submitted" ? (
                            <span>{selectedBaskets.length > 0 && `(${selectedBaskets.length})`} Generate PDF</span>
                        ) : (
                            <>
                                <LoadingIcon />
                                Loading ...
                            </>
                        )}
                    </Menu.Button>
                </div>
                <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="px-1 py-1 ">
                            {items.map((item) => (
                                <Menu.Item key={item.key}>{({ active }) => <div className={`${active ? "bg-gray-100 text-black" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}>{item.label}</div>}</Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    );
};

export default PDFPrintButton;
