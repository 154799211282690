import React, { useState, useEffect, useRef } from "react";

import { GoSearch } from "react-icons/go";
import { Input } from "antd";
import ButtonFilter from "./Filters/ButtonFilter";

const FilterMenu = ({ dataElements = [], dataLabelProp, groupOptionsLabel = undefined, defaultElements = [], onSelect = (baskets) => {}, onClear = () => {} }) => {
    const [initialOptions, setInitialOptions] = useState({});
    const [options, setOptions] = useState(initialOptions);

    const [isOpenFilter, setOpenFilter] = useState(false);
    const [searchQuery, setSearchQuery] = useState(undefined);

    const ref = useRef();
    // const selectedElements = Object.keys(options)
    //     .map((option) => Object.values(option)[0])
    //     .reduce((acc, baskets) => {
    //         const selectedBaskets = baskets.filter((basket) => basket.checked);
    //         return [...acc, ...selectedBaskets];
    //     }, []);

    const selectedElements = Object.values(initialOptions)
        .filter((baskets) => baskets.filter((basket) => basket.checked).length > 0)
        .reduce((acc, baskets) => {
            return [...acc, ...baskets.filter((basket) => basket.checked)];
        }, []);

    const groupOptions = (data, categoryLabel) => {
        return data.reduce((acc, nextValue) => {
            let obj = {
                ...nextValue,
                checked: defaultElements.find((defaultEement) => defaultEement.id === nextValue.id) !== undefined,
            };
            const categoryValue = obj[categoryLabel] || "My baskets";
            if (!acc[categoryValue]) {
                acc[categoryValue] = [obj];
            } else {
                acc[categoryValue] = [...acc[categoryValue], obj];
            }
            return acc;
        }, {});
    };

    // let result = groupOptions(dataElements, 'group');

    useEffect(() => {
        if (dataElements?.length > 0) {
            let newOptions = {};
            if (groupOptionsLabel) {
                newOptions = groupOptions(dataElements, groupOptionsLabel);
            } else {
                newOptions = dataElements.map((elem) =>
                    Object.assign({
                        ...elem,
                        checked: defaultElements.find((defaultEement) => defaultEement.id === elem.id) !== undefined,
                    }),
                );
            }
            setOptions(newOptions);
            setInitialOptions(newOptions);
        }
    }, [dataElements]);

    useEffect(() => {
        isOpenFilter && document.addEventListener("mousedown", clickHandleOutside);
    }, [isOpenFilter]);

    useEffect(() => {
        let newOptions = initialOptions;
        if (searchQuery?.length > 0 && searchQuery !== "") {
            newOptions = Object.keys(newOptions)
                .filter((groupOption) => {
                    const baskets = newOptions[groupOption];
                    return baskets.find((basket) => basket.name.toLowerCase().includes(searchQuery.toLowerCase()));
                })
                .reduce((acc, groupOption) => {
                    const baskets = newOptions[groupOption];
                    acc[groupOption] = baskets.filter((basket) => basket.name.toLowerCase().includes(searchQuery.toLowerCase()));
                    return acc;
                }, {});
        }
        if (JSON.stringify(newOptions) !== JSON.stringify(options)) setOptions(newOptions);
    }, [searchQuery, dataElements]);

    const clickHandleOutside = (event) => {
        // && !ref?.current.contains(event.target)
        if (isOpenFilter && !ref?.current?.contains(event.target)) {
            document.removeEventListener("mousedown", clickHandleOutside);
            setOpenFilter(false);
        }
    };

    const handleClick = () => {
        setOpenFilter(!isOpenFilter);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const selectedBasketIds =
            dataElements.length > 1
                ? Array.from(event.target.elements.namedItem("basket").values())
                      .filter((elem) => elem.checked)
                      .map((elem) => elem.value)
                : [event.target.elements.namedItem("basket").value];
        const selectedBaskets = dataElements.filter((elem) => selectedBasketIds.includes(elem.id));
        onSelect(selectedBaskets);
        setOpenFilter(false);
    };

    return (
        <div ref={ref}>
            <ButtonFilter onClick={handleClick} isActive={defaultElements?.length > 0} title={"Basket"} />
            {isOpenFilter && (
                <form onSubmit={handleSubmit} className="flex flex-col gap-4 max-h-[400px] absolute z-30 top-90 bg-white shadow-md p-4">
                    <Input
                        size="small"
                        placeholder="Search ..."
                        onChange={(e) => setSearchQuery(e.target.value)}
                        value={searchQuery}
                        prefix={<GoSearch className="w-4 h-4 text-blue-400" />}
                        style={{
                            borderColor: "#00b4d8",
                        }}
                        allowClear
                    />
                    <section className="flex flex-col gap-2 h-min overflow-y-scroll">
                        {options.length <= 0 ? (
                            <div>No results found</div>
                        ) : (
                            Object.keys(options)?.map((groupOption) => (
                                <>
                                    <span className="py-1 text-xs font-semibold border-b border-gray-200">{groupOption}</span>
                                    <ul className="flex flex-col gap-2">
                                        {options[groupOption]?.map((item, index) => (
                                            <fieldset key={item.id} className="flex items-center gap-1">
                                                <input
                                                    type="checkbox"
                                                    id={`basket-option-${item.id}`}
                                                    name="basket"
                                                    value={item.id}
                                                    checked={initialOptions[groupOption].find((opt) => opt.id === item.id).checked}
                                                    onChange={() => {
                                                        const tmpIndex = initialOptions[groupOption].findIndex((opt) => opt.id === item.id);
                                                        initialOptions[groupOption][tmpIndex].checked = !initialOptions[groupOption][tmpIndex].checked;
                                                        setInitialOptions({ ...initialOptions });
                                                    }}
                                                    style={{
                                                        accentColor: "#0088FF",
                                                    }}
                                                />
                                                <label htmlFor={`basket-option-${item.id}`}>{item[dataLabelProp]}</label>
                                            </fieldset>
                                        ))}
                                    </ul>
                                </>
                            ))
                        )}
                    </section>
                    <div className="border-b"></div>
                    <span className="text-sm font-semibold">
                        {selectedElements
                            ?.slice(0, 3)
                            .map((basket) => basket.name)
                            .join(", ")
                            .concat(selectedElements.length > 3 ? " ..." : "")}
                    </span>
                    <div className="self-end flex gap-2">
                        <button className="px-3 py-1.5 text-xs font-semibold rounded-sm text-white bg-blue-500" onClick={() => onClear()}>
                            Clear
                        </button>
                        <button type="submit" className="px-3 py-1.5 text-xs font-semibold rounded-sm text-white bg-blue-500">
                            Apply
                        </button>
                    </div>
                </form>
            )}
        </div>
    );
};

export { FilterMenu };
