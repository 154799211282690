import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { Typography } from "antd";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import FiltersContext from "../../contexts/Filters";
import MeasuresContext from "../../contexts/Measures";
import * as Md from "../../md/full";
import TableComponent from "../TableComponent";

const VAtable = ({ ...props }) => {
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const {
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterCustmix,
        filterVersionStd,
        filterExtrapType,
        filterRolledForward,
        filterChannel3,
        filterCo2,
        filterListPrice,
        filterPs,
        filterKw,
    } = useContext(FiltersContext);
    const filters = [
        filterCountryId,
        filterMthCode,
        filterChannel1,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterCustmix,
        filterVersionStd,
        filterExtrapType,
        filterRolledForward,
        filterChannel3,
        filterCo2,
        filterListPrice,
        filterPs,
        filterKw,
    ];
    const { seriesBy } = useContext(MeasuresContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CustId],
            filters: filters,
        },
    });

    useEffect(() => {
        const slices = result?.data()?.slices().toArray();
        const newdata = {};
        slices?.forEach((slice) => {
            newdata[slice.sliceTitles()[0]] = Number(slice.dataPoints()[28].rawValue);
        });
        const total = Object.values(newdata).reduce((a, b) => a + b, 0);
        newdata["Total"] = total;
        setTableData([newdata]);
    }, [result]);
    useEffect(() => {
        if (tableData[0] !== undefined) {
            const colLabels = Object.keys(tableData[0]).map((key) => {
                return {
                    title: <center>{key !== "Total" ? <img src={`/images/logos/Logo_${key}.png`} width={64} height={64} alt={key} /> : key}</center>,
                    dataIndex: key,
                    key: key,
                    width: 100,
                    render: (text) => (
                        <Typography.Text style={{ fontSize: 12 }} strong>
                            {text}
                        </Typography.Text>
                    ),
                    align: "center",
                };
            });
            colLabels.unshift({
                title: null,
                dataIndex: null,
                width: 200,
                align: "center",
                fixed: "left",
                render: () => (
                    <Typography.Text style={{ fontSize: 14 }} strong>
                        All Durations
                    </Typography.Text>
                ),
            });
            setColumns(colLabels);
        }
    }, [tableData]);
    return status === "loading" ? (
        <LoadingComponent />
    ) : (
        <div>
            <TableComponent
                columns={columns}
                bordered
                className="border border-gray-200"
                dataSource={tableData}
                rowKey="key"
                size="small"
                pagination={false}
                scroll={{ x: columns.reduce((a, b) => a + b.width, 0) }}
                widgetProps={{
                    id: "vehicle-available",
                    section: "datacoverageadjusted",
                    filters,
                    metadata: {
                        Databy: "All Durations",
                    },
                }}
                {...props}
            />
        </div>
    );
};
export default VAtable;
