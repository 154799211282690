import React, { useContext, useEffect, useState } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newArithmeticMeasure } from "@gooddata/sdk-model";
import { newJsonPivot } from "../../utils/jsonPivot";
import MeasuresContext, { flagServiceMap } from "../../contexts/Measures";

import * as Md from "../../md/full";
import Servicesbanner from "../Layout/Servicesbanner";

const BasketSynthesis = ({ id, execDefinition, componentChild, ...props }) => {
    const { selectedKpi, seriesBy } = useContext(MeasuresContext);
    // const [categoryColors, setCategoryColors] = useState([]);
    const [data, setData] = useState([]);
    const [newSeries, setNewSeries] = useState([...execDefinition.seriesBy]);
    const { result, status, error } = useExecutionDataView({
        execution: {
            seriesBy: [...newSeries],
            slicesBy: execDefinition.slicesBy,
            filters: execDefinition.filters,
        },
    });
    const { flag_services } = props;

    useEffect(() => {
        if (result) {
            const data = result
                ?.data()
                .series()
                .toArray()
                .find((serie) => serie.measureTitle() === newSeries[selectedKpi.index].measure.localIdentifier);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            if (dataPoints) {
                const parsedData = newJsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
                setData(parsedData);
            }
        }
    }, [result, selectedKpi]);

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    return data.length > 0 ? (
        <div>
            {React.createElement(componentChild, { data, ...props })}
            <Servicesbanner kpiSettings={flag_services} />
        </div>
    ) : status === "loading" ? (
        <LoadingComponent />
    ) : (
        <ErrorComponent message={error?.message} />
    );
};

export default BasketSynthesis;
