import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext, useEffect, useState } from "react";

import { backendWithCredentials, createBackend } from "./backend";
import { useAuthState, initialState } from "./state";

const noop = () => Promise.resolve();

const defaultContext = {
    ...initialState,
    accessToken: localStorage.getItem("token"),
    backend: createBackend(),
    login: noop,
    logout: noop,
};

export const AuthContext = createContext(defaultContext);

export const AuthProvider = ({ children }) => {
    const { onLoginStart, onLoginSuccess, onLoginError, onLogoutStart, onLogoutSuccess, onLogoutError, authStatus, authError } = useAuthState(initialState);
    const auth0 = useAuth0();
    const [token, setToken] = useState(defaultContext.accessToken);
    const [backend, setBackend] = useState(defaultContext.backend);

    const login = async (username, password) => {
        onLoginStart();
        try {
            if (process.env.REACT_APP_SET_HOSTNAME) {
                auth0.loginWithRedirect().then(async (res) => {
                    await backend.authenticate();
                    onLoginSuccess();
                });
            } else {
                const newBackend = backendWithCredentials(backend, username, password);
                await newBackend.authenticate();
                setBackend(newBackend);
                onLoginSuccess();
            }
        } catch (err) {
            onLoginError(err);
            throw err;
        }
    };

    const logout = async () => {
        onLogoutStart();
        try {
            await backend.deauthenticate();
            onLogoutSuccess();
        } catch (err) {
            onLogoutError(err);
            throw err;
        }
    };

    useEffect(() => {
        const auth = async () => {
            try {
                await backend.authenticate();
                onLoginSuccess();
            } catch (err) {
                // we do not care about the error in this context
                onLoginError();
            }
        };

        auth().catch(console.error);
        (async () => {
            if (token === null || !auth0.isAuthenticated) {
                try {
                    let token = await auth0.getAccessTokenSilently();
                    localStorage.setItem("token", token);
                    setToken(token);
                } catch {
                    auth0.loginWithRedirect();
                }
            }
        })();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                authStatus,
                authError,
                token,
                backend,
                login,
                logout,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);

export const useBackend = () => {
    const { backend: backendFromAuth } = useAuth();
    return backendFromAuth;
};
