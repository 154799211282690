import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { GET_NOTIFICATIONS, INSERT_NOTIFICATIONS, UPDATE_NOTIFICATION } from "../queries/notifications";

let NotificationContext = React.createContext({
    notifications: [],
    insertNotifications: (objects) => new Promise(null),
    deactivateNotification: (notif) => {},
});

function NotificationProvider({ children }) {
    const [notifications, setNotifications] = useState([]);
    const [insertNotificationsMutation] = useMutation(INSERT_NOTIFICATIONS);
    const [updateNotification] = useMutation(UPDATE_NOTIFICATION, {
        refetchQueries: [
            {
                query: GET_NOTIFICATIONS,
            },
        ],
    });
    const { data } = useQuery(GET_NOTIFICATIONS);

    function insertNotifications(objects) {
        return insertNotificationsMutation({
            variables: {
                data: objects,
            },
        });
    }

    function deactivateNotification(notification) {
        return updateNotification({
            variables: {
                data: {
                    is_opened: true,
                    opened_at: new Date().toISOString(),
                },
                id: notification.id,
            },
        });
    }

    useEffect(() => {
        if (data?.re_rentaleye_notifications?.length > 0) setNotifications([...data?.re_rentaleye_notifications]);
    }, [data]);

    return (
        <NotificationContext.Provider
            value={{
                notifications,
                insertNotifications,
                deactivateNotification,
            }}
        >
            {children}
        </NotificationContext.Provider>
    );
}

const useNotificationContext = () => useContext(NotificationContext);

export { useNotificationContext, NotificationProvider };
