import { gql } from "@apollo/client";

const INSERT_WIDGET = gql`
    mutation insertWidget($widget: re_rentaleye_dashboard_widgets_insert_input!) {
        insert_re_rentaleye_dashboard_widgets_one(object: $widget) {
            id
        }
    }
`;

const GET_WIDGETS = gql`
    query getWidgets {
        re_rentaleye_dashboard_widgets(order_by: { position: asc }) {
            id
            label
            title
            description
            section
            filters
            metadata
            width
            height
            position
        }
    }
`;

const DELETE_WIDGET = gql`
    mutation deleteWidget($id: uuid!) {
        delete_re_rentaleye_dashboard_widgets_by_pk(id: $id) {
            id
        }
    }
`;

const UPDATE_WIDGETS = gql`
    mutation updateWidgets($payload: [re_rentaleye_dashboard_widgets_updates!]!) {
        update_re_rentaleye_dashboard_widgets_many(updates: $payload) {
            returning {
                id
            }
        }
    }
`;

export { INSERT_WIDGET, GET_WIDGETS, DELETE_WIDGET, UPDATE_WIDGETS };
