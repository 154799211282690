import React, { useContext, useEffect, useState } from "react";
import CustomerSettings from "../components/Settings/CustomerSettings";
import { RentalSettingsForm } from "../components/Settings/RentalSettingsForm";
import { ScopeSettings } from "../components/Settings/ScopeSettings";
import { useUserContext } from "../contexts/User";
import FiltersContext from "../contexts/Filters";
import { Modal, message } from "antd";
import LoadingSpinner from "../components/LoadingSpinner";
import { GeneralSettings } from "../components/Settings/GeneralSettings";
import { Formik } from "formik";
import BasketSettings from "../components/Settings/BasketSettings";

export function Settings() {
    const { user, defaultUserPref, kpiSettings, updateUser, updateScopeSettings, resetUserPref } = useUserContext();
    const { getFirstFilterValue } = useContext(FiltersContext);
    const [resetSettingsStatus, setResetSettingsStatus] = useState(undefined);
    const [settingsValues, setSettingsValues] = useState({
        basket_sections: user?.basket_sections,
        enable_multiselection: user?.enable_multiselection,
        fl_rw: defaultUserPref?.fl_rw,
        ...kpiSettings,
    });

    const onResetSettings = () => {
        setResetSettingsStatus("processing");
        const ctryid = getFirstFilterValue("filterCountryId");
        const channel1 = getFirstFilterValue("filterChannel1");
        return resetUserPref(ctryid, channel1).then((res) => setResetSettingsStatus(false));
    };

    async function onSubmitForm(newValues) {
        // Get the props that had been changed
        const updatedUserProps = Object.entries(newValues)
            .filter(([key, val]) => (key === "enable_multiselection" || key === "basket_sections") && settingsValues[key] !== val)
            .reduce((acc, [key, val]) => {
                return {
                    ...acc,
                    [key]: val,
                };
            }, {});
        const updatedSettingsProps = Object.entries(newValues)
            .filter(([key, val]) => settingsValues[key] !== val && key !== "enable_multiselection" && key !== "basket_sections")
            .reduce((acc, [key, val]) => {
                return {
                    ...acc,
                    [key]: val,
                };
            }, {});
        // Change user or user prefs
        let updateUserResult, updateSettingsResult;
        try {
            if (Object.keys(updatedUserProps)?.length > 0)
                updateUserResult = await updateUser({
                    variables: {
                        args: updatedUserProps,
                    },
                });
            if (Object.keys(updatedSettingsProps)?.length > 0) updateSettingsResult = await updateScopeSettings({ id: defaultUserPref.key, newScopeSettings: updatedSettingsProps });
        } catch (e) {
            message.error({
                content: "Error updating the settings",
                style: {
                    right: "20px",
                    top: "70px",
                    position: "absolute",
                },
                duration: 2,
            });
        }
        if (updateUserResult?.data || updateSettingsResult?.data) {
            message.success({
                content: "Settings updated",
                style: {
                    right: "20px",
                    top: "70px",
                    position: "absolute",
                },
                duration: 2,
            });
        }
    }

    useEffect(() => {
        // Initialisation
        let obj = settingsValues;
        if (!isNaN(user?.enable_multiselection)) obj.enable_multiselection = user.enable_multiselection;
        if (!isNaN(defaultUserPref?.fl_rw)) obj.fl_rw = defaultUserPref.fl_rw;
        if (user?.basket_sections !== undefined) obj.basket_sections = user.basket_sections;
        if (kpiSettings)
            obj = {
                ...obj,
                ...kpiSettings,
            };
        if (JSON.stringify(obj) !== JSON.stringify(settingsValues)) {
            setSettingsValues({
                ...obj,
            });
        }
    }, [user, defaultUserPref, kpiSettings]);

    return resetSettingsStatus === "processing" ? (
        <LoadingSpinner />
    ) : (
        <Formik initialValues={settingsValues} onSubmit={onSubmitForm} enableReinitialize>
            {({ values, handleSubmit, setFieldValue }) => {
                const kpiFormValues = Object.entries(values)
                    .filter(([key, val]) => key in kpiSettings)
                    .reduce(
                        (acc, [key, val]) =>
                            Object.assign({
                                ...acc,
                                [key]: val,
                            }),
                        {},
                    );
                return (
                    <div className="space-y-4">
                        <div className="flex gap-2 items-center">
                            <button onClick={() => setResetSettingsStatus("loading")} className="self-start text-steel text-base font-medium px-3 py-2 border-2 border-steel rounded shadow-md hover:bg-backgroundColor">
                                Reset settings
                            </button>
                            {JSON.stringify(settingsValues) !== JSON.stringify(values) && (
                                <button type="submit" onClick={() => handleSubmit()} className="self-start text-steel text-base font-medium px-3 py-2 border-2 border-steel rounded shadow-md hover:bg-backgroundColor">
                                    Save
                                </button>
                            )}
                        </div>
                        <Modal open={resetSettingsStatus === "loading"} width={800} onCancel={() => setResetSettingsStatus(undefined)} onOk={() => onResetSettings()}>
                            <div className="flex flex-col items-stretch justify-center gap-4">
                                <span className="text-lg text-gray-500">Your settings will be reverted to the system settings</span>
                                <span className="text-base font-medium text-indigo-700">Are you sure to proceed?</span>
                            </div>
                        </Modal>
                        <form className="flex flex-col gap-4 relative max-w-full mx-auto divide divide-y">
                            <div className="pt-2">
                                <GeneralSettings initialValues={values} onChange={setFieldValue} />
                            </div>
                            <div className="pt-2">
                                <BasketSettings initialValues={values} onChange={setFieldValue} />
                            </div>
                            <div className="pt-2">
                                <RentalSettingsForm kpiSettings={kpiFormValues} onChange={setFieldValue} />
                            </div>
                            <div className="pt-2">
                                <ScopeSettings initialValues={values} onChange={setFieldValue} />
                            </div>
                            <div className="pt-4">
                                <CustomerSettings />
                            </div>
                        </form>
                    </div>
                );
            }}
        </Formik>
    );
}
