import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import jsonPivot from "../../utils/jsonPivot";

const AdjustedDataCoverageWidget = ({ id, execDefinition, componentChild, ...props }) => {
    const Databy = props?.metadata?.Databy;
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const { result, status, error } = useExecutionDataView({
        execution: execDefinition,
    });

    useEffect(() => {
        if (Databy !== "All Durations") {
            const slices = result?.data()?.slices().toArray();
            const newdata = [];
            const data = slices?.map((slice) => {
                return {
                    CustId: slice.sliceTitles()[0],
                    [Databy]: slice.sliceTitles()[1],
                    count: Number(slice.dataPoints()[28].rawValue),
                };
            });
            data?.forEach((item) => {
                const newitem = {
                    CustId: item.CustId,
                    [Databy]: item[Databy],
                    count: item.count,
                };
                if (!newdata.some((e) => e.CustId === newitem.CustId && e[Databy] === newitem[Databy])) {
                    newdata.push(newitem);
                }
            });
            if (newdata.length > 0) {
                const dataSource = jsonPivot(newdata, { row: [Databy], column: "CustId", value: "count" });
                const newdataSource = dataSource.map((item) => {
                    const total = Object.values(item)
                        .filter((m) => typeof m === "number")
                        .reduce((a, b) => a + b, 0);
                    const newitem = {
                        ...item,
                        Total: total,
                    };
                    return newitem;
                });
                setData(newdataSource);

                setColumns(
                    Object.keys(newdataSource[0]).map((m) => {
                        return {
                            title: () => <center>{[Databy, "Total"].includes(m) ? m : <img src={`/images/logos/Logo_${m}.png`} width={64} height={64} alt={m} />}</center>,
                            dataIndex: m,
                            key: m,
                            width: m === Databy ? 200 : 100,
                            fixed: [Databy].includes(m) ? "left" : null,
                            align: "center",
                            render: (cell) => {
                                return (
                                    <Typography.Text style={{ fontSize: 12 }} strong>
                                        {cell}
                                    </Typography.Text>
                                );
                            },
                            filters: [Databy].includes(m)
                                ? null
                                : [
                                      {
                                          text: "Show values only",
                                          value: null,
                                      },
                                  ],

                            onFilter: (value, record) => ([Databy].includes(m) ? value : record[m] !== value),
                        };
                    }),
                );
            }
        }
    }, [result]);
    useEffect(() => {
        if (Databy === "All Durations") {
            const slices = result?.data()?.slices().toArray();
            const newdata = {};
            slices?.forEach((slice) => {
                newdata[slice.sliceTitles()[0]] = Number(slice.dataPoints()[28].rawValue);
            });
            const total = Object.values(newdata).reduce((a, b) => a + b, 0);
            newdata["Total"] = total;
            setData([newdata]);
        }
    }, [result]);
    useEffect(() => {
        if (Databy === "All Durations") {
            if (data[0] !== undefined) {
                const colLabels = Object.keys(data[0]).map((key) => {
                    return {
                        title: <center>{key !== "Total" ? <img src={`/images/logos/Logo_${key}.png`} width={64} height={64} alt={key} /> : key}</center>,
                        dataIndex: key,
                        key: key,
                        width: 100,
                        render: (text) => (
                            <Typography.Text style={{ fontSize: 12 }} strong>
                                {text}
                            </Typography.Text>
                        ),
                        align: "center",
                    };
                });
                colLabels.unshift({
                    title: null,
                    dataIndex: null,
                    width: 200,
                    align: "center",
                    fixed: "left",
                    render: () => (
                        <Typography.Text style={{ fontSize: 14 }} strong>
                            All Durations
                        </Typography.Text>
                    ),
                });
                setColumns(colLabels);
            }
        }
    }, [data]);

    return status === "success" && data?.length > 0 ? React.createElement(componentChild, { data, columns, ...props }) : status === "loading" ? <LoadingComponent /> : <ErrorComponent message={error?.message} />;
};

export default AdjustedDataCoverageWidget;
