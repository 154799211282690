import React, { useEffect, useState } from "react";

import { useExecutionDataView } from "@gooddata/sdk-ui";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";

import * as Md from "../../md/full";
const AttributeFilter = ({ seriesBy = [], mdAttribute, filters, onSelect = (elem) => {} }) => {
    const { result } = useExecutionDataView({
        execution: {
            seriesBy: [...seriesBy, Md.FinRental.Avg],
            slicesBy: [mdAttribute],
            filters,
        },
    });
    const [elements, setElements] = useState([]);
    const [selectedDuration, setSelectedDuration] = useState(undefined);

    const onSelectDuration = (event) => {
        setSelectedDuration(event.target.value);
        onSelect(newPositiveAttributeFilter(mdAttribute, [event.target.value]));
    };

    useEffect(() => {
        const attributeValues = result
            ?.meta()
            .allHeaders()[0][0]
            .map((headerItem) => headerItem?.attributeHeaderItem.name);
        attributeValues?.length > 0 && setElements(attributeValues);
    }, [result]);

    return (
        elements.length > 0 && (
            <div>
                <label htmlFor="benchmark" className="font-semibold text-gray-500 text-sm mr-2">
                    Extrapolated to
                </label>
                <select name="benchmark" defaultValue={""} onChange={onSelectDuration} className="cursor-pointer mx-2 px-1 py-0.5 border w-32 text-center text-sm font-semibold border-indigo-700 text-indigo-700 hover:bg-backgroundColor outline-indigo-700 rounded">
                    <option disabled value={""} className="text-sm font-semibold">
                        Month / Km
                    </option>
                    {elements.map((m) => (
                        <option key={m} className="text-xs font-semibold font-sans">
                            {m}
                        </option>
                    ))}
                </select>
            </div>
        )
    );
};

export { AttributeFilter };
