import { newNegativeAttributeFilter, newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { ErrorComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import { AttributeFilter } from "../../components/Filters/AttributeFilter";
// import { RefreshIcon } from "../components/Icons/RefreshIcon";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useAppContext } from "../../contexts/AppContext";
import FiltersContext from "../../contexts/Filters";
import MeasuresContext from "../../contexts/Measures";
// import MeasuresContext from "../contexts/Measures";
import * as Md from "../../md/full";
import { LoadingIcon } from "../../components/Icons/LoadingIcon";
import Export from "./Export";
import { useQuery } from "@apollo/client";
import { ENABLED_SETTINGS, GET_KPI_ADJ_SETTINGS } from "../../queries/Users";
import Exportbtn from "../../components/Export/Exportbtn";
import { Tooltip } from "antd";
import { CaretDownFill, CaretUpFill } from "../../components/Icons/CaretIcon";
import Widget from "../../components/Widget";

const OEM = () => {
    const [offers, setOffers] = useState([]);
    const [bestOffers, setBestOffers] = useState([]);
    // const [offertype, setOffertype] = useState("OEM");
    const [selectedDuration, setSelectedDuration] = useState();
    const [modalVisible, setModalVisible] = useState(false);
    const [exportData, setExportData] = useState([]);
    const contentRef = useRef();
    const scrollRef = useRef();
    const [contentWidth, setContentWidth] = useState(0);
    // const offertypes = ["OEM", "CAP"];
    const { getCountryByCode } = useAppContext();
    const { getFiltersForFootnote } = useContext(FiltersContext);
    // const { currentFilter, currentFilterHistory, getFiltersForFootnote } = useContext(FiltersContext);
    const { seriesBy } = useContext(MeasuresContext);
    const {
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterChannel3,
        filterMake,
        filterModel,
        filterFuel,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        filterRolledForward,
        filterVersionStd,
    } = useContext(FiltersContext);
    //
    const oemFilters = [
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterChannel3,
        newPositiveAttributeFilter(Md.Extraptype, ["No Extrapolation"]),
        filterMake,
        filterModel,
        filterFuel,
        newPositiveAttributeFilter(Md.CustId, ["OEM"]),
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        filterRolledForward,
        filterVersionStd,
    ];
    const BenchmarkFilters = [
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterChannel3,
        filterMake,
        filterModel,
        filterFuel,
        newNegativeAttributeFilter(Md.Extraptype, ["No Extrapolation"]),
        newPositiveAttributeFilter(Md.CustId, ["OEM"]),
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        filterRolledForward,
        filterVersionStd,
    ];
    const ExportFilters = [
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterChannel3,
        filterMake,
        filterModel,
        filterFuel,
        newPositiveAttributeFilter(Md.CustId, ["OEM"]),
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterListPrice,
        filterPs,
        filterKw,
        filterCo2,
        filterCustmix,
        filterRolledForward,
        filterVersionStd,
    ];
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [...seriesBy, Md.Deposit.Avg, Md.Doors.Avg],
            // seriesBy: [Md.ListPrice.Avg, Md.Rental.Avg, Md.Deposit.Avg, Md.Co2malus.Avg, Md.Kw.Avg, Md.Ps.Avg, seriesBy],
            slicesBy: [Md.VersionStd, Md.CorrMake, Md.Fuel, Md.Channel3, Md.Duration, Md.Channel2, Md.CorrModel, Md.VehSegment, Md.BodyStyle, Md.TrimName, Md.Version, Md.GearsPlus, Md.Drive, Md.Warning, Md.Url],
            filters: [...oemFilters],
        },
    });
    const { result: result2, status: status2 } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.VersionStd, Md.CorrMake, Md.Fuel, Md.Channel3, Md.Duration],
            filters: [...BenchmarkFilters, selectedDuration],
        },
    });
    const column = [
        {
            title: "Vehicle",
            key: "Vehicle",
            type: "String",
        },
        {
            title: "List Price",
            key: "List Price",
            type: "Numeric",
        },
        {
            title: "Channel",
            key: "Channel",
            type: "String",
        },
        {
            title: "Finance Product",
            key: "Finance Product",
            type: "String",
        },
        {
            title: "Month",
            key: "Month",
            type: "Numeric",
        },
        {
            title: "Mileage",
            key: "Mileage",
            type: "Numeric",
        },
        {
            title: "Web Rental",
            key: "Web Rental",
            type: "Numeric",
        },
        {
            title: "Deposit",
            key: "Deposit",
            type: "Numeric",
        },
        {
            title: "RV",
            key: "RV",
            type: "Numeric",
        },
        {
            title: "Month Extrapolated",
            key: "Month Extrapolated",
            type: "Numeric",
        },
        {
            title: "Mileage Extrapolated",
            key: "Mileage Extrapolated",
            type: "Numeric",
        },
        {
            title: "Adj. Rental",
            key: "Adj. Rental",
            type: "Numeric",
        },
        {
            title: "Leasing Factor",
            key: "Leasing Factor",
            type: "Numeric",
        },
        {
            title: "Web Rental Includes",
            key: "Web Rental Includes",
            type: "String",
        },
        {
            title: "Adj. Rental Includes",
            key: "Adj. Rental Includes",
            type: "String",
        },
        {
            title: "Make",
            key: "Make",
            type: "String",
        },
        {
            title: "Model",
            key: "Model",
            type: "String",
        },
        {
            title: "Energy",
            key: "Energy",
            type: "String",
        },
        {
            title: "Trim",
            key: "Trim",
            type: "String",
        },
        {
            title: "Segment",
            key: "Segment",
            type: "String",
        },
        {
            title: "Body",
            key: "Body",
            type: "String",
        },
        {
            title: "GearBox",
            key: "GearBox",
            type: "String",
        },
        {
            title: "Doors",
            key: "Doors",
            type: "Numeric",
        },
        {
            title: "Power",
            key: "Power",
            type: "Numeric",
        },
        {
            title: "Transmission",
            key: "Transmission",
            type: "String",
        },
        {
            title: "Version",
            key: "Version",
            type: "String",
        },
        {
            title: "Comment",
            key: "Comment",
            type: "String",
        },
    ];
    function sortColumn(column, sortOrder) {
        let comparator;
        if (column.type === "String") {
            comparator = sortOrder === "asc" ? (a, b) => a[column.key].localeCompare(b[column.key]) : (a, b) => b[column.key].localeCompare(a[column.key]);
        } else {
            comparator = sortOrder === "asc" ? (a, b) => a[column.key] - b[column.key] : (a, b) => b[column.key] - a[column.key];
        }
        const sortedData = offers.sort(comparator);
        setOffers([...sortedData]);
    }
    // Service Settings included in adj Rental
    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };
    const { data: kpiSettingsResults } = useQuery(GET_KPI_ADJ_SETTINGS, {
        variables: {
            ctryid: filterCountryId.positiveAttributeFilter.in["values"][0],
            channel1: filterChannel1.positiveAttributeFilter.in["values"][0],
        },
    });
    const Enabled = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid: filterCountryId.positiveAttributeFilter.in["values"][0], channel1: filterChannel1.positiveAttributeFilter.in["values"][0] },
    }).data?.re_rentaleye_re_services_settings[0];
    const settings = kpiSettingsResults?.re_rentaleye_re_params_user_prefs.map((kpi) => {
        return {
            [settingsAliases.flag_ser]: Enabled?.flag_ser ? (kpi.flag_ser ? "Y" : "N") : "",
            [settingsAliases.flag_bre]: Enabled?.flag_bre ? (kpi.flag_bre ? "Y" : "N") : "",
            [settingsAliases.flag_ins]: Enabled?.flag_ins ? (kpi.flag_ins ? "Y" : "N") : "",
            [settingsAliases.flag_rep]: Enabled?.flag_rep ? (kpi.flag_rep ? "Y" : "N") : "",
            [settingsAliases.flag_fin]: Enabled?.flag_fin ? (kpi.flag_fin ? "Y" : "N") : "",
            [settingsAliases.flag_co2]: Enabled?.flag_co2 ? (kpi.flag_co2 ? "Y" : "N") : "",
            [settingsAliases.flag_tyr]: Enabled?.flag_tyr ? (kpi.flag_tyr ? "Y" : "N") : "",
            [settingsAliases.flag_win]: Enabled?.flag_win ? (kpi.flag_win ? "Y" : "N") : "",
            [settingsAliases.flag_evb]: Enabled?.flag_evb ? (kpi.flag_evb ? "Y" : "N") : "",
        };
    });

    const services = settings && Object.keys(settings[0]).filter((key) => settings[0][key] === "Y");
    const ServicesMessage = services?.length > 0 ? `${services.join(", ")}` : "No Services";

    useEffect(() => {
        //First Part of Data
        const slices = result?.data()?.slices()?.toArray();
        if (slices) {
            const data = slices.map((slice) => {
                const services = {
                    Maintenance: Number(slice.dataPoints()[5].rawValue),
                    Tyres: Number(slice.dataPoints()[7].rawValue),
                    Breakdown: Number(slice.dataPoints()[9].rawValue),
                    Replacement: Number(slice.dataPoints()[11].rawValue),
                    Insurance: Number(slice.dataPoints()[13].rawValue),
                    "Financial Loss": Number(slice.dataPoints()[15].rawValue),
                    "Winter Tyres": Number(slice.dataPoints()[17].rawValue),
                    "EV Bonus": Number(slice.dataPoints()[19].rawValue),
                    "Co2 Malus": Number(slice.dataPoints()[21].rawValue),
                };
                return {
                    Vehicle: slice.sliceTitles()[0],
                    "List Price": Number(slice.dataPoints()[1].rawValue) > 0 ? Number(Number(slice.dataPoints()[1].rawValue).toFixed(0)) : null,
                    Channel: slice.sliceTitles()[5],
                    "Finance Product": slice.sliceTitles()[3],
                    Month: Number(slice.sliceTitles()[4].split("/")[0]),
                    Mileage: Number(slice.sliceTitles()[4].split("/")[1]),
                    "Web Rental": Number(slice.dataPoints()[3].rawValue) > 0 ? Number(Number(slice.dataPoints()[3].rawValue).toFixed(0)) : null,
                    Deposit: Number(slice.dataPoints()[31].rawValue) > 0 ? Number(Number(slice.dataPoints()[31].rawValue).toFixed(0)) : null,
                    RV: Number(slice.dataPoints()[25].rawValue) > 0 ? Number(Number(slice.dataPoints()[25].rawValue).toFixed(0)) : null,
                    "Web Rental Includes":
                        Object.entries(services)
                            .filter((m) => m[1] === 1)
                            .map((m) => m[0])
                            .join(", ") || "No Services",
                    "Adj. Rental": selectedDuration === undefined || selectedDuration?.positiveAttributeFilter.in["values"][0] === slice.sliceTitles()[4] ? (Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null) : null,
                    "Leasing Factor": selectedDuration === undefined || selectedDuration?.positiveAttributeFilter.in["values"][0] === slice.sliceTitles()[4] ? (Number(slice.dataPoints()[1].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null) : null,
                    "Adj. Rental Includes": ServicesMessage,
                    Make: slice.sliceTitles()[1],
                    Model: slice.sliceTitles()[6],
                    Energy: slice.sliceTitles()[2],
                    Trim: slice.sliceTitles()[9],
                    Segment: slice.sliceTitles()[7],
                    Body: slice.sliceTitles()[8],
                    GearBox: slice.sliceTitles()[11],
                    Doors: Number(slice.dataPoints()[32].rawValue),
                    Power: Number(slice.dataPoints()[22].rawValue),
                    Transmission: slice.sliceTitles()[12],
                    Version: slice.sliceTitles()[10],
                    Comment: slice.sliceTitles()[13],
                    Url: slice.sliceTitles()[14],
                    [selectedDuration?.positiveAttributeFilter.in["values"][0] === slice.sliceTitles()[4] && "Month Extrapolated"]: Number(slice.sliceTitles()[4].split("/")[0]),
                    [selectedDuration?.positiveAttributeFilter.in["values"][0] === slice.sliceTitles()[4] && "Mileage Extrapolated"]: Number(slice.sliceTitles()[4].split("/")[1]),
                };
            });
            // find duplicates versions
            const duplicates = data.filter((item, index) => data.findIndex((i) => i.Vehicle === item.Vehicle) !== index);
            // remove duplicates that have no ajusted rental or leasing factor
            const dataWithoutDuplicates = data.filter((item) => duplicates.findIndex((i) => i.Vehicle === item.Vehicle) === -1 || (item["Adj. Rental"] !== null && item["Leasing Factor"] !== null));

            const datatoExport = slices.map((slice) => {
                const services = {
                    Maintenance: Number(slice.dataPoints()[5].rawValue),
                    Tyres: Number(slice.dataPoints()[7].rawValue),
                    Breakdown: Number(slice.dataPoints()[9].rawValue),
                    Replacement: Number(slice.dataPoints()[11].rawValue),
                    Insurance: Number(slice.dataPoints()[13].rawValue),
                    "Financial Loss": Number(slice.dataPoints()[15].rawValue),
                    "Winter Tyres": Number(slice.dataPoints()[17].rawValue),
                    "EV Bonus": Number(slice.dataPoints()[19].rawValue),
                    "Co2 Malus": Number(slice.dataPoints()[21].rawValue),
                };
                return {
                    Vehicle: slice.sliceTitles()[0],
                    "List Price": Number(slice.dataPoints()[1].rawValue) > 0 ? Number(Number(slice.dataPoints()[1].rawValue).toFixed(0)) : null,
                    Channel: slice.sliceTitles()[5],
                    "Finance Product": slice.sliceTitles()[3],
                    Month: Number(slice.sliceTitles()[4].split("/")[0]),
                    Mileage: Number(slice.sliceTitles()[4].split("/")[1]),
                    "Web Rental": Number(slice.dataPoints()[3].rawValue) > 0 ? Number(Number(slice.dataPoints()[3].rawValue).toFixed(0)) : null,
                    Deposit: Number(slice.dataPoints()[31].rawValue) > 0 ? Number(Number(slice.dataPoints()[31].rawValue).toFixed(0)) : null,
                    RV: Number(slice.dataPoints()[25].rawValue) > 0 ? Number(Number(slice.dataPoints()[25].rawValue).toFixed(0)) : null,
                    "Web Rental Includes":
                        Object.entries(services)
                            .filter((m) => m[1] === 1)
                            .map((m) => m[0])
                            .join(", ") || "No Services",
                    "Adj. Rental": selectedDuration === undefined || selectedDuration?.positiveAttributeFilter.in["values"][0] === slice.sliceTitles()[4] ? (Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null) : null,
                    "Leasing Factor": selectedDuration === undefined || selectedDuration?.positiveAttributeFilter.in["values"][0] === slice.sliceTitles()[4] ? (Number(slice.dataPoints()[1].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null) : null,
                    "Adj. Rental Includes": ServicesMessage,
                    Make: slice.sliceTitles()[1],
                    Model: slice.sliceTitles()[6],
                    Energy: slice.sliceTitles()[2],
                    Trim: slice.sliceTitles()[9],
                    Segment: slice.sliceTitles()[7],
                    Body: slice.sliceTitles()[8],
                    GearBox: slice.sliceTitles()[11],
                    Doors: Number(slice.dataPoints()[32].rawValue),
                    Power: Number(slice.dataPoints()[22].rawValue),
                    Transmission: slice.sliceTitles()[12],
                    Version: slice.sliceTitles()[10],
                    Comment: slice.sliceTitles()[13],
                    Url: slice.sliceTitles()[14],
                };
            });
            setExportData(datatoExport);
            //Second Part of Data
            const slices2 = result2?.data()?.slices()?.toArray();
            const data2 = slices2?.map((slice) => {
                return {
                    Vehicle: slice.sliceTitles()[0],
                    "Adj. Rental": Number(slice.dataPoints()[23].rawValue) > 0 ? Number(Number(slice.dataPoints()[23].rawValue).toFixed(0)) : null,
                    "Leasing Factor": Number(slice.dataPoints()[1].rawValue) > 0 ? Number((100 * Number(slice.dataPoints()[24].rawValue)).toFixed(2)) : null,
                    "Month Extrapolated": Number(slice.sliceTitles()[4].split("/")[0]),
                    "Mileage Extrapolated": Number(slice.sliceTitles()[4].split("/")[1]),
                };
            });
            //Merge Data
            const data3 = dataWithoutDuplicates?.map((item) => {
                const item2 = data2?.find((i) => i?.Vehicle === item?.Vehicle);
                return {
                    ...item,
                    ...item2,
                };
            });
            if (selectedDuration !== undefined) setOffers(data3);
            else setOffers(data);

            //Best Offers >> Conditions : Leasing Factor > 0, lowest 5 leasing factor offers, segment lenght = 1
            const bestOffers = data3
                ?.filter((item) => item["Leasing Factor"] > 0 && item.Segment.length === 1)
                .sort((a, b) => a["Leasing Factor"] - b["Leasing Factor"])
                .slice(0, 5);
            setBestOffers(bestOffers);
        }
    }, [result, result2]);

    const syncScroll = (src, target) => {
        target.current.scrollLeft = src.current.scrollLeft;
    };

    const handleContentScroll = () => syncScroll(contentRef, scrollRef);
    const handleScrollScroll = () => syncScroll(scrollRef, contentRef);

    useEffect(() => {
        const contentElement = contentRef.current;
        const scrollElement = scrollRef.current;

        if (contentElement && scrollElement) {
            setContentWidth(contentElement?.scrollWidth);
            contentElement?.addEventListener("scroll", handleContentScroll);
            scrollElement?.addEventListener("scroll", handleScrollScroll);

            return () => {
                contentElement?.removeEventListener("scroll", handleContentScroll);
                scrollElement?.removeEventListener("scroll", handleScrollScroll);
            };
        }
    }, [handleContentScroll, handleScrollScroll, contentRef, scrollRef]);

    return (
        <div>
            {status === "loading" && <LoadingSpinner />}
            {status === "error" && <ErrorComponent message={`No Data available for your current filters`} description="If 'Promotions' is not activated in your workspace, Please contact experteye support." />}
            {status === "success" && (
                <div className="flex flex-col gap-8">
                    {/* // best offers table */}
                    <div>
                        <Widget
                            title={`Top 5 promotions of the month — ${getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])} | ${filterChannel1?.positiveAttributeFilter?.in["values"][0]} | ${filterMthCode?.positiveAttributeFilter?.in["values"][0]}`}
                            description="This section share the best 5 offers of the month as published on the websites"
                            footnote={getFiltersForFootnote()}
                        >
                            <div className="max-h-screen overflow-x-scroll">
                                <table className="table-auto w-max">
                                    <colgroup>
                                        <col span={1} />
                                        <col span={8} />
                                        <col span={4} />
                                        <col span={14} />
                                    </colgroup>
                                    <thead className="sticky top-0 z-10 bg-gray-100">
                                        <tr>
                                            <th colSpan={1} className="sticky left-0 bg-gray-100"></th>
                                            <th className="px-4 py-2 text-xs text-white bg-indigo-700" colSpan={8}>
                                                Published Promotions
                                            </th>
                                            <th className="px-4 py-2 text-xs text-white bg-indigo-500" colSpan={4}>
                                                Extrapolated Promotions
                                            </th>
                                            <th className="px-4 py-2" colSpan={14}></th>
                                        </tr>
                                        <tr>
                                            {column.map((col) => (
                                                <th
                                                    key={col.key} // column key
                                                    className={col.key === "Vehicle" ? "px-4 py-2 text-xs sticky left-0 bg-gray-100" : "px-4 py-2 text-xs"}
                                                >
                                                    <span className="flex items-center justify-between">
                                                        <div className="flex-1">{col.title}</div>
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bestOffers.map((offer, index) => (
                                            <tr key={index}>
                                                <td className="border px-4 py-2 text-left sticky left-0 bg-white" style={{ fontSize: "11px" }}>
                                                    {offer["Vehicle"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["List Price"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Channel"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Finance Product"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Month"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Mileage"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Web Rental"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Deposit"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["RV"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Month Extrapolated"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Mileage Extrapolated"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Adj. Rental"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Leasing Factor"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Web Rental Includes"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Adj. Rental Includes"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Make"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Model"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Energy"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Trim"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Segment"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Body"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["GearBox"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Doors"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Power"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Transmission"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Version"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Comment"]}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </Widget>
                    </div>
                    {/* // all offers table */}
                    <div className="flex flex-row items-center">
                        <div className="flex flex-row justify-start gap-8 items-center">
                            <div className="items-center">
                                <Tooltip title="display offers with original rentals, mileage and duration" color="#9C8ACE" placement="topLeft">
                                    <input type="radio" className="accent-indigo-700 cursor-pointer" checked={selectedDuration === undefined} onChange={() => setSelectedDuration(undefined)} name="Non Extrapolated" />
                                    <label className="font-semibold text-gray-500 text-medium cursor-pointer hover:opacity-80" htmlFor="Non Extrapolated" onClick={() => setSelectedDuration(undefined)}>
                                        {" "}
                                        Not Extrapolated
                                    </label>
                                </Tooltip>
                            </div>
                            <Tooltip title="display adjusted rental extrapolated to chosen mileage and duration" color="#9C8ACE" placement="topLeft">
                                <div className="items-center flex flex-row gap-2">
                                    <input type="radio" className="accent-indigo-700" checked={selectedDuration !== undefined} />
                                    <AttributeFilter mdAttribute={Md.Duration} filters={BenchmarkFilters} onSelect={setSelectedDuration} seriesBy={[Md.Co2malus.Avg, Md.ListPrice.Avg, Md.Ps.Avg, Md.Kw.Avg]} />
                                    {status2 === "loading" && selectedDuration && <LoadingIcon />}
                                </div>
                            </Tooltip>
                        </div>
                        <div className="self-stretch flex justify-end gap-4 ml-8">
                            <Exportbtn onclick={() => setModalVisible(true)} />
                        </div>
                    </div>
                    {/* <div className="flex flex-row justify-between">
                        <div className="flex flex-row">
                            {offertypes.map((type) => (
                                <button key={type} className={`${offertype === type ? "bg-indigo-700 text-white border-indigo-700 border-1" : "bg-indigo-100 text-indigo-700 border-gray-300 border-1"} px-4 py-2 rounded-md mr-2`} onClick={() => setOffertype(type)}>
                                    {type}
                                </button>
                            ))}
                        </div>
                    </div> */}

                    <Widget
                        title={`Promotions - ${getCountryByCode(filterCountryId.positiveAttributeFilter.in?.values[0])} | ${filterChannel1.positiveAttributeFilter.in["values"][0]} |${
                            selectedDuration === undefined ? " Not Extrapolated" : " Extrapolated To " + selectedDuration?.positiveAttributeFilter.in?.values[0]
                        }`}
                        description={"This section shares OEM promotional offers of the month as published on the websites"}
                        footnote={getFiltersForFootnote()}
                    >
                        <div className="flex flex-col gap-4">
                            <div ref={scrollRef} className="overflow-x-scroll h-5">
                                <div style={{ width: `${contentWidth}px` }} />
                            </div>
                            <div ref={contentRef} className="max-h-screen overflow-scroll scrollbar-hide">
                                <table className="table-auto w-max">
                                    <colgroup>
                                        <col span={1} />
                                        <col span={8} />
                                        <col span={4} />
                                        <col span={14} />
                                    </colgroup>
                                    <thead className="sticky top-0 z-20 bg-gray-100">
                                        <tr>
                                            <th colSpan={1} className="sticky left-0 bg-gray-100"></th>
                                            <th className="px-4 py-2 text-xs text-white bg-indigo-700" colSpan={8}>
                                                Published Promotions
                                            </th>
                                            <th className="px-4 py-2 text-xs text-white bg-indigo-500" colSpan={4}>
                                                Extrapolated Promotions
                                            </th>
                                            <th className="px-4 py-2" colSpan={14}></th>
                                        </tr>
                                        <tr>
                                            {column.map((col) => (
                                                <th
                                                    key={col.key} // column key
                                                    className={col.key === "Vehicle" ? "px-4 py-2 text-xs sticky left-0 bg-gray-100" : "px-4 py-2 text-xs"}
                                                >
                                                    <span className="flex items-center justify-between">
                                                        <div className="flex-1">{col.title}</div>
                                                        <div className="flex flex-col mx-2">
                                                            <span>
                                                                <CaretUpFill width={12} onClick={() => sortColumn(col, "desc")} className="fill-current cursor-pointer text-gray-300 hover:text-indigo-500" />
                                                            </span>
                                                            <span>
                                                                <CaretDownFill width={12} className="fill-current text-gray-300 cursor-pointer hover:text-indigo-500" onClick={() => sortColumn(col, "asc")} />
                                                            </span>
                                                        </div>
                                                    </span>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {offers.map((offer, index) => (
                                            <tr key={index}>
                                                <td className="border px-4 py-2 text-left sticky left-0 bg-white" style={{ fontSize: "11px" }}>
                                                    {offer["Vehicle"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["List Price"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Channel"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Finance Product"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Month"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Mileage"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Web Rental"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Deposit"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["RV"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Month Extrapolated"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Mileage Extrapolated"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Adj. Rental"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Leasing Factor"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Web Rental Includes"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Adj. Rental Includes"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Make"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Model"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Energy"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Trim"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Segment"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Body"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["GearBox"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Doors"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Power"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Transmission"]}
                                                </td>
                                                <td className="border px-4 py-2 w-96 text-left" style={{ fontSize: "11px" }}>
                                                    {offer["Version"]}
                                                </td>
                                                <td className="border px-4 py-2 text-center" style={{ fontSize: "11px" }}>
                                                    {offer["Comment"]}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Widget>
                </div>
            )}
            <Export filters={ExportFilters} seriesBy={seriesBy} data={exportData} modalVisible={modalVisible} setModalVisible={setModalVisible} />
        </div>
    );
};

export default OEM;
