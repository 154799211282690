import React from "react";
import { ArrowDownIcon } from "../Icons/Arrows";

const ButtonFilter = ({ onClick = () => {}, isActive = false, title }) => (
    <button
        onClick={onClick}
        className={(isActive ? "text-white bg-indigo-500" : "text-indigo-900 hover:bg-indigo-100") + " flex justify-between items-center w-[125px] max-[1400px]:w-[95px] max-[1200px]:w-[70px] max-[1000px]:w-[60px] px-2.5 py-0.5 border border-indigo-500 rounded shadow-button font-semibold"}
    >
        <div className="inline max-w-1/2 whitespace-nowrap overflow-hidden text-ellipsis">
            <span>{title}</span>
        </div>
        <ArrowDownIcon width={20} height={15} fill={isActive ? "white" : "#0a0d79"} />
    </button>
);

export default ButtonFilter;
