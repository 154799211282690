import React, { useContext, useEffect, useState } from "react";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { Modal } from "antd";
import MeasuresContext from "../../contexts/Measures";
import { useUserContext } from "../../contexts/User";

/**
 * Component for downloading the full datalist into csv or excel format
 */
const ExportFullCatalog = ({ execDefinition, toTransform, onComplete, basketList, servicemessage }) => {
    const [data, setData] = useState([]);
    const { selectedAdjDeposit } = useContext(MeasuresContext);
    const { kpiSettings } = useUserContext();
    const { result, status } = useExecutionDataView({
        execution: execDefinition,
    });

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        const series = result?.data().series().toArray();
        const dataTmp = toTransform({ slices, series, baskets: basketList, selectedAdj: selectedAdjDeposit, kpiSettings: kpiSettings });
        if (dataTmp?.data) {
            setData(dataTmp.data.map((item) => ({ ...item, "Adj_Rental Includes": servicemessage })));
        } else dataTmp?.length > 0 && setData(dataTmp.map((item) => ({ ...item, "Adj_Rental Includes": servicemessage })));
    }, [result, selectedAdjDeposit]);

    useEffect(() => {
        data?.length > 0 && onComplete(data);
    }, [data]);

    return (
        <Modal open={status === "loading"} footer={null} width={800}>
            <div className="flex flex-col items-center justify-center gap-2 h-64">
                <div className="text-lg font-semibold text-gray-500">Generating your file</div>
                <span className="font-medium text-gray-500">This would take a moment, please wait</span>
                <div className="my-4">
                    <LoadingComponent />
                </div>
            </div>
        </Modal>
    );
};

export default ExportFullCatalog;
