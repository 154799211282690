import { Modal } from "antd";
import React from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { useMutation, useQuery } from "@apollo/client";
import { GET_NOTIFICATION_BY_EMAIL, UPDATE_USER_NOTIFICATIONS } from "../../queries/notifications";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useEffect } from "react";

const Popup = () => {
    const { user: ssoUser } = useAuth0();
    const { data: userNotification } = useQuery(GET_NOTIFICATION_BY_EMAIL, {
        variables: {
            email: ssoUser?.email,
        },
    });
    const [notification, setNotification] = useState(userNotification?.re_rentaleye_user_notifications[0]);
    const [visible, setVisible] = useState(true);
    useEffect(() => {
        setNotification(userNotification?.re_rentaleye_user_notifications[0]);
    }, [userNotification]);
    const [updateNotification] = useMutation(UPDATE_USER_NOTIFICATIONS, {
        refetchQueries: [
            {
                query: GET_NOTIFICATION_BY_EMAIL,
                variables: {
                    email: ssoUser?.email,
                },
            },
        ],
    });
    function deactivateNotification(notification) {
        return updateNotification({
            variables: {
                data: {
                    opened: true,
                    opened_at: new Date().toISOString(),
                },
                id: notification?.message_id,
            },
        });
    }
    return (
        notification !== undefined && (
            <Modal
                open={visible && notification?.opened === false}
                title={
                    <div className="text-indigo-900 text-base flex flex-row">
                        <InformationCircleIcon className="h-6 w-6 mr-2 text-indigo-900" />
                        Rentaleye Notification
                    </div>
                }
                closable={false}
                footer={[
                    <button
                        key="ok"
                        className="text-base text-white rounded-md font-medium px-8 py-1 mx-2 my-2 bg-indigo-900 hover:bg-indigo-700"
                        onClick={() => {
                            setVisible(false);
                            deactivateNotification(notification);
                        }}
                    >
                        OK
                    </button>,
                ]}
            >
                <p className="text-base font-medium">{notification?.message_text}</p>
            </Modal>
        )
    );
};

export default Popup;
