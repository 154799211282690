import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import UserContext from "../../contexts/User";
import { useAppContext } from "../../contexts/AppContext";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Modal, Table, message } from "antd";
import DropIcon from "../Icons/DropIcon";
import { gql, useMutation } from "@apollo/client";
import update from "immutability-helper";
import FiltersContext from "../../contexts/Filters";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import * as Md from "../../md/full";
import { GET_ALL_USER_PREFERENCES, UPDATE_CUSTOMER_SLOTS_SETTINGS } from "../../queries/Users";
import LoadingSpinner from "../LoadingSpinner";
import AddIcon from "../Icons/AddIcon";

const type = "DraggableBodyRow";

const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ""}`}
            style={{
                cursor: "move",
                ...style,
            }}
            {...restProps}
        />
    );
};

const DnDModal = ({ isOpen, setIsOpen }) => {
    // Get the list of cust ids
    const { filterCountryId, filterChannel1 } = useContext(FiltersContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [Md.FinRental.Avg],
            slicesBy: [Md.CustId],
            filters: [filterCountryId, filterChannel1],
        },
    });

    // Add drop icon button
    const playerTableColumns = [
        {
            title: "Available players",
            dataIndex: "player",
            key: "player",
            render: (cellValue, record, index) => {
                if (record === null) {
                    return <span className="flex justify-center p-1 italic text-sm font-medium text-gray-700">Empty slot</span>;
                }
                return (
                    <span className="flex items-center text-base gap-3 p-1">
                        <div className="flex-1 flex gap-2">
                            <img src={`/images/logos/Logo_${record.key}.png`} width={70} height={70} /> {cellValue}
                        </div>
                        {index < 8 ? (
                            <button onClick={() => onDropSlot(index)} className="text-gray-700 hover:text-red">
                                <DropIcon />
                            </button>
                        ) : (
                            <button onClick={() => onAddSlot(index)} className="text-gray-700 hover:text-green">
                                <AddIcon />
                            </button>
                        )}
                    </span>
                );
            },
        },
    ];

    const [allCustLabels, setAllCustLabels] = useState([]);
    const { defaultUserPref } = useContext(UserContext);
    const { getFilterValue } = useContext(FiltersContext);
    const { getCustlabelByCustId } = useAppContext();
    const [updateSettings] = useMutation(UPDATE_CUSTOMER_SLOTS_SETTINGS);
    const [customerSlots, setCustomerSlot] = useState([]);
    const ctryid = getFilterValue("filterCountryId")[0];
    const channel1 = getFilterValue("filterChannel1")[0];
    const rePositionAllPlayers = useCallback(
        (players, slots) => {
            /**
             * This method re-position players values to be idential as slots array
             */
            slots.forEach((elem, index) => {
                let currentIndex = players.findIndex((player) => player.key === elem);
                if (currentIndex !== -1) {
                    let playerObj = players[currentIndex];
                    players.splice(currentIndex, 1);
                    players.splice(index, 0, playerObj);
                }
            });
            return players;
        },
        [customerSlots],
    );
    const onDropSlot = (position) => {
        let droppedSlots = allCustLabels.splice(position, 1, null);
        if (droppedSlots?.length > 0) {
            allCustLabels.push(droppedSlots[0]);
            setAllCustLabels([...allCustLabels]);
        }
    };
    const onAddSlot = (position) => {
        let droppedSlots = allCustLabels.splice(position, 1);
        let nullIndex = allCustLabels.findIndex((elem) => elem === null);
        if (nullIndex === -1) {
            nullIndex = 7;
        }
        let changedSlots = allCustLabels.splice(nullIndex, 1, droppedSlots[0]);
        if (droppedSlots?.length > 0) {
            changedSlots[0] !== null && allCustLabels.push(changedSlots[0]);
            setAllCustLabels([...allCustLabels]);
        }
    };

    useEffect(() => {
        const initialSlotsData = defaultUserPref
            ? Object.entries(defaultUserPref)
                  .filter(([key, elem]) => key.includes("customer_slot") && elem !== "NULL" && elem !== null)
                  .map(([key, elem]) => elem)
            : [];
        setCustomerSlot([...initialSlotsData]);
    }, [defaultUserPref]);

    useEffect(() => {
        if (result) {
            const dataSlices = result?.data().slices().toArray();
            const custLabels = dataSlices.map((dataSlice, index) =>
                Object.assign({
                    key: dataSlice.sliceTitles()[0],
                    player: getCustlabelByCustId(dataSlice.sliceTitles()[0]),
                }),
            );
            let reAllCustLabels = rePositionAllPlayers(custLabels, customerSlots);
            setAllCustLabels(reAllCustLabels);
        }
    }, [result]);

    function onSaveClick() {
        let mutationData = allCustLabels
            .map((elem, index) =>
                Object.assign({
                    [`customer_slot_${index + 1}`]: elem?.key || null,
                }),
            )
            .reduce((acc, elem2) => {
                return {
                    ...acc,
                    ...elem2,
                };
            }, {});

        updateSettings({
            variables: {
                ...mutationData,
                country: ctryid,
                channel1: channel1,
            },
            refetchQueries: [
                {
                    query: GET_ALL_USER_PREFERENCES,
                },
            ],
        })
            .then((res) => {
                // refrechSettings({
                //     ctryid,
                //     channel1
                // });
                message.success({
                    content: "Player slots updated",
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 2,
                });
            })
            .catch((e) => {
                message.error({
                    content: `${e}`,
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 2,
                });
            });
    }
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            let dragRow = allCustLabels[dragIndex];
            let mutableCustLabels = allCustLabels;
            if (allCustLabels[hoverIndex] === null) {
                mutableCustLabels = update(allCustLabels, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 1, dragRow],
                    ],
                });
            } else {
                mutableCustLabels = update(allCustLabels, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                });
            }
            setAllCustLabels(mutableCustLabels);
        },
        [allCustLabels],
    );

    return (
        <Modal
            okText="Save"
            title="Edit Customer Slots"
            open={isOpen}
            onOk={() => {
                onSaveClick();
                setIsOpen(false);
            }}
            onCancel={() => setIsOpen(false)}
        >
            {status === "loading" ? (
                <div className="flex justify-center items-center">
                    <LoadingSpinner />
                </div>
            ) : (
                <DndProvider backend={HTML5Backend}>
                    <div className="flex justify-evenly">
                        <Table
                            rowClassName={(record, index) => (index < 8 ? "shadow-inner shadow-backgroundColor" : "bg-gray-200")}
                            columns={playerTableColumns}
                            dataSource={allCustLabels}
                            style={{
                                minWidth: "40%",
                            }}
                            size="small"
                            components={components}
                            onRow={(_, index) => {
                                const attr = {
                                    index,
                                    moveRow,
                                };
                                return attr;
                            }}
                            pagination={false}
                            scroll={{ y: 400 }}
                            showHeader={false}
                        />
                    </div>
                </DndProvider>
            )}
        </Modal>
    );
};

const Selectedcust = () => {
    const { defaultUserPref } = useContext(UserContext);
    const { getCustlabelByCustId } = useAppContext();
    const [customerSlot, setCustomerSlot] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const initialSlotsData = defaultUserPref
            ? Object.entries(defaultUserPref)
                  .filter(([key, elem]) => key.includes("customer_slot") && elem !== "NULL" && elem !== null)
                  .map(([key, elem]) => elem)
            : [];
        setCustomerSlot([...initialSlotsData]);
    }, [defaultUserPref]);

    return (
        <div className="flex justify-between items-center my-4 flex-1">
            <div className="flex gap-2 items-center flex-wrap">
                <span className="text-sm font-medium text-indigo-700">Selected Player Slots:</span>
                <ul className="my-0 flex gap-1 flex-wrap">
                    {customerSlot.map((elem, index) => (
                        <li key={index} className="text-sm font-medium text-indigo-700">
                            {getCustlabelByCustId(elem)}
                            {index !== customerSlot.length - 1 && " | "}
                        </li>
                    ))}
                </ul>
            </div>
            <button className="w-44 bg-indigo-700 hover:bg-indigo-500 text-white font-semibold shadow-button py-1 rounded-md" onClick={() => setIsOpen(true)}>
                Edit players slots
            </button>
            <DnDModal isOpen={isOpen} setIsOpen={setIsOpen} />
        </div>
    );
};

export default Selectedcust;
