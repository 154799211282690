import React, { useContext } from "react";
import { useQuery } from "@apollo/client";
import { CloseOutlined } from "@ant-design/icons";
import { useAppContext } from "../../contexts/AppContext";
import FiltersContext from "../../contexts/Filters";
import { GET_RE_RULES_UPDATES } from "../../queries/Users";
import UserContext from "../../contexts/User";
import "../../styles/ticker.css";

const News = () => {
    const { getCountryByCode, activenews, setActivenews } = useAppContext();
    const { filterCountryId, filterChannel1 } = useContext(FiltersContext);
    const { updateUser } = useContext(UserContext);
    const countryFilterValue = filterCountryId.positiveAttributeFilter.in["values"][0] || "";
    const channel1FilterValue = filterChannel1.positiveAttributeFilter.in["values"][0] || "";
    const { data } = useQuery(GET_RE_RULES_UPDATES, {
        variables: {
            ctryid: countryFilterValue,
            channel1: channel1FilterValue,
        },
    });

    const dataNotes = data?.re_rentaleye_re_rules_updates.filter((note) => note.release_type === "data")[0];

    function onClose() {
        setActivenews(!activenews);
        updateUser({
            variables: {
                args: {
                    news_visible: false,
                },
            },
        });
    }

    return (
        dataNotes !== undefined && (
            <div className={"flex items-start gap-2 p-2 text-overflow min-h-6 max-w-full bg-indigo-300 shadow-md  font-semibold text-base text-white"}>
                <CloseOutlined
                    className="self-center p-2 rounded-sm"
                    style={{
                        color: "#ffffff",
                    }}
                    onClick={onClose}
                />
                <div className="flex gap-2">
                    <span>{`${getCountryByCode(countryFilterValue)} - ${channel1FilterValue} latest news  : `}</span>
                    <span className={"flex-1 flex flex-col"}>
                        {dataNotes.updatetext}
                        <em className="not-italic font-light">{dataNotes.vatrules}</em>
                    </span>
                </div>
            </div>
        )
    );
};

export default News;
