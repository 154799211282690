import React, { useCallback, useContext, useState } from "react";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../md/full";

const BrandConfiguratorContext = React.createContext({
    filterSelectors: {},
    toGDFilters: () => [],
    setFilterSelector: (newConfig) => {},
});

const CustIDByScope = {
    "FR-B2B": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "DE-B2B": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "BE-B2B": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "IT-B2B": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "ES-B2B": {
        promo: ["OEM"],
        config: ["COH", "COP"],
    },
    "NL-B2B": {
        promo: ["OBH", "OEH"],
        config: [],
    },
    "UK-B2B": {
        promo: ["OEM"],
        config: [],
    },
    // B2C
    "FR-B2C": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "DE-B2C": {
        promo: ["OEM"],
        config: ["CAP"],
    },
    "BE-B2C": {
        promo: ["OEM"],
        config: ["CAP", "COH", "COP"],
    },
    "IT-B2C": {
        promo: ["OEM"],
        config: ["CAP", "COH", "COP"],
    },
    "ES-B2C": {
        promo: ["OEM"],
        config: ["CAP", "COH", "COP"],
    },
    "NL-B2C": {
        promo: ["OEH"],
        config: [],
        // config: "CAP",
    },
    "UK-B2C": {
        promo: ["OEP"],
        config: [],
    },
    "GB-B2C": {
        promo: ["3PO"],
        config: [],
    },
};

const BrandColors = {
    ABARTH: "#E5E4CA",
    "ALFA ROMEO": "#C00000",
    ALPINE: "#003399",
    AUDI: "#595959",
    BMW: "#A02B93",
    BYD: "#DAE9F8",
    CITROEN: "#D97710",
    CUPRA: "#9F0001",
    DACIA: "#2D4ACB",
    DS: "#945200",
    FIAT: "#8ED973",
    FORD: "#61CBF3",
    HYUNDAI: "#4D93D9",
    JAGUAR: "#3C7D22",
    JEEP: "#A98E63",
    KIA: "#BE7380",
    MADZA: "#D0D0D0",
    "MERCEDES-BENZ": "#EE66E1",
    MG: "#DAF2D0",
    MINI: "#ADADAD",
    NISSAN: "#002060",
    OPEL: "#DAE54E",
    PEUGEOT: "#4F46E5",
    POLESTAR: "#DAF2D0",
    RENAULT: "#ffd500",
    SEAT: "#8C2518",
    SKODA: "#0D9488",
    TESLA: "#FF2600",
    TOYOTA: "#FF9300",
    VOLKSWAGEN: "#45578c",
    VOLVO: "#4E8FAD",
};

const BrandConfiguratorProvider = ({ children }) => {
    const [filterSelectors, setFilterSelector] = useState({});

    const toGDFilters = useCallback(() => {
        const makes = Object.keys(filterSelectors);
        let models = [];
        //  makes.map(make => Object.keys(filterSelectors[make])).flat();
        let versionStd = [];
        makes.forEach((make) => {
            models = [...models, ...Object.keys(filterSelectors[make])];
            versionStd = [
                ...versionStd,
                ...Object.values(filterSelectors[make])
                    .flat()
                    .map((versionObj) => versionObj.label),
            ];
        });
        return [newPositiveAttributeFilter(Md.CorrMake, makes), newPositiveAttributeFilter(Md.CorrModel, models), newPositiveAttributeFilter(Md.VersionStd, versionStd)];
    }, [filterSelectors]);

    return (
        <BrandConfiguratorContext.Provider
            value={{
                filterSelectors,
                toGDFilters,
                setFilterSelector,
            }}
        >
            {children}
        </BrandConfiguratorContext.Provider>
    );
};

const useBrandConfigurator = () => useContext(BrandConfiguratorContext);

export { useBrandConfigurator, BrandConfiguratorProvider, CustIDByScope, BrandColors };
