import { useQuery } from "@apollo/client";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { Typography } from "antd";
import React, { useEffect } from "react";
import { useContext } from "react";
import { useState } from "react";
import FiltersContext from "../../contexts/Filters";
import { ENABLED_SETTINGS } from "../../queries/Users";
import * as Md from "../../md/full";
import TableComponent from "../TableComponent";

const WBItable = ({ ...props }) => {
    const [WBItable, setWBItable] = useState([]);
    const {
        filterMthCode,
        filterCountryId,
        filterChannel1,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterCustmix,
        filterVersionStd,
        filterExtrapType,
        filterRolledForward,
        filterChannel3,
        filterCo2,
        filterListPrice,
        filterPs,
        filterKw,
    } = useContext(FiltersContext);
    const filters = [
        filterCountryId,
        filterMthCode,
        filterChannel1,
        filterMake,
        filterModel,
        filterFuel,
        filterCustId,
        filterCustLabel,
        filterVehType,
        filterVehSegment,
        filterBody,
        filterTrim,
        filterDrive,
        filterGearPlus,
        filterSize,
        filterCustmix,
        filterVersionStd,
        filterExtrapType,
        filterRolledForward,
        filterChannel3,
        filterCo2,
        filterListPrice,
        filterPs,
        filterKw,
    ];
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [Md.FlBreakdown.Avg, Md.FlCo2malus.Avg, Md.FlFinancialloss.Avg, Md.FlInsurance.Avg, Md.FlReplacement.Avg, Md.FlTyres.Avg, Md.FlWintertyres.Avg, Md.FlEvbonus.Avg, Md.FlService.Avg, Md.ListPrice.Avg, Md.Ps.Avg, Md.Kw.Avg, Md.Co2malus.Avg],
            slicesBy: [Md.CustId],
            filters: filters,
        },
    });
    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };
    const Enabled = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid: filterCountryId.positiveAttributeFilter.in["values"][0], channel1: filterChannel1.positiveAttributeFilter.in["values"][0] },
    }).data?.re_rentaleye_re_services_settings[0];
    useEffect(() => {
        const slices = result?.data()?.slices().toArray();
        const data = slices?.map((slice) => {
            return {
                CustId: slice.sliceTitles()[0],
                [settingsAliases.flag_bre]: +slice.dataPoints()[0].rawValue === 1 ? "Y" : +slice.dataPoints()[0].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[0].rawValue * 100) + "%",
                [settingsAliases.flag_co2]: +slice.dataPoints()[1].rawValue === 1 ? "Y" : +slice.dataPoints()[1].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[1].rawValue * 100) + "%",
                [settingsAliases.flag_fin]: +slice.dataPoints()[2].rawValue === 1 ? "Y" : +slice.dataPoints()[2].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[2].rawValue * 100) + "%",
                [settingsAliases.flag_ins]: +slice.dataPoints()[3].rawValue === 1 ? "Y" : +slice.dataPoints()[3].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[3].rawValue * 100) + "%",
                [settingsAliases.flag_rep]: +slice.dataPoints()[4].rawValue === 1 ? "Y" : +slice.dataPoints()[4].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[4].rawValue * 100) + "%",
                [settingsAliases.flag_tyr]: +slice.dataPoints()[5].rawValue === 1 ? "Y" : +slice.dataPoints()[5].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[5].rawValue * 100) + "%",
                [settingsAliases.flag_win]: +slice.dataPoints()[6].rawValue === 1 ? "Y" : +slice.dataPoints()[6].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[6].rawValue * 100) + "%",
                [settingsAliases.flag_evb]: +slice.dataPoints()[7].rawValue === 1 ? "Y" : +slice.dataPoints()[7].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[7].rawValue * 100) + "%",
                [settingsAliases.flag_ser]: +slice.dataPoints()[8].rawValue === 1 ? "Y" : +slice.dataPoints()[8].rawValue === 0 ? "N" : Math.round(+slice.dataPoints()[8].rawValue * 100) + "%",
            };
        });
        setWBItable(data);
    }, [result]);
    const Customer = {
        title: "",
        dataIndex: "CustId",
        key: "CustId",
        render: (cell) => (
            <center>
                <img src={`/images/logos/Logo_${cell}.png`} width={64} height={64} alt={cell} />
            </center>
        ),
        align: "center",
        width: 100,
    };
    const columns =
        Enabled &&
        Object.keys(Enabled)
            .filter((key) => Enabled[key] === 1)
            .map((key) => {
                return {
                    title: settingsAliases[key],
                    dataIndex: settingsAliases[key],
                    key: settingsAliases[key],
                    render: (text) => (
                        <Typography.Text style={{ fontSize: 12 }} strong>
                            {text}
                        </Typography.Text>
                    ),
                    align: "center",
                    width: 100,
                };
            });
    columns?.length > 0 && columns.unshift(Customer);
    return status === "loading" ? (
        <LoadingComponent />
    ) : (
        <div>
            <TableComponent
                bordered
                className="border border-gray-200"
                dataSource={columns?.length > 0 ? WBItable : null}
                columns={columns}
                scroll={{ y: 400 }}
                pagination={false}
                widgetProps={{
                    id: "monthly-rate-scraping",
                    filters,
                    section: "datacoverage",
                }}
                {...props}
            />
        </div>
    );
};

export default WBItable;
