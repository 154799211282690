import React, { useContext } from "react";
import HighchartsComponent from "../../HighchartsComponent";
import { useAppContext } from "../../../contexts/AppContext";
import FiltersContext from "../../../contexts/Filters";
import { LoadingComponent } from "@gooddata/sdk-ui";

/**
 *
 * @param {*} data: List of cust kpi values for each vehicle
 * @returns
 */
const BasketSynthesisChart = ({ printStatus, data, basketReference = undefined, filters, isLoading = false, width = null, title, kpiSettings, ...props }) => {
    const id = "basket-synthesis-graph";
    const { getParamsByCustID } = useAppContext();

    const { getFirstFilterValue } = useContext(FiltersContext);

    const chartData = data.map((elem, index) => {
        const dataValues = Object.entries(elem)
            .filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null)
            .map(([key, value]) => value)
            .sort((a, b) => a - b);

        return Object.assign({
            name: elem.versionStd,
            y: dataValues[0],
            color: elem.versionStd === basketReference?.versionStd && "#3a0ca3",
        });
    });
    chartData.sort((a, b) => b.y - a.y);

    const categories = chartData.map((item) => item.name);
    const custOfferSeries = data.reduce((acc, elem) => {
        const custOffersMatrix = Object.entries(elem).filter(([key, value]) => key !== "versionStd" && key !== "make" && value !== null);
        // find position of the versionStd in the data used in bar chart
        const xIndex = categories.findIndex((category) => category === elem?.versionStd);
        custOffersMatrix.forEach((custOffer) => {
            const custObj = getParamsByCustID(custOffer[0], getFirstFilterValue("filterCountryId"));
            const custSerie = acc.find((item) => item.id === custOffer[0]);
            if (custSerie !== undefined) {
                custSerie.data.push({
                    x: xIndex,
                    y: custOffer[1],
                });
            } else {
                acc.push(
                    Object.assign({
                        type: "scatter",
                        id: custOffer[0],
                        name: custObj?.custLabel,
                        color: custObj?.hexColor,
                        data: [
                            {
                                x: xIndex,
                                y: custOffer[1],
                            },
                        ],
                    }),
                );
            }
        });
        return acc;
    }, []);

    return isLoading ? (
        <LoadingComponent />
    ) : (
        <div className={!printStatus && "border border-gray-200 shadow rounded"}>
            <HighchartsComponent
                enableScreenshot={!printStatus}
                widgetProps={{
                    id,
                    filters,
                    section: "basketsynthesis",
                    flag_services: kpiSettings,
                }}
                options={{
                    chart: {
                        height: categories.length < 6 ? 450 : 600,
                        width: width,
                    },
                    plotOptions: {
                        // series: {
                        // },
                        bar: {
                            pointWidth: 30.0,
                            borderRadius: 2.0,
                            color: "#506e96",
                            enableMouseTracking: false,
                            dataLabels: {
                                enabled: true,
                                align: "right",
                                x: -15,
                                color: "#FFFFFF",
                            },
                        },
                        scatter: {
                            stickyTracking: false,
                            // findNearestPointBy: 'y',
                            marker: {
                                radius: 6,
                            },
                        },
                    },
                    title: {
                        text: title,
                        style: {
                            fontSize: printStatus ? "20px" : "14px",
                            padding: printStatus ? "16px" : null,
                            fontWeight: "bold",
                            color: "#4d4d4d",
                            textAlign: "center",
                        },
                    },
                    tooltip: {
                        snap: 0,
                        formatter: function () {
                            if (this.series.name !== "Cheapest competitor") {
                                return `<b>${this.series.name}</b><br/>${this.y}`;
                            } else return `<span>${this.x}</span><br/><em>Adj.rental</em> ${this.y}`;
                        },
                    },
                    series: [
                        {
                            name: "Cheapest competitor",
                            type: "bar",
                            data: chartData,
                        },
                        ...custOfferSeries,
                    ],
                    xAxis: {
                        categories: categories,
                    },
                    yAxis: {
                        title: {
                            text: null,
                        },
                        plotLines: basketReference?.value && [
                            {
                                value: basketReference?.value,
                                width: 2,
                                zIndex: 10,
                                color: "#FF0000",
                                dashStyle: "shortdash",
                            },
                        ],
                    },
                }}
                {...props}
            />
        </div>
    );
};

export default BasketSynthesisChart;
