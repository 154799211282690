import React, { Fragment, useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { ShoppingBagIcon, BellIcon } from "@heroicons/react/24/outline";

import { Menu, Transition } from "@headlessui/react";
import { useBasketContext } from "../../contexts/BasketProvider";
import Basket from "../Baskets/Basket";
import { useWorkspaceList } from "../../contexts/WorkspaceList";
import { useWorkspace } from "../../contexts/Workspace";
import UserContext from "../../contexts/User";
import NotificationList from "../Notifications/NotificationList";
import { useNotificationContext } from "../../contexts/NotificationProvider";
import { useAppContext } from "../../contexts/AppContext";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
import { supportEmail } from "../../constants";
import { GuidebookIcon, LogoutIcon, SupportIcon } from "../Icons/MenuIcons";
import { navigation } from "./Sidebar";

const userNavigation = [...navigation.filter((nav) => nav.insideProfileMenu), { name: "Guidebook", icon: GuidebookIcon, pathname: "docs/guidebook.pdf" }, { name: "Support", icon: SupportIcon, pathname: `mailto:${supportEmail}` }, { name: "Sign out", icon: LogoutIcon, href: "/logout" }];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const Header = ({ title, ...props }) => {
    let { userBaskets, vehicles, setBasketModal, isBasketModalOpened, setSelectedBaskets } = useBasketContext();
    const workspaceList = useWorkspaceList();
    const { workspace, setWorkspace } = useWorkspace();
    const { user } = useContext(UserContext);
    const { notifications } = useNotificationContext();
    const { isSideBarCollapsed, setCollapseSidebar } = useAppContext();
    const [openNotification, setOpenNotification] = useState(false);
    const activeNotifications = notifications?.filter((notif) => !notif.is_opened);

    const onNotificationClick = useCallback(
        (objId) => {
            const basketNotif = userBaskets.find((item) => item.id === objId);
            basketNotif && setSelectedBaskets([basketNotif]);
        },
        [userBaskets],
    );
    const collapseWidget = isSideBarCollapsed ? GoSidebarCollapse : GoSidebarExpand;

    return (
        <header className={`fixed w-full flex items-center gap-2 z-50 bg-white pt-3 pb-2 pl-2 pr-3 border border-b ${props.className}`}>
            <img src="/images/experteye-logo.png" alt="Experteye Logo" width="140" height="130" />
            {/* {React.createElement(collapseWidget, { onClick: () => setCollapseSidebar(!isSideBarCollapsed), className: "cursor-pointer w-7 h-7 text-steel pb-1 hover:text-indigo-500" })} */}
            <h2 className="text-2xl text-indigo-700 leading-none my-0 mb-1.5 ml-4">{title}</h2>
            <div className="flex gap-4 items-center content-center ml-auto">
                <label htmlFor="workspace" className="mt-8 block text-xs font-medium text-white">
                    Workspace
                </label>
                <select id="workspace" name="workspace" value={workspace} onChange={(e) => setWorkspace(e.target.value)} className="mt-1 block w-full rounded-md border-gray-300 py-2 px-3 text-xs font-semibold hover:opacity-80 cursor-pointer">
                    {workspaceList &&
                        workspaceList?.data &&
                        workspaceList?.data.map((m) => (
                            <option key={m.id} value={m.id}>
                                {m.title}
                            </option>
                        ))}
                </select>
                <div
                    className="cursor-pointer"
                    onClick={() => {
                        setBasketModal(!isBasketModalOpened);
                    }}
                >
                    <ShoppingBagIcon className={`w-6 relative ${vehicles.length > 0 ? "top-1" : ""} text-indigo-700 hover:opacity-80`} aria-hidden="true" />
                    {vehicles.length > 0 && <div className="relative bottom-1 left-2 bg-backgroundColor font-semibold text-xs px-2 rounded-full">{vehicles.length}</div>}
                </div>
                <div className="cursor-pointer" onClick={() => setOpenNotification(!openNotification)}>
                    <BellIcon className={`w-6 relative ${activeNotifications.length > 0 ? "top-1" : ""} text-indigo-700 hover:opacity-80 cursor-pointer`} />
                    {activeNotifications.length > 0 && <div className="relative bottom-2 left-4 w-2 h-2 rounded-full border border-secondary-500 bg-secondary-500"></div>}
                </div>
                <Basket vehicles={vehicles} />
                <NotificationList isOpen={openNotification} closeHandler={() => setOpenNotification(false)} onSelectItem={onNotificationClick} />
            </div>
            {/* {window.location.pathname === "/" && (
                <NewspaperIcon
                    aria-activedescendant="news"
                    className={activenews ? "mt-0.5 mx-1 w-6 text-indigo-900 hover:opacity-80 cursor-pointer" : "mt-0.5 mx-1 w-6 text-gray-300 hover:opacity-80 cursor-pointer"}
                    onClick={() => {
                        setActivenews(!activenews);
                    }}
                    aria-hidden="true"
                />
            )} */}
            <div className="ml-4 flex md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div">
                    <div>
                        <Menu.Button className="text-white bg-indigo-700 h-10 w-10 rounded-full">
                            <span className="sr-only">Open user menu</span>
                            <div className="text-xl">{user?.username[0]?.toUpperCase()}</div>
                        </Menu.Button>
                    </div>

                    <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95">
                        <Menu.Items className="origin-top-right absolute right-0 mt-2 mr-1.5 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                            {userNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                    {({ active }) => (
                                        <Link to={item?.pathname ? { pathname: item.pathname } : item.href} target={item.pathname && "_blank"} className={classNames(active ? "bg-gray-100" : "", `flex gap-2 items-center px-${item.dimension ? 3 : 4} py-2 text-sm text-gray-700`)}>
                                            {React.createElement(item.icon, { className: `${item.dimension || "w-5 h-5"} fill-gray-700 hover:fill-blue-500` })}
                                            {item.name}
                                        </Link>
                                    )}
                                </Menu.Item>
                            ))}
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            {/* </div> */}
        </header>
    );
};

export default Header;
