import React from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const UpDownButton = ({ visibilityState = true, onClickHandler = () => {} }) =>
    visibilityState ? (
        <UpOutlined
            className="p-2 rounded-full hover:bg-backgroundColor"
            style={{
                color: "#506e96",
            }}
            onClick={onClickHandler}
        />
    ) : (
        <DownOutlined
            className="p-2 rounded-full hover:bg-backgroundColor"
            style={{
                color: "#506e96",
            }}
            onClick={onClickHandler}
        />
    );

export default UpDownButton;
