import React, { useContext, useCallback, useEffect, useRef, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { message, Table } from "antd";

import UserContext from "../../contexts/User";
import FiltersContext from "../../contexts/Filters";
import { useAppContext } from "../../contexts/AppContext";
import * as Md from "../../md/full";
import DropIcon from "../Icons/DropIcon";
import { GET_ALL_USER_PREFERENCES } from "../../queries/Users";

const type = "DraggableBodyRow";
const DraggableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
        accept: type,
        collect: (monitor) => {
            const { index: dragIndex } = monitor.getItem() || {};
            if (dragIndex === index) {
                return {};
            }
            return {
                isOver: monitor.isOver(),
                dropClassName: dragIndex < index ? " drop-over-downward" : " drop-over-upward",
            };
        },
        drop: (item) => {
            moveRow(item.index, index);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {
            index,
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ""}`}
            style={{
                cursor: "move",
                ...style,
            }}
            {...restProps}
        />
    );
};

const slotTableColumns = [
    {
        title: "Selected players",
        dataIndex: "player",
        key: "player",
        render: (cellValue, record) => (
            <span className="flex gap-2 p-1 items-center text-base">
                <img src={`/images/logos/Logo_${record.key}.png`} width={70} height={70} /> {cellValue}
            </span>
        ),
    },
];

const UPDATE_CUSTOMER_SLOTS_SETTINGS = gql`
    mutation updateCustomerSlots($country: String, $channel1: String, $customer_slot_1: String, $customer_slot_2: String, $customer_slot_3: String, $customer_slot_4: String, $customer_slot_5: String, $customer_slot_6: String, $customer_slot_7: String, $customer_slot_8: String) {
        update_re_rentaleye_re_params_user_prefs(
            where: { ctryid: { _eq: $country }, channel1: { _eq: $channel1 } }
            _set: { customer_slot_1: $customer_slot_1, customer_slot_2: $customer_slot_2, customer_slot_3: $customer_slot_3, customer_slot_4: $customer_slot_4, customer_slot_5: $customer_slot_5, customer_slot_6: $customer_slot_6, customer_slot_7: $customer_slot_7, customer_slot_8: $customer_slot_8 }
        ) {
            returning {
                customer_slot_1
                customer_slot_2
                customer_slot_3
                customer_slot_4
                customer_slot_5
                customer_slot_6
                customer_slot_7
                customer_slot_8
            }
        }
    }
`;

const CustomerSettings = () => {
    // Get the list of cust ids
    const { filterCountryId, filterChannel1 } = useContext(FiltersContext);
    const { result, status } = useExecutionDataView({
        execution: {
            seriesBy: [Md.FinRental.Avg],
            slicesBy: [Md.CustId],
            filters: [filterCountryId, filterChannel1],
        },
    });

    // Add drop icon button
    const playerTableColumns = [
        {
            title: "Available players",
            dataIndex: "player",
            key: "player",
            render: (cellValue, record, index) => {
                if (record === null) {
                    return <span className="flex justify-center p-1 italic text-sm font-medium text-gray-700">Empty slot</span>;
                }
                return (
                    <span className="flex items-center text-base gap-3 p-1">
                        <div className="flex-1 flex gap-2">
                            <img src={`/images/logos/Logo_${record.key}.png`} width={70} height={70} /> {cellValue}
                        </div>
                        {index < 8 && (
                            <button onClick={() => onDropSlot(index)} className="text-gray-700 hover:text-red">
                                <DropIcon />
                            </button>
                        )}
                    </span>
                );
            },
        },
    ];

    const [allCustLabels, setAllCustLabels] = useState([]);

    const { defaultUserPref } = useContext(UserContext);
    const { getFilterValue } = useContext(FiltersContext);
    const { getCustlabelByCustId } = useAppContext();
    const [updateSettings] = useMutation(UPDATE_CUSTOMER_SLOTS_SETTINGS);
    const [customerSlots, setCustomerSlot] = useState([]);
    const [isSettingsUpdate, setSettingsStatus] = useState(false);
    const [isEditAllowed, setEdit] = useState(false);

    const ctryid = getFilterValue("filterCountryId")[0];
    const channel1 = getFilterValue("filterChannel1")[0];
    const sourceData =
        customerSlots &&
        customerSlots?.map((elem) =>
            Object.assign({
                key: elem,
                player: getCustlabelByCustId(elem),
            }),
        );
    const rePositionAllPlayers = useCallback(
        (players, slots) => {
            /**
             * This method re-position players values to be idential as slots array
             */
            slots.forEach((elem, index) => {
                let currentIndex = players.findIndex((player) => player.key === elem);
                if (currentIndex !== -1) {
                    let playerObj = players[currentIndex];
                    players.splice(currentIndex, 1);
                    players.splice(index, 0, playerObj);
                }
            });
            return players;
        },
        [customerSlots],
    );
    const onDropSlot = (position) => {
        let droppedSlots = allCustLabels.splice(position, 1, null);
        if (droppedSlots?.length > 0) {
            allCustLabels.push(droppedSlots[0]);
            setAllCustLabels([...allCustLabels]);
        }
    };

    useEffect(() => {
        const initialSlotsData = defaultUserPref
            ? Object.entries(defaultUserPref)
                  .filter(([key, elem]) => key.includes("customer_slot") && elem !== "NULL" && elem !== null)
                  .map(([key, elem]) => elem)
            : [];
        setCustomerSlot([...initialSlotsData]);
    }, [defaultUserPref]);

    useEffect(() => {
        // setSettingsStatus(JSON.stringify(customerSlots) !== JSON.stringify(initialSlotsData));
        if (isEditAllowed) {
            setSettingsStatus(allCustLabels.length > 0 && JSON.stringify(allCustLabels.slice(0, 8)) !== JSON.stringify(sourceData.slice(0, 8)));
        }
        // // Remove empty slots that aren't part of the first sixth slots
        // let emptySlotIndex = allCustLabels;
        // allCustLabels.forEach((elem, index) => {
        //     if (elem === null && index > 5) {
        //         emptySlotIndex.splice(index, 1, null);
        //     }
        // });
        // if (emptySlotIndex.length >0 &&  JSON.stringify(emptySlotIndex) !==  JSON.stringify(allCustLabels)) {

        //     setAllCustLabels([...emptySlotIndex]);
        // }
    }, [allCustLabels, sourceData, isEditAllowed]);

    useEffect(() => {
        if (result) {
            const dataSlices = result?.data().slices().toArray();
            const custLabels = dataSlices.map((dataSlice, index) =>
                Object.assign({
                    key: dataSlice.sliceTitles()[0],
                    player: getCustlabelByCustId(dataSlice.sliceTitles()[0]),
                }),
            );
            let reAllCustLabels = rePositionAllPlayers(custLabels, customerSlots);
            setAllCustLabels(reAllCustLabels);
        }
    }, [result]);

    function onSaveClick(e) {
        e.preventDefault();
        let mutationData = allCustLabels
            .map((elem, index) =>
                Object.assign({
                    [`customer_slot_${index + 1}`]: elem?.key || null,
                }),
            )
            .reduce((acc, elem2) => {
                return {
                    ...acc,
                    ...elem2,
                };
            }, {});

        updateSettings({
            variables: {
                ...mutationData,
                country: ctryid,
                channel1: channel1,
            },
            refetchQueries: [
                {
                    query: GET_ALL_USER_PREFERENCES,
                },
            ],
        })
            .then((res) => {
                // refrechSettings({
                //     ctryid,
                //     channel1
                // });
                message.success({
                    content: "Player slots updated",
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 2,
                });
                setEdit(false);
                setSettingsStatus(false);
            })
            .catch((e) => {
                message.error({
                    content: `${e}`,
                    style: {
                        right: "20px",
                        top: "70px",
                        position: "absolute",
                    },
                    duration: 2,
                });
            });
    }
    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };
    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            let dragRow = allCustLabels[dragIndex];
            let mutableCustLabels = allCustLabels;
            if (allCustLabels[hoverIndex] === null) {
                mutableCustLabels = update(allCustLabels, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 1, dragRow],
                    ],
                });
            } else {
                mutableCustLabels = update(allCustLabels, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                });
            }
            setAllCustLabels(mutableCustLabels);
        },
        [allCustLabels],
    );

    return (
        <section className="flex flex-row gap-4">
            <div className="flex justify-between items-top">
                <div className="flex flex-col">
                    <h3 className="text-xl font-semibold text-indigo-700">Slots order in Baskets</h3>
                    {isEditAllowed && <span className="text-sm font-medium">Drag & Drop to specify order</span>}
                    {isSettingsUpdate ? (
                        <button className="self-start mt-4 text-steel text-base font-medium px-3 py-2 border-2 border-steel rounded shadow-md hover:bg-backgroundColor" onClick={onSaveClick}>
                            Save
                        </button>
                    ) : (
                        !isEditAllowed && (
                            <button
                                className="self-start text-steel text-base font-medium px-3 py-2 border-2 border-steel rounded shadow-md hover:bg-backgroundColor"
                                onClick={() => {
                                    setEdit(true);
                                }}
                            >
                                Edit slots
                            </button>
                        )
                    )}
                </div>
            </div>
            <DndProvider backend={HTML5Backend}>
                <div className="flex justify-evenly ml-48">
                    {isEditAllowed && (
                        <Table
                            rowClassName={(record, index) => (index < 8 ? "shadow-inner shadow-backgroundColor" : "bg-gray-200")}
                            columns={playerTableColumns}
                            dataSource={allCustLabels}
                            style={{
                                minWidth: "40%",
                            }}
                            size="small"
                            components={components}
                            onRow={(_, index) => {
                                const attr = {
                                    index,
                                    moveRow,
                                };
                                return attr;
                            }}
                            pagination={false}
                        />
                    )}
                    {!isEditAllowed && (
                        <Table
                            columns={slotTableColumns}
                            dataSource={sourceData}
                            style={{
                                minWidth: "40%",
                            }}
                            size="small"
                            pagination={false}
                        />
                    )}
                </div>
            </DndProvider>
        </section>
    );
};

export default CustomerSettings;
