import React from "react";
import { newArithmeticMeasure } from "@gooddata/sdk-model";

import { flagServiceMap } from "../../contexts/Measures";
import * as Md from "../../md/full";

export const CarSelectorWrapper = ({ componentChild, execDefinition = {}, flag_services, ...props }) => {
    const { seriesBy, slicesBy, filters } = execDefinition;
    // const [newSeries, setNewSeries] = useState([...seriesBy]);
    const newSeries = [...seriesBy];
    const enabledServices = Object.keys(flag_services)
        .filter((flService) => flService in flagServiceMap && flag_services[flService])
        .map((flService) => flagServiceMap[flService]);
    newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
    newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));

    return <div>{React.createElement(componentChild, { seriesBy: [...newSeries], slicesBy, filters, kpiSettings: flag_services, ...props })}</div>;
};
