import { useMutation, useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { DELETE_CUSTOM_FIELD, GET_CAR_SELECTOR_CUSTOM_FIELDS, INSERT_CAR_SELECTOR_CONFIG } from "../queries/CarSelector";
import FiltersContext from "./Filters";

class ModelObject {
    /**
     * This object represent the model that will be selected inside the model table
     */
    constructor({ make, model, fuel, filters, Versions, minListPrice, maxListPrice, evolution, players, selectionStatus = "none", vehicles = [] }) {
        this.make = make;
        this.model = model;
        this.fuel = fuel;
        this.filters = filters;
        this.Versions = Versions;
        this.minListPrice = minListPrice;
        this.maxListPrice = maxListPrice;
        this.evolution = evolution;
        this.players = players;
        this.selectionStatus = selectionStatus;
        this.showViz = false;
        this.openVehicleTable = false;
        this.vehicles = vehicles;
    }
}

function VehicleObject({ Version, Average, listPrice }, args = {}) {
    /**
     * This object represent the vehicle that will be selected inside the car selector table
     */
    return {
        Version,
        Average,
        listPrice,
        showViz: false,
        ...args,
    };
}

const CarSelectorContext = React.createContext({
    selectedModels: null,
    selectedVehicles: null,
    vizObj: undefined,
    customFields: [],
    hiddenPlayersField: undefined,
    loadingCustomFields: false,
    editCarSelectorConfig: (config) => Promise.resolve(undefined),
    dropField: ({ id }) => Promise.resolve(undefined),
    selectModelObject: (model) => {},
    setSelectedVehicles: (vehicles) => {},
    setSelectedModels: (models) => {},
    setSelectedVehiclesByModel: (model, vehicles) => {},
    insertVehicle: (vehicle) => {},
    removeVehicle: (vehicle) => {},
    showViz: (obj) => {},
    unSelectVizObj: () => {},
    isModelOpen: (obj) => {
        return false;
    },
});

const CarSelectorProvider = ({ children }) => {
    const [selectedModels, setSelectedModels] = useState([]);
    const [selectedVehicles, setSelectedVehicles] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [hiddenPlayersField, setHiddenPlayersField] = useState(undefined);
    // Latest obj to viz
    const [vizObj, setVizObj] = useState(undefined);
    const { getFirstFilterValue } = useContext(FiltersContext);

    const countryId = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");

    const { data: queryResult, loading: loadingCustomFields, refetch } = useQuery(GET_CAR_SELECTOR_CUSTOM_FIELDS);
    const [insertCarSelectorConfig] = useMutation(INSERT_CAR_SELECTOR_CONFIG, {
        refetchQueries: [GET_CAR_SELECTOR_CUSTOM_FIELDS],
    });
    const [deleteFieldWrapper] = useMutation(DELETE_CUSTOM_FIELD, {
        refetchQueries: [GET_CAR_SELECTOR_CUSTOM_FIELDS],
    });
    function selectModelObject(modelObject) {
        let modelsList = selectedModels;
        const modelItemIndex = modelsList.findIndex((item) => item.make === modelObject.make && item.model === modelObject.model && item.fuel === modelObject.fuel);
        if (modelItemIndex !== -1) {
            modelsList.splice(modelItemIndex, 1, modelObject);
        } else {
            modelsList = [...selectedModels, modelObject];
        }
        setSelectedModels([...modelsList]);
    }

    function setSelectedVehiclesByModel(modelObject, vehicles) {
        modelObject.vehicles = vehicles;
        selectModelObject(modelObject);
    }

    /**
     * Put a vehicle on the selection
     * @param {*} vehicle
     */
    function insertVehicle(vehicle) {
        if (!selectedVehicles.find((obj) => obj.Version === vehicle.Version)) {
            setSelectedVehicles([...selectedVehicles, vehicle]);
        }
    }

    /**
     * Drop a vehicle from the selection
     * @param {*} vehicle
     */
    function removeVehicle(vehicle) {
        const index = selectedVehicles.findIndex((obj) => obj.Version === vehicle.Version);
        if (index !== -1) {
            selectedVehicles.splice(index, 1);
            setSelectedVehicles([...selectedVehicles]);
        }
    }

    function showViz(obj) {
        // Only one model must be visualized
        obj.showViz = true;
        setVizObj(obj);
    }

    function unSelectVizObj() {
        setVizObj(undefined);
    }

    function isModelOpen(obj) {
        return selectedModels.find((elem) => elem.openVehicleTable && elem.make === obj.make && elem.model === obj.model && elem.fuel === obj.fuel);
    }

    function editCarSelectorConfig(config) {
        const payload = Object.entries(config).reduce((acc, [key, val]) => {
            const propKey = key === "fieldName" ? "field_name" : key;
            return {
                ...acc,
                [propKey]: val,
            };
        }, {});
        const filters = {
            country: countryId,
            channel1: channel1,
        };
        return insertCarSelectorConfig({
            variables: {
                config: {
                    ...payload,
                    filters,
                },
            },
        }).then((res) => refetch());
    }

    function dropField(field) {
        return deleteFieldWrapper({
            variables: {
                id: field.id,
            },
        });
    }

    useEffect(() => {
        if (selectedModels?.length > 0) {
            const vehiclesByModel = selectedModels.reduce((acc, nextObj) => {
                return [...acc, ...nextObj.vehicles.filter((vehicle) => vehicle !== undefined)];
            }, []);
            setSelectedVehicles([...vehiclesByModel]);
        }
    }, [selectedModels]);

    useEffect(() => {
        if (queryResult?.re_rentaleye_car_selector_config) {
            const data = queryResult?.re_rentaleye_car_selector_config
                .filter((item) => item.filters?.country === countryId && item.filters?.channel1 === channel1)
                .map((item) =>
                    Object.assign({
                        ...item,
                        fieldName: item.field_name,
                    }),
                );
            setCustomFields([...data.filter((item) => item.operation !== "Showing")]);
            const obj = data.find((item) => item.operation === "Showing");
            if (obj) {
                const { id, players, field_name, operation } = obj;
                setHiddenPlayersField({ id, players, field_name, operation });
            } else {
                setHiddenPlayersField(undefined);
            }
        }
    }, [queryResult, countryId, channel1]);

    return (
        <CarSelectorContext.Provider
            value={{
                selectedModels,
                selectedVehicles,
                vizObj,
                customFields,
                loadingCustomFields,
                hiddenPlayersField,
                selectModelObject,
                setSelectedVehicles,
                insertVehicle,
                removeVehicle,
                setSelectedModels,
                setSelectedVehiclesByModel,
                showViz,
                unSelectVizObj,
                isModelOpen,
                editCarSelectorConfig,
                dropField,
            }}
        >
            {children}
        </CarSelectorContext.Provider>
    );
};

const useCarSelector = () => useContext(CarSelectorContext);

export { CarSelectorProvider, useCarSelector, ModelObject, VehicleObject };
