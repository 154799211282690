import React, { useContext, useEffect, useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import MeasuresContext from "../../contexts/Measures";
import FiltersContext from "../../contexts/Filters";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { Modal } from "antd";
import { utils, writeFileXLSX } from "xlsx";

const mode = (arr) => {
    const store = {};
    arr.forEach((num) => (store[num] ? (store[num] += 1) : (store[num] = 1)));
    return Object.keys(store)
        .filter((val) => val !== "null")
        .sort((a, b) => (store[b] > store[a] ? 1 : -1))[0];
};

// array of columns key used for sorting
const primaryColumnKeys = ["Version", "List Price", "Average"];

export default function VehicleExport({ seriesBy, slicesBy, filters, kpi, onDownloadCallback }) {
    const { getCustlabelByCustId } = useAppContext();
    const { selectedAdjDeposit } = useContext(MeasuresContext);
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { result } = useExecutionDataView({
        execution: {
            seriesBy,
            slicesBy,
            filters,
        },
    });
    const [data, setData] = useState([]);
    const fileName = `Version-rental-prices_${getFirstFilterValue("filterCountryId")}-${getFirstFilterValue("filterChannel1")}-${getFirstFilterValue("filterDuration")}.xlsx`;

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        let predefined = [];
        if (slices?.length > 0) {
            if (selectedAdjDeposit !== "Total Payable by month") {
                predefined = slices.filter((slice) => slice.dataPoints()[2].rawValue !== null);
            } else {
                predefined = slices;
            }
            const preData = predefined?.map((slice, index) => {
                return Object.assign({
                    id: index,
                    Version: slice.sliceTitles()[0],
                    "List Price": Math.round(Number(slice.dataPoints()[1].rawValue)),
                    Average: kpi?.index === 24 ? Number(100 * Number(slice.dataPoints()[kpi?.index]?.rawValue)).toFixed(2) : Math.round(Number(slice.dataPoints()[kpi?.index].rawValue)),
                    [getCustlabelByCustId(slice.sliceTitles()[1])]: slice.dataPoints()[kpi?.index]?.rawValue !== null ? (kpi?.index === 24 ? Number(100 * Number(slice.dataPoints()[kpi?.index]?.rawValue)).toFixed(2) : Math.round(Number(slice.dataPoints()[kpi?.index]?.rawValue))) : null,
                });
            });
            let postData = [];
            for (let indexArr in preData) {
                let filteredArr = preData
                    .filter((elem, index) => elem?.Version === preData[indexArr].Version)
                    .map((elem, index) => {
                        return Object.assign({
                            ...elem,
                        });
                    });

                let obj = {};
                for (let indexFilteredArr in filteredArr) {
                    const { id = 0, ...newObj } = filteredArr[indexFilteredArr];
                    obj = { ...obj, ...newObj };
                    // Sorting attributes
                    obj = Object.keys(obj)
                        .sort((a, b) => (primaryColumnKeys.includes(a) || primaryColumnKeys.includes(b) ? primaryColumnKeys[a] || primaryColumnKeys[b] : a.localeCompare(b)))
                        .reduce((acc, key) => Object.assign({ ...acc, [key]: obj[key] }), {});
                    let elem = preData.findIndex((elem) => filteredArr[indexFilteredArr].id === elem?.id);
                    delete preData[elem];
                }
                const listPricesValues = Object.entries(obj)
                    .filter(([key, value]) => key === "List Price")
                    .map(([key, val]) => val);
                // let listPriceSum = listPricesValues.reduce((acc, currentVal) => acc + currentVal, 0);
                // let listPriceAvg = listPriceSum > 0 ? listPriceSum / listPricesValues.length : "";
                let ModelistPrice = mode(listPricesValues);
                // Calculate the average of price offers by customers
                const priceOffersValues = Object.entries(obj)
                    .filter(([key, value]) => !["id", "Version", "List Price", "Average"].includes(key) && value > 0)
                    .map(([key, val]) => Number(val));
                let priceOffersSum = priceOffersValues.reduce((acc, currentVal) => acc + currentVal, 0);
                let priceOffersAvg = priceOffersSum > 0 ? (kpi?.index === 24 ? Number(priceOffersSum / priceOffersValues.length).toFixed(2) : Math.round(Number(priceOffersSum / priceOffersValues.length))) : "";
                obj = {
                    ...obj,
                    "List Price": Number(ModelistPrice) > 0 ? Number(ModelistPrice) : null,
                    Average: priceOffersAvg,
                };
                postData.push(obj);
            }
            preData && postData.length > 0 && setData(postData);
        }
    }, [result]);

    useEffect(() => {
        if (data.length > 0) {
            const ws = utils.json_to_sheet(data);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, fileName);
            onDownloadCallback();
        }
    }, [data]);

    return (
        <Modal open={data?.length <= 0} footer={null} width={800}>
            <div className="flex flex-col items-center justify-center gap-2 h-64">
                <div className="text-lg font-semibold text-gray-500">Generating your speadsheet</div>
                <span className="font-medium text-gray-500">Please, wait a moment</span>
                <div>
                    <LoadingComponent />
                </div>
            </div>
        </Modal>
    );
}
