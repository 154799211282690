const COUNTRY_CODES = {
    BE: "Belgium",
    ES: "Spain",
    DE: "Germany",
    FR: "France",
    FF: "France",
    IT: "Italy",
    NL: "Netherlands",
    UK: "United Kingdom",
    GB: "United Kingdom",
};

function Customer(custId, custLabel, ctryId, hexColor) {
    this.custId = custId;
    this.custLabel = custLabel;
    this.ctryId = ctryId;
    this.hexColor = hexColor;
    this.countryName = countryName;
    return this;
}

function countryName() {
    return COUNTRY_CODES[this.ctryId];
}

export { Customer, COUNTRY_CODES };
