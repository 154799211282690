import React from "react";
import { LoadingComponent, ErrorComponent } from "@gooddata/sdk-ui";
import { useAppContext } from "../../contexts/AppContext";

import HighchartsComponent, { Highcharts } from "../HighchartsComponent";

export default function VolumeCompetitiveness({ data, error, status, filters, benchmark, ...props }) {
    const id = "competitiveness-volume-chart";
    const { getParamsByCustLabel } = useAppContext();

    return (
        <>
            {status === "loading" && <LoadingComponent />}
            {status === "error" && <ErrorComponent message="There was an error getting your execution" description={JSON.stringify(error, null, 2)} />}

            {status === "success" && (
                <HighchartsComponent
                    widgetProps={{
                        id,
                        filters,
                        section: "competitiveness",
                        benchmark,
                    }}
                    options={{
                        chart: {
                            type: "column",
                            height: props.height || 500,
                        },
                        title: {
                            text: "",
                            style: {
                                display: "none",
                            },
                        },
                        subtitle: {
                            text: "",
                            style: {
                                display: "none",
                            },
                        },
                        xAxis: {
                            categories: data.map((m) => m["custLabel"]),
                            title: {
                                text: "",
                            },
                            labels: {
                                useHTML: true,
                                formatter: ({ value }) => {
                                    return `<div style="width:70px; height:70 ;"><img src="/images/logos/Logo_${getParamsByCustLabel(value)?.custId}.png" width="70" height="70" /></div>`;
                                },
                            },
                        },
                        yAxis: {
                            // min: 0,
                            title: {
                                text: "",
                            },
                            labels: {
                                formatter: function () {
                                    return this.value;
                                },
                            },
                        },
                        plotOptions: {
                            column: {
                                pointPadding: 0.2,
                                borderWidth: 0,
                            },
                            series: {
                                dataLabels: {
                                    enabled: true,
                                    formatter: function () {
                                        return Highcharts.numberFormat(this.y, 0);
                                    },
                                },
                            },
                        },
                        series: [
                            {
                                name: "Vehicles Volume",
                                data: data.map((m) => ({ y: m["count"], color: getParamsByCustLabel(m["custLabel"])?.hexColor })),
                                showInLegend: false,
                            },
                        ],
                    }}
                    {...props}
                />
            )}
        </>
    );
}
