import { Combobox, Transition } from "@headlessui/react";
import React, { Fragment, useContext, useState } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { ArrowDownIcon } from "../Icons/Arrows";
import MeasuresContext, { Deposit } from "../../contexts/Measures";

const deposit = Object.keys(Deposit).sort((a, b) => (a !== "Total Payable by month" ? a.localeCompare(b) : b.localeCompare(a)));

const SelectAdjDeposit = ({ title = "" }) => {
    const { selectedAdjDeposit, setSelectedAdjDeposit } = useContext(MeasuresContext);
    const [query, setQuery] = useState("");
    const handleChange = (value) => {
        setSelectedAdjDeposit(value);
    };

    const filtredDeposit =
        query === ""
            ? deposit
            : deposit.filter((depositvalue) => {
                  return depositvalue.toLowerCase().includes(query.toLowerCase());
              });
    return (
        <Combobox value={selectedAdjDeposit} onChange={handleChange}>
            <div className="relative mt-1">
                <Combobox.Input
                    className="w-[160px] px-2.5 py-1 bg-steel border border-indigo-500 text-white rounded font-normal shadow-button"
                    displayValue={(selectedDeposit) => (selectedDeposit === "Total Payable by month" ? "Target Deposit" : selectedDeposit)}
                    onChange={(event) => setQuery(event.target.value)}
                />
                <Combobox.Button className="absolute inset-y-0 right-0  flex items-center rounded-r-md px-2 focus:outline-none">
                    <ArrowDownIcon fill={"#3a0ca3"} width={20} height={20} />
                </Combobox.Button>
                <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0" afterLeave={() => setQuery("")}>
                    <Combobox.Options className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" onSelect={handleChange}>
                        <Combobox.Option disabled className="px-2 font-semibold">
                            {title}
                        </Combobox.Option>
                        {filtredDeposit.length === 0 && query !== "" ? (
                            <div className="relative cursor-default select-none px-4 py-2 text-gray-700">Nothing found.</div>
                        ) : (
                            filtredDeposit.map((depositvalue) => (
                                <Combobox.Option key={depositvalue} value={depositvalue} as={Fragment} className={({ active }) => (active ? "bg-backgroundColor" : "") + " cursor-default py-2 px-3"}>
                                    {({ active, selected }) => {
                                        return (
                                            <div className="flex justify-between">
                                                <span>{depositvalue}</span>
                                                {selected && (
                                                    <div>
                                                        <CheckIcon className="h-5 w-5" color="#490fcc" />
                                                    </div>
                                                )}
                                            </div>
                                        );
                                    }}
                                </Combobox.Option>
                            ))
                        )}
                    </Combobox.Options>
                </Transition>
            </div>
        </Combobox>
    );
};

export default SelectAdjDeposit;
