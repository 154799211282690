import React, { useContext, useState, useEffect } from "react";
import { Switch } from "antd";
import UserContext from "../../contexts/User";

const GeneralSettings = ({ initialValues, onChange = (key, val) => {} }) => {
    const { user } = useContext(UserContext);
    const [mutliSelectionFl, setMutliSelectionFl] = useState(user?.enable_multiselection);
    useEffect(() => {
        // Initialisation
        if (isNaN(mutliSelectionFl) && user?.enable_multiselection !== mutliSelectionFl) setMutliSelectionFl(user.enable_multiselection);
    }, [user, mutliSelectionFl]);

    return (
        <section className="space-y-4">
            <div>
                <h3 className="text-xl font-semibold text-indigo-700 my-0">Car selector configuration</h3>
                <span className="text-sm text-gray-500 opacity-80 font-medium">The multi-selection feature allows to select more than one vehicles in the Car Selector. If more than one car is selected, the graphs are different</span>
            </div>
            <div className="w-max flex gap-2 items-center">
                <Switch
                    size="small"
                    style={{
                        backgroundColor: initialValues.enable_multiselection ? "#3a0ca3" : "#EEF1FF",
                    }}
                    onChange={(checked) => {
                        onChange("enable_multiselection", checked);
                    }}
                    checked={initialValues.enable_multiselection}
                />
                <label className="text-base" htmlFor={"key"}>
                    Enable <span className="italic">Multi-selection</span>
                </label>
            </div>
        </section>
    );
};

// const UPDATE_USER_DETAIL = gql`
//     mutation updateUser($catalogByVersion: Boolean) {
//         update_re_rentaleye_users(where: {}, _set: { catalogue_by_version: $catalogByVersion }) {
//             returning {
//                 catalogue_by_version
//             }
//         }
//     }
// `;

// const catalogOptions = [
//     {
//         id: "version",
//         label: "Version",
//         value: true,
//     },
//     {
//         id: "model",
//         label: "Model/Energy",
//         value: false,
//     },
// ];

export { GeneralSettings };
