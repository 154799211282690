// @ts-ignore
import React, { useCallback, useContext, useMemo } from "react";
import { AttributeFilterButton, useAttributeFilterContext } from "@gooddata/sdk-ui-filters";
import noop from "lodash/noop";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../md/full";
import ButtonFilter from "./Filters/ButtonFilter";
import MixButtonFilter from "./Filters/MixButtonFilter";

const defaultCustomProps = {
    onMyCallback: noop,
};

const CustomPropsContext = React.createContext(defaultCustomProps);

const CustomPropsProvider = (props) => {
    const { children, ...customProps } = props;

    return <CustomPropsContext.Provider value={customProps}>{children}</CustomPropsContext.Provider>;
};

const useCustomPropsContext = () => useContext(CustomPropsContext);

const CustomDropdownActions = (props) => {
    const { onApplyButtonClick } = props;
    // passing custom props to AttributeFilter components is recommended via Context
    const { onMyCallback } = useCustomPropsContext();
    return (
        <div
            style={{
                display: "flex",
                padding: 10,
                margin: 0,
                justifyContent: "right",
            }}
        >
            <button className="gd-button-action gd-button-small" style={{ marginRight: 5 }} onClick={onMyCallback}>
                Clear
            </button>

            <button className="gd-button-action gd-button-small" onClick={onApplyButtonClick}>
                Apply
            </button>
        </div>
    );
};

const CustomDropdownButton = (props) => {
    const { title, selectedItemsCount, isLoading, onClick } = props;

    // accessing internal data and callbacks of attribute filter component
    // this hook is in @alpha stage interface and behavior could be changed in future
    const { /*totalElementsCount,*/ isCommittedSelectionInverted } = useAttributeFilterContext();

    const selectedLabel = selectedItemsCount === 0 ? (isCommittedSelectionInverted ? "ALL" : "NONE") : `(${selectedItemsCount})`;

    const buttonTitle = isLoading ? "Loading ..." : title;

    return <>{title !== "Version Mix" ? <ButtonFilter onClick={onClick} isActive={selectedLabel !== "NONE" && !isLoading} title={buttonTitle} /> : <MixButtonFilter onClick={onClick} isActive={selectedLabel !== "NONE" && !isLoading} title={buttonTitle} />}</>;
};

export default function AttributeFilterWithCustomizations({ slicesBy, filter, title, currentFilter, updateFilter, filterName }) {
    const { result } = useExecutionDataView(
        {
            execution: { seriesBy: [Md.ListPrice.Avg, Md.Ps.Avg, Md.Co2malus.Avg, Md.Kw.Avg], slicesBy, filters: currentFilter },
        },
        [currentFilter],
    );

    const staticElements = useMemo(
        () =>
            result
                ?.meta()
                .allHeaders()[0][0]
                // @ts-ignore
                .map((m) => ({ name: m?.attributeHeaderItem?.name, title: m?.attributeHeaderItem?.name, uri: m?.attributeHeaderItem?.uri })) ?? [],

        [result],
    );

    const onApply = useCallback(
        (filter, _isInverted) => {
            updateFilter(filterName, filter);
        },
        [filterName, updateFilter],
    );

    const myCustomCallback = useCallback(() => {
        updateFilter(filterName, newPositiveAttributeFilter(slicesBy[0], []));
    }, [filterName, slicesBy, updateFilter]);

    return (
        <>
            <CustomPropsProvider onMyCallback={myCustomCallback}>
                <AttributeFilterButton title={title} filter={filter} onApply={onApply} DropdownButtonComponent={CustomDropdownButton} DropdownActionsComponent={CustomDropdownActions} staticElements={staticElements} />
            </CustomPropsProvider>
        </>
    );
}
