import { useQuery } from "@apollo/client";
import { Typography } from "antd";
import React from "react";
import { useContext } from "react";
import FiltersContext from "../../contexts/Filters";
import { ENABLED_SETTINGS, GET_KPI_ADJ_SETTINGS } from "../../queries/Users";
import TableComponent from "../TableComponent";

const PAItable = ({ ...props }) => {
    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };
    const { filterCountryId, filterChannel1 } = useContext(FiltersContext);
    const { data: kpiSettingsResults } = useQuery(GET_KPI_ADJ_SETTINGS, {
        variables: {
            ctryid: filterCountryId.positiveAttributeFilter.in["values"][0],
            channel1: filterChannel1.positiveAttributeFilter.in["values"][0],
        },
    });
    const Enabled = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid: filterCountryId.positiveAttributeFilter.in["values"][0], channel1: filterChannel1.positiveAttributeFilter.in["values"][0] },
    }).data?.re_rentaleye_re_services_settings[0];
    const kpiSettings = kpiSettingsResults?.re_rentaleye_re_params_user_prefs.map((kpi) => {
        return {
            [settingsAliases.flag_ser]: Enabled?.flag_ser ? (kpi.flag_ser ? "Y" : "N") : "",
            [settingsAliases.flag_bre]: Enabled?.flag_bre ? (kpi.flag_bre ? "Y" : "N") : "",
            [settingsAliases.flag_ins]: Enabled?.flag_ins ? (kpi.flag_ins ? "Y" : "N") : "",
            [settingsAliases.flag_rep]: Enabled?.flag_rep ? (kpi.flag_rep ? "Y" : "N") : "",
            [settingsAliases.flag_fin]: Enabled?.flag_fin ? (kpi.flag_fin ? "Y" : "N") : "",
            [settingsAliases.flag_co2]: Enabled?.flag_co2 ? (kpi.flag_co2 ? "Y" : "N") : "",
            [settingsAliases.flag_tyr]: Enabled?.flag_tyr ? (kpi.flag_tyr ? "Y" : "N") : "",
            [settingsAliases.flag_win]: Enabled?.flag_win ? (kpi.flag_win ? "Y" : "N") : "",
            [settingsAliases.flag_evb]: Enabled?.flag_evb ? (kpi.flag_evb ? "Y" : "N") : "",
        };
    });
    const columns =
        Enabled &&
        Object.keys(Enabled)
            .filter((key) => Enabled[key] === 1)
            .map((key) => {
                return {
                    title: settingsAliases[key],
                    dataIndex: settingsAliases[key],
                    key: settingsAliases[key],
                    render: (text) => (
                        <Typography.Text style={{ fontSize: 12 }} strong>
                            {text}
                        </Typography.Text>
                    ),
                    align: "center",
                    width: 100,
                };
            });
    columns?.length > 0 &&
        columns.unshift({
            title: null,
            dataIndex: null,
            key: null,
            align: "center",
            width: 100,
            render: () => (
                <Typography.Text style={{ fontSize: 12 }} strong>
                    {""}
                </Typography.Text>
            ),
        });
    return (
        <TableComponent
            bordered
            className="border border-gray-200"
            dataSource={columns?.length > 0 ? kpiSettings : null}
            columns={columns}
            pagination={false}
            scroll={{ x: 400 }}
            widgetProps={{
                id: "monthly-rate-adjusted",
                filters: [filterChannel1, filterCountryId],
                section: "datacoverage",
            }}
            {...props}
        />
    );
    // <Table bordered className="border border-gray-200" dataSource={columns?.length > 0 ? kpiSettings : null} columns={columns} pagination={false} scroll={{ x: 400 }}></Table>;
};

export default PAItable;
