import React from "react";

import LogoutForm from "../components/Auth/LogoutForm";
import { useAuth } from "../contexts/Auth";

const Logout = () => {
    const { logout } = useAuth();

    return <LogoutForm logout={logout} />;
};

export default Logout;
