import React from "react";
import { withRouter } from "react-router-dom";
import { Modal } from "antd";
import { useBasketContext } from "../../contexts/BasketProvider";
import { CreateBasketForm } from "./CreateBasketForm";

function Basket({ vehicles }) {
    const { isBasketModalOpened, setBasketModal, basket, vehicles: basketVehicles } = useBasketContext();

    return (
        <Modal
            open={isBasketModalOpened}
            style={{
                float: "right",
                top: "70px",
                right: "50px",
            }}
            width={700}
            onCancel={() => {
                setBasketModal(false);
            }}
            footer={null}
        >
            {basket && (
                <div className="flex flex-row items-center">
                    <span className="text-steel text-xl font-semibold">
                        Loading <span className="font-bold">"{basket.name}"</span>
                    </span>
                </div>
            )}
            {basket && <div className="mt-1.5 border-b border-indigo-500 border-[1px]" />}
            {vehicles.length > 0 && <CreateBasketForm initialValues={{ vehicles: basketVehicles }} />}
        </Modal>
    );
}

export default withRouter(Basket);
