import React from "react";
import { BarChart } from "../Visualization/BarChart";
import { useAppContext } from "../../contexts/AppContext";

function BasketBarChart({ custIndex, title, filters, kpiSettings, subtitle = undefined, reference = {}, chartProps = {}, data = [], displayMode, ...props }) {
    const { getColorByCustId, getCustlabelByCustId } = useAppContext();
    const barsColor = getColorByCustId(custIndex);
    return (
        data.length > 0 && (
            <div key={custIndex} className="border-2 border-gray-300 rounded">
                <BarChart
                    metadata={{
                        custIndex,
                        displayMode,
                        flag_services: kpiSettings,
                    }}
                    section={"basketbar"}
                    filters={filters}
                    id={"basket-bar-chart"}
                    title={title}
                    subtitle={subtitle}
                    barsColor={barsColor}
                    categories={data?.map((dp) => getCustlabelByCustId(dp.title))}
                    series={
                        data.length > 0
                            ? [
                                  {
                                      name: custIndex,
                                      data: data?.map((dp) => {
                                          if (dp.title === reference?.versionStd) {
                                              return Object.assign({
                                                  y: dp.dataPoint,
                                                  color: "#B2B2B2",
                                              });
                                          } else if (dp.title.length === 3) {
                                              return Object.assign({
                                                  y: dp.dataPoint,
                                                  color: getColorByCustId(dp.title),
                                              });
                                          } else {
                                              return Object.assign({
                                                  y: dp.dataPoint,
                                                  color: barsColor,
                                              });
                                          }
                                      }),
                                  },
                              ]
                            : []
                    }
                    referenceLine={{
                        value: reference?.value,
                    }}
                    {...chartProps}
                    {...props}
                />
            </div>
        )
    );
}
export { BasketBarChart };
