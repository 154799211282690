// @ts-nocheck
import React, { useState, useEffect } from "react";
import { Modal, Select, Button, Table, Typography } from "antd";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../../md/full";
import { mode, weigthedAverage } from "../../../utils/calculationUtils";

const mapResultToVersions = (slices, kpi) => {
    return slices.map((slice) => {
        const dataPoints = slice.dataPoints();
        return {
            Version: slice.sliceTitles()[0],
            CustId: slice.sliceTitles()[1],
            "List Price": Math.round(Number(slice.dataPoints()[1].rawValue)) > 0 ? Math.round(Number(slice.dataPoints()[1].rawValue)) : null,
            Model: slice.sliceTitles()[2],
            Value: dataPoints[kpi?.index]?.rawValue !== null ? (kpi?.index === 24 ? Math.round(10000 * Number(dataPoints[kpi?.index]?.rawValue)) / 100 : Math.round(Number(dataPoints[kpi?.index]?.rawValue))) : null,
            versionCount: Number(dataPoints[30].rawValue),
            Segment: slice.sliceTitles()[3],
            Style: slice.sliceTitles()[4],
            Gear: slice.sliceTitles()[5],
            Energy: slice.sliceTitles()[6],
            Make: slice.sliceTitles()[7],
            Doors: slice.dataPoints()[32].rawValue,
            Kw: slice.dataPoints()[29].rawValue,
            Power: slice.dataPoints()[22].rawValue,
            CO2: slice.dataPoints()[31].rawValue,
            "Adj. Deposit": slice.dataPoints()[2].rawValue,
        };
    });
};

const MapToVersionSelection = (data, kpi) => {
    if (data.length > 0) {
        // @ts-ignore
        const custIdKeys = [...new Set(data.map(({ CustId }) => CustId))];
        let vehicleOffersObj = data
            // .filter((item) => (activePlayers.length > 0 ? activePlayers.includes(item.CustId) : true))
            .reduce((acc, nextItem) => {
                const vehicleInfo = Object.entries(nextItem)
                    .filter(([key, val]) => !["CustId", "Value", "List Price"].includes(key))
                    .reduce((acc, [key, value]) => Object.assign({ [key]: value, ...acc }), {});
                if (nextItem.Version in acc && nextItem.CustId in acc[nextItem.Version]) {
                    acc[nextItem.Version][nextItem.CustId].values.push(nextItem.Value);
                    acc[nextItem.Version][nextItem.CustId].versionCount.push(nextItem.versionCount);
                } else {
                    acc[nextItem.Version] = {
                        [nextItem.CustId]: {
                            values: [nextItem.Value],
                            versionCount: [nextItem.versionCount],
                        },
                        ...vehicleInfo,
                        ...acc[nextItem.Version],
                    };
                }
                acc[nextItem.Version] = {
                    ...acc[nextItem.Version],
                    listPrices: "listPrices" in acc[nextItem.Version] ? [...acc[nextItem.Version]["listPrices"], nextItem["List Price"]] : [nextItem["List Price"]],
                };
                return acc;
            }, {});
        // Calculate the weighted average
        Object.keys(vehicleOffersObj).forEach((key) => {
            custIdKeys.forEach((custId) => {
                vehicleOffersObj[key][custId] = custId in vehicleOffersObj[key] ? kpi?.roundValue(weigthedAverage(vehicleOffersObj[key][custId]?.values, vehicleOffersObj[key][custId]?.versionCount)) : null;
            });
        });
        const dataSource = Object.values(vehicleOffersObj).map((item) => {
            const custValues = Object.keys(item)
                .filter((key) => custIdKeys.includes(key) && item[key] > 0)
                .map((key) => item[key]);
            let rentalSum = custValues.reduce((acc, currentVal) => acc + currentVal, 0);
            let Average = rentalSum > 0 ? kpi?.roundValue(rentalSum / custValues.length) : null;
            let ModListPrice = item.listPrices?.length > 0 ? Math.round(Number(mode(item.listPrices))) || null : null;

            return { Version: item.Version, "List Price": ModListPrice, Average, ...item };
        });
        return dataSource;
    }
};

const BasketVersionMatch = ({ Vehicle, onCancel, seriesBy, slicesBy, kpi, currentFilter, filters, onAdd = (values) => {}, onReplace = (values) => {} }) => {
    const [currentStep, setCurrentStep] = useState(1);
    const [filteredVersions, setFilteredVersions] = useState([]);
    const [matchVersions, setMatchVersions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selection, setSelection] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const [selectedVehicle, setSelectedVehicle] = useState();
    const TotalSteps = 4;

    // fetch vehicle data

    const {
        status: statusVehicle,
        result: resultVehicle,
        error: errorVehicle,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters: [...filters, newPositiveAttributeFilter(Md.VersionStd, [Vehicle])],
        },
    });
    useEffect(() => {
        if (statusVehicle === "success") {
            const slices = resultVehicle.data().slices().toArray();
            if (slices.length !== 0) {
                setSelectedVehicle(mapResultToVersions(slices, kpi)[0]);
                setCurrentStep(2);
            }
        }
    }, [statusVehicle, resultVehicle, errorVehicle, kpi]);

    // First query
    const {
        status: statusFirstQuery,
        result: resultFirstQuery,
        error: errorFirstQuery,
    } = useExecutionDataView(
        {
            execution: {
                seriesBy: seriesBy,
                slicesBy: slicesBy,
                filters: [...currentFilter, newPositiveAttributeFilter(Md.VehSegment, [selectedVehicle?.Segment]), newPositiveAttributeFilter(Md.BodyStyle, [selectedVehicle?.Style]), newPositiveAttributeFilter(Md.CorrMake, [selectedVehicle?.Make])],
            },
        },
        [selectedVehicle],
    );

    useEffect(() => {
        if (statusFirstQuery === "success" && currentStep === 2) {
            const slices = resultFirstQuery.data().slices().toArray();
            if (slices.length !== 0) {
                setFilteredVersions(mapResultToVersions(slices, kpi));
                setCurrentStep(4); // Go to final step
            } else {
                setCurrentStep(3); // Go to model selection
            }
        }
    }, [statusFirstQuery, resultFirstQuery, errorFirstQuery]);

    // fetch models based on selected brands
    const {
        status: statusModels,
        result: resultModels,
        error: errorModels,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CorrModel],
            filters: [...filters, newPositiveAttributeFilter(Md.CorrMake, [selectedVehicle?.Make])],
        },
    });

    useEffect(() => {
        if (statusModels === "success" && currentStep === 3) {
            setModels(
                resultModels
                    .data()
                    .slices()
                    .toArray()
                    .map((item) => item.sliceTitles()[0]),
            );
        }
    }, [statusModels, resultModels, errorModels, currentStep]);

    // Final query
    const {
        status: statusFinalQuery,
        result: resultFinalQuery,
        error: errorFinalQuery,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters: [...currentFilter, newPositiveAttributeFilter(Md.CorrModel, selectedModel)],
        },
    });

    useEffect(() => {
        if (statusFinalQuery === "success" && currentStep === 4 && selectedModel.length > 0) {
            const slices = resultFinalQuery.data().slices().toArray();
            if (slices.length !== 0) {
                setFilteredVersions(mapResultToVersions(slices, kpi));
            }
        }
    }, [statusFinalQuery, resultFinalQuery, errorFinalQuery, selectedModel]);

    useEffect(() => {
        let resultedata = [];
        if (filteredVersions.length !== 0 && currentStep === TotalSteps) {
            // Group by model, body, gear, and fuel
            const groupedVersions = filteredVersions.reduce((acc, curr) => {
                const key = `${curr.Model}-${curr.BodyStyle}-${curr.Gear}-${curr.Fuel}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr);
                return acc;
            }, {});

            // Calculate average list price for each group
            const avgListPrices = Object.keys(groupedVersions).reduce((acc, key) => {
                const group = groupedVersions[key];
                const totalListPrice = group.reduce((sum, version) => sum + (version["List Price"] || 0), 0);
                acc[key] = totalListPrice / group.length;
                return acc;
            }, {});

            // Filter versions based on power
            const filteredByPower = filteredVersions.filter((version) => {
                const power = Number(version.Power);
                const selectedPower = Number(selectedVehicle?.Power);
                const powerThreshold = selectedPower * 0.3;
                return power >= selectedPower - powerThreshold && power <= selectedPower + powerThreshold;
            });

            if (filteredByPower.length > 0) {
                // If versions match the power criteria, use these
                // setMatchVersions(MapToVersionSelection(filteredByPower, kpi));
                resultedata = MapToVersionSelection(filteredByPower, kpi);
            } else {
                // If no versions match the power criteria, filter by range index
                const filteredByRangeIndex = filteredVersions.filter((version) => {
                    const groupKey = `${version.Model}-${version.BodyStyle}-${version.Gear}-${version.Fuel}`;
                    const avgListPrice = avgListPrices[groupKey];
                    const rangeIndex = (version["List Price"] || 0) / avgListPrice;
                    const selectedRangeIndex = (selectedVehicle?.["List Price"] || 0) / avgListPrice;
                    const rangeIndexThreshold = 0.025; // 2.5%
                    return Math.abs(rangeIndex - selectedRangeIndex) <= rangeIndexThreshold;
                });

                if (filteredByRangeIndex.length > 0) {
                    // If versions match the range index criteria, use these
                    // setMatchVersions(MapToVersionSelection(filteredByRangeIndex, kpi));
                    resultedata = MapToVersionSelection(filteredByRangeIndex, kpi);
                } else {
                    // If no versions match the range index criteria, use all filtered versions
                    // setMatchVersions(MapToVersionSelection(filteredVersions, kpi));
                    resultedata = MapToVersionSelection(filteredVersions, kpi);
                }
            }
        }
        if (resultedata.length > 0) {
            // filter by gear,doors,energy
            const firstFilter = resultedata.filter((version) => {
                return version.Gear === selectedVehicle?.Gear && version.Doors === selectedVehicle?.Doors && version.Energy === selectedVehicle?.Energy;
            });
            if (firstFilter.length > 0) {
                setMatchVersions(firstFilter);
            } else {
                // if if gear = MANUAL then AUTO and vice versa, if energy = PETROL or DIESEL then HYBRID and vice versa, if energy = ELECTRIC then HYBRID, if energy = PHEV then ELECTRIC
                setMatchVersions(
                    resultedata.filter((version) => {
                        return (
                            (selectedVehicle?.Gear === "MANUAL" && version.Gear === "AUTO") ||
                            (selectedVehicle?.Gear === "AUTO" && version.Gear === "MANUAL") ||
                            (["PETROL", "DIESEL"].includes(selectedVehicle?.Energy) && version.Energy === "HYBRID") ||
                            (["HYBRID"].includes(selectedVehicle?.Energy) && ["PETROL", "DIESEL"].includes(version.Energy)) ||
                            (["ELECTRIC"].includes(selectedVehicle?.Energy) && ["HYBRID"].includes(version.Energy)) ||
                            (["PHEV"].includes(selectedVehicle?.Energy) && ["ELECTRIC"].includes(version.Energy))
                        );
                    }),
                );
            }
        }
    }, [filteredVersions, selectedVehicle]);

    useEffect(() => {
        if (matchVersions.length > 0) {
            setColumns(
                Object.keys(matchVersions[0])
                    .filter((m) => ["Version", "List Price", "Average", "Model", "Segment", "Style", "Gear", "Energy", "Doors", "Kw", "Power", "CO2", "Adj. Deposit"].includes(m))
                    .map((m) => {
                        const custIDs = matchVersions.map((item) => item.CustId);
                        return {
                            title: () => <div>{["Version", "List Price", "Average"].includes(m) ? <span>{m}</span> : <span className="flex-1 self-center">{m}</span>}</div>,
                            fixed: ["Version"].includes(m) ? "left" : null,
                            align: m !== "Version" && "center",
                            dataIndex: m,
                            key: m,
                            width: m === "Version" ? 350 : !custIDs.includes(m) ? (m.length > 8 ? m.length * 12 : 100) : 100,
                            render: (cell, record) => {
                                return <Typography.Text>{cell}</Typography.Text>;
                            },
                            filters: !custIDs.includes(m)
                                ? null
                                : [
                                      {
                                          text: "Show values only",
                                          value: null,
                                      },
                                  ],

                            onFilter: (value, record) => (["Version", "List Price", "Average"].includes(m) ? value : record[m] !== value),
                        };
                    }),
            );
        }
    }, [matchVersions]);

    const handleModelSelection = (value) => {
        setSelectedModel(value);
    };

    const nextStep = () => {
        if (currentStep === 3) {
            setCurrentStep(4);
        }
    };
    const resetState = () => {
        setSelectedVehicle(undefined);
        setColumns([]);
        setCurrentStep(1);
        setFilteredVersions([]);
        setMatchVersions([]);
        setSelection([]);
        setModels([]);
        setSelectedModel([]);
    };

    const handleCancel = () => {
        resetState();
        onCancel();
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Initializing ...</span>
                        <LoadingComponent />
                    </div>
                );
            case 2:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Searching for exact matches ...</span>
                        <LoadingComponent />
                    </div>
                );
            case 3:
                return (
                    <div>
                        <h2>Version Match Not Found</h2>
                        <p className="text-secondary-300">Please select a set of models to find a match:</p>
                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Select model" onChange={handleModelSelection}>
                            {models.map((model) => (
                                <Select.Option key={model} value={model}>
                                    {model}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <p className="text-indigo-700 text-base font-medium">Selected Vehicle : {selectedVehicle?.Version}</p>
                        <h2>List of Versions that Match</h2>
                        <Table
                            dataSource={matchVersions}
                            columns={columns}
                            scroll={{ x: 800, y: 300 }}
                            rowKey={(record) => record.Version}
                            pagination={false}
                            rowSelection={{
                                type: "radio",
                                onSelect: (record, selected, selectedRows, nativeEvent) => {
                                    setSelection([...selectedRows.filter((row) => row?.Version)]);
                                },
                                selectedRowKeys: selection.map((item) => item.Version),
                            }}
                            loading={matchVersions.length === 0}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            title="Version Match"
            width={1200}
            open={true}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="next"
                    type="primary"
                    onClick={
                        currentStep === 4
                            ? () => {
                                  onAdd(selection[0].Version);
                                  handleCancel();
                              }
                            : nextStep
                    }
                    disabled={(currentStep === 4 && selection.length === 0) || (currentStep === 3 && selectedModel.length === 0) || currentStep === 1 || currentStep === 2}
                >
                    {currentStep === 4 ? "Add To Basket" : "Next"}
                </Button>,
                currentStep === 4 ? (
                    <Button
                        key="replace"
                        type="primary"
                        onClick={() => {
                            onReplace(selection[0].Version);
                            handleCancel();
                        }}
                        disabled={selection.length === 0}
                    >
                        Replace
                    </Button>
                ) : null,
            ]}
        >
            {renderStep()}
        </Modal>
    );
};

export default BasketVersionMatch;
