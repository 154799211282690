import React, { useEffect, useState, useContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { Switch } from "antd";
import FiltersContext from "../../contexts/Filters";

const ENABLED_SETTINGS = gql`
    query ($ctryid: String, $channel1: String) {
        re_rentaleye_re_services_settings(where: { ctryid: { _eq: $ctryid }, channel: { _eq: $channel1 }, category: { _eq: "Settings" } }) {
            flag_ser
            flag_bre
            flag_ins
            flag_rep
            flag_fin
            flag_co2
            flag_tyr
            flag_win
            flag_evb
        }
    }
`;

const settingsAliases = {
    flag_ser: "Maintenance",
    flag_bre: "Breakdown",
    flag_ins: "Insurance",
    flag_rep: "Replacement",
    flag_fin: "Financial loss",
    flag_co2: "CO2 Malus",
    flag_tyr: "Tyres",
    flag_win: "Winter Tyres",
    flag_evb: "EV Bonus",
};

function RentalSettingsForm({ kpiSettings, onChange = (key, val) => {} }) {
    const [settings, setSettings] = useState({});
    const { getFirstFilterValue } = useContext(FiltersContext);
    const ctryid = getFirstFilterValue("filterCountryId");
    const channel1 = getFirstFilterValue("filterChannel1");
    // Query to get active flags given a country/channel1
    const { data: enabledServices } = useQuery(ENABLED_SETTINGS, {
        variables: { ctryid, channel1 },
    });

    useEffect(() => {
        // Initialize kpi settings
        const activeFlags = enabledServices?.re_rentaleye_re_services_settings[0];
        if (activeFlags && Object.keys(kpiSettings).length > 0) {
            const userFlags = Object.entries(kpiSettings)
                .filter(([key, value]) => key in activeFlags && activeFlags[key] === 1)
                .reduce(
                    (acc, [key, value]) =>
                        Object.assign({
                            ...acc,
                            [key]: value,
                        }),
                    {},
                );
            setSettings({ ...userFlags });
        }
    }, [kpiSettings, enabledServices]);

    return (
        <section>
            <form className="space-y-4">
                <div className="flex flex-col">
                    <h3 className="text-xl font-semibold text-indigo-700 my-0">Rental Price Adjustments</h3>
                    <span className="text-sm text-gray-500 opacity-80 font-medium">Flag services used to calculate the adjusted rental measure</span>
                </div>
                <div className="w-2/3 grid grid-cols-3 gap-2">
                    {Object.entries(settings).map(([key, val]) => {
                        return (
                            key in settingsAliases && (
                                // Enabled &&
                                // Enabled[key] === 1 &&
                                <div key={key} className="w-max flex gap-2 items-center">
                                    <Switch
                                        size="small"
                                        style={{
                                            backgroundColor: val ? "#3a0ca3" : "#EEF1FF",
                                        }}
                                        onChange={(checked) => {
                                            onChange(key, checked ? 1 : 0);
                                        }}
                                        checked={settings[key] === 1}
                                    />
                                    <label className="text-base" htmlFor={key}>
                                        {settingsAliases[key]}
                                    </label>
                                </div>
                            )
                        );
                    })}
                </div>
            </form>
        </section>
    );
}

export { RentalSettingsForm };
