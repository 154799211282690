import React, { useEffect, useState } from "react";
import { useExecutionDataView } from "@gooddata/sdk-ui";
import benchmarkComp from "../../utils/benchmarkComp";
import { newArithmeticMeasure } from "@gooddata/sdk-model";
import * as Md from "../../md/full";
import { flagServiceMap } from "../../contexts/Measures";

const CompetitivenessWidgetWrapper = ({ execDefinition, componentChild, ...props }) => {
    const [data, setData] = useState([]);
    const [flat, setFlat] = useState([]);
    const benchmark = props.benchmark;
    const { seriesBy, slicesBy, filters } = execDefinition;
    const [newSeries, setNewSeries] = useState([...seriesBy]);
    const { result, status, error } = useExecutionDataView({
        execution: {
            seriesBy: [...newSeries],
            slicesBy,
            filters,
        },
    });
    const { flag_services } = props;

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        if (slices) {
            const flat = slices?.map((slice) => {
                return {
                    Custmixindex: slice.sliceTitles()[0],
                    custId: slice.sliceTitles()[1],
                    custLabel: slice.sliceTitles()[2],
                    adjRental: Number(slice.dataPoints()[23].rawValue),
                    count: Number(slice.dataPoints()[28].rawValue),
                };
            });

            setFlat(flat);
        }
    }, [result]);

    useEffect(() => {
        if (flat.length > 0) {
            const pivots = [...new Set(flat.map(({ custLabel }) => custLabel))].reduce((a, b) => ({ ...a, [b]: null }), {});

            const o1 = flat.reduce((a, b) => {
                a[b.Custmixindex] = a[b.Custmixindex] || null;
                a[b.Custmixindex] = a[b.Custmixindex] ? { Custmixindex: b.Custmixindex, ...pivots, ...a[b.Custmixindex], [b.custLabel]: b.adjRental } : { ...pivots, [b.custLabel]: b.adjRental };
                return a;
            }, {});

            const o2 = flat.reduce((a, b) => {
                a[b.Custmixindex] = a[b.Custmixindex] || null;
                a[b.Custmixindex] = a[b.Custmixindex] ? { Custmixindex: b.Custmixindex, ...pivots, ...a[b.Custmixindex], [b.custLabel]: b.count } : { ...pivots, [b.custLabel]: b.count };
                return a;
            }, {});

            const values = benchmarkComp(Object.values(o1), Object.values(o2), "Custmixindex", benchmark, "adjRental");
            // Sort datafrom negative to positive
            const benchmarkData = values.filter((f) => f.count > 0).sort((a, b) => a.adjRental - b.adjRental);
            setData(benchmarkData);
        }
    }, [flat, benchmark]);

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    return React.createElement(componentChild, { data, error, status, kpiSettings: flag_services, ...props });
};

export default CompetitivenessWidgetWrapper;
