import React, { useState, Fragment, useEffect, useMemo } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import { useExecutionDataView } from "@gooddata/sdk-ui";

import * as Md from "../md/full";
import { ArrowDownIcon } from "./Icons/Arrows";

export default function ComboBoxFilterField({ value, filterAttribute, mdAttribute, title, filterID, currentFilter, onUpdateFilter }) {
    // const { updateFilter } = useContext(FiltersContext);
    const [item, setSelectedItem] = useState(filterAttribute);
    const [query, setQuery] = useState("");

    const { result } = useExecutionDataView(
        {
            execution: { seriesBy: [Md.ListPrice.Avg, Md.Ps.Avg, Md.Co2malus.Avg, Md.Kw.Avg], slicesBy: [mdAttribute], filters: currentFilter.filter((f) => f !== filterAttribute) },
        },
        [currentFilter],
    );

    const staticElements = useMemo(
        () =>
            result
                ?.meta()
                .allHeaders()[0][0]
                // @ts-ignore
                .map((m) => ({ name: m?.attributeHeaderItem?.name, title: m?.attributeHeaderItem?.name, uri: m?.attributeHeaderItem?.uri })) ?? [],

        [result],
    );

    // const { elements } = useAttributeFilterController({
    //     filter: item,
    // });

    // var filterElements = [];
    // var selectedElement = {};

    const selectedElement = staticElements.find((f) => f.title === value) || {};

    // if (["filterMthCode", "filterChannel1", "filterDuration"].includes(filterID)) {
    //     filterElements = staticElements;
    //     selectedElement = elements.find((f) => f.title === value) || {};
    // } else {
    //     filterElements = elements?.filter((elem) => elem.title.includes(query.toUpperCase()));
    //     selectedElement = elements.find((f) => f.title === value) || {};
    // }

    useEffect(() => {
        if (item.positiveAttributeFilter?.in.values.length > 0) {
            onUpdateFilter(filterID, item);
        }
    }, [filterID, item, onUpdateFilter]);

    return (
        <Combobox
            value={selectedElement}
            onChange={(obj) => {
                setSelectedItem(newPositiveAttributeFilter(mdAttribute, [obj.title]));
            }}
        >
            <div className="relative mt-1">
                <Combobox.Input
                    onChange={(event) => {
                        setQuery(event.target.value);
                    }}
                    displayValue={(item) => item?.title}
                    className="w-[125px] px-2.5 py-1 bg-steel border border-indigo-500 text-white rounded font-normal shadow-button"
                />
                <Combobox.Button className="absolute inset-y-0 right-0  flex items-center rounded-r-md px-2 focus:outline-none">
                    <ArrowDownIcon fill={"#3a0ca3"} width={20} height={20} />
                </Combobox.Button>
                {staticElements.length > 0 && (
                    <Combobox.Options className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Combobox.Option disabled className="px-2 font-semibold">
                            {title}
                        </Combobox.Option>
                        {staticElements.map((elem, index) => (
                            <Combobox.Option key={index} value={elem} as={Fragment} className={({ active }) => (active ? "bg-backgroundColor" : "") + " cursor-default py-2 px-3"}>
                                {({ active, selected }) => {
                                    return (
                                        <div className="flex justify-between">
                                            <span className="whitespace-nowrap">{elem.title}</span>
                                            {selected && (
                                                <div>
                                                    <CheckIcon className="h-5 w-5" color="#490fcc" />
                                                </div>
                                            )}
                                        </div>
                                    );
                                }}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}
