import React, { useState, useEffect, useContext, createContext } from "react";
import { gql, useQuery } from "@apollo/client";
import { COUNTRY_CODES, Customer } from "../types/Customer";
import FiltersContext from "./Filters";

const GET_CUSTOMER_PARAMS = gql`
    {
        re_rentaleye_re_params_custid {
            ctryid
            cust_id
            cust_hex
            cust_label
        }
    }
`;

const initialValues = {
    customers: [],
    benchmarkReference: "",
    setBenchmarkReference: (newBenchmark) => {},
    getColorByCustId: null,
    getParamsByCustLabel: null,
    getCustlabelByCustId: null,
    getParamsByCustID: null,
    isSideBarCollapsed: true,
    setCollapseSidebar: (val) => {},
    getCountryByCode: (countryCode) => {},
};

const AppContext = createContext(initialValues);

const AppProvider = ({ children }) => {
    const { getFirstFilterValue } = useContext(FiltersContext);
    const { data } = useQuery(GET_CUSTOMER_PARAMS);
    const ctryid = getFirstFilterValue("filterCountryId");
    const [customers, setCustomers] = useState([]);
    const [activenews, setActivenews] = useState(true);
    // Benchmark for competitiveness
    const [benchmarkReference, setBenchmarkReference] = useState(undefined);
    // Show filters
    const [showMoreFilters, setShowMoreFilters] = useState(false);
    // Sidebar status
    const [isSideBarCollapsed, setCollapseSidebar] = useState(true);
    // filter animation
    // const [animateFilters, setAnimateFilters] = useState(true);
    useEffect(() => {
        if (data?.re_rentaleye_re_params_custid) {
            const customersData = data.re_rentaleye_re_params_custid.map((elem) => new Customer(elem.cust_id, elem.cust_label, elem.ctryid, elem.cust_hex));
            setCustomers(customersData);
        }
    }, [data]);

    // useEffect(() => {
    //     // Animate filters only once
    //     if (showMoreFilters === animateFilters) {
    //         setAnimateFilters(!animateFilters);
    //     }
    // }, [showMoreFilters]);

    function getColorByCustId(custID) {
        return customers.find((elem) => elem.custId === custID && elem.ctryId === ctryid)?.hexColor;
    }

    function getParamsByCustLabel(custLABEL) {
        return customers.find((elem) => elem.custLabel === custLABEL && elem.ctryId === ctryid);
    }

    const getParamsByCustID = (custID, ctryid) => {
        return customers.find((elem) => elem.custId === custID && elem.ctryId === ctryid);
    };

    function getCustlabelByCustId(custID) {
        let filteredCustomer = customers.find((elem) => elem.custId === custID && elem.ctryId === ctryid);
        return filteredCustomer ? filteredCustomer.custLabel : custID;
    }
    const getCountryByCode = (countryCode) => COUNTRY_CODES[countryCode];

    return (
        <AppContext.Provider
            value={{
                ...initialValues,
                customers: customers,
                activenews: activenews,
                showMoreFilters,
                benchmarkReference,
                isSideBarCollapsed,
                // animateFilters,
                // setAnimateFilters,
                getColorByCustId,
                getParamsByCustLabel,
                getCustlabelByCustId,
                getParamsByCustID,
                getCountryByCode,
                setActivenews,
                setShowMoreFilters,
                setBenchmarkReference,
                setCollapseSidebar,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

const useAppContext = () => useContext(AppContext);

export { AppProvider, useAppContext };
