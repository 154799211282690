import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "antd";
import { useNotificationContext } from "../../contexts/NotificationProvider";
import FiltersContext from "../../contexts/Filters";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../md/full";

const NotificationList = ({ isOpen, closeHandler = () => {}, onSelectItem = (obj) => {}, ...props }) => {
    const history = useHistory();
    const { updateFilter } = useContext(FiltersContext);
    const { notifications, deactivateNotification } = useNotificationContext();

    function onItemClick(obj, index) {
        return Promise.resolve(history.push(obj.route))
            .then((res) => {
                if (!obj.is_opened) {
                    deactivateNotification(obj);
                }
            })
            .finally(() => {
                closeHandler();
                if (obj?.metadata?.country) updateFilter("filterCountryId", newPositiveAttributeFilter(Md.Ctryid, [obj.metadata.country]));
                setTimeout(() => {
                    onSelectItem(obj.obj_id);
                }, 1000);
            });
    }

    return (
        <Modal
            open={isOpen}
            width={650}
            style={{
                float: "right",
                top: "70px",
                right: "30px",
            }}
            bodyStyle={{
                padding: "4px 0px",
            }}
            onCancel={closeHandler}
            footer={null}
        >
            <div>
                <h3 className="p-2">Latest notifications</h3>
                <ul className="max-h-96 divide-y divide-blue-100 overflow-y-auto">
                    {notifications.map((notification, index) => {
                        const notificationDate = new Date(notification.created_at).toLocaleDateString("default", {
                            day: "2-digit",
                            month: "short",
                            // year: 'numeric'
                        });
                        const notificationHourDate = new Date(notification.created_at).toLocaleTimeString("default", {
                            hour: "numeric",
                            minute: "numeric",
                        });
                        return (
                            <li
                                key={index}
                                onClick={() => onItemClick(notification, index)}
                                className={`cursor-pointer px-2 py-1.5 flex justify-between items-center hover:bg-backgroundColor 
                            ${notification.is_opened ? "" : "bg-backgroundColor"}`}
                            >
                                <div className="flex items-center space-x-2">
                                    <div className={`w-2 h-2 ${!notification.is_opened ? "rounded-full bg-indigo-500" : "rounded-full"} `}></div>
                                    <span className={`${!notification.is_opened ? "font-medium" : ""}`}>{notification.message}</span>
                                </div>
                                <div className="pr-1 flex flex-col">
                                    <span className="text-sm text-indigo-500 font-medium">{notificationDate}</span>
                                    <span className="text-xs text-gray-700">{notificationHourDate}</span>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </Modal>
    );
};

export default NotificationList;
