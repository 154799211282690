import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import React, { useContext, useEffect, useState } from "react";
import { newJsonPivot } from "../../utils/jsonPivot";
import * as Md from "../../md/full";
import MeasuresContext, { flagServiceMap } from "../../contexts/Measures";
import { newArithmeticMeasure } from "@gooddata/sdk-model";
import Servicesbanner from "../Layout/Servicesbanner";

const BasketBarWidget = ({ id, execDefinition, componentChild, ...props }) => {
    const { selectedKpi } = useContext(MeasuresContext);
    const { seriesBy: execSeries, slicesBy, filters } = execDefinition;
    const [newSeries, setNewSeries] = useState([...execSeries]);
    // const [categoryColors, setCategoryColors] = useState([]);
    const [data, setData] = useState([]);
    const { result, status, error } = useExecutionDataView({
        execution: {
            seriesBy: [...newSeries],
            slicesBy,
            filters,
        },
    });
    const { flag_services } = props;

    useEffect(() => {
        if (result) {
            const data = result
                ?.data()
                .series()
                .toArray()
                .find((serie) => serie.measureTitle() === newSeries[selectedKpi.index].measure.localIdentifier);
            const dataPoints = data?.dataPoints().map((dp) =>
                Object.freeze({
                    value: Number(dp.rawValue) === 0 ? null : selectedKpi.index === 24 ? Number((Number(dp.rawValue) * 100).toFixed(2)) : Math.round(Number(dp.rawValue)),
                    custId: dp.sliceDesc.sliceTitles()[0],
                    versionStd: dp.sliceDesc.sliceTitles()[1],
                }),
            );
            if (dataPoints) {
                const parsedData = newJsonPivot(dataPoints, { row: "versionStd", column: "custId", value: "value" });
                const prepasedData = newJsonPivot(dataPoints, { row: "custId", column: "versionStd", value: "value" });
                if (props.displayMode.key === "player") setData(prepasedData);
                else setData(parsedData);
            }
        }
    }, [result, selectedKpi, newSeries]);

    useEffect(() => {
        if (flag_services) {
            const enabledServices = Object.keys(flag_services)
                .filter((flService) => flService in flagServiceMap && flag_services[flService])
                .map((flService) => flagServiceMap[flService]);
            newSeries.splice(23, 1, newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"));
            newSeries.splice(24, 1, newArithmeticMeasure([newArithmeticMeasure([Md.FinRental.Avg, Md.AdjDeposit.Avg, ...enabledServices], "sum"), Md.ListPrice.Avg], "ratio"));
            setNewSeries([...newSeries]);
        }
    }, [componentChild, flag_services]);

    let player;
    let keys;
    if (props.displayMode.key === "player") {
        player = data?.find((elem) => elem.custId === props.custIndex);
        keys = player && Object.keys(player).filter((key) => key !== "custId");
    } else {
        player = data?.find((elem) => elem.versionStd === props.custIndex);
        keys = player && Object.keys(player).filter((key) => key !== "versionStd");
    }
    let barChartData = keys
        ?.map((key) => {
            return {
                title: key,
                dataPoint: player[key],
            };
        })
        .filter((elem) => elem.dataPoint !== null);
    barChartData?.sort((a, b) => b.dataPoint - a.dataPoint);

    return status !== "loading" && data.length > 0 ? (
        <>
            {React.createElement(componentChild, { data: barChartData, custIndex: props.custIndex, displayMode: props.displayMode, kpiSettings: flag_services, ...props })}
            <Servicesbanner kpiSettings={flag_services} />
        </>
    ) : (
        <LoadingComponent />
    );
};

export default BasketBarWidget;
