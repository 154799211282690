import React from "react";
import HighchartsComponent from "../HighchartsComponent";
import { useAppContext } from "../../contexts/AppContext";

export function BarChart({
    series,
    barsColor = undefined,
    title,
    subtitle = undefined,
    categories,
    width = null,
    height = null,
    labelsFormatter = undefined,
    maxValue = 1000,
    barWidth = 25,
    pdfMode = false,
    referenceLine = undefined,
    marginLeft = null,
    marginTop = null,
    screenshotName = "spot-figures",
    metadata,
    filters,
    id,
    section,
    ...props
}) {
    const { getCustlabelByCustId } = useAppContext();
    return (
        <HighchartsComponent
            widgetProps={{
                id,
                filters,
                section,
                ...metadata,
            }}
            options={{
                chart: {
                    type: "bar",
                    marginLeft: marginLeft,
                    // series.length > 0 ? 190 : 0,
                    marginTop: marginTop !== null ? marginTop : pdfMode ? 30 : 60,
                    spacingLeft: !pdfMode ? 15 : null,
                    // spacingRight: 10,
                    width: width,
                    height: height,
                },
                plotOptions: {
                    bar: {
                        color: barsColor,
                        pointWidth: barWidth,
                        dataLabels: {
                            enabled: true,
                            align: pdfMode ? "center" : "right",
                            color: "#FFFFFF",
                            x: -20,
                        },
                    },
                },
                legend: {
                    enabled: false,
                },
                title: {
                    text: title,
                    align: "center",
                    style: {
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "#595959",
                    },
                    widthAdjust: -160,
                },
                subtitle: {
                    text: subtitle,
                    align: "center",
                    style: {
                        fontWeight: 500,
                        fontStyle: "italic",
                    },
                },
                xAxis: {
                    categories:
                        series.length > 0
                            ? categories.map((categorie) => {
                                  if (categorie.length === 3) {
                                      return getCustlabelByCustId(categorie);
                                  } else {
                                      return categorie;
                                  }
                              })
                            : null,
                    labels: labelsFormatter || {
                        padding: 3,
                        reserveSpace: series.length > 0,
                        style: {
                            fontSize: pdfMode ? "8px" : "10px", // '10px
                            fontWeight: 600,
                            textOverflow: "none",
                        },
                    },
                    title: {
                        text: null,
                    },
                },
                yAxis: {
                    labels: {
                        enabled: false,
                    },
                    title: {
                        text: null,
                    },
                    min: 0,
                    max: maxValue,
                    tickAmount: pdfMode ? 3 : null,
                    plotLines: referenceLine && [
                        {
                            value: referenceLine.value,
                            width: 2,
                            zIndex: 10,
                            color: "#FF0000",
                            dashStyle: "shortdash",
                        },
                    ],
                },
                series: series.length > 0 ? series : null,
                lang: {
                    noData: "No data available",
                },
                noData: {
                    style: {
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "#595959",
                    },
                },
            }}
            enableScreenshot={!pdfMode}
            imgName={screenshotName}
            {...props}
        />
    );
}
