import "react-app-polyfill/stable";
import ReactDOM from "react-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { AppProviders } from "./contexts";

import "./styles/global.css";

// import "@gooddata/sdk-ui-charts/styles/css/main.css";
// import "@gooddata/sdk-ui-ext/styles/css/main.css";
import "@gooddata/sdk-ui-filters/styles/css/main.css";
// import "@gooddata/sdk-ui-geo/styles/css/main.css";
// import "@gooddata/sdk-ui-kit/styles/css/main.css";
import "@gooddata/sdk-ui-pivot/styles/css/main.css";
// import "@gooddata/sdk-ui-dashboard/styles/css/main.css";

import "antd/dist/antd.css";

import { Auth0Provider } from "@auth0/auth0-react";
import { auth0Domain, auth0ClientID, hasuraServerUri } from "./constants";

ReactDOM.render(
    <Auth0Provider domain={auth0Domain} clientId={auth0ClientID} redirectUri={window.location.origin} audience={hasuraServerUri}>
        <AppProviders>
            <App />
        </AppProviders>
    </Auth0Provider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
