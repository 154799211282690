import React, { useContext } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";

import HighchartsComponent from "../HighchartsComponent";
import { useAppContext } from "../../contexts/AppContext";
import Servicesbanner from "../Layout/Servicesbanner";
import MeasuresContext from "../../contexts/Measures";
import Highcharts from "highcharts";
import UserContext from "../../contexts/User";

// allMeasures is an object that contains all the measures and their respective index

const processCustomerData = (data) => {
    const result = {};
    //@ts-ignore
    const custIds = [...new Set(data.map((item) => item.custId))];
    const allMeasures = {
        "List Price": 1,
        Deposit: 2,
        "Web Price": 3,
        Maintenance: 4,
        Tyres: 6,
        Breakdown: 8,
        Replacements: 10,
        Insurance: 12,
        "Financial Loss": 14,
        "Winter Tyres": 16,
        "EV Bonus": 18,
        "CO2 Malus": 20,
    };

    custIds.forEach((custId) => {
        const customerData = data.filter((item) => item.custId === custId);

        result[custId] = {};

        Object.entries(allMeasures).forEach(([measure, index]) => {
            const measureData = customerData[index].data.map((item) => item.value);
            const monthData = customerData[index].data.map((item) => item.month);
            const changes = [];

            for (let i = 1; i < measureData.length; i++) {
                if (measureData[i] !== measureData[i - 1] && measureData[i] !== null && measureData[i - 1] !== null) {
                    changes.push({
                        month: monthData[i],
                        //Number(measureData[i] - measureData[i - 1]).toFixed(2),
                        value: Math.round(measureData[i] - measureData[i - 1]),
                    });
                }
            }

            if (changes.length > 0) {
                result[custId][measure] = changes;
            }
        });

        if (Object.keys(result[custId]).length === 0) {
            delete result[custId];
        }
    });

    return result;
};

export default function LineVehicles({ seriesBy, slicesBy, filters, ...props }) {
    const id = "vehicle-trends-chart";
    const { getColorByCustId, getCustlabelByCustId } = useAppContext();
    const { selectedKpi, selectedAdjDeposit } = useContext(MeasuresContext);
    const { kpiSettings } = useContext(UserContext);
    const { result, status } = useExecutionDataView({ execution: { seriesBy, slicesBy, filters } });
    const series = result ? result.data().series().toArray() : [];
    const slices = result ? result.data().slices().toArray() : [];

    const settingsAliases = {
        flag_ser: "Maintenance",
        flag_bre: "Breakdown",
        flag_ins: "Insurance",
        flag_rep: "Replacement",
        flag_fin: "Financial loss",
        flag_co2: "CO2 Malus",
        flag_tyr: "Tyres",
        flag_win: "Winter Tyres",
        flag_evb: "EV Bonus",
    };

    const services = Object.keys(kpiSettings)
        .filter((key) => kpiSettings[key] === true)
        .map((key) => settingsAliases[key]);

    const valableCustIds =
        series &&
        series
            .map((s) => {
                return {
                    custId: s.scopeTitles()[0],
                    data: s.dataPoints(),
                };
            })
            .reduce((acc, val, index) => {
                const groupIndex = Math.floor(index / (seriesBy.length - 1));
                if (!acc[groupIndex]) {
                    acc[groupIndex] = [];
                }
                acc[groupIndex].push(val);
                return acc;
            }, [])
            .filter((f) => f[2].data.filter((m) => m.rawValue !== null).length > 0)
            .map((m) => m[0].custId);
    const preline =
        selectedAdjDeposit !== "Total Payable by month"
            ? valableCustIds.length > 0 &&
              series &&
              series
                  .filter((f, i) => i % (seriesBy.length - 1) === selectedKpi?.index) // 27 is max measures, modulo needs to find series
                  .filter((f) => valableCustIds.includes(f.scopeTitles()[0]))
                  ?.map((value) => {
                      return {
                          name: getCustlabelByCustId(value.scopeTitles()[0]),
                          data: value.dataPoints().map((p) => (Number(p.rawValue) > 1 ? Math.round(Number(p.rawValue)) : Math.round(10000 * Number(p.rawValue)) / 100)),
                          color: getColorByCustId(value.scopeTitles()[0]),
                      };
                  })
            : series &&
              series
                  .filter((f, i) => i % (seriesBy.length - 1) === selectedKpi?.index) // 27 is max measures, modulo needs to find series
                  ?.map((value) => {
                      return {
                          name: getCustlabelByCustId(value.scopeTitles()[0]),
                          data: value.dataPoints().map((p) => (Number(p.rawValue) > 1 ? Math.round(Number(p.rawValue)) : Math.round(10000 * Number(p.rawValue)) / 100)),
                          color: getColorByCustId(value.scopeTitles()[0]),
                      };
                  });
    const lines =
        preline &&
        preline.reduce((a, b) => {
            if (!a[b.name]) {
                a[b.name] = { name: b.name, data: b.data.map((m) => (m === 0 ? null : m)), color: b.color };
            } else {
                a[b.name]["data"] = a[b.name]["data"].map((a, i) => {
                    return a + b["data"][i] === 0 ? null : a + b["data"][i];
                });
            }
            return a;
        }, []);

    const data = slices?.map((slice) => ({
        label: slice.sliceTitles()[0],
        ...slice.dataPoints().map((p) => Number(p.rawValue)),
    }));

    const diff = series.map((s, index) => {
        return {
            custId: getCustlabelByCustId(s.scopeTitles()[0]),
            data: s.dataPoints().map((p, i) => {
                return {
                    month: slices[i].sliceTitles()[0],
                    value: p.rawValue,
                };
            }),
        };
    });

    const diffrenceData = processCustomerData(diff);
    return (
        <>
            {status === "loading" && (
                <div className="my-2">
                    <LoadingComponent />
                </div>
            )}

            {status === "error" && <ErrorComponent message="Unable to calculate your query" description={"Please verify if the selected vehicles are compatible with the active filters"} />}

            {status === "success" && (
                <>
                    <HighchartsComponent
                        widgetProps={{
                            id,
                            filters,
                            section: "car-selector",
                            flag_services: props?.kpiSettings,
                        }}
                        options={{
                            chart: {
                                type: "spline",
                                height: props.height || 400,
                            },
                            title: {
                                text: "",
                                style: {
                                    display: "none",
                                },
                            },
                            subtitle: {
                                text: "",
                                style: {
                                    display: "none",
                                },
                            },
                            xAxis: {
                                categories: data.map((m) => m["label"]),
                                crosshair: true,
                            },
                            yAxis: {
                                title: {
                                    text: "",
                                },
                            },
                            tooltip: {
                                useHTML: true,
                                backgroundColor: "rgba(255, 255, 255, 0.9)",
                                shadow: true,
                                style: {
                                    fontSize: "12px",
                                    color: "#333",
                                },
                                formatter: function () {
                                    let s = '<table style="border-collapse: collapse; width: 100%;">';
                                    s += `<tr><th colspan="2" padding-bottom: 5px;"><b>${this.x}</b></th></tr>`;
                                    s += `<tr>
                                            <td style="text-align: left; padding: 2px;"><b>${this.series.name}:</b></td>
                                            <td style="text-align: right; padding: 2px;">${Highcharts.numberFormat(this.y, 0)}</td>
                                          </tr>`;

                                    const custId = this.series.name;
                                    if (diffrenceData[custId]) {
                                        Object.entries(diffrenceData[custId]).forEach(([measure, changes]) => {
                                            const change = changes.find((c) => c.month == this.x);
                                            if (change && change.value !== 0 && [...services, "List Price", "Deposit", "Web Price"].includes(measure)) {
                                                s += `<tr>
                                                        <td style="text-align: left; padding: 2px;">${measure}:</td>
                                                        <td style="text-align: right; padding: 2px;">${change.value > 0 ? "+" + change.value : change.value}</td>
                                                      </tr>`;
                                            }
                                        });
                                    }
                                    s += "</table>";
                                    return s;
                                },
                            },
                            plotOptions: {
                                column: {
                                    borderWidth: 0,
                                    pointPadding: 0.1,
                                    groupPadding: 0,
                                    pointWidth: 55,
                                },
                                series: {
                                    dataLabels: {
                                        enabled: true,
                                        // formatter: function () {
                                        //     return Highcharts.numberFormat(this.y, 0);
                                        // },
                                    },
                                },
                            },
                            series: Object.values(lines),
                        }}
                        imgName="car-selector-trends"
                        {...props}
                    />
                    {selectedKpi?.id === 1 && <Servicesbanner kpiSettings={props?.kpiSettings} />}
                </>
            )}
        </>
    );
}
