import React, { useContext } from "react";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import LoadingSpinner from "../components/LoadingSpinner";
import FiltersContext from "../contexts/Filters";
import { GET_RE_RULES_UPDATES } from "../queries/Users";

const News = () => {
    const { filterCountryId, filterChannel1 } = useContext(FiltersContext);
    const countryFilterValue = filterCountryId.positiveAttributeFilter.in["values"][0] || "";
    const channel1FilterValue = filterChannel1.positiveAttributeFilter.in["values"][0] || "";
    const { data, error, loading } = useQuery(GET_RE_RULES_UPDATES, {
        variables: {
            ctryid: countryFilterValue,
            channel1: channel1FilterValue,
        },
    });
    const dataNotes = data?.re_rentaleye_re_rules_updates.filter((note) => note.release_type === "data");
    const applicationNotes = data?.re_rentaleye_re_rules_updates.filter((note) => note.release_type === "application");

    return loading ? (
        <LoadingSpinner />
    ) : error ? (
        <div className="flex items-center gap-4">
            <InformationCircleIcon className="w-6 h-6 text-gray-500" />
            <span className="text-xl font-semibold text-gray-500 leading-none">Unable to load Release notes</span>
        </div>
    ) : (
        <div className="flex flex-col my-4 w-4/5 divide-gray-200 divide-y-2 gap-2">
            {dataNotes?.length > 0 && (
                <section className="flex flex-col gap-2">
                    <h3 className="text-steel text-xl font-semibold">Data & publication</h3>
                    <table className="text-left shadow-md">
                        <thead className="bg-indigo-700 text-white">
                            <th className="px-2 py-2">Date</th>
                            <th></th>
                            <th>Update</th>
                        </thead>
                        <tbody>
                            {dataNotes?.map((item) => (
                                <tr className="border-b border-gray-300 font-medium  hover:bg-gray-200 py-2">
                                    <td className="w-24 px-2 py-2 pr-4 text-xs font-semibold text-gray-500 ">{moment(item.released_at).format("DD.MM.YYYY")}</td>
                                    <td className="w-32"></td>
                                    <td className="flex flex-col items-stretch gap-1 py-2">
                                        <span className="text-sm font-medium">{item.updatetext}</span>
                                        <span className="text-xs font-semibold text-gray-500">{item.vatrules}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* <ul>
                        {dataNotes?.map((item) => (
                            <li className="flex items-center gap-2">
                                <InformationCircleIcon className="w-6 h-6 mx-4" />
                                <div className="flex flex-col items-stretch">
                                    <span className="text-sm font-medium">{item.updatetext}</span>
                                    <span className="text-xs font-semibold text-gray-500">{item.vatrules}</span>
                                </div>
                            </li>
                        ))}
                    </ul> */}
                </section>
            )}
            {applicationNotes?.length > 0 && (
                <section>
                    <h3 className="pt-2 text-steel text-xl font-semibold">New features</h3>
                    <table className="text-left shadow-md ">
                        <thead className="bg-indigo-700 text-white">
                            <th className="px-2 py-2">Date</th>
                            <th>Section</th>
                            <th>New feature</th>
                        </thead>
                        <tbody>
                            {applicationNotes?.map((item) => (
                                <tr className="border-b border-gray-300 font-medium  hover:bg-gray-200 py-2">
                                    <td className="w-24 px-2 py-2 pr-4 text-xs font-semibold text-gray-500 ">{moment(item.released_at).format("DD.MM.YYYY")}</td>
                                    <td className="w-32">{item.release_section}</td>
                                    <td className="pr-3">{item.updatetext}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            )}
        </div>
    );
};

export default News;
