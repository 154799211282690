import React, { useState, useEffect } from "react";
import { Modal, Select, Button, Table, Typography, Tooltip } from "antd";
import { LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";
import { newPositiveAttributeFilter } from "@gooddata/sdk-model";
import * as Md from "../../md/full";
import { mode, weigthedAverage } from "../../utils/calculationUtils";
import { BiInfoCircle } from "react-icons/bi";

const mapResultToVersions = (slices, kpi) => {
    return slices.map((slice) => {
        const dataPoints = slice.dataPoints();
        return {
            Version: slice.sliceTitles()[0],
            CustId: slice.sliceTitles()[1],
            "List Price": Math.round(Number(slice.dataPoints()[1].rawValue)) > 0 ? Math.round(Number(slice.dataPoints()[1].rawValue)) : null,
            CellColor: slice.sliceTitles()[2],
            Model: slice.sliceTitles()[3],
            Value: dataPoints[kpi?.index]?.rawValue !== null ? (kpi?.index === 24 ? Math.round(10000 * Number(dataPoints[kpi?.index]?.rawValue)) / 100 : Math.round(Number(dataPoints[kpi?.index]?.rawValue))) : null,
            versionCount: Number(dataPoints[30].rawValue),
            Segment: slice.sliceTitles()[4],
            Style: slice.sliceTitles()[5],
            Gear: slice.sliceTitles()[6],
            Energy: slice.sliceTitles()[7],
            Doors: slice.dataPoints()[32].rawValue,
            Kw: slice.dataPoints()[29].rawValue,
            CO2: slice.dataPoints()[31].rawValue,
            "Adj. Deposit": slice.dataPoints()[2].rawValue,
            Power: slice.dataPoints()[22].rawValue,
        };
    });
};

const MapToVersionSelection = (data, kpi) => {
    if (data.length > 0) {
        // @ts-ignore
        const custIdKeys = [...new Set(data.map(({ CustId }) => CustId))];
        let vehicleOffersObj = data
            // .filter((item) => (activePlayers.length > 0 ? activePlayers.includes(item.CustId) : true))
            .reduce((acc, nextItem) => {
                const vehicleInfo = Object.entries(nextItem)
                    .filter(([key, val]) => !["CustId", "Value", "List Price"].includes(key))
                    .reduce((acc, [key, value]) => Object.assign({ [key]: value, ...acc }), {});
                if (nextItem.Version in acc && nextItem.CustId in acc[nextItem.Version]) {
                    acc[nextItem.Version][nextItem.CustId].values.push(nextItem.Value);
                    acc[nextItem.Version][nextItem.CustId].versionCount.push(nextItem.versionCount);
                } else {
                    acc[nextItem.Version] = {
                        [nextItem.CustId]: {
                            values: [nextItem.Value],
                            versionCount: [nextItem.versionCount],
                        },
                        ...vehicleInfo,
                        ...acc[nextItem.Version],
                    };
                }
                acc[nextItem.Version] = {
                    ...acc[nextItem.Version],
                    listPrices: "listPrices" in acc[nextItem.Version] ? [...acc[nextItem.Version]["listPrices"], nextItem["List Price"]] : [nextItem["List Price"]],
                };
                return acc;
            }, {});
        // Calculate the weighted average
        Object.keys(vehicleOffersObj).forEach((key) => {
            custIdKeys.forEach((custId) => {
                vehicleOffersObj[key][custId] = custId in vehicleOffersObj[key] ? kpi?.roundValue(weigthedAverage(vehicleOffersObj[key][custId]?.values, vehicleOffersObj[key][custId]?.versionCount)) : null;
            });
        });
        const dataSource = Object.values(vehicleOffersObj).map((item) => {
            const custValues = Object.keys(item)
                .filter((key) => custIdKeys.includes(key) && item[key] > 0)
                .map((key) => item[key]);
            let rentalSum = custValues.reduce((acc, currentVal) => acc + currentVal, 0);
            let Average = rentalSum > 0 ? kpi?.roundValue(rentalSum / custValues.length) : null;
            let ModListPrice = item.listPrices?.length > 0 ? Math.round(Number(mode(item.listPrices))) || null : null;

            return { Version: item.Version, "List Price": ModListPrice, Average, ...item };
        });
        return dataSource;
    }
};

const VersionMatch = ({ selectedVehicle, onCancel, seriesBy, slicesBy, kpi, filters, action = (values) => {} }) => {
    const [rangeIndexCoeff, setRangeIndex] = useState(0.025);
    const [powerThresholdCoeff, setPowerThreshold] = useState(0.3);
    const [currentStep, setCurrentStep] = useState(0);
    const [selectedBrands, setSelectedBrands] = useState([]);
    const [filteredVersions, setFilteredVersions] = useState([]);
    const [matchVersions, setMatchVersions] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selection, setSelection] = useState([]);
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState([]);
    const TotalSteps = 4;
    // Fetch brands
    const {
        status: statusBrands,
        result: resultBrands,
        error: errorBrands,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CorrMake],
            filters: [...filters],
        },
    });

    useEffect(() => {
        if (statusBrands === "success") {
            setBrands(
                resultBrands
                    .data()
                    .slices()
                    .toArray()
                    .map((item) => item.sliceTitles()[0]),
            );
            setCurrentStep(1);
        }
    }, [statusBrands, resultBrands, errorBrands]);

    // First query
    const {
        status: statusFirstQuery,
        result: resultFirstQuery,
        error: errorFirstQuery,
    } = useExecutionDataView(
        {
            execution: {
                seriesBy: seriesBy,
                slicesBy: slicesBy,
                filters: [...filters, newPositiveAttributeFilter(Md.VehSegment, [selectedVehicle.Segment]), newPositiveAttributeFilter(Md.BodyStyle, [selectedVehicle.Style]), newPositiveAttributeFilter(Md.CorrMake, selectedBrands)],
            },
        },
        [selectedVehicle, selectedBrands],
    );

    useEffect(() => {
        if (currentStep === 2) {
            if (statusFirstQuery === "success") {
                const slices = resultFirstQuery.data().slices().toArray();
                if (slices.length !== 0) {
                    setFilteredVersions(mapResultToVersions(slices, kpi));
                    setCurrentStep(TotalSteps);
                } else {
                    setCurrentStep(3);
                }
            } else {
                setCurrentStep(3);
            }
        }
    }, [statusFirstQuery, resultFirstQuery, errorFirstQuery]);

    // fetch models based on selected brands
    const {
        status: statusModels,
        result: resultModels,
        error: errorModels,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: [Md.CorrModel],
            filters: [...filters, newPositiveAttributeFilter(Md.CorrMake, selectedBrands)],
        },
    });

    useEffect(() => {
        if (statusModels === "success") {
            setModels(
                resultModels
                    .data()
                    .slices()
                    .toArray()
                    .map((item) => item.sliceTitles()[0]),
            );
        }
    }, [statusModels, resultModels, errorModels]);

    // Final query
    const {
        status: statusFinalQuery,
        result: resultFinalQuery,
        error: errorFinalQuery,
    } = useExecutionDataView({
        execution: {
            seriesBy: seriesBy,
            slicesBy: slicesBy,
            filters: [...filters, newPositiveAttributeFilter(Md.CorrModel, selectedModel)],
        },
    });

    useEffect(() => {
        if (statusFinalQuery === "success" && currentStep === TotalSteps && selectedModel.length > 0) {
            const slices = resultFinalQuery.data().slices().toArray();
            if (slices.length !== 0) {
                setFilteredVersions(mapResultToVersions(slices, kpi));
            }
        }
    }, [statusFinalQuery, resultFinalQuery, errorFinalQuery]);

    useEffect(() => {
        let resultedata = [];
        if (filteredVersions.length !== 0) {
            // Group by model, body, gear, and fuel
            const groupedVersions = filteredVersions.reduce((acc, curr) => {
                const key = `${curr.Model}-${curr.Style}-${curr.Gear}-${curr.Energy}`;
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr);
                return acc;
            }, {});

            // Calculate average list price for each group
            const avgListPrices = Object.keys(groupedVersions).reduce((acc, key) => {
                const group = groupedVersions[key];
                const totalListPrice = group.reduce((sum, version) => sum + (version["List Price"] || 0), 0);
                acc[key] = totalListPrice / group.length;
                return acc;
            }, {});

            // Filter versions based on power
            const filteredByPower = filteredVersions.filter((version) => {
                const power = Number(version.Power);
                const selectedPower = Number(selectedVehicle.Power);
                const powerThreshold = selectedPower * powerThresholdCoeff;
                return power >= selectedPower - powerThreshold && power <= selectedPower + powerThreshold;
            });

            // Filter by range index
            const filterByRangeIndex = (versions) => {
                return versions
                    .map((version) => {
                        const groupKey = `${version.Model}-${version.Style}-${version.Gear}-${version.Energy}`;
                        const avgListPrice = avgListPrices[groupKey];
                        const rangeIndex = (version["List Price"] || 0) / avgListPrice;
                        const selectedRangeIndex = (selectedVehicle["List Price"] || 0) / avgListPrice;
                        version["rangeIndex"] = rangeIndex;
                        version["rangeIndexGap"] = Math.abs(rangeIndex - selectedRangeIndex);
                        return version;
                    })
                    .filter((version) => {
                        return version["rangeIndexGap"] <= rangeIndexCoeff;
                    });
            };
            // Apply filters in the desired order
            if (filteredByPower.length > 0) {
                const filteredByPowerAndRange = filterByRangeIndex(filteredByPower);
                if (filteredByPowerAndRange.length > 0) {
                    resultedata = MapToVersionSelection(filteredByPowerAndRange, kpi);
                } else {
                    resultedata = MapToVersionSelection(filteredByPower, kpi);
                }
            } else {
                const filteredByRangeIndex = filterByRangeIndex(filteredVersions);
                if (filteredByRangeIndex.length > 0) {
                    resultedata = MapToVersionSelection(filteredByRangeIndex, kpi);
                } else {
                    resultedata = MapToVersionSelection(filteredVersions, kpi);
                }
            }
        }
        if (resultedata.length > 0) {
            // filter by gear,doors,energy
            const firstFilter = resultedata.filter((version) => {
                return version.Gear === selectedVehicle.Gear && version.Doors === selectedVehicle.Doors && version.Energy === selectedVehicle.Energy;
            });
            if (firstFilter.length > 0) {
                setMatchVersions(firstFilter);
                setCurrentStep(TotalSteps);
            } else {
                // if if gear = MANUAL then AUTO and vice versa, if energy = PETROL or DIESEL then HYBRID and vice versa, if energy = ELECTRIC then ELECTRIC OR PHEV OR HYBRID, if energy = PHEV then ELECTRIC
                const secondFilter = resultedata.filter((version) => {
                    return (
                        (selectedVehicle.Gear === "MANUAL" && version.Gear === "AUTO") ||
                        (selectedVehicle.Gear === "AUTO" && version.Gear === "MANUAL") ||
                        (["PETROL", "DIESEL"].includes(selectedVehicle.Energy) && version.Energy === "HYBRID") ||
                        (["HYBRID"].includes(selectedVehicle.Energy) && ["PETROL", "DIESEL"].includes(version.Energy)) ||
                        (["ELECTRIC"].includes(selectedVehicle.Energy) && ["ELECTRIC", "PHEV", "HYBRID"].includes(version.Energy)) ||
                        (["PHEV"].includes(selectedVehicle.Energy) && ["ELECTRIC"].includes(version.Energy))
                    );
                });
                if (secondFilter.length > 0) {
                    setMatchVersions(secondFilter);
                    setCurrentStep(TotalSteps);
                } else {
                    setMatchVersions(resultedata);
                    setCurrentStep(TotalSteps);
                }
            }
        }
    }, [filteredVersions, selectedVehicle, rangeIndexCoeff, powerThresholdCoeff, kpi]);

    useEffect(() => {
        if (matchVersions.length > 0) {
            const columns = ["Version", "List Price", "Range index", "Range index Gap", "Power", "Power Gap", "Average", "Model", "Segment", "Style", "Gear", "Energy", "Doors", "Kw", "CO2", "Adj. Deposit"];
            setColumns(
                columns.map((m) => {
                    const custIDs = matchVersions.map((item) => item.CustId);
                    return {
                        title: () => <div>{["Version", "Average"].includes(m) ? <span>{m}</span> : <span className="flex-1 self-center">{m}</span>}</div>,
                        fixed: ["Version"].includes(m) ? "left" : null,
                        align: m !== "Version" && "center",
                        dataIndex: m,
                        key: m,
                        width: m === "Version" ? 350 : ["List Price", "Power"].includes(m) ? 200 : !custIDs.includes(m) ? (m.length > 8 ? m.length * 12 : 100) : 100,
                        render: (cell, record) => {
                            // if (m === "List Price" && selectedVehicle["List Price"] > 0 && cell > 0) {
                            //     return <Typography.Text>{`${cell}`}</Typography.Text>;
                            // }
                            // if (m === "Power" && cell > 0) {
                            //     return <Typography.Text>{`${cell}`}</Typography.Text>;
                            // }
                            let cellContent = cell;
                            if (m === "Power Gap" && record.Power !== null) {
                                const diffPercent = Math.round((Number(record.Power) / Number(selectedVehicle.Power) - 1) * 100);
                                cellContent = `${diffPercent} %`;
                            } else if (m === "Range index Gap" && record["rangeIndex"] > 0) {
                                cellContent = `${Number(record["rangeIndexGap"] * 100).toFixed(2)}%`;
                            } else if (m === "Range index" && record["rangeIndex"] > 0) {
                                cellContent = `${Number(record["rangeIndex"] * 100).toFixed(2)}%`;
                            }

                            return <Typography.Text>{cellContent}</Typography.Text>;
                        },
                        filters: !custIDs.includes(m)
                            ? null
                            : [
                                  {
                                      text: "Show values only",
                                      value: null,
                                  },
                              ],

                        onFilter: (value, record) => (["Version", "List Price", "Average"].includes(m) ? value : record[m] !== value),
                    };
                }),
            );
        }
    }, [matchVersions, selectedVehicle]);

    const handleBrandSelection = (value) => {
        if (selectedBrands.length === 12) {
            return;
        }
        setSelectedBrands(value);
    };

    const handleModelSelection = (value) => {
        setSelectedModel(value);
    };

    const nextStep = () => {
        if (currentStep === 1 && (selectedBrands.length === 0 || selectedBrands.length > 12)) {
            return;
        }
        if (currentStep === 3 && selectedModel === null) {
            return;
        }
        setCurrentStep((prevStep) => prevStep + 1);
    };
    const resetState = () => {
        setCurrentStep(0);
        setSelectedBrands([]);
        setFilteredVersions([]);
        setMatchVersions([]);
        setSelection([]);
        setBrands([]);
        setModels([]);
        setSelectedModel([]);
        setRangeIndex(0.025);
        setPowerThreshold(0.3);
    };

    const handleCancel = () => {
        resetState();
        onCancel();
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Initializing ...</span>
                        <LoadingComponent />
                    </div>
                );
            case 1:
                return (
                    <div className="flex flex-col gap-3">
                        <div>
                            <h2>Brand Selection</h2>
                            <Select mode="multiple" style={{ width: "100%" }} allowClear placeholder="Select brands" onChange={handleBrandSelection} maxTagCount={12}>
                                {brands.map((brand) => (
                                    <Select.Option key={brand} value={brand}>
                                        {brand}
                                    </Select.Option>
                                ))}
                            </Select>
                            <span className="text-secondary-300 text-xs block mt-2 mb-2">* Select up to 12 brands </span>
                        </div>
                        <div className="flex flex-col justify-center gap-2">
                            <div className="text-center flex w-1/4 justify-between">
                                <div className="flex gap-1 items-center">
                                    <Tooltip title="Power threshold is the percentage of power difference between the selected vehicle and the matched vehicle.">
                                        <BiInfoCircle className="text-indigo-700 w-5 h-5 hover:opacity-50" />
                                    </Tooltip>
                                    <span className=" text-base font-medium">Power (+/-) :</span>
                                </div>
                                <div className="flex gap-1">
                                    <input type="number" className="w-20 px-2 py-0.5 text-center font-medium border border-indigo-700 rounded-md" value={Number(powerThresholdCoeff * 100)} onChange={(e) => setPowerThreshold(Number(Number(e.target.value) / 100))} />
                                    <span className=" text-base font-medium">%</span>
                                </div>
                            </div>
                            <div className="text-center flex  w-1/4 justify-between">
                                <div className="flex gap-1 items-center">
                                    <Tooltip title="Range index threshold is the percentage of the price difference between the selected vehicle and the matched vehicle.">
                                        <BiInfoCircle className="text-indigo-700 w-5 h-5 hover:opacity-50" />
                                    </Tooltip>
                                    <span className=" text-base font-medium">RangeIndex (+/-) :</span>
                                </div>
                                <div className="flex gap-1">
                                    <input type="number" className="w-20 px-2 py-0.5 text-center font-medium  border border-indigo-700 rounded-md" value={Number(rangeIndexCoeff * 100)} onChange={(e) => setRangeIndex(Number(Number(e.target.value) / 100))} />
                                    <span className=" text-base font-medium">%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div className="text-center">
                        <span className="text-indigo-700 text-base font-medium">Searching for exact matches ...</span>
                        <LoadingComponent />
                    </div>
                );
            case 3:
                return (
                    <div>
                        <h2>Version Match Not Found</h2>
                        <p className="text-secondary-300">Please select a set of models to find a match:</p>
                        <Select style={{ width: "100%" }} mode="multiple" placeholder="Select model" onChange={handleModelSelection}>
                            {models.map((model) => (
                                <Select.Option key={model} value={model}>
                                    {model}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                );
            case TotalSteps:
                return (
                    <div>
                        <p className="text-indigo-700 text-base font-medium">Selected Vehicle : {selectedVehicle.Version}</p>
                        <p className="text-indigo-700 text-sm font-medium">List price : {selectedVehicle["List Price"]}</p>
                        <p className="text-indigo-700 text-sm font-medium">Power Threshold : {powerThresholdCoeff * 100}%</p>
                        <p className="text-indigo-700 text-sm font-medium">Range Index Threshold : {rangeIndexCoeff * 100}%</p>
                        <h2>List of Versions that Match</h2>
                        <Table
                            dataSource={matchVersions}
                            columns={columns}
                            scroll={{ x: 800, y: 300 }}
                            rowKey={(record) => record.Version}
                            pagination={false}
                            rowSelection={{
                                type: "checkbox",
                                onSelect: (record, selected, selectedRows, nativeEvent) => {
                                    setSelection([...selectedRows.filter((row) => row?.Version)]);
                                },
                                onSelectAll: (selected, selectedRows, changeRows) => {
                                    setSelection([...selectedRows.filter((row) => row?.Version)]);
                                },
                                selectedRowKeys: selection.map((item) => item.Version),
                            }}
                            loading={matchVersions.length === 0}
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <Modal
            title="Version Match"
            width={1200}
            open={true}
            onCancel={handleCancel}
            footer={[
                <Button
                    key="next"
                    type="primary"
                    onClick={
                        currentStep === TotalSteps
                            ? () => {
                                  action([selectedVehicle, ...selection]);
                                  handleCancel();
                              }
                            : nextStep
                    }
                    disabled={(currentStep === TotalSteps && selection.length === 0) || (currentStep === 1 && (selectedBrands.length === 0 || selectedBrands.length > 12)) || (currentStep === 3 && selectedModel.length === 0) || currentStep === 0 || currentStep === 2}
                >
                    {currentStep === TotalSteps ? "Select" : "Next"}
                </Button>,
            ]}
        >
            {renderStep()}
        </Modal>
    );
};

export default VersionMatch;
