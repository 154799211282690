import React from "react";
import { Switch } from "antd";
const ScopeSettings = ({ initialValues, onChange = (key, val) => {} }) => {
    return (
        <section className="space-y-4">
            <h3 className="text-xl font-semibold text-indigo-700">Scope settings</h3>
            <div className="w-max flex gap-2 items-center">
                <Switch
                    size="small"
                    style={{
                        backgroundColor: initialValues.fl_rw ? "#3a0ca3" : "#EEF1FF",
                    }}
                    onChange={(checked) => {
                        onChange("fl_rw", initialValues.fl_rw === 1 ? 0 : 1);
                    }}
                    checked={initialValues.fl_rw === 1}
                />
                <label className="text-base" htmlFor={"key"}>
                    Include <span className="italic">Rolled forward</span>
                </label>
            </div>
        </section>
    );
};

export { ScopeSettings };
