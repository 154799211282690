import React from "react";

const Exportbtn = ({ onclick }) => {
    return (
        <div>
            <button className="flex text-white bg-indigo-700 px-12 py-1 border hover:bg-indigo-500 border-indigo-700 rounded font-semibold shadow-button" onClick={onclick}>
                Export
            </button>
        </div>
    );
};

export default Exportbtn;
