import React from "react";
import { LoadingComponent } from "@gooddata/sdk-ui";

import { TrendChart } from "../Visualization/TrendChart";

function BasketLineChart({ kpiSettings, title, subtitle = undefined, categories, minValue, maxValue, data, width = null, height = null, chartProps = {}, custIndex, filters, displayMode, ...props }) {
    return data?.length <= 0 ? (
        <div className="p-10">
            <LoadingComponent />
        </div>
    ) : (
        data?.length > 0 && (
            <div className="border-2 border-gray-300 rounded">
                <TrendChart
                    metadata={{ custIndex, displayMode, flag_services: kpiSettings }}
                    section={"basketline"}
                    filters={filters}
                    id={"basket-line-chart"}
                    title={title}
                    subtitle={subtitle}
                    categories={categories}
                    legendStyle={{
                        fontSize: "10px",
                        fontWeigth: "600",
                        color: "#595959",
                    }}
                    series={data ? data : []}
                    width={width}
                    height={height}
                    {...chartProps}
                    minValue={minValue}
                    maxValue={maxValue}
                    {...props}
                />{" "}
            </div>
        )
    );
}

export { BasketLineChart };
