import React, { useState } from "react";
import { BsInfoCircle } from "react-icons/bs";
import { Tooltip } from "antd";

const Widget = ({ title, description, footnote, ...props }) => {
    // const [showDescription, setShowDescription] = useState(false);

    return (
        <article className="flex flex-col gap-4">
            <div className="inline-flex gap-2 items-center">
                <h3 className="text-indigo-700 my-0">{title}</h3>
                <Tooltip title={description} color="#6B50B6" placement="right" overlayStyle={{ minWidth: description.split(" ").length > 10 ? "420px" : null }}>
                    <BsInfoCircle
                        className="w-4 h-4 text-gray-500 cursor-help"
                        // onClick={() => setShowDescription(!showDescription)}
                    />
                </Tooltip>
            </div>
            {/* {showDescription ? <span className="h-5 text-sm text-gray-500 opacity-80 font-medium">{description}</span> : <div className="h-5" />} */}
            {props.children}
            <span className="text-center text-xs">{footnote}</span>
        </article>
    );
};

export default Widget;
