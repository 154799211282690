import React, { useEffect, useState, useContext } from "react";
import { ErrorComponent, LoadingComponent, useExecutionDataView } from "@gooddata/sdk-ui";

import { Table, Typography } from "antd";
import { TfiWorld } from "react-icons/tfi";
import * as Md from "../../md/full";
import UserContext from "../../contexts/User";
import Servicesbanner from "../Layout/Servicesbanner";
import MeasuresContext from "../../contexts/Measures";

export default function AdjustmentVehicles({ seriesBy, filters }) {
    const { kpiSettings } = useContext(UserContext);
    const { selectedAdjDeposit } = useContext(MeasuresContext);

    const { result, error, status } = useExecutionDataView({
        execution: {
            seriesBy: [...seriesBy, Md.Deposit.Avg, Md.OtrPrice.Avg, Md.Apr.Avg, Md.FinanceContrib.Avg, Md.TotalCharges.Avg, Md.TotalPayable.Avg],
            slicesBy: [Md.CustId, Md.Version, Md.Channel3, Md.Url, Md.DateDatasets.DateOfScraping.Date.DdMmYyyy],
            filters,
        },
    });

    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    function cleanEmptyValues(dataSource, fieldNames = []) {
        let res = dataSource;
        fieldNames.forEach((fieldName) => {
            const motif = fieldName === "Interest Rate" ? dataSource.filter((elem) => elem[fieldName] === null).length : dataSource.filter((elem) => Number(elem[fieldName]) === 0).length;
            if (motif === dataSource.length) {
                res = res.map((obj) => {
                    return Object.keys(obj)
                        .filter((key) => key !== fieldName)
                        .reduce((acc, nextKey) => {
                            return { ...acc, [nextKey]: obj[nextKey] };
                        }, {});
                });
            }
        });
        return res;
    }

    useEffect(() => {
        const slices = result?.data().slices().toArray();
        const calculateAdjustment = (slice, n, pref) => {
            return Math.round((pref - Number(slice.dataPoints()[n + 1].rawValue)) * Number(slice.dataPoints()[n].rawValue));
        };

        if (slices && kpiSettings) {
            const dataSource = slices
                ?.map((slice) => {
                    const services = {
                        Maintenance: Number(slice.dataPoints()[5].rawValue),
                        Tyres: Number(slice.dataPoints()[7].rawValue),
                        Breakdown: Number(slice.dataPoints()[9].rawValue),
                        Replacement: Number(slice.dataPoints()[11].rawValue),
                        Insurance: Number(slice.dataPoints()[13].rawValue),
                        "Financial Loss": Number(slice.dataPoints()[15].rawValue),
                        "Winter Tyres": Number(slice.dataPoints()[17].rawValue),
                        "EV Bonus": Number(slice.dataPoints()[19].rawValue),
                        "CO2 Malus": Number(slice.dataPoints()[21].rawValue),
                    };
                    const composer = {
                        flag_ser: kpiSettings["flag_ser"],
                        flag_tyr: kpiSettings["flag_tyr"],
                        flag_bre: kpiSettings["flag_bre"],
                        flag_rep: kpiSettings["flag_rep"],
                        flag_ins: kpiSettings["flag_ins"],
                        flag_fin: kpiSettings["flag_fin"],
                        flag_win: kpiSettings["flag_win"],
                        flag_co2: kpiSettings["flag_co2"],
                        flag_evb: kpiSettings["flag_evb"],
                    };
                    return {
                        Player: slice.sliceTitles()[0],
                        Version: slice.sliceTitles()[1],
                        Link: slice.sliceTitles()[3].startsWith("http", 0) ? slice.sliceTitles()[3] : "",
                        Date: slice.sliceTitles()[4],
                        "List Price": slice.dataPoints()[1].rawValue === "0" ? 0 : Number(slice.dataPoints()[1].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[1].rawValue)) : null,
                        "Finance Type": slice.sliceTitles()[2],
                        "Web Rental": slice.dataPoints()[3].rawValue === "0" ? 0 : Number(slice.dataPoints()[3].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[3].rawValue)) : null,
                        "Web Deposit": slice.dataPoints()[31].rawValue === "0" ? 0 : Math.round(Number(slice.dataPoints()[31].rawValue)),
                        "Target Deposit": selectedAdjDeposit === "Total Payable by month" ? "Total Payable" : Number(selectedAdjDeposit),
                        "Adj. Deposit": slice.dataPoints()[2].rawValue === null ? null : Math.round(Number(slice.dataPoints()[2].rawValue)),
                        "Adj. Rental": slice.dataPoints()[23].rawValue === "0" ? 0 : Number(slice.dataPoints()[23].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[23].rawValue)) : null,
                        "Leasing Factor": slice.dataPoints()[24].rawValue === "0" ? 0 : Number(slice.dataPoints()[24].rawValue) > 0 ? Math.round(10000 * Number(slice.dataPoints()[24].rawValue)) / 100 : null,
                        "Interest Rate": slice.dataPoints()[33].rawValue === "0" ? 0 + "%" : Number(slice.dataPoints()[33].rawValue) > 0 ? Number(100 * Number(slice.dataPoints()[33].rawValue)).toFixed(2) + "%" : null,
                        RV: slice.dataPoints()[25].rawValue === "0" ? 0 : Number(slice.dataPoints()[25].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[25].rawValue)) : null,
                        Discount: slice.dataPoints()[26].rawValue === "0" ? 0 : Number(slice.dataPoints()[26].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[26].rawValue)) : null,
                        "Finance Contribution": slice.dataPoints()[34].rawValue === "0" ? 0 : Number(slice.dataPoints()[34].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[34].rawValue)).toFixed(0) : null,
                        "Total Charges": slice.dataPoints()[35].rawValue === "0" ? 0 : Number(slice.dataPoints()[35].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[35].rawValue)).toFixed(0) : null,
                        "Total Payable": slice.dataPoints()[36].rawValue === "0" ? 0 : Number(slice.dataPoints()[36].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[36].rawValue)).toFixed(0) : null,
                        "OTR Price": slice.dataPoints()[32].rawValue === "0" ? 0 : Number(slice.dataPoints()[32].rawValue) > 0 ? Math.round(Number(slice.dataPoints()[32].rawValue)) : null,
                        Maintenance: calculateAdjustment(slice, 4, composer["flag_ser"]),
                        Tyres: calculateAdjustment(slice, 6, composer["flag_tyr"]),
                        Breakdown: calculateAdjustment(slice, 8, composer["flag_bre"]),
                        Replacement: calculateAdjustment(slice, 10, composer["flag_rep"]),
                        Insurance: calculateAdjustment(slice, 12, composer["flag_ins"]),
                        "Financial Loss": calculateAdjustment(slice, 14, composer["flag_fin"]),
                        "Winter Tyres": calculateAdjustment(slice, 16, composer["flag_win"]),
                        "EV Bonus": calculateAdjustment(slice, 18, composer["flag_evb"]),
                        "CO2 Malus": calculateAdjustment(slice, 20, composer["flag_co2"]),
                        "Web_Price Includes": Object.entries(services)
                            .filter((m) => m[1] === 1)
                            .map((m) => m[0])
                            .join(", "),
                    };
                })
                // Empty adj deposit
                .filter((elem) => (selectedAdjDeposit !== "Total Payable by month" ? elem["Adj. Deposit"] !== null : true));
            // Hide null or zero values
            const cleanedData = cleanEmptyValues(dataSource, columnsToBeCleaned);
            setData(cleanedData.map((m, i) => ({ key: i, ...m })));
            setColumns(
                Object.keys(cleanedData[0]).map((m) => ({
                    title: m,
                    fixed: ["Player", "Version"].includes(m) ? "left" : null,
                    dataIndex: m,
                    key: m,
                    width:
                        m === "Version"
                            ? 300
                            : m === "Link"
                            ? 90
                            : m === "Web_Price Includes"
                            ? dataSource
                                  .filter((elem) => elem["Web_Price Includes"].length > 0)
                                  .map((elem) => elem["Web_Price Includes"])
                                  .sort((a, b) => b.localeCompare(a))[0]?.length * 10 || 100
                            : 120,
                    align: m === "Version" ? "left" : "center",
                    render: (cell) => (
                        <div>
                            {m === "Player" ? (
                                <center>
                                    <img src={`/images/logos/Logo_${cell}.png`} width={70} height={70} alt={m} />
                                </center>
                            ) : m === "Link" ? (
                                cell ? (
                                    <center>
                                        <a href={cell} target="_blank" rel="noreferrer">
                                            <TfiWorld className="cursor-pointer w-4 h-4 text-indigo-500 hover:text-indigo-700" />
                                        </a>
                                    </center>
                                ) : null
                            ) : (
                                <Typography.Text style={{ fontSize: 11 }}>{cell}</Typography.Text>
                            )}
                        </div>
                    ),
                })),
            );
        }
    }, [result, kpiSettings]);

    return (
        <>
            {status === "loading" && (
                <div className="my-2">
                    <LoadingComponent />
                </div>
            )}

            {status === "error" && <ErrorComponent message="Unable to calculate your query" description={"Please verify if the selected vehicles are compatible with the active filters"} />}

            {status === "success" && (
                <>
                    {data.length > 0 && (
                        <div className="bg-white rounded-md overflow-hidden">
                            <Table dataSource={data} columns={columns} rowKey="key" size="small" pagination={false} scroll={{ x: columns.reduce((a, b) => a + b.width, 0), y: 400 }} />
                            <Servicesbanner kpiSettings={kpiSettings} />
                        </div>
                    )}
                </>
            )}
        </>
    );
}

const columnsToBeCleaned = ["Interest Rate", "RV", "Discount", "Finance Contribution", "Total Charges", "Total Payable", "Maintenance", "Breakdown", "Insurance", "Replacement", "Financial Loss", "CO2 Malus", "Tyres", "Winter Tyres", "EV Bonus"];
