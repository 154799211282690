import React, { useCallback, useEffect, useRef, useState } from "react";
import { ArrowDownIcon } from "../Icons/Arrows";
import { Menu } from "@headlessui/react";
import { FaRegCheckCircle } from "react-icons/fa";

const DropdownCheckbox = ({ items = [], title, ...props }) => {
    const [displayDropdown, setDisplayDropdown] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const ref = useRef();

    const clickOutsideTheBox = useCallback(
        (event) => {
            if (displayDropdown && !ref?.current?.contains(event.target)) {
                setDisplayDropdown(false);
            }
        },
        [displayDropdown, ref],
    );

    useEffect(() => {
        if (displayDropdown) {
            document.addEventListener("mousedown", clickOutsideTheBox);
        } else if (selectedItems.length > 0) props.onSelectItems([...selectedItems.map((obj) => obj.value)]);

        return () => {
            document.removeEventListener("mousedown", clickOutsideTheBox);
        };
    }, [displayDropdown, selectedItems]);

    return (
        <div ref={ref} className="relative">
            <button
                className="w-full flex items-end justify-between text-indigo-900 hover:bg-indigo-100  border border-indigo-500 rounded shadow-button font-semibold px-2.5 py-1"
                onClick={(e) => {
                    e.preventDefault();
                    setDisplayDropdown(!displayDropdown);
                }}
            >
                <span>{title}</span>
                <ArrowDownIcon width={20} height={20} />
            </button>
            {displayDropdown && (
                <div>
                    <ul className="absolute z-20 py-4 shadow-md bg-white w-full h-48 overflow-y-scroll">
                        {items.map((item) => {
                            const isSelected = selectedItems.find((obj) => obj.key === item.key) !== undefined;
                            return (
                                <li
                                    className={`flex items-center gap-2 hover:bg-indigo-100 py-1 px-2 cursor-pointer`}
                                    key={item.key}
                                    onClick={() => {
                                        const arr = selectedItems;
                                        const index = arr.findIndex((obj) => obj.key === item.key);
                                        if (index !== -1) arr.splice(index, 1);
                                        else arr.push(item);
                                        setSelectedItems([...arr]);
                                    }}
                                >
                                    <FaRegCheckCircle className={isSelected ? "text-indigo-500" : "text-gray-300"} />
                                    <label htmlFor={item.value} className="cursor-pointer">
                                        {item.label}
                                    </label>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

const Dropdown = ({ items = [], title, ...props }) => {
    const [btnTitle, setBtnTitle] = useState(title);
    return (
        <div className="relative">
            <Menu>
                <Menu.Button className={"w-full flex items-end justify-between text-indigo-900 hover:bg-indigo-100  border border-indigo-500 rounded shadow-button font-semibold px-2.5 py-1"}>
                    <label>{btnTitle}</label>
                    <ArrowDownIcon width={20} height={20} fill={"#0a0d79"} />
                </Menu.Button>
                <Menu.Items className="absolute top-5 py-4 shadow-md bg-white w-60 z-20">
                    {btnTitle !== title && (
                        <Menu.Item>
                            <li className="flex items-center gap-2 py-1 px-2 opacity-2 text-gray-300">{title}</li>
                        </Menu.Item>
                    )}
                    {items.map((item) => (
                        <Menu.Item>
                            {(obj) => (
                                <div
                                    className="hover:bg-indigo-100 py-1 px-2 cursor-pointer"
                                    onClick={() => {
                                        props.onSelect(item);
                                        setBtnTitle(item.label);
                                    }}
                                >
                                    {item.label}
                                </div>
                            )}
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Menu>
        </div>
    );
};

export { DropdownCheckbox, Dropdown };
