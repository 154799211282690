import React, { useContext, useEffect, useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { Button, message, Modal, Table, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined, ShareAltOutlined, TeamOutlined } from "@ant-design/icons";
import moment from "moment";
import { useBasketContext } from "../../contexts/BasketProvider";
import FiltersContext from "../../contexts/Filters";
import { Basket } from "../../types/Basket";
import { useUserContext } from "../../contexts/User";
import { DELETE_BASKET_MUTATION } from "../../queries/baskets";
import { useNotificationContext } from "../../contexts/NotificationProvider";
import { BasketShareModal } from "./BasketShareModal";

const EditableCell = ({ title, children, record, dataIndex, editable, handleSave = (values) => {}, ...restProps }) => {
    const [editing, setEditing] = useState(false);
    const [inputValue, setInputValue] = useState(record && record[dataIndex]);
    const inputRef = useRef(null);

    useEffect(() => {
        if (editing) {
            inputRef.current?.focus();
        }
    }, [editing]);

    const toggleEdit = () => {
        setEditing(!editing);
    };

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const save = () => {
        if (record && record[dataIndex] !== inputValue) {
            handleSave({
                [dataIndex]: inputValue,
            });
            toggleEdit();
        }
    };
    const childNode = editing ? (
        <div className="flex items-center gap-4">
            <input
                className="flex-1 p-1 focus:outline-indigo-300"
                ref={inputRef}
                type="text"
                defaultValue={inputValue}
                onChange={handleInputChange}
                onBlur={() => setEditing(false)}
                onKeyDown={(e) => {
                    e.keyCode === 13 && save();
                }}
            />
        </div>
    ) : (
        <div className={editable && "pr-12"} onClick={() => editable && toggleEdit()}>
            {children}
        </div>
    );

    return <td {...restProps}>{childNode}</td>;
};

const components = {
    body: {
        cell: EditableCell,
    },
};

function BasketsTable({ baskets = [], selectable = false, actionable = true, onSelectBaskets = (baskets) => {} }) {
    const [deleteBasket] = useMutation(DELETE_BASKET_MUTATION);
    const { editBasket, loadBasket, selectedBasketGroup, refetchUserBaskets, shareBasket, selectedBaskets, dropBasketUserPermission, removeAssignedUsersFromBasket, updateBasketAssignmentInfo } = useBasketContext();
    const { getFilterValue } = useContext(FiltersContext);
    const { user } = useUserContext();
    const { insertNotifications } = useNotificationContext();
    const countryValue = getFilterValue("filterCountryId")[0];
    const [ranking, setRanking] = useState([]);
    const [showShareModal, setShareModal] = useState(false);
    const [selectedBasket, setSelectedBasket] = useState(undefined);

    const basketTableActions = [
        {
            id: 2,
            title: "Edit basket",
            color: "#7F7F7F",
            icon: (
                <EditOutlined
                    style={{
                        color: "#7F7F7F",
                    }}
                />
            ),
            iconHandler: onLoad,
        },
        {
            id: 1,
            title: "Share basket",
            color: "#6B50B6",
            icon: (
                <ShareAltOutlined
                    style={{
                        color: "#6B50B6",
                    }}
                />
            ),
            iconHandler: onShareBtnClick,
        },
        {
            id: 3,
            title: "Delete basket",
            color: "#dc143c",
            icon: (
                <DeleteOutlined
                    style={{
                        color: "#dc143c",
                    }}
                />
            ),
            iconHandler: onDelete,
        },
        {
            id: 4,
            title: "Basket users",
            color: "#6B50B6",
            icon: (
                <TeamOutlined
                    style={{
                        color: "#6B50B6",
                    }}
                />
            ),
            iconHandler: displayBasketTeam,
        },
        {
            id: 5,
            title: "Drop basket",
            color: "#dc143c",
            icon: (
                <DeleteOutlined
                    style={{
                        color: "#dc143c",
                    }}
                />
            ),
            iconHandler: dropBasketPermission,
        },
    ];
    const columns = [
        {
            title: "Basket name",
            dataIndex: "name",
            key: "name",
            onCell: (record) => ({
                record,
                editable: record.type === "user",
                dataIndex: "name",
                handleSave: (values) => {
                    const setInput = {
                        ...values,
                    };
                    return editBasket(record.key, setInput);
                },
            }),
            render: (colValue, row) => (
                <Tooltip title={row?.description} color="#595959">
                    <span>{colValue}</span>
                </Tooltip>
            ),
        },
        {
            title: "Type",
            key: "basketType",
            render: (_, row) => {
                let basket = baskets.find((elem) => elem.id === row.key);
                let basketTypeComponent = <span>Personal</span>;
                if (basket.type === "system") {
                    basketTypeComponent = <span>Experteye</span>;
                } else if (basket.createdBy !== user?.auth0_id) {
                    basketTypeComponent = <span>Shared</span>;
                }
                return basketTypeComponent;
            },
        },
        // {
        //     title: "Group",
        //     dataIndex: "basketgroup",
        //     key: "basketgroup",
        //     onCell: (record) => ({
        //         record,
        //         editable: record.type === "user",
        //         dataIndex: "basketgroup",
        //         handleSave: (values) => {
        //             const setInput = {
        //                 ...values,
        //             };
        //             return editBasket(record.key, setInput);
        //         },
        //     }),
        // },
        {
            title: "Nb vehicles",
            dataIndex: "vehiclesCount",
            key: "vehiclesCount",
            align: "center",
        },
        {
            title: "Created at",
            dataIndex: "createdAt",
            key: "createdAt",
        },
        {
            title: "Last edit",
            dataIndex: "updatedAt",
            key: "updatedAt",
        },
        {
            title: "Printing Order",
            dataIndex: "ranking",
            key: "ranking",
            align: "center",
        },
        actionable
            ? {
                  title: "",
                  key: "actions",
                  render: (_, row) => {
                      let columnActions = basketTableActions;
                      let basket = baskets.find((elem) => elem.id === row.key);
                      columnActions = columnActions.filter((item) => (basket.type === "user" && (basket.createdBy !== user?.auth0_id ? [2, 4, 5].includes(item.id) : [1, 2, 3].includes(item.id))) || (basket.type === "system" && item.id === 2));
                      return (
                          <div className="flex gap-2">
                              {columnActions.map((action) => (
                                  <Tooltip title={action.title} color={action.color}>
                                      <Button
                                          key={action.id}
                                          type="text"
                                          onClick={() => {
                                              action.iconHandler(basket);
                                          }}
                                          icon={action.icon}
                                      />
                                  </Tooltip>
                              ))}
                          </div>
                      );
                  },
                  align: "center",
              }
            : {},
    ];

    const dataSource =
        baskets &&
        baskets
            .filter((basket) => selectedBasketGroup === undefined || basket.group === selectedBasketGroup)
            .map((basket) => {
                return Object.assign({
                    key: basket.id,
                    name: basket.name,
                    type: basket.type,
                    basketgroup: basket.group,
                    basketFilters: basket.basketFilters,
                    description: basket.description,
                    vehiclesCount: basket.basketFilters.filter((obj) => obj.type === "version_std").length,
                    createdAt: basket.createdAt && moment(basket.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    updatedAt: basket.updatedAt && moment(basket.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
                    printStatus: basket?.printStatus,
                    ranking: ranking.findIndex((elem) => elem.id === basket.id) > -1 ? ranking.findIndex((elem) => elem.id === basket.id) + 1 : "",
                });
            });

    function onDelete(basket) {
        Modal.confirm({
            title: "Are you sure you want to delete this basket?",
            content: "This action is irreversible",
            onCancel: () => {},
            onOk: () => {
                return deleteBasket({
                    variables: {
                        id: basket.id,
                    },
                }).then((res) => {
                    basket.clearVizData();
                    // Display a message
                    message.info({
                        content: "Basket had been deleted",
                        duration: 1,
                    });
                    // Notify assigned users
                    const assignedUsers = res.data.delete_re_rentaleye_basket_permissions.returning;
                    const messageNotif = `The basket "${basket.name}" had been deleted by ${user.username}`;
                    const notifObjs = assignedUsers.map((item) =>
                        Object.assign({
                            auth_user_id: item.basket_permissions_user.auth0_id,
                            message: messageNotif,
                            obj_id: basket.id,
                            obj_type: "re_rentaleye_baskets",
                            route: "/baskets",
                        }),
                    );
                    insertNotifications(notifObjs).then((res) => {
                        refetchUserBaskets({ country: countryValue });
                    });
                });
            },
        });
    }

    function dropBasketPermission(basket) {
        Modal.confirm({
            title: "Are you sure you want to remove this basket?",
            content: "This action is irreversible",
            onCancel: () => {},
            onOk: () => {
                return dropBasketUserPermission({
                    basket: basket,
                }).then((res) => {
                    basket.clearVizData();
                    // Display a message
                    message.info({
                        content: "Basket had been deleted",
                        duration: 1,
                    });
                    // Notify basket owner
                    const basketOwner = res.data.delete_re_rentaleye_basket_permissions.returning?.length > 0 && res.data.delete_re_rentaleye_basket_permissions.returning[0].basket_permissions_assigned_by;
                    const messageNotif = `${user.username} drop the basket "${basket.name}" from his list`;
                    const notifObjs = [
                        Object.assign({
                            auth_user_id: basketOwner?.auth0_id,
                            message: messageNotif,
                            obj_id: basket.id,
                            obj_type: "re_rentaleye_baskets",
                            route: "/baskets",
                        }),
                    ];
                    insertNotifications(notifObjs).then((res) => {
                        refetchUserBaskets({ country: countryValue });
                    });
                });
            },
        });
    }

    function onLoad(basket) {
        let vehicles = basket.basketFilters.filter(({ type, value }) => value && type === "version_std").map(({ type, value }) => Object.assign({ Vehicle: value }));
        loadBasket(basket, vehicles);
        const contentMessage = basket.createdBy === user.auth0_id ? `The ${basket.name} basket has been loaded` : "The basket has been copied. You can modify and save it with a new name";
        message.info({
            content: contentMessage,
            style: {
                right: "20px",
                top: "70px",
                position: "absolute",
            },
            duration: 1.5,
        });
    }

    function onShareBtnClick(basket) {
        setSelectedBasket(basket);
        setShareModal(true);
    }

    function onCloseShareModal() {
        setShareModal(false);
    }

    function displayBasketTeam(basket) {
        setSelectedBasket(basket);
        setShareModal(true);
    }

    return (
        <div className="flex flex-col shadow-md rounded-lg">
            <Table
                components={components}
                dataSource={dataSource}
                columns={columns}
                size="small"
                // key="name"
                // rowClassName={(record, index) => {
                //     return record.type === "system" ? "bg-gray-100" : "";
                // }}
                rowSelection={
                    selectable && {
                        selectedRowKeys: selectedBaskets.map((basket) => basket.id),
                        onChange: (selectedRowKey, selectedRows) => {
                            let initialBaskets = selectedBaskets.filter((basket) => basket.printStatus && selectedRowKey.includes(basket.id));
                            let baskets = selectedRows
                                .filter((row) => !initialBaskets.map((basket) => basket.id).includes(row.key))
                                .map(
                                    (row) =>
                                        new Basket({
                                            id: row.key,
                                            name: row.name,
                                            type: row.basketType,
                                            description: row.description,
                                            group: row.basketgroup,
                                            createdAt: row.created_at,
                                            updatedAt: row.updated_at,
                                            basketFilters: row.basketFilters,
                                        }),
                                );
                            setRanking([...initialBaskets, ...baskets]);
                            onSelectBaskets([...initialBaskets, ...baskets]);
                        },
                    }
                }
            />
            <BasketShareModal showModal={showShareModal} onCloseModal={onCloseShareModal} selectedBasket={selectedBasket} />
        </div>
    );
}

export default BasketsTable;
