import { createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { hasuraServerUri } from "../constants";

const httpLink = createHttpLink({
    uri: hasuraServerUri,
});

const authLink = setContext((_, { headers }) => {
    const token = localStorage.getItem("token");
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const apolloLink = authLink.concat(httpLink);

export { apolloLink };
