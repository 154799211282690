import React, { useState, Fragment, useEffect } from "react";
import { Combobox } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ArrowDownIcon } from "./Icons/Arrows";

export function ComboBoxInput({ title, dataElements = [], defaultElement = undefined, onSelect }) {
    const [item, setSelectedItem] = useState(defaultElement);
    const [query, setQuery] = useState("");

    const filterElements = dataElements?.filter((elem) => elem?.includes(query));

    useEffect(() => {
        onSelect(item);
    }, [item]);

    useEffect(() => {
        onSelect(defaultElement);
    }, [defaultElement]);

    return (
        <Combobox
            value={item}
            onChange={(obj) => {
                setSelectedItem(obj);
            }}
        >
            <div className="relative mt-1">
                <div className="flex gap-2 items-center">
                    <Combobox.Input
                        onChange={(event) => {
                            setQuery(event.target.value);
                        }}
                        displayValue={(itemInput) => (item ? item : "")}
                        className="w-[200px] rounded-md border border-indigo-700 bg-white py-1 pl-3 shadow-sm focus:border-indigo-700 focus:outline-none focus:ring-1 focus:ring-indigo-700 sm:text-sm"
                    />

                    {item && (
                        <CloseCircleOutlined
                            style={{
                                color: "#ff7d93",
                            }}
                            onClick={() => setSelectedItem("")}
                        />
                    )}
                </div>
                <Combobox.Button className="absolute inset-y-0 ml-40  flex items-center rounded-r-md px-2 focus:outline-none">
                    <ArrowDownIcon width={20} height={15} fill={"#0a0d79"} />
                </Combobox.Button>
                {filterElements.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        <Combobox.Option disabled className="px-2 font-semibold">
                            {title}
                        </Combobox.Option>
                        {filterElements.map((elem, index) => (
                            <Combobox.Option key={index} value={elem} as={Fragment} className={({ active }) => (active ? "bg-backgroundColor" : "") + " cursor-default py-2 px-3"}>
                                {({ active, selected }) => {
                                    return (
                                        <div className="flex">
                                            <span className="flex justify-between">
                                                {elem}{" "}
                                                {selected && (
                                                    <div>
                                                        <CheckIcon className="h-5 w-5" color="#490fcc" />
                                                    </div>
                                                )}
                                            </span>
                                        </div>
                                    );
                                }}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
}
