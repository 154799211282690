import React, { useEffect, useState } from "react";
import { gql, useMutation } from "@apollo/client";
import { LoadingOutlined } from "@ant-design/icons";
import { message, Spin } from "antd";

import { GET_BASKETS, useBasketContext } from "../contexts/BasketProvider";
import { useHistory } from "react-router-dom";
import { CreateBasketForm } from "../components/Baskets/CreateBasketForm";

const UPDATE_BASKET_MUTATION = gql`
    mutation updateBasket($id: uuid!, $name: String!, $basketgroup: String, $description: String, $filters: [filters_insert_input!]!) {
        update_baskets_filters_by_pk(pk_columns: { id: $id }, _set: { description: $description, basketgroup: $basketgroup, name: $name }) {
            id
        }
        delete_filters(where: { basket_id: { _eq: $id } }) {
            returning {
                basket_id
            }
        }

        insert_filters(objects: $filters) {
            returning {
                id
            }
        }
    }
`;

const LoadingIcon = <LoadingOutlined style={{ fontSize: 24 }} />;

export function EditBasketView({ vehicles = [] }) {
    const [updateBasket] = useMutation(UPDATE_BASKET_MUTATION);
    const basketContext = useBasketContext();
    const history = useHistory();
    let [formStatus, setFormStatus] = useState("");

    let basket = {
        name: basketContext.basket ? basketContext.basket.name : "",
        description: basketContext.basket ? basketContext.basket.description : "",
        basketgroup: basketContext.basket ? basketContext.basket.basketgroup : "",
        vehicles: vehicles.length > 0 ? vehicles : basketContext.vehicles,
    };

    useEffect(() => {
        if (formStatus === "success") {
            basketContext.emptyBasket();
            setTimeout(() => {
                history.push("/baskets");
            }, 1000);
        }
    }, [formStatus]);

    function submitForm(values) {
        let filters = values.vehicles.map((vehicle) =>
            Object.assign({
                type: "version_std",
                value: vehicle.Vehicle,
                basket_id: basketContext.basket.id,
            }),
        );
        let mutationVariables = {
            id: basketContext.basket.id,
            name: values.name,
            basketgroup: values.basketgroup,
            description: values.description,
            filters: filters,
        };
        setFormStatus("submit");
        updateBasket({
            variables: mutationVariables,
            refetchQueries: [
                {
                    query: GET_BASKETS,
                },
            ],
        }).then((res) => {
            setFormStatus("success");
            message.success({
                content: "Your basket had been updated",
                style: {
                    top: "80px",
                    right: "20px",
                    position: "absolute",
                },
                duration: 2,
            });
        });
    }

    let form = <CreateBasketForm initialValues={basket} formHandler={submitForm} />;
    return formStatus === "submit" ? <Spin indicator={LoadingIcon}>{form}</Spin> : form;
}
