import { gql } from "@apollo/client";

const INSERT_NOTIFICATIONS = gql`
    mutation insertNotifications($data: [re_rentaleye_notifications_insert_input!]!) {
        insert_re_rentaleye_notifications(objects: $data) {
            returning {
                id
            }
        }
    }
`;

const GET_NOTIFICATIONS = gql`
    query getNotifications {
        re_rentaleye_notifications(order_by: { created_at: desc }) {
            id
            message
            is_opened
            created_at
            obj_id
            obj_type
            route
            metadata
        }
    }
`;

const GET_NOTIFICATION_BY_EMAIL = gql`
    query getNotification($email: String) {
        re_rentaleye_user_notifications(where: { receiver_email: { _eq: $email } }) {
            message_id
            message_text
            opened
            opened_at
            receiver_email
        }
    }
`;

const UPDATE_USER_NOTIFICATIONS = gql`
    mutation updateUserNotifications($data: re_rentaleye_user_notifications_set_input!, $id: uuid!) {
        update_re_rentaleye_user_notifications(_set: $data, where: { message_id: { _eq: $id } }) {
            returning {
                message_id
            }
        }
    }
`;

const UPDATE_NOTIFICATION = gql`
    mutation updateNotification($data: re_rentaleye_notifications_set_input!, $id: uuid!) {
        update_re_rentaleye_notifications(_set: $data, where: { id: { _eq: $id } }) {
            returning {
                id
            }
        }
    }
`;

export { INSERT_NOTIFICATIONS, GET_NOTIFICATIONS, UPDATE_NOTIFICATION, GET_NOTIFICATION_BY_EMAIL, UPDATE_USER_NOTIFICATIONS };
