import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import UserContext from "../../contexts/User";
import { Tooltip } from "antd";
import { useAppContext } from "../../contexts/AppContext";
import "../../styles/sidebar.css";
import { BasketIcon, BrandModelIcon, CarSelectorIcon, CompetitivenessIcon, DashboardIcon, DataCoverageIcon, ExportIcon, PromotionIcon, TrendsIcon } from "../Icons/SideBarIcon";
import { LatestUpdateIcon, SettingsIcon } from "../Icons/MenuIcons";

const navigation = [
    { name: "My Dashboard", href: "/", icon: DashboardIcon, current: false, colorStyle: "fill" },
    { name: "Trends", href: "/trends", icon: TrendsIcon, current: false, colorStyle: "fill" },
    { name: "Car Selector", href: "/vehicles", icon: CarSelectorIcon, current: false, colorStyle: "fill" },
    { name: "Brand and model analysis", href: "/brand_model_analysis", icon: BrandModelIcon, current: false, colorStyle: "fill" },
    { name: "Promotions", href: "/oemoffers", icon: PromotionIcon, current: false, colorStyle: "stroke" },
    { name: "Baskets", href: "/baskets", icon: BasketIcon, current: false, colorStyle: "fill" },
    { name: "Competitiveness", href: "/competitiveness", icon: CompetitivenessIcon, current: false, colorStyle: "stroke" },
    { name: "Export", href: "/export", icon: ExportIcon, current: false, colorStyle: "stroke" },
    { name: "Data Coverage", href: "/info", icon: DataCoverageIcon, current: false, colorStyle: "fill" },
    { name: "Settings", icon: SettingsIcon, href: "/settings", dimension: "w-6 h-6", insideProfileMenu: true },
    { name: "Latest Updates", icon: LatestUpdateIcon, href: "/news", insideProfileMenu: true },
];

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

const Sidebar = () => {
    const location = useLocation();
    const { defaultUserPref } = useContext(UserContext);
    const { isSideBarCollapsed } = useAppContext();
    const [links, setLinks] = useState(initLinks(navigation));
    const ref = useRef(undefined);

    useEffect(() => {
        if (defaultUserPref?.fl_demo === 0) {
            let index = links.findIndex((elem) => elem.name === "Export");
            links[index].disabled = true;
            setLinks([...links]);
        }
    }, [defaultUserPref?.fl_demo]);

    useEffect(() => {
        if (!isSideBarCollapsed && ref?.current.classList.contains("sidebar")) {
            ref?.current.classList.remove("sidebar");
        }
    }, [ref]);

    function initLinks(navArr) {
        if (defaultUserPref?.fl_demo === 0) {
            let index = navArr.findIndex((elem) => elem.name === "Export");
            navArr[index].disabled = true;
        }
        return navArr;
    }

    return (
        <div ref={ref} className={`max-md:hidden md:inset-y-0 md:fixed md:min-h-screen border-r border-gray-200 pt-8 px-3 ${!isSideBarCollapsed ? "sidebar" : ""}`}>
            {/* Sidebar component, swap this element with another sidebar if you like */}
            {/* <div className="flex flex-col "> */}
            {/* {isSideBarCollapsed ? 
                <img src="/images/experteye-logo.png" alt="Experteye Logo" width="130" height="130" />
                : <img src="/images/experteye-logo-white.png" alt="Experteye Logo" width="130" height="130" />
                } */}
            <nav className={`pt-28 flex flex-col gap-3 items-center px-2`}>
                {navigation
                    .filter((nav) => !nav.insideProfileMenu)
                    .map((item) => {
                        const linkStyle =
                            `${["/", "/info"].includes(item.href) ? "w-7 h-7" : "w-6 h-6"} fill-current ` +
                            `${item.disabled ? "fill-gray-300 opacity-75 hover:fill-gray-100" : location.pathname === item.href ? `${item.colorStyle}-secondary-300 hover:${item.colorStyle}-secondary-300` : `${item.colorStyle}-indigo-700 hover:${item.colorStyle}-indigo-500`}`;
                        // const linkStyle = `w-6 h-6 text-indigo-700`;
                        const linkIcon = React.createElement(item.icon, { className: linkStyle, color: location.pathname === item.href ? "#ff7d93" : "#3a0ca3" });
                        return (
                            <Link key={item.name} to={!item.disabled && defaultUserPref ? item.href : null}>
                                {isSideBarCollapsed ? (
                                    <Tooltip
                                        title={item.name}
                                        placement="right"
                                        color="#EEF1FF"
                                        zIndex={100}
                                        overlayInnerStyle={{
                                            fontSize: "18px",
                                            fontWeight: 600,
                                            color: "#03071e",
                                            boxShadow: "1px 1px 3px #506e96",
                                        }}
                                    >
                                        <span className="mr-1 self-stretch" aria-hidden="true">
                                            {linkIcon}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span className="mr-1 self-stretch" aria-hidden="true">
                                        {linkIcon}
                                    </span>
                                )}
                                {!isSideBarCollapsed && item.name}
                            </Link>
                        );
                    })}
                {/* <div className="flex flex-col ml-3 py-2">
                        <a href={`mailto:${supportEmail}`} className="text-white hover:text-secondary-300 group flex text-sm mb-2 font-medium rounded-md ">
                            <QuestionMarkCircleIcon aria-hidden="true" className="mr-2 flex-shrink-0 h-5 w-5" />
                            Support
                        </a>
                        <p className="text-white text-xs font-semibold">
                            © 2024{" "}
                            <a className="text-white hover:text-secondary-300" href="https://www.experteye.com" target="_blank" rel="noreferrer">
                                Experteye
                            </a>
                        </p>
                    </div> */}
                {/* <button onClick={() => setModalOpen(true)} className={classNames("text-white-600 hover:bg-gray-50 hover:text-gray-900", "group flex items-center px-2 py-2 text-sm font-medium rounded-md mb-2 mx-3")}>
                                    <ScaleIcon className={classNames("text-white-400 group-hover:text-gray-500", "mr-3 flex-shrink-0 h-6 w-6")} aria-hidden="true" />
                                    SETTINGS
                                </button> */}
            </nav>
            {/* </div> */}
        </div>
    );
};

export { Sidebar, navigation };
