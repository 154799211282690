import { gql } from "@apollo/client";

const GET_USER_DETAIL = gql`
    query getUserDetail {
        re_rentaleye_users {
            username
            catalogue_by_version
            news_visible
        }
    }
`;

// Get user by email
const GET_USER_BY_EMAIL = gql`
    query getUserByEmail($email: String) {
        re_rentaleye_users(where: { username: { _eq: $email } }) {
            id
            auth0_id
            username
            catalogue_by_version
            news_visible
            enable_multiselection
            basket_sections
        }
    }
`;

const GET_FILTER_USER_BY_EMAIL_LIST = gql`
    query getUserByEmail($emailList: [String]) {
        re_rentaleye_users(where: { username: { _in: $emailList } }) {
            id
            auth0_id
            username
            catalogue_by_version
            news_visible
        }
    }
`;
const UPDATE_USER_DETAIL = gql`
    mutation updateUser($args: re_rentaleye_users_set_input) {
        update_re_rentaleye_users(where: {}, _set: $args) {
            returning {
                catalogue_by_version
                news_visible
            }
        }
    }
`;

const UPDATE_SCOPE_SETTINGS = gql`
    mutation updateSettings($key: String, $args: re_rentaleye_re_params_user_prefs_set_input) {
        update_re_rentaleye_re_params_user_prefs(where: { key: { _eq: $key } }, _set: $args) {
            returning {
                fl_rw
            }
        }
    }
`;

const GET_ALL_USER_PREFERENCES = gql`
    query getScopes {
        re_rentaleye_re_params_user_prefs {
            key
            ctryid
            duration
            channel1
            mth_code
            login
            customer_slot_1
            customer_slot_2
            customer_slot_3
            customer_slot_4
            customer_slot_5
            customer_slot_6
            customer_slot_7
            customer_slot_8
            fl_default
            fl_rw
            fl_demo
            flag_ser
            flag_tyr
            flag_bre
            flag_rep
            flag_ins
            flag_fin
            flag_win
            flag_co2
            flag_evb
        }
    }
`;

// User prefs provided by the system
const GET_ALL_USER_PREFERENCES_SYSTEM = gql`
    query getSystemScopes {
        re_rentaleye_re_params_user_prefs_system {
            key
            ctryid
            duration
            channel1
            mth_code
            customer_slot_1
            customer_slot_2
            customer_slot_3
            customer_slot_4
            customer_slot_5
            customer_slot_6
            customer_slot_7
            customer_slot_8
            fl_default
            fl_rw
            fl_demo
            login
            flag_ser
            flag_tyr
            flag_bre
            flag_rep
            flag_ins
            flag_fin
            flag_win
            flag_co2
            flag_evb
        }
    }
`;

const ENABLED_SETTINGS = gql`
    query ($ctryid: String, $channel1: String) {
        re_rentaleye_re_services_settings(where: { ctryid: { _eq: $ctryid }, channel: { _eq: $channel1 }, category: { _eq: "CarInfo" } }) {
            flag_ser
            flag_bre
            flag_ins
            flag_rep
            flag_fin
            flag_co2
            flag_tyr
            flag_win
            flag_evb
        }
    }
`;
const GET_RE_RULES_UPDATES = gql`
    query ($ctryid: String!, $channel1: String!) {
        re_rentaleye_re_rules_updates(where: { ctryid: { _in: [$ctryid, "*"] }, channel1: { _in: [$channel1, "*"] } }, order_by: { released_at: desc }) {
            maintenancetext
            updatetext
            vatrules
            release_section
            release_type
            released_at
        }
    }
`;

const GET_CONTEXT_USER_PREFERENCES = gql`
    query ($ctryid: String, $channel1: String) {
        re_rentaleye_re_params_user_prefs(where: { ctryid: { _eq: $ctryid }, channel1: { _eq: $channel1 } }) {
            ctryid
            duration
            channel1
            mth_code
            customer_slot_1
            customer_slot_2
            customer_slot_3
            customer_slot_4
            customer_slot_5
            customer_slot_6
            customer_slot_7
            customer_slot_8
        }
    }
`;

const GET_KPI_ADJ_SETTINGS = gql`
    query ($ctryid: String, $channel1: String) {
        re_rentaleye_re_params_user_prefs(where: { ctryid: { _eq: $ctryid }, channel1: { _eq: $channel1 } }) {
            flag_ser
            flag_tyr
            flag_bre
            flag_rep
            flag_ins
            flag_fin
            flag_win
            flag_co2
            flag_evb
        }
    }
`;

const INSERT_EVENT_LOGS = gql`
    mutation InsertEventsLog($login: String, $section_name: String) {
        insert_re_rentaleye_events_log(objects: { login: $login, section_name: $section_name }) {
            affected_rows
        }
    }
`;

// Insert or update user prefs
const UPSERT_USER_PREFS = gql`
    mutation upsetUserPrefs($object: re_rentaleye_re_params_user_prefs_insert_input!) {
        insert_re_rentaleye_re_params_user_prefs_one(
            object: $object
            on_conflict: {
                constraint: re_params_user_prefs_pk
                update_columns: [fl_rw, flag_bre, flag_fin, flag_co2, flag_evb, flag_ins, flag_rep, flag_ser, flag_tyr, flag_win, customer_slot_1, customer_slot_2, customer_slot_3, customer_slot_4, customer_slot_5, customer_slot_6, customer_slot_7, customer_slot_8]
            }
        ) {
            key
        }
    }
`;

const INSERT_BULK_USER_PREFS = gql`
    mutation insertBulkUserPrefs($objects: [re_rentaleye_re_params_user_prefs_insert_input!]!) {
        insert_re_rentaleye_re_params_user_prefs(objects: $objects) {
            returning {
                key
            }
        }
    }
`;

const UPDATE_CUSTOMER_SLOTS_SETTINGS = gql`
    mutation updateCustomerSlots($country: String, $channel1: String, $customer_slot_1: String, $customer_slot_2: String, $customer_slot_3: String, $customer_slot_4: String, $customer_slot_5: String, $customer_slot_6: String, $customer_slot_7: String, $customer_slot_8: String) {
        update_re_rentaleye_re_params_user_prefs(
            where: { ctryid: { _eq: $country }, channel1: { _eq: $channel1 } }
            _set: { customer_slot_1: $customer_slot_1, customer_slot_2: $customer_slot_2, customer_slot_3: $customer_slot_3, customer_slot_4: $customer_slot_4, customer_slot_5: $customer_slot_5, customer_slot_6: $customer_slot_6, customer_slot_7: $customer_slot_7, customer_slot_8: $customer_slot_8 }
        ) {
            returning {
                customer_slot_1
                customer_slot_2
                customer_slot_3
                customer_slot_4
                customer_slot_5
                customer_slot_6
                customer_slot_7
                customer_slot_8
            }
        }
    }
`;

export {
    UPDATE_USER_DETAIL,
    GET_CONTEXT_USER_PREFERENCES,
    GET_ALL_USER_PREFERENCES_SYSTEM,
    GET_KPI_ADJ_SETTINGS,
    GET_ALL_USER_PREFERENCES,
    GET_USER_DETAIL,
    ENABLED_SETTINGS,
    GET_RE_RULES_UPDATES,
    INSERT_EVENT_LOGS,
    UPSERT_USER_PREFS,
    INSERT_BULK_USER_PREFS,
    GET_USER_BY_EMAIL,
    GET_FILTER_USER_BY_EMAIL_LIST,
    UPDATE_SCOPE_SETTINGS,
    UPDATE_CUSTOMER_SLOTS_SETTINGS,
};
